import { RequestApi } from "@omnijus/common";
import { CLIENT_ID } from "../../services/client-id";
import { FiltroArea } from "../../services/filtros/models/filtro-area";
import { FiltroForo } from "../../services/filtros/models/filtro-foro";
import { FiltroOrgao } from "../../services/filtros/models/filtro-orgao";
import { FiltroSecao } from "../../services/filtros/models/filtro-secao";

const urlBaseConsulta = process.env.REACT_APP_BFF_OPERACIONAL_API_URL + "/api/Dominio/v1/Consulta";

const requestApi = new RequestApi(CLIENT_ID);

export const DominioService = {
    consultarArea: async (id?: number) => {
        const url = new URL(`${urlBaseConsulta}/Area`);

        if (id !== undefined) {
            url.pathname = url.pathname.concat(`/${id}`);
        }

        return await requestApi.get<Array<FiltroArea>>(url.toString());
    },
    consultarOrgao: async (params?: { id?: string; idsAreas?: Array<number>; uf?: string }) => {
        const url = new URL(`${urlBaseConsulta}/Orgao`);

        if (params?.id !== undefined) {
            url.pathname = url.pathname.concat(`/${params.id}`);
        }

        if (params?.idsAreas !== undefined && params?.idsAreas.length > 0) {
            params.idsAreas.forEach((id) => {
                url.searchParams.append("idsAreas", id.toString());
            });
        }

        if (params?.uf !== undefined) {
            url.searchParams.set("uf", params.uf);
        }

        return await requestApi.get<Array<FiltroOrgao>>(url.toString());
    },
    consultarOrgaoUnidade: async (params?: { id?: string; idsOrgaos?: Array<string>; nomeComarca?: string }) => {
        const url = new URL(`${urlBaseConsulta}/OrgaoUnidade`);

        if (params?.id !== undefined) {
            url.pathname = url.pathname.concat(`/${params.id}`);
        }

        if (params?.idsOrgaos !== undefined && params?.idsOrgaos.length > 0) {
            params.idsOrgaos.forEach((id) => {
                url.searchParams.append("idsOrgaos", id);
            });
        }

        if (params?.nomeComarca !== undefined) {
            url.searchParams.set("nomeComarca", params.nomeComarca);
        }

        return await requestApi.get<Array<FiltroForo>>(url.toString());
    },
    consultarOrgaoUnidadeSecao: async (params?: { id?: string; idsOrgaosUnidades?: Array<string> }) => {
        const url = new URL(`${urlBaseConsulta}/OrgaoUnidadeSecao`);

        if (params?.id !== undefined) {
            url.pathname = url.pathname.concat(`/${params.id}`);
        }

        return await requestApi.post<Array<FiltroSecao>>(url.toString(), params);
    },
};
