import { Loading, OmnijusCard } from "@omnijus/common";
import { format } from "date-fns";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { ReactComponent as UploadIcon } from "../../assets/images/upload-icon.svg";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import { Movimento } from "../../services/agendamento/models/movimento";
import { ProcessoService } from "../../services/processo/processo-service";
import { ReactComponent as DocumentIcon } from "../../assets/images/document-icon.svg";
import { Link } from "react-router-dom";
import { ModalIncluirDocumentosEmUmMovimento } from "../../pages/atualizacao-processos/modal-incluir-documentos-movimento";
import { TipoDocumentoService } from "../../services/tipo-documento/tipo-documento-service";
import { BotaoDownloadDocumentosMovimento } from "../../shared/buttons/botao-download-documentos-movimento";
import styles from "./movimentos-processo.module.scss";

export interface MovimentosProcessoProps {
    idProcesso: string;
    movimentos?: Movimento[] | undefined;
    numeroProcesso?: string;
    exibirBotaoUpload?: boolean;
    actions?: ReactNode | ReactNode[];
}

export const MovimentosProcesso = ({
    idProcesso,
    movimentos,
    numeroProcesso,
    exibirBotaoUpload,
    actions,
}: MovimentosProcessoProps) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [movimentoSelecionado, setMovimentoSelecionado] = useState<Movimento>();
    const [promise, setPromise] = useState<Promise<Movimento[] | undefined>>();
    const [tiposDocumento, setTiposDocumento] = useState<
        {
            id: number;
            value: string;
        }[]
    >();

    const columns = useMemo<IDataTableColumn<Movimento>[]>(
        () => [
            {
                name: "Data",
                minWidth: "11rem",
                selector: (row) => format(new Date(row.data), "dd/MM/yyyy HH:mm"),
                sortable: true,
                grow: 0,
            },
            {
                name: "Fase",
                selector: (row) => row.fase,
                sortable: true,
                grow: 0,
            },
            {
                name: "Movimentação",
                wrap: true,
                selector: (row) => row.movimentacao,
                sortable: true,
            },
            {
                button: true,
                name: "Documento(s)",
                grow: 0,
                selector: (row) =>
                    row.documentos?.length > 1 ? (
                        <BotaoDownloadDocumentosMovimento
                            idProcesso={idProcesso}
                            idProcessoMovimento={row.idProcessoMovimento}
                        />
                    ) : row.documentos?.length === 1 ? (
                        <Link to={{ pathname: row.documentos[0].path }} target="_blank">
                            <DocumentIcon title={row.documentos[0].nome} />
                        </Link>
                    ) : (
                        "-"
                    ),
                width: "7rem",
            },
            {
                button: true,
                name: "Upload",
                selector: (row) => (
                    <UploadIcon
                        style={{ cursor: "pointer", width: 17.5 }}
                        title="Clique para adicionar documentos ao movimento"
                        onClick={() => {
                            setMovimentoSelecionado(row);
                            setModalOpen(true);
                        }}
                    />
                ),
                grow: 0,
                omit: !tiposDocumento,
            },
        ],
        [idProcesso, tiposDocumento]
    );

    useEffect(() => {
        if (movimentos) {
            setPromise(Promise.resolve(movimentos));
        } else {
            setPromise(ProcessoService.obterMovimentos(idProcesso));
        }
    }, [idProcesso, movimentos]);

    useEffect(() => {
        (async () => {
            exibirBotaoUpload && setTiposDocumento(await TipoDocumentoService.listarCombo());
        })();
    }, [exibirBotaoUpload]);

    return (
        <>
            <Loading promise={promise}>
                {(movimentos) => (
                    <OmnijusCard
                        header={
                            <div className={styles.header}>
                                <h3>Movimentos</h3>
                                {actions}
                            </div>
                        }
                        body={
                            <DataTable
                                noHeader={true}
                                noDataComponent={<p>A consulta não retornou registros</p>}
                                data={movimentos || []}
                                columns={columns}
                                style={{ maxHeight: "22rem", overflowY: "auto" }}
                            />
                        }
                    />
                )}
            </Loading>

            {tiposDocumento && movimentoSelecionado && numeroProcesso && (
                <ModalIncluirDocumentosEmUmMovimento
                    isModalOpen={isModalOpen}
                    onCloseModal={() => setModalOpen(false)}
                    tiposDocumento={tiposDocumento}
                    numeroProcesso={numeroProcesso}
                    movimento={movimentoSelecionado}
                    idProcesso={idProcesso}
                />
            )}
        </>
    );
};
