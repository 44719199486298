import React from "react";
import { LinhaTempoAudiencia } from "../../../services/audiencia/models/linha-tempo-audiencia";
import styles from "./detalhamento-audiencia.module.scss";
import { ReactComponent as CheckIcon } from "../../../assets/images/green-checkmark-icon.svg";
import { format } from "date-fns";
import { Loading, OmnijusCard } from "@omnijus/common";
import { useState } from "react";
import { useEffect } from "react";
import { AudienciaService } from "../../../services/audiencia/audiencia-service";

interface DetalhamentoAudienciaLinhaTempoProps {
    idAudiencia: string;
}

export const DetalhamentoAudienciaLinhaTempo = (props: DetalhamentoAudienciaLinhaTempoProps) => {
    const [promise, setPromise] = useState<Promise<LinhaTempoAudiencia | undefined>>();

    useEffect(() => {
        setPromise(AudienciaService.obterLinhaTempo(props.idAudiencia));
    }, [props.idAudiencia]);

    return (
        <OmnijusCard 
            header={<h3>Acompanhamento</h3>}
            body={
                <Loading promise={promise}>
                    {linhaTempo =>
                        linhaTempo === undefined ?
                            <p>Não encontrado</p> 
                            :
                            <ul className={styles["etapas"]}>
                                <li className={styles["item"]}>
                                    Inserção no sistema 
                                    {
                                        linhaTempo.criacao ?
                                            <span className={styles["highlight"]}>
                                                {format(linhaTempo.criacao, "dd/MM/yyyy HH:mm")}
                                            </span>
                                            :
                                            null
                                    }
                                    <CheckIcon className={linhaTempo.criacao ? styles["confirmed"] : ""} />
                                </li>
                                <li className={styles["item"]}>
                                    Geração da contestação 
                                    {
                                        linhaTempo.geracaoContestacao ?
                                            <span className={styles["highlight"]}>
                                                {format(linhaTempo.geracaoContestacao, "dd/MM/yyyy HH:mm")}
                                            </span>
                                            :
                                            null
                                    }
                                    <CheckIcon className={linhaTempo.geracaoContestacao ? styles["confirmed"] : ""} />
                                </li>
                                <li className={styles["item"]}>
                                    Integração com o parceiro 
                                    {
                                        linhaTempo.integracaoParceiro ?
                                            <span className={styles["highlight"]}>
                                                {format(linhaTempo.integracaoParceiro, "dd/MM/yyyy HH:mm")}
                                            </span>
                                            :
                                            null
                                    }
                                    <CheckIcon className={linhaTempo.integracaoParceiro ? styles["confirmed"] : ""} />
                                </li>
                                <li className={styles["item"]}>
                                    Avaliação dos executores 
                                    {
                                        linhaTempo.avaliacaoExecutores ?
                                            <span className={styles["highlight"]}>
                                                {format(linhaTempo.avaliacaoExecutores, "dd/MM/yyyy HH:mm")}
                                            </span>
                                            :
                                            null
                                    }
                                    <CheckIcon className={linhaTempo.avaliacaoExecutores ? styles["confirmed"] : ""} />
                                </li>
                                <li className={styles["item"]}>
                                    Geração dos documentos 
                                    {
                                        linhaTempo.geracaoDocumentos ?
                                            <span className={styles["highlight"]}>
                                                {format(linhaTempo.geracaoDocumentos, "dd/MM/yyyy HH:mm")}
                                            </span>
                                            :
                                            null
                                    }
                                    <CheckIcon className={linhaTempo.geracaoDocumentos ? styles["confirmed"] : ""} />
                                </li>
                                <li className={styles["item"]}>
                                    Aprovação dos documentos 
                                    {
                                        linhaTempo.aprovacaoDocumentos ?
                                            <span className={styles["highlight"]}>
                                                {format(linhaTempo.aprovacaoDocumentos, "dd/MM/yyyy HH:mm")}
                                            </span>
                                            :
                                            null
                                    }
                                    <CheckIcon className={linhaTempo.aprovacaoDocumentos ? styles["confirmed"] : ""} />
                                </li>
                                <li className={styles["item"]}>
                                    Realização da audiência 
                                    {
                                        linhaTempo.realizacaoAudiencia ?
                                            <span className={styles["highlight"]}>
                                                {format(linhaTempo.realizacaoAudiencia, "dd/MM/yyyy HH:mm")}
                                            </span>
                                            :
                                            null
                                    }
                                    <CheckIcon className={linhaTempo.realizacaoAudiencia ? styles["confirmed"] : ""} />
                                </li>
                                <li className={styles["item"]}>
                                    Recebimento da ata 
                                    {
                                        linhaTempo.recebimentoAta ?
                                            <span className={styles["highlight"]}>
                                                {format(linhaTempo.recebimentoAta, "dd/MM/yyyy HH:mm")}
                                            </span>
                                            :
                                            null
                                    }
                                    <CheckIcon className={linhaTempo.recebimentoAta ? styles["confirmed"] : ""} />
                                </li>
                            </ul>
                    }
                </Loading>
            }
        />
    );
}