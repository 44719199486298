import React from "react";
import DownloadIcon from "../../../assets/images/download-icon.png";
import "./button-export-csv.scss";

interface ExportarProps {
    onClick: () => void;
    texto?: string;
}

export const Exportar = (props: ExportarProps) => (
    <button className="exportar-csv" onClick={props.onClick}>
        <img alt="Ícone de download" src={DownloadIcon} />
        <span className="texto">{props.texto || "Exportar"}</span>
    </button>
);
