import React from "react";
import { OmnijusTextField } from "../fields/omnijus-text-field";

interface OmnijusFiltroCpfCnpjProps {
    disabled?: boolean;
    hideLabel?: boolean;
    name: string;
    label: string;
}

export const OmnijusFiltroCpfCnpj = (props: OmnijusFiltroCpfCnpjProps) => {
    return (
        <OmnijusTextField
            name={props.name}
            label={props.hideLabel ? undefined : props.label}
            placeholder="CPF / CNPJ"
            disabled={props.disabled}
            masks={[{ mask: "###.###.###-##" }, { mask: "##.###.###/####-##" }]}
        />
    );
};
