import { Back, ButtonPrimary, ButtonSecondary, Loading } from "@omnijus/common";
import { Form, Formik } from "formik";
import { ActionFeedback } from "@omnijus/common";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { AudienciaService } from "../../../services/audiencia/audiencia-service";
import { InformacoesPoliticaEProcesso } from "../../../services/audiencia/models/informacoes-politica-processo";
import { ParametroEdicaoAudiencia } from "../../../services/audiencia/models/parametro-edicao-audiencia";
import { EdicaoAudienciaDadosParceiro } from "./edicao-audiencia-dados-parceiro";
import { EdicaoAudienciaEnderecamento } from "./edicao-audiencia-enderecamento";
import { EdicaoAudienciaPrincipal } from "./edicao-audiencia-principal";
import { validationSchema } from "./edicao-audiencia-validation-schema";
import styles from "./edicao-audiencia.module.scss";

export type FormularioEdicaoAudiencia = Partial<ParametroEdicaoAudiencia>;

export const EdicaoAudiencia = () => {
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const [promise, setPromise] = useState<Promise<InformacoesPoliticaEProcesso | undefined>>();

    const onCancelar = async () => {
        const confirmed = await ActionFeedback.confirm(
            "Deseja mesmo cancelar a edição da audiência?",
            "Cancelar a edição da audiência",
            "Continuar a edição"
        );

        if (confirmed) {
            history.goBack();
        }
    };

    useEffect(() => {
        setPromise(
            AudienciaService.obterInformacoesPoliticaProcesso({
                idProcessoAudiencia: id,
            })
        );
    }, [id]);

    return (
        <div className={styles["edicao-audiencia"]}>
            <Back />
            <Loading promise={promise}>
                {(informacoesPoliticaEProcesso) =>
                    informacoesPoliticaEProcesso === undefined ? (
                        <p className={styles["erro-informacoes"]}>
                            Processo e/ou Política de audiência não encontrado(s)
                        </p>
                    ) : (
                        <>
                            <h2>Edição de Audiência</h2>
                            <Formik
                                initialValues={
                                    {
                                        idProcessoAudiencia: id,
                                        quantidadeAudiencista: 0,
                                        quantidadePreposto: 0,
                                        instrucoesTeleaudiencia: "",
                                        linkAudiencia: "",
                                        local: undefined,
                                        modalidade: undefined,
                                        nomeLocal: "",
                                        observacoes: "",
                                        observacoesParceiro: "",
                                        propostaAcordo: "",
                                    } as FormularioEdicaoAudiencia
                                }
                                validationSchema={() => validationSchema(informacoesPoliticaEProcesso)}
                                onSubmit={async (parametro) => {
                                    await ActionFeedback.processing({
                                        title: "Editando audiência",
                                        execution: AudienciaService.editar(parametro as ParametroEdicaoAudiencia),
                                    });

                                    ActionFeedback.info({
                                        title: "Audiência editada com sucesso",
                                    });

                                    history.goBack();
                                }}
                            >
                                <Form className={styles["cards-wrapper"]}>
                                    <EdicaoAudienciaEnderecamento idAudiencia={id} />
                                    <EdicaoAudienciaPrincipal
                                        idAudiencia={id}
                                        politicaAudiencia={informacoesPoliticaEProcesso.politicaAudiencia}
                                        processo={informacoesPoliticaEProcesso.processo}
                                    />
                                    <EdicaoAudienciaDadosParceiro idAudiencia={id} />
                                    <div className={styles["actions"]}>
                                        <ButtonSecondary onClick={onCancelar}>Cancelar</ButtonSecondary>
                                        <ButtonPrimary type="submit">Salvar</ButtonPrimary>
                                    </div>
                                </Form>
                            </Formik>
                        </>
                    )
                }
            </Loading>
        </div>
    );
};
