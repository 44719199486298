import { RequestApi } from "@omnijus/common";
import { CLIENT_ID } from "../../services/client-id";
import { ProcessoAudiencia } from "./models/processo-audiencia";

const processoAudienciaUrl = process.env.REACT_APP_BFF_OPERACIONAL_API_URL + "/api/Processo/v1/ProcessoAudiencia";

const requestApi = new RequestApi(CLIENT_ID);

export const ProcessoAudienciaService = {
    getListarPendencia: async (idProcesso: string) => {
        const url = new URL(`${processoAudienciaUrl}/ListarPendencia`);

        url.searchParams.set("idProcesso", idProcesso);

        const res = await requestApi.get<ProcessoAudiencia[]>(url.toString());
        res?.forEach((audiencia) => (audiencia.dataHora = audiencia.dataHora && new Date(audiencia.dataHora)));
        return res;
    },
};
