import { ReactComponent as DownloadIcon } from "../../assets/images/download-icon.svg";
import { ActionFeedback } from "@omnijus/common";
import { ProcessoService } from "../../services/processo/processo-service";
import React from "react";

export const BotaoDownloadDocumentosMovimento = ({
    idProcesso,
    idProcessoMovimento,
}: {
    idProcesso: string;
    idProcessoMovimento: string;
}) => (
    <DownloadIcon
        title="Clique para baixar os documentos do movimento"
        style={{ cursor: "pointer" }}
        onClick={async () => {
            await ActionFeedback.processing({
                title: "Processando...",
                execution: ProcessoService.downloadArquivosMovimento(idProcesso, idProcessoMovimento),
                onError: async (error: any) => {
                    return await ActionFeedback.error({
                        icon: "error",
                        html: error?.json?.erros
                            ? error.json.erros.join("<br />")
                            : error?.json?.title
                            ? error?.json?.title
                            : "Não foi possível realizar o download dos documentos",
                    });
                },
            });
        }}
    />
);
