import { RequestApi } from "@omnijus/common";
import { CLIENT_ID } from "../../services/client-id";

const requestApi = new RequestApi(CLIENT_ID);

const tipoDocumentoUrl = process.env.REACT_APP_BFF_OPERACIONAL_API_URL + "/api/Processo/v1/TipoDocumento";

export const TipoDocumentoService = {
    listarCombo: async (somenteProtocolaveis: boolean = false) => {
        const url = new URL(`${tipoDocumentoUrl}/ListarCombo`);

        url.search = RequestApi.objectToQueryString({ somenteProtocolaveis });

        return await requestApi.get<{ id: number; value: string }[] | undefined>(url.toString(), (res, json) => ({
            res: res,
            json: json,
        }));
    },
};
