import { ActionFeedback, ButtonDownload, Loading, OmnijusCard, OmnijusTextField, TypeUtils } from "@omnijus/common";
import { format } from "date-fns";
import { useFormikContext } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { AudienciaService } from "../../../services/audiencia/audiencia-service";
import { DetalheAudiencia } from "../../../services/audiencia/models/detalhe-audiencia";
import { PoliticaAudiencia, Processo } from "../../../services/audiencia/models/informacoes-politica-processo";
import { LocalAudiencia } from "../../../services/audiencia/models/local-audiencia";
import { ModalidadeAudiencia } from "../../../services/audiencia/models/modalidade-audiencia";
import { ProcessoService } from "../../../services/processo/processo-service";
import { CampoDetalhamento } from "../../../shared/campo-detalhamento/campo-detalhamento";
import { OmnijusRadioField } from "../../../shared/form/fields/omnijus-radio-field";
import { OmnijusTextAreaField } from "../../../shared/form/fields/omnijus-textarea-field";
import { FormularioEdicaoAudiencia } from "./edicao-audiencia";
import styles from "./edicao-audiencia.module.scss";

const downloadDocumento = async (idDocumento: string, filename: string) => {
    return await ActionFeedback.processing({
        title: "Gerando arquivo para download",
        execution: ProcessoService.downloadDocumento(idDocumento, filename),
    });
};

interface EdicaoAudienciaPrincipalProps {
    idAudiencia: string;
    politicaAudiencia?: PoliticaAudiencia;
    processo?: Processo;
}

export const EdicaoAudienciaPrincipal = (props: EdicaoAudienciaPrincipalProps) => {
    const { values, setFieldValue } = useFormikContext<FormularioEdicaoAudiencia>();
    const [promise, setPromise] = useState<Promise<DetalheAudiencia | undefined>>();
    const [detalheAudiencia, setDetalheAudiencia] = useState<DetalheAudiencia>();

    const thenObter = useCallback(
        (detalhe?: DetalheAudiencia) => {
            setFieldValue(
                TypeUtils.nameof<FormularioEdicaoAudiencia>("quantidadeAudiencista"),
                detalhe?.quantidadeAudiencista
            );
            setFieldValue(
                TypeUtils.nameof<FormularioEdicaoAudiencia>("quantidadePreposto"),
                detalhe?.quantidadePreposto
            );
            setFieldValue(
                TypeUtils.nameof<FormularioEdicaoAudiencia>("modalidade"),
                detalhe?.teleaudiencia ? ModalidadeAudiencia.Teleaudiencia : ModalidadeAudiencia.Presencial
            );
            setFieldValue(
                TypeUtils.nameof<FormularioEdicaoAudiencia>("linkAudiencia"),
                detalhe?.teleaudiencia ? detalhe.nomeLocal : ""
            );
            setFieldValue(
                TypeUtils.nameof<FormularioEdicaoAudiencia>("local"),
                detalhe?.nomeLocal === props.processo?.nomeTribunal
                    ? LocalAudiencia.MesmoLocal
                    : LocalAudiencia.OutroLocal
            );
            setFieldValue(TypeUtils.nameof<FormularioEdicaoAudiencia>("nomeLocal"), detalhe?.nomeLocal);
            setFieldValue(TypeUtils.nameof<FormularioEdicaoAudiencia>("observacoes"), detalhe?.observacoes || "");

            setDetalheAudiencia(detalhe);

            return detalhe;
        },
        [setFieldValue, props.processo?.nomeTribunal]
    );

    useEffect(() => {
        setPromise(AudienciaService.obter(props.idAudiencia).then(thenObter));
    }, [props.idAudiencia, thenObter]);

    useEffect(() => {
        let nomeLocal = values.linkAudiencia;

        if (values.modalidade === ModalidadeAudiencia.Teleaudiencia) {
            nomeLocal = values.linkAudiencia;
        } else if (values.local === LocalAudiencia.MesmoLocal) {
            nomeLocal = props.processo?.nomeTribunal || "";
        } else if (values.local === LocalAudiencia.OutroLocal) {
            nomeLocal = detalheAudiencia?.nomeLocal || "";
        }

        setFieldValue(TypeUtils.nameof<FormularioEdicaoAudiencia>("nomeLocal"), nomeLocal);
    }, [
        values.modalidade,
        values.linkAudiencia,
        values.local,
        detalheAudiencia?.nomeLocal,
        setFieldValue,
        props.processo?.nomeTribunal,
    ]);

    useEffect(() => {
        if (values.modalidade === ModalidadeAudiencia.Teleaudiencia) {
            const linkAudiencia = detalheAudiencia?.teleaudiencia ? detalheAudiencia.nomeLocal : "";
            setFieldValue(TypeUtils.nameof<FormularioEdicaoAudiencia>("linkAudiencia"), linkAudiencia);
        } else if (values.modalidade === ModalidadeAudiencia.Presencial) {
            const linkAudiencia = "";
            setFieldValue(TypeUtils.nameof<FormularioEdicaoAudiencia>("linkAudiencia"), linkAudiencia);
        }
    }, [values.modalidade, setFieldValue, detalheAudiencia?.teleaudiencia, detalheAudiencia?.nomeLocal]);

    return (
        <OmnijusCard
            header={<h3>Audiência</h3>}
            body={
                <Loading promise={promise}>
                    {(detalhes) =>
                        detalhes === undefined ? (
                            <p>Não encontrado</p>
                        ) : (
                            <ul className={styles["principal"]}>
                                <li className={styles["item"]}>
                                    <CampoDetalhamento label="Tipo" value={detalhes.descricaoTipoAudiencia} />
                                </li>
                                <li className={styles["item"]}>
                                    <CampoDetalhamento
                                        label="Data e Hora"
                                        value={format(detalhes.dataHora, "dd/MM/yyyy HH:mm")}
                                    />
                                </li>
                                {props.politicaAudiencia ? (
                                    <>
                                        <li className={styles["item"]}>
                                            <OmnijusTextField
                                                name="quantidadeAudiencista"
                                                label="Quantidade de Audiencista"
                                                disabled={!(props.politicaAudiencia?.solicitaAudiencista || false)}
                                                placeholder={
                                                    !props.politicaAudiencia?.solicitaAudiencista ? "Não solicita" : ""
                                                }
                                                masks={[
                                                    props.politicaAudiencia?.solicitaAudiencista
                                                        ? { mask: "##", charType: "numeric" }
                                                        : { mask: "", charType: "numeric" },
                                                ]}
                                            />
                                        </li>
                                        <li className={styles["item"]}>
                                            <OmnijusTextField
                                                name="quantidadePreposto"
                                                label="Quantidade de Preposto"
                                                disabled={!(props.politicaAudiencia?.solicitaPreposto || false)}
                                                placeholder={
                                                    !props.politicaAudiencia?.solicitaPreposto ? "Não solicita" : ""
                                                }
                                                masks={[
                                                    props.politicaAudiencia?.solicitaPreposto
                                                        ? { mask: "##", charType: "numeric" }
                                                        : { mask: "", charType: "numeric" },
                                                ]}
                                            />
                                        </li>
                                    </>
                                ) : null}
                                <li className={styles["item"]}>
                                    <CampoDetalhamento label="Status" value={detalhes.descricaoStatusAudiencia} />
                                </li>
                                <li className={styles["item"]}>
                                    <CampoDetalhamento
                                        label="Ata"
                                        value={
                                            detalhes.idDocumentoAta ? (
                                                <ButtonDownload
                                                    onClick={() =>
                                                        downloadDocumento(
                                                            detalhes.idDocumentoAta!,
                                                            `Ata_Audiencia_${detalhes.idDocumentoAta!}.zip`
                                                        )
                                                    }
                                                />
                                            ) : (
                                                "-"
                                            )
                                        }
                                    />
                                </li>
                                <li className={styles["item"]}>
                                    <CampoDetalhamento
                                        label="Etapa de Andamento"
                                        value={detalhes.descricaoStatusSolicitacaoAudiencia}
                                    />
                                </li>
                                <li className={styles["item"]}>
                                    <OmnijusRadioField
                                        name="modalidade"
                                        label="Modalidade"
                                        disabled={true}
                                        options={[
                                            { label: "Presencial", value: ModalidadeAudiencia.Presencial },
                                            { label: "Teleaudiência", value: ModalidadeAudiencia.Teleaudiencia },
                                        ]}
                                    />
                                </li>
                                <li className={styles["item"]}>
                                    <OmnijusTextField
                                        name="linkAudiencia"
                                        label="Link da Audiência"
                                        disabled={values.modalidade === ModalidadeAudiencia.Presencial}
                                    />
                                </li>
                                <li className={styles["item"]}>
                                    <OmnijusRadioField
                                        name="local"
                                        label="Local"
                                        disabled={values.modalidade === ModalidadeAudiencia.Teleaudiencia}
                                        options={[
                                            { label: "Mesmo local", value: LocalAudiencia.MesmoLocal },
                                            { label: "Outro local", value: LocalAudiencia.OutroLocal },
                                        ]}
                                    />
                                </li>
                                <li className={styles["item"]}>
                                    <OmnijusTextField
                                        name="nomeLocal"
                                        label="Descrição do Local"
                                        disabled={
                                            values.modalidade === ModalidadeAudiencia.Teleaudiencia ||
                                            values.local === LocalAudiencia.MesmoLocal
                                        }
                                    />
                                </li>
                                <li className={styles["item"]}>
                                    <OmnijusTextAreaField name="observacoes" label="Observações" />
                                </li>
                            </ul>
                        )
                    }
                </Loading>
            }
        />
    );
};
