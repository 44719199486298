import classNames from "classnames";
import { Field, FieldProps } from "formik";
import React from "react";
import "./inputs.scss";

interface OmnijusRadioFieldProps {
    label?: string;
    name: string;
    options: Array<{ label: string; value: string }>;
    vertical?: boolean;
    disabled?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const OmnijusRadioField = (props: OmnijusRadioFieldProps) => {
    return (
        <Field name={props.name} id={props.name}>
            {({ field, meta }: FieldProps) => (
                <div className="form-field-control">
                    {props.label ? (
                        <label className="label" htmlFor={props.name}>
                            {props.label}
                        </label>
                    ) : null}
                    <div className={classNames("omni-radio-check", { vertical: props.vertical })}>
                        {props.options.map((o, i) => (
                            <div className="option" key={o.value}>
                                <label>
                                    <input
                                        {...field}
                                        type="radio"
                                        value={o.value}
                                        className={"input" + (meta.touched && meta.error ? " input-error" : "")}
                                        checked={field.value === o.value}
                                        disabled={props.disabled}
                                        onChange={(e) => {
                                            field.onChange && field.onChange(e);
                                            props.onChange && props.onChange(e);
                                        }}
                                    />
                                    {o.label}
                                </label>
                            </div>
                        ))}
                    </div>
                    <div className="error">{meta.touched && meta.error ? meta.error : ""}</div>
                </div>
            )}
        </Field>
    );
};
