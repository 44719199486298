import React from "react";
import ArrowLeft from "../../../assets/images/arrow-left.png";
import styles from "./button-back.module.scss";
import { useHistory } from "react-router-dom";

const Back = (props: { to?: string }) => {
    const history = useHistory();
    return (
        <div className={styles.backButton} onClick={(event) => (props.to ? history.push(props.to) : history.goBack())}>
            <img alt="Seta para esqueda" src={ArrowLeft} />
            <span>Voltar</span>
        </div>
    );
};

export default Back;
