import {
    mdiCheck,
    mdiCheckboxBlankOutline,
    mdiCheckboxOutline,
    mdiChevronDown,
    mdiChevronRight,
    mdiCircleSmall,
    mdiCollapseAllOutline,
    mdiExpandAllOutline,
    mdiMinusBoxOutline,
} from "@mdi/js";
import Icon from "@mdi/react";
import { Loading } from "@omnijus/common";
import { useField } from "formik";
import React, { useEffect, useState } from "react";
import CheckboxTree from "react-checkbox-tree";
import { Providencia } from "../../../services/providencia/models/providencia";
import { ProvidenciaService } from "../../../services/providencia/providencia-service";
import styles from "./perfil.module.scss";

interface PerfilProvidenciasProps {
    idPerfil: string;
    name: string;
}

export const PerfilProvidencias = (props: PerfilProvidenciasProps) => {
    const [promise, setPromise] = useState<Promise<Array<Providencia> | undefined>>();
    const [input, , helper] = useField<Array<string>>(props.name);

    useEffect(() => {
        setPromise(ProvidenciaService.listar());
    }, []);

    return (
        <Loading promise={promise}>
            {(providencias) =>
                providencias ? (
                    <div className={styles.treeInline}>
                        <CheckboxTree
                            nodes={[
                                {
                                    label: "Marcar todas",
                                    value: "marcarTodos",
                                    children: providencias.map((p) => ({
                                        label: p.descricao,
                                        value: p.id.toString(),
                                    })),
                                },
                            ]}
                            expanded={["marcarTodos"]}
                            checked={input.value}
                            onCheck={(value) => helper.setValue(value)}
                            showNodeIcon={false}
                            icons={{
                                check: <Icon path={mdiCheckboxOutline} size={1} />,
                                uncheck: <Icon path={mdiCheckboxBlankOutline} size={1} />,
                                halfCheck: <Icon path={mdiMinusBoxOutline} size={1} className="indeterminate" />,
                                expandClose: <Icon path={mdiChevronRight} size={1} />,
                                expandOpen: <Icon path={mdiChevronDown} size={1} />,
                                expandAll: <Icon path={mdiExpandAllOutline} size={1} />,
                                collapseAll: <Icon path={mdiCollapseAllOutline} size={1} />,
                                parentClose: <Icon path={mdiCheck} size={1} />,
                                parentOpen: <Icon path={mdiCheck} size={1} />,
                                leaf: <Icon path={mdiCircleSmall} size={1} />,
                            }}
                        />
                    </div>
                ) : (
                    <p>Providências não encontradas</p>
                )
            }
        </Loading>
    );
};
