import { ActionFeedback, ButtonPrimary, OmnijusCard } from "@omnijus/common";
import { Form, Formik, useFormikContext } from "formik";
import React from "react";
import { AtualizacaoProcessoService } from "../../../services/atualizacao-processo/atualizacao-processo-service";
import { AtualizarFaseMomentoProcessoCommand } from "../../../services/atualizacao-processo/models/atualizar-fase-momento-command";
import { OmnijusFiltroFaseProcesso } from "../../../shared/form/custom-fields/omnijus-filtro-fase-processo";
import { OmnijusFiltroMomentoProcesso } from "../../../shared/form/custom-fields/omnijus-filtro-momento-processo";
import { OmnijusFiltroTipoResultadoSentenca } from "../../../shared/form/custom-fields/omnijus-filtro-tipo-resultado-sentenca";
import { OmnijusCurrencyField } from "../../../shared/form/fields/omnijus-currency-field";
import { OmnijusDateField } from "../../../shared/form/fields/omnijus-date-field";
import styles from "../atualizacao-processo.module.scss";
import { date as YupDate, number as YupNumber, object as YupObject, ObjectSchema } from "yup";
import { DetalheProcesso } from "../../../services/processo/models/detalhe-processo";
import { IdMomentoProcesso } from "../../../services/atualizacao-processo/enums/IdMomentoProcesso";
import { IdFaseProcesso } from "../../../services/atualizacao-processo/enums/IdFaseProcesso";

interface Fields {
    dataMovimento: Date;
    idFaseProcesso: number;
    idMomentoProcesso: number;
    idTipoResultadoSentenca?: number;
    valorDanosMorais?: number;
    valorDanosMateriais?: number;
    valorRepeticaoDeIndebitos?: number;
}

const validationSchema: ObjectSchema<Fields | undefined> = YupObject({
    dataMovimento: YupDate().required("Informe uma data").typeError("Data inválida"),
    idFaseProcesso: YupNumber().required("Informe a fase do processo").positive("Informe a fase do processo"),
    idMomentoProcesso: YupNumber().required("Informe o momento do processo").positive("Informe o momento do processo"),
    idTipoResultadoSentenca: YupNumber().when(["idFaseProcesso", "idMomentoProcesso"], {
        is: (idFaseProcesso, idMomentoProcesso) =>
            idFaseProcesso === IdFaseProcesso.Julgamento && idMomentoProcesso === IdMomentoProcesso.Sentenca,
        then: YupNumber()
            .required("Informe o tipo do resultado da sentença")
            .min(1, "Informe o tipo do resultado da sentença"),
    }),
});

export const Campos = ({ idRito }: { idRito: number }) => {
    const { values } = useFormikContext<{
        idFaseProcesso: number;
        idMomentoProcesso: number;
    }>();
    return (
        <>
            <div className={styles.formLinha1}>
                <OmnijusDateField name="dataMovimento" label="Data Movimento" />
                <OmnijusFiltroFaseProcesso name="idFaseProcesso" label="Fase" idRito={idRito} />
                {values.idFaseProcesso && (
                    <OmnijusFiltroMomentoProcesso name="idMomentoProcesso" label="Momento" idRito={idRito} />
                )}
            </div>
            <div className={`${styles.marginTop} ${styles.formLinha2}`}>
                {Number(values.idFaseProcesso) === IdFaseProcesso.Julgamento &&
                    Number(values.idMomentoProcesso) === IdMomentoProcesso.Sentenca && (
                        <>
                            <OmnijusFiltroTipoResultadoSentenca
                                name="idTipoResultadoSentenca"
                                label="Tipo Resultado Sentença"
                            />
                            <OmnijusCurrencyField name="valorDanosMorais" label="Valor Danos Morais" />
                            <OmnijusCurrencyField name="valorDanosMateriais" label="Valor Danos Materiais" />
                            <OmnijusCurrencyField
                                name="valorRepeticaoDeIndebitos"
                                label="Valor Repetição de Indébitos"
                            />
                        </>
                    )}
            </div>
        </>
    );
};

export interface FormAtualizacaoFaseMomentoProps {
    detalheProcesso: DetalheProcesso;
    onSubmit?: () => void;
}

export const FormAtualizacaoFaseMomento = ({ detalheProcesso, onSubmit }: FormAtualizacaoFaseMomentoProps) => {
    return (
        <OmnijusCard
            header={<h2>Atualização de Fase / Momento</h2>}
            body={
                <Formik
                    enableReinitialize
                    onSubmit={async (values) => {
                        const command: AtualizarFaseMomentoProcessoCommand = {
                            idProcesso: detalheProcesso.idProcesso,
                            ...values,
                        };

                        await ActionFeedback.processing({
                            title: "Processando...",
                            execution: AtualizacaoProcessoService.atualizarFaseMomento(command),
                        });

                        await ActionFeedback.info({
                            text: "Atualização de fase / momento realizada com sucesso!",
                        });

                        onSubmit && onSubmit();
                    }}
                    initialValues={
                        {
                            dataMovimento: new Date(),
                            idFaseProcesso: detalheProcesso.idFase,
                            idMomentoProcesso: detalheProcesso.idMomento,
                            idTipoResultadoSentenca: undefined,
                            valorDanosMorais: undefined,
                            valorDanosMateriais: undefined,
                            valorRepeticaoDeIndebitos: undefined,
                        } as Fields
                    }
                    validationSchema={validationSchema}
                >
                    <Form>
                        <Campos idRito={detalheProcesso.idRito} />
                        <div className={`${styles.alignRight} ${styles.marginTop}`}>
                            <ButtonPrimary type="submit">Salvar</ButtonPrimary>
                        </div>
                    </Form>
                </Formik>
            }
        />
    );
};
