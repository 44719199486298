import { Copiavel, Loading, OmnijusCard } from "@omnijus/common";
import { showToast } from "../../../lib/toast";
import React, { useEffect, useState } from "react";
import { AudienciaService } from "../../../services/audiencia/audiencia-service";
import { DadosParceiroAudiencia } from "../../../services/audiencia/models/dados-parceiro-audiencia";
import { CampoDetalhamento } from "../../../shared/campo-detalhamento/campo-detalhamento";
import { OmnijusTextAreaField } from "../../../shared/form/fields/omnijus-textarea-field";
import styles from "./edicao-audiencia.module.scss";

interface EdicaoAudienciaDadosParceiroProps {
    idAudiencia: string;
}

export const EdicaoAudienciaDadosParceiro = (props: EdicaoAudienciaDadosParceiroProps) => {
    const [promise, setPromise] = useState<Promise<DadosParceiroAudiencia | undefined>>();

    useEffect(() => {
        setPromise(AudienciaService.obterDadosSolicitacaoParceiro(props.idAudiencia));
    }, [props.idAudiencia]);

    return (
        <OmnijusCard
            header={<h3>Dados no Parceiro</h3>}
            body={
                <Loading promise={promise}>
                    {(dadosParceiro) =>
                        dadosParceiro === undefined ? (
                            <p>Não encontrado</p>
                        ) : (
                            <ul className={styles["dados-parceiro"]}>
                                <li className={styles["item"]}>
                                    <OmnijusTextAreaField
                                        name="observacoesParceiro"
                                        label="Observações para o parceiro"
                                    />
                                </li>
                                <li className={styles["item"]}>
                                    <OmnijusTextAreaField name="propostaAcordo" label="Proposta de acordo" />
                                </li>
                                <li className={styles["item"]}>
                                    <OmnijusTextAreaField
                                        name="instrucoesTeleaudiencia"
                                        label="Instruções para teleaudiência"
                                    />
                                </li>
                                <li className={styles["item"]}>
                                    <CampoDetalhamento
                                        label="ID da Audiência"
                                        value={
                                            dadosParceiro.idAudiencia ? (
                                                <Copiavel
                                                    informacaoCopiavel={dadosParceiro.idAudiencia}
                                                    mensagem="ID da audiência no parceiro copiado"
                                                    onCopiado={() => showToast("ID da audiência no parceiro copiado")}
                                                />
                                            ) : (
                                                "-"
                                            )
                                        }
                                    />
                                </li>
                            </ul>
                        )
                    }
                </Loading>
            }
        />
    );
};
