import { Loading, OmnijusCard } from "@omnijus/common";
import React, { useEffect, useState } from "react";
import { AudienciaService } from "../../../services/audiencia/audiencia-service";
import { EnderecamentoAudiencia } from "../../../services/audiencia/models/enderecamento-audiencia";
import { CampoDetalhamento } from "../../../shared/campo-detalhamento/campo-detalhamento";
import styles from "./edicao-audiencia.module.scss";

interface EdicaoAudienciaEnderecamentoProps {
    idAudiencia: string;
}

export const EdicaoAudienciaEnderecamento = (props: EdicaoAudienciaEnderecamentoProps) => {
    const [promise, setPromise] = useState<Promise<EnderecamentoAudiencia | undefined>>();

    useEffect(() => {
        setPromise(AudienciaService.obterEnderecamento(props.idAudiencia));
    }, [props.idAudiencia]);

    return (
        <OmnijusCard
            header={<h3>Dados de Endereçamento</h3>}
            body={
                <Loading promise={promise}>
                    {(enderecamento) =>
                        enderecamento === undefined ? (
                            <p>Não encontrado</p>
                        ) : (
                            <ul className={styles["enderecamento"]}>
                                <li className={styles["item"]}>
                                    <CampoDetalhamento label="Nº do Processo" value={enderecamento.numeroProcesso} />
                                </li>
                                <li className={styles["item"]}>
                                    <CampoDetalhamento label="Cliente" value={enderecamento.nomeCliente} />
                                </li>
                                <li className={styles["item"]}>
                                    <CampoDetalhamento label="Tribunal" value={enderecamento.nomeTribunal} />
                                </li>
                                <li className={styles["item"]}>
                                    <CampoDetalhamento label="Foro" value={enderecamento.nomeForo} />
                                </li>
                                <li className={styles["item"]}>
                                    <CampoDetalhamento label="Vara" value={enderecamento.nomeVara} />
                                </li>
                            </ul>
                        )
                    }
                </Loading>
            }
        />
    );
};
