import { OmnijusCard, useAuth } from "@omnijus/common";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { OmnijusFiltroCliente } from "../../shared/form/custom-fields/omnijus-filtro-cliente";
import { ButtonPrimarySmall } from "../../shared/form/fields/omnijus-button-primary";
import styles from "./dashboard.module.scss";
import { SumarioProcessos } from "./sumario-processos";
import { object as YupObject, string as YupString } from "yup";

export const Dashboard = () => {
    const auth = useAuth();
    const [idCliente, setIdCliente] = useState<string>();
    return (
        <div className={styles.dashboard}>
            <h1>Olá, {auth?.user?.profile.name || ""}</h1>
            <h2>Seus processos em fases</h2>
            <Formik
                validationSchema={YupObject().shape({
                    idCliente: YupString().required("Selecione um cliente"),
                })}
                initialValues={{
                    idCliente: undefined,
                }}
                onSubmit={(values) => {
                    setIdCliente(values.idCliente);
                }}
            >
                <Form>
                    <OmnijusCard
                        body={
                            <div style={{ display: "grid", gridTemplateColumns: "4fr 8fr", columnGap: "1rem" }}>
                                <OmnijusFiltroCliente name="idCliente" label="Cliente" />

                                <div style={{ marginTop: "1.4rem" }}>
                                    <ButtonPrimarySmall type="submit">Buscar</ButtonPrimarySmall>
                                </div>
                            </div>
                        }
                    />
                </Form>
            </Formik>

            {idCliente && <OmnijusCard body={<SumarioProcessos idCliente={idCliente} />} />}
        </div>
    );
};
