import { OmnijusCard, OmnijusTextAreaField, OmnijusTextField, TypeUtils } from "@omnijus/common";
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { PoliticaAudiencia, Processo } from "../../../services/audiencia/models/informacoes-politica-processo";
import { LocalAudiencia } from "../../../services/audiencia/models/local-audiencia";
import { ModalidadeAudiencia } from "../../../services/audiencia/models/modalidade-audiencia";
import { StatusAudiencia } from "../../../services/audiencia/models/status-audiencia";
import { StatusSolicitacaoAudiencia } from "../../../services/audiencia/models/status-solicitacao-audiencia";
import { FiltrosAudienciaService } from "../../../services/filtros/filtros-audiencia-service";
import { FiltroStatusSolicitacao } from "../../../services/filtros/models/filtro-status-solicitacao";
import { OmnijusFiltroStatusAudiencia } from "../../../shared/form/custom-fields/omnijus-filtro-status-audiencia";
import { OmnijusFiltroTipoAudiencia } from "../../../shared/form/custom-fields/omnijus-filtro-tipo-audiencia";
import { OmnijusDateField } from "../../../shared/form/fields/omnijus-date-field";
import { OmnijusRadioField } from "../../../shared/form/fields/omnijus-radio-field";
import { FormularioInclusaoAudiencia } from "./inclusao-audiencia";
import styles from "./inclusao-audiencia.module.scss";

interface InclusaoAudienciaDadosPrincipaisProps {
    processo?: Processo;
    politicaAudiencia?: PoliticaAudiencia;
}

const statusAudienciaParaStatusSolicitacaoAudiencia = (statusAudiencia: StatusAudiencia) => {
    switch (statusAudiencia) {
        case StatusAudiencia.Designada:
            return StatusSolicitacaoAudiencia.Nova;
        case StatusAudiencia.Redesignada:
            return StatusSolicitacaoAudiencia.Nova;
        case StatusAudiencia.Cancelada:
            return StatusSolicitacaoAudiencia.Cancelada;
        case StatusAudiencia.Realizada:
            return StatusSolicitacaoAudiencia.AudienciaRealizadaAguardandoAta;
        default:
            throw new Error("Não existe uma tradução para o status informado " + statusAudiencia);
    }
};

export const InclusaoAudienciaDadosPrincipais = (props: InclusaoAudienciaDadosPrincipaisProps) => {
    const { values, setFieldValue } = useFormikContext<FormularioInclusaoAudiencia>();
    const [opcoesStatusSolicitacaoAudiencia, setOpcoesStatusSolicitacaoAudiencia] =
        useState<Array<FiltroStatusSolicitacao>>();

    useEffect(() => {
        FiltrosAudienciaService.listarStatusSolicitacaoAudiencia().then((opcoes) => {
            setOpcoesStatusSolicitacaoAudiencia(opcoes!);
        });
    }, []);

    useEffect(() => {
        let etapaAndamento = "";

        if (values.idStatusAudiencia) {
            let idStatusSolicitacaoAudiencia = statusAudienciaParaStatusSolicitacaoAudiencia(
                Number(values.idStatusAudiencia)
            );

            etapaAndamento = opcoesStatusSolicitacaoAudiencia?.find((o) => o.id === idStatusSolicitacaoAudiencia)
                ?.value!;
        } else {
            etapaAndamento = "";
        }

        setFieldValue(TypeUtils.nameof<FormularioInclusaoAudiencia>("etapaAndamento"), etapaAndamento);
    }, [values.idStatusAudiencia, setFieldValue, opcoesStatusSolicitacaoAudiencia]);

    useEffect(() => {
        let nomeLocal = values.linkAudiencia;

        if (values.modalidade === ModalidadeAudiencia.Teleaudiencia) {
            nomeLocal = values.linkAudiencia;
        } else if (values.local === LocalAudiencia.MesmoLocal) {
            nomeLocal = props.processo?.nomeTribunal || "";
        }

        setFieldValue(TypeUtils.nameof<FormularioInclusaoAudiencia>("nomeLocal"), nomeLocal);
    }, [values.modalidade, values.linkAudiencia, values.local, setFieldValue, props]);

    useEffect(() => {
        let linkAudiencia = values.linkAudiencia;

        if (values.modalidade === ModalidadeAudiencia.Presencial) {
            linkAudiencia = "";
        }

        setFieldValue(TypeUtils.nameof<FormularioInclusaoAudiencia>("linkAudiencia"), linkAudiencia);
    }, [values.modalidade, values.linkAudiencia, setFieldValue]);

    return (
        <OmnijusCard
            header={<h3>Audiência</h3>}
            body={
                <div className={`${styles["grid-campos"]} ${styles["grid-campos-audiencia"]}`}>
                    <OmnijusFiltroTipoAudiencia name="idTipoAudiencia" label="Tipo" />
                    <OmnijusDateField name="dataHora" label="Data e Hora" timeInput={true} timeFormat="HH:mm" />
                    {props.politicaAudiencia ? (
                        <>
                            <OmnijusTextField
                                name="quantidadeAudiencista"
                                label="Quantidade de Audiencista"
                                disabled={!(props.politicaAudiencia?.solicitaAudiencista || false)}
                                placeholder={!props.politicaAudiencia?.solicitaAudiencista ? "Não solicita" : ""}
                                masks={[
                                    props.politicaAudiencia?.solicitaAudiencista
                                        ? { mask: "##", charType: "numeric" }
                                        : { mask: "", charType: "numeric" },
                                ]}
                            />
                            <OmnijusTextField
                                name="quantidadePreposto"
                                label="Quantidade de Preposto"
                                disabled={!(props.politicaAudiencia?.solicitaPreposto || false)}
                                placeholder={!props.politicaAudiencia?.solicitaPreposto ? "Não solicita" : ""}
                                masks={[
                                    props.politicaAudiencia?.solicitaPreposto
                                        ? { mask: "##", charType: "numeric" }
                                        : { mask: "", charType: "numeric" },
                                ]}
                            />
                        </>
                    ) : null}
                    <OmnijusFiltroStatusAudiencia name="idStatusAudiencia" label="Status" />
                    <OmnijusTextField name="etapaAndamento" label="Etapa de Andamento" disabled={true} />
                    <OmnijusRadioField
                        name="modalidade"
                        label="Modalidade"
                        options={[
                            { label: "Presencial", value: ModalidadeAudiencia.Presencial },
                            { label: "Teleaudiência", value: ModalidadeAudiencia.Teleaudiencia },
                        ]}
                    />
                    <OmnijusTextField
                        name="linkAudiencia"
                        label="Link da Audiência"
                        disabled={values.modalidade === ModalidadeAudiencia.Presencial}
                    />
                    <OmnijusRadioField
                        name="local"
                        label="Local"
                        disabled={values.modalidade === ModalidadeAudiencia.Teleaudiencia}
                        options={[
                            { label: "Mesmo local", value: LocalAudiencia.MesmoLocal },
                            { label: "Outro local", value: LocalAudiencia.OutroLocal },
                        ]}
                    />
                    <OmnijusTextField
                        name="nomeLocal"
                        label="Descrição do Local"
                        disabled={
                            values.modalidade === ModalidadeAudiencia.Teleaudiencia ||
                            values.local === LocalAudiencia.MesmoLocal
                        }
                    />
                    <OmnijusTextAreaField name="observacoes" label="Observações" />
                </div>
            }
        />
    );
};
