import { Loading } from "@omnijus/common";
import React, { useEffect, useState } from "react";
import { Providencia } from "../../../services/providencia/models/providencia";
import { ProvidenciaService } from "../../../services/providencia/providencia-service";
import { OmnijusSelectField } from "../fields/omnijus-select-field";

interface OmnijusFiltroProvidenciaProps {
    hideLabel?: boolean;
    name?: string;
    label?: string;
    isDisabled?: boolean;
    isMulti?: boolean;
    options?: Providencia[];
    somenteExibirInclusaoAgendamento?: boolean;
    somenteIncluirPeticionamento?: boolean;
}

export const OmnijusFiltroProvidencia = (props: OmnijusFiltroProvidenciaProps) => {
    const [promiseOpcoes, setPromiseOpcoes] = useState<Promise<Providencia[] | undefined>>();

    useEffect(() => {
        if (props.options && props.options.length > 0) {
            setPromiseOpcoes(Promise.resolve(props.options));
        } else {
            setPromiseOpcoes(
                ProvidenciaService.listarProvidencia(
                    props.somenteExibirInclusaoAgendamento,
                    props.somenteIncluirPeticionamento
                )
            );
        }
    }, [props.options, props.somenteExibirInclusaoAgendamento, props.somenteIncluirPeticionamento]);

    return (
        <Loading promise={promiseOpcoes}>
            {(opcoes) => (
                <OmnijusSelectField
                    isMulti={props.isMulti}
                    name={props.name || "idProvidencia"}
                    label={props.label}
                    options={opcoes
                        ?.map((o) => ({
                            value: o.id.toString(),
                            label: o.descricao,
                        }))
                        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
                />
            )}
        </Loading>
    );
};
