import { CardFiltros, Loading, OmnijusCard, OmnijusTextField } from "@omnijus/common";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { object as YupObject, string as YupString } from "yup";
import DataTable from "react-data-table-component";
import { AtualizacaoProcesso } from "../../services/atualizacao-processo/models/atualizacao-processo";
import { AtualizacaoProcessoService } from "../../services/atualizacao-processo/atualizacao-processo-service";
import { FiltroAtualizacaoProcesso } from "../../services/atualizacao-processo/models/filtro-atualizacao-processo";
import { OmnijusFiltroCliente } from "../../shared/form/custom-fields/omnijus-filtro-cliente";
import { OmnijusCampoNumeroProcesso } from "../../shared/form/custom-fields/omnijus-campo-numero-processo";
import styles from "./atualizacao-processo.module.scss";
import { useHistory } from "react-router-dom";

export const PesquisaProcessos = () => {
    const [promise, setPromise] = useState<Promise<AtualizacaoProcesso[] | undefined>>();
    const history = useHistory();

    return (
        <div className={styles.atualizacaoProcesso}>
            <h2>Pesquisa para atualização de processos</h2>
            <CardFiltros
                textoBotao="Filtrar"
                onBuscar={async (values) => {
                    let algumFiltroInformado = Object.values(values).some((value) => value);

                    if (!algumFiltroInformado) {
                        Swal.fire({
                            icon: "warning",
                            text: "É necessário definir ao menos um dos critérios de consulta",
                        });

                        return;
                    }

                    setPromise(AtualizacaoProcessoService.listar(values));
                }}
                initialValues={
                    {
                        numeroProcesso: "",
                        idCliente: "",
                        nomeParteAutora: "",
                    } as FiltroAtualizacaoProcesso
                }
                botaoFiltrarInline
                validationSchema={YupObject().shape({
                    numeroProcesso: YupString().min(25, "O número de processo deve conter exatamente 20 dígitos"),
                })}
            >
                <OmnijusCampoNumeroProcesso name="numeroProcesso" label="Nº do Processo" />
                <OmnijusTextField name="nomeParteAutora" label="Nome Parte Autora" />
                <OmnijusFiltroCliente name="idCliente" label="Cliente" />
            </CardFiltros>

            {promise ? (
                <Loading promise={promise}>
                    {(agendamentos) => (
                        <div style={{ marginTop: "1rem" }}>
                            <OmnijusCard
                                body={
                                    <DataTable
                                        customStyles={{
                                            rows: {
                                                style: {
                                                    cursor: "pointer",
                                                },
                                            },
                                        }}
                                        noHeader={true}
                                        pagination={true}
                                        paginationPerPage={20}
                                        noDataComponent={<p>A consulta não retornou registros</p>}
                                        data={agendamentos || []}
                                        onRowClicked={(row) => {
                                            history.push(`/atualizacao-processos/${row.numeroProcesso}`);
                                        }}
                                        columns={[
                                            {
                                                name: "Nº do Processo",
                                                minWidth: "15rem",
                                                selector: (row) => row.numeroProcesso,
                                                sortable: true,
                                            },
                                            {
                                                name: "Parte Autora",
                                                minWidth: "15rem",
                                                selector: (row) => row.nomeParteAutora,
                                                format: (row) => row.nomeParteAutora?.toUpperCase(),
                                                sortable: true,
                                            },
                                            {
                                                name: "Status",
                                                minWidth: "15rem",
                                                selector: (row) => row.descricaoStatusProcesso,
                                                sortable: true,
                                            },
                                            {
                                                name: "Fase",
                                                minWidth: "15rem",
                                                selector: (row) => row.faseProcesso,
                                                sortable: true,
                                            },
                                            {
                                                name: "Momento",
                                                minWidth: "15rem",
                                                selector: (row) => row.momentoProcesso,
                                                sortable: true,
                                            },
                                        ]}
                                    />
                                }
                            />
                        </div>
                    )}
                </Loading>
            ) : (
                <p className="text-center">Utilize os filtros para realizar uma nova busca!</p>
            )}
        </div>
    );
};
