import { Loading } from "@omnijus/common";
import React, { useEffect, useState } from "react";
import { FiltroTipoEntrevistaGuiada } from "../../../services/filtros/filtro-tipo-entrevista-guiada";
import { FiltrosAudienciaService } from "../../../services/filtros/filtros-audiencia-service";
import { OmnijusSelectField } from "../../../shared/form/fields/omnijus-select-field";

interface OmnijusFiltroTipoAudienciaProps {
  hideLabel?: boolean;
  name?: string;
  label?: string;
}

export const OmnijusFiltroTipoAudiencia = (props: OmnijusFiltroTipoAudienciaProps) => {
  const [promise, setPromise] = useState<Promise<Array<FiltroTipoEntrevistaGuiada> | undefined>>();

  useEffect(() => {
      setPromise(FiltrosAudienciaService.listarTipoAudiencia());
  }, []);

  return (
    <Loading promise={promise}>
      {opcoesTipoAudiencia => (
        <OmnijusSelectField
          name={props.name || "IdTipoAudiencia"}
          label={props.hideLabel ? undefined : props.label || "Tipo de Audiência"}
          options={opcoesTipoAudiencia
            ?.map((o) => ({
                value: o.id.toString(),
                label: o.value.toString(),
            }))
            .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
        />
      )}
    </Loading>
  );
};
