import { RequestApi } from "@omnijus/common";
import { CLIENT_ID } from "../../../services/client-id";
import { ModuloViewModel } from "./modulo-viewmodel";

const baseUrl = process.env.REACT_APP_BFF_OPERACIONAL_API_URL + "/api/ConfiguracaoControleAcesso";

const request = new RequestApi(CLIENT_ID);

export const listarModulos = async () => {
    const url = `${baseUrl}/v1/Modulo`;
    return await request.get<ModuloViewModel[]>(url);
};

export const obterModulo = async (id: string) => {
    const url = `${baseUrl}/v1/Modulo/${id}`;
    return await request.get<ModuloViewModel>(url);
};
