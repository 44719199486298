import { RequestApi } from "@omnijus/common";
import { CLIENT_ID } from "../../services/client-id";
import { FiltroEntrevistaGuiada } from "./filtro-entrevista-guiada";
import { FiltroTemplateEntrevistaGuiadaParceiro } from "./filtro-template-entrevista-guiada-parceiro";
import { FiltroTipoEntrevistaGuiada } from "./filtro-tipo-entrevista-guiada";

const requestApi = new RequestApi(CLIENT_ID);

const filtrosEntrevistaGuiadaUrl =
    process.env.REACT_APP_BFF_OPERACIONAL_API_URL! + "/api/Atividade/v1/EntrevistaGuiada";
const filtrosEntrevistaGuiadaPrestadorUrl =
    process.env.REACT_APP_BFF_OPERACIONAL_API_URL! + "/api/Atividade/v1/EntrevistaGuiada";

export const FiltroEntrevistaGuiadaService = {
    listarTipoEntrevistaGuiada: async () => {
        let url = `${filtrosEntrevistaGuiadaPrestadorUrl}/ListarTiposEntrevistaGuiada`;
        return await requestApi.get<FiltroTipoEntrevistaGuiada[]>(url);
    },
    listarEntrevistaGuiada: async () => {
        let url = `${filtrosEntrevistaGuiadaUrl}/ListarComboEntrevistaGuiada`;
        return await requestApi.get<FiltroEntrevistaGuiada[]>(url);
    },
    listarTemplateEntrevistaGuiadaParceiro: async () => {
        let url = `${filtrosEntrevistaGuiadaPrestadorUrl}/ListarTemplatesEntrevistasGuiadas`;
        return await requestApi.get<FiltroTemplateEntrevistaGuiadaParceiro[]>(url);
    },
};
