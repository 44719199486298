import { ActionFeedback, SelectObject } from "@omnijus/common";
import { useField } from "formik";
import { debounce } from "lodash";
import React from "react";
import AsyncSelect from "react-select/async";
import { UsuarioFilter } from "../../../services/configuracoes/usuario/usuario-filter";
import { UsuarioService } from "../../../services/configuracoes/usuario/usuario-service";
import styles from "../field.module.scss";

interface OmnijusCampoUsuarioProps {
    hideLabel?: boolean;
    name?: string;
    label?: string;
    reloadOnChangeName?: string;
    isMulti?: boolean;
    onChange?: (o: SelectObject | SelectObject[]) => void;
    className?: string;
}

const loadOptions = (term: string, callback: any) => {
    let filter = {
        term: term,
    } as UsuarioFilter;
    UsuarioService.Listar(filter)
        .then((u) => {
            callback(u?.results.map((item) => ({ value: item.id, label: item.nome })));
        })
        .catch((err) => {
            ActionFeedback.error({
                icon: "error",
                titleText: "Erro ao buscar usuários",
                text: err.toString(),
            });
        });
};

const debouncedLoadOptions = debounce(loadOptions, 500);

export const OmnijusCampoUsuario = (props: OmnijusCampoUsuarioProps) => {
    const name = props.name || "idUsuario";
    const [, meta, helpers] = useField({ multiple: props.isMulti, name: name });

    return (
        <div className={`${styles["field-control"]} ${props.className || ""}`}>
            {props.label ? (
                <label className={styles["label"]} htmlFor={props.name}>
                    {props.label}
                </label>
            ) : null}
            <AsyncSelect
                loadOptions={debouncedLoadOptions}
                loadingMessage={() => "Carregando..."}
                noOptionsMessage={() => "Nenhum usuário encontrado"}
                placeholder="Informe o nome do usuário para pesquisar"
                isMulti={props.isMulti}
                onChange={(e) => {
                    if (props.isMulti) {
                        let aux = e as SelectObject[];
                        helpers.setTouched(true);
                        if (props.onChange) {
                            props.onChange(aux);
                        } else {
                            helpers.setValue(aux?.map((a) => a.value));
                        }
                    } else {
                        let aux = e as SelectObject;
                        if (props.onChange) {
                            props.onChange(aux);
                        } else {
                            setTimeout(() => {
                                helpers.setValue(aux?.value || "");
                                helpers.setTouched(true);
                            }, 0);
                        }
                    }
                }}
            />
            {meta.touched && meta.error ? (
                <p className={styles["error"]}>
                    {typeof meta.error === "object" ? (Object.values(meta.error)[0] as string) : meta.error}
                </p>
            ) : null}
        </div>
    );
};
