import { OmnijusCard, OmnijusTextField } from "@omnijus/common";
import { ReactComponent as SearchIcon } from "../../../assets/images/search-icon.svg";
import { ReactComponent as EditPenIcon } from "../../../assets/images/edit-pen.svg";
import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { OmnijusCampoNumeroProcesso } from "../../../shared/form/custom-fields/omnijus-campo-numero-processo";
import { FormularioInclusaoAudiencia } from "./inclusao-audiencia";
import styles from "./inclusao-audiencia.module.scss";
import { Processo } from "../../../services/audiencia/models/informacoes-politica-processo";

interface InclusaoAudienciaEnderecamentoProps {
    processo?: Processo;
    onLimparProcesso: () => void;
    onPesquisarProcesso: (numeroProcesso: string) => void;
}

export const InclusaoAudienciaEnderecamento = (props: InclusaoAudienciaEnderecamentoProps) => {
    const { values, setValues} = useFormikContext<FormularioInclusaoAudiencia>();

    useEffect(() => {
        let valuesCopy = values;

        valuesCopy.nomeCliente = props.processo?.nomeCliente || "";
        valuesCopy.nomeTribunal = props.processo?.nomeTribunal || "";
        valuesCopy.nomeVara = props.processo?.nomeVara || "";
        valuesCopy.nomeForo = props.processo?.nomeForo || "";

        setValues(valuesCopy);
    }, [values, setValues, props.processo]);

    return (
        <OmnijusCard
            header={<h3>Dados de Endereçamento</h3>}
            body={
                <div className={`${styles["grid-campos"]} ${styles["grid-campos-enderecamento"]}`}>
                    <div className={styles["numero-processo"]}>
                        <OmnijusCampoNumeroProcesso
                            name="numeroProcesso"
                            label="Número do Processo"
                            disabled={props.processo !== undefined}
                            actionIcon={
                                props.processo ?
                                    <EditPenIcon />
                                    :
                                    <SearchIcon />
                            }
                            onAction={() => {
                                if (props.processo) {
                                    props.onLimparProcesso();
                                } else {
                                    props.onPesquisarProcesso(values.numeroProcesso || "");
                                }
                            }}
                        />
                    </div>
                    <OmnijusTextField
                        label="Cliente"
                        name="nomeCliente"
                        disabled={true}
                    />
                    <OmnijusTextField
                        label="Tribunal"
                        name="nomeTribunal"
                        disabled={true}
                    />
                    <OmnijusTextField
                        label="Foro"
                        name="nomeForo"
                        disabled={true}
                    />
                    <OmnijusTextField
                        label="Vara"
                        name="nomeVara"
                        disabled={true}
                    />
                </div>
            }
        />
    );
}