import { Loading, OmnijusCard } from "@omnijus/common";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { AudienciaService } from "../../../services/audiencia/audiencia-service";
import { ExecutorAudiencia } from "../../../services/audiencia/models/executor-audiencia";
import styles from "./detalhamento-audiencia.module.scss";

interface DetalhamentoAudienciaExecutoresProps {
    idAudiencia: string;
}

export const DetalhamentoAudienciaExecutores = (props: DetalhamentoAudienciaExecutoresProps) => {
    const [promise, setPromise] = useState<Promise<Array<ExecutorAudiencia> | undefined>>();
    
    useEffect(() => {
        setPromise(AudienciaService.obterExecutores(props.idAudiencia));
    }, [props.idAudiencia]);
    
    return (
        <OmnijusCard
            header={<h3>Executores</h3>}
            body={
                <Loading promise={promise}>
                    {executores => 
                        executores === undefined ?
                            <p>Não encontrado</p>
                            :
                            <DataTable
                                data={executores}
                                noHeader={true}
                                className={styles["executores"]}
                                noDataComponent="Sem executores"
                                columns={[
                                    {
                                        name: "Nome",
                                        selector: executor => executor.nome,
                                        sortable: true
                                    },
                                    {
                                        name: "Aprovado",
                                        selector: executor => executor.aprovado ? "Sim" : "Não",
                                        sortable: true
                                    }
                                ]}
                            />
                    }
                </Loading>
            }
        />
    );
}