import { ButtonSecondary } from "@omnijus/common";
import React, { useMemo, useState } from "react";
import styles from "../movimentacao-assistida.module.scss";
import { analiseRotuloEditavel } from "./analise-rotulo";
import { AnaliseRotuloBotaoSair } from "./analise-rotulo-botao-sair";
import { AnaliseRotuloBotaoPular } from "./analise-rotulo-botao-pular";
import { AnaliseRotuloBotaoAprovar } from "./analise-rotulo-botao-aprovar";
import { ModalRotulos } from "./modal-rotulos";
import { RotuloMovimento } from "../../../services/movimentacao-assistida/models/rotulo-movimento";
import { useLocation } from "react-router-dom";
import { parseFiltroMovimentoRotulo } from "../../../services/filtros/filtro-movimento-rotulo";

export const CardRotulos = ({
    rotuloMovimento,
    idStatusDocumentoRotulo,
    idProcessoMovimento,
    idProcessoMovimentoRotulo,
    onChangeRotulo,
}: {
    idStatusDocumentoRotulo: number;
    rotuloMovimento: RotuloMovimento;
    idProcessoMovimento: string;
    idProcessoMovimentoRotulo: string;
    onChangeRotulo: (rotulo: RotuloMovimento) => void;
}) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const queryString = useLocation().search;
    const filtroMovimentoRotulo = useMemo(() => parseFiltroMovimentoRotulo(queryString), [queryString]);
    return (
        <>
            <div className={styles.marginBottom}>
                <>
                    <h2>Rótulo</h2>

                    <div className={styles.rotulos}>
                        <div
                            key={rotuloMovimento.id}
                            className={styles.rotulo}
                            style={{
                                backgroundColor: `#${rotuloMovimento.corFundoDisplay}`,
                            }}
                        >
                            {rotuloMovimento.nome?.toUpperCase()}
                        </div>
                    </div>

                    <div className={`${styles.marginTop}`}>
                        {analiseRotuloEditavel(idStatusDocumentoRotulo) && (
                            <div className={`${styles.alignRight} ${styles.botoesAnaliseRotulo}`}>
                                <div />
                                <div />
                                <ButtonSecondary onClick={() => setModalOpen(true)}>Ajustar Rótulo</ButtonSecondary>
                            </div>
                        )}
                    </div>
                </>
            </div>
            <div className={styles.botoesAnaliseRotulo}>
                <AnaliseRotuloBotaoSair
                    idProcessoMovimentoRotulo={idProcessoMovimentoRotulo}
                    idStatusDocumentoRotulo={idStatusDocumentoRotulo}
                />
                <AnaliseRotuloBotaoPular
                    idStatusDocumentoRotulo={idStatusDocumentoRotulo}
                    idProcessoMovimentoRotulo={idProcessoMovimentoRotulo}
                    idProcessoMovimento={idProcessoMovimento}
                    filtroMovimentoRotulo={filtroMovimentoRotulo}
                />
                <AnaliseRotuloBotaoAprovar
                    idProcessoMovimento={idProcessoMovimento}
                    idStatusDocumentoRotulo={idStatusDocumentoRotulo}
                    idProcessoMovimentoRotulo={idProcessoMovimentoRotulo}
                    rotuloMovimento={rotuloMovimento}
                    filtroMovimentoRotulo={filtroMovimentoRotulo}
                />
            </div>
            {isModalOpen && (
                <ModalRotulos
                    rotuloMovimento={rotuloMovimento}
                    onSetRotulo={onChangeRotulo}
                    isModalOpen={isModalOpen}
                    onCloseModal={() => setModalOpen(false)}
                />
            )}
        </>
    );
};
