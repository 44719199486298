import { RequestApi } from "@omnijus/common";
import { CLIENT_ID } from "../../../services/client-id";
import { SubsidioProcesso } from "./models/subsidio-processo";

const requestApi = new RequestApi(CLIENT_ID);

const processoSubsidioUrl = process.env.REACT_APP_BFF_OPERACIONAL_API_URL + "/api/Processo/v1/ProcessoSubsidio";

export const SubsidioProcessoService = {
    getAll: async (idProcesso: string) => {
        let url = new URL(`${processoSubsidioUrl}/Processo/${idProcesso}/Subsidios`);
        return await requestApi.get<SubsidioProcesso[]>(url.toString()).then((res) =>
            res?.map((r) => ({
                ...r,
                dataSolicitacao: new Date(r.dataSolicitacao),
                dataRetorno: r.dataRetorno && new Date(r.dataRetorno),
            }))
        );
    },
    downloadTodosSubsidios: async (idProcesso: string, filename: string) => {
        let url = new URL(`${processoSubsidioUrl}/Processo/${idProcesso}/ProcessoDocumento/DownloadTodos`);
        return await requestApi.download(url.toString(), filename);
    },
    downloadSubsidio: async (idProcessoSubsidio: string, filename: string) => {
        let url = new URL(`${processoSubsidioUrl}/${idProcessoSubsidio}/ProcessoDocumento/Download`);
        return await requestApi.download(url.toString(), filename);
    },
};
