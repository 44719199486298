import { RequestApi } from "@omnijus/common";
import { CLIENT_ID } from "../../services/client-id";
import { FiltroStatusSolicitacao } from "./models/filtro-status-solicitacao";

const baseUrl = process.env.REACT_APP_BFF_OPERACIONAL_API_URL + "/api/Atividade/v1";

const requestApi = new RequestApi(CLIENT_ID);

export const FiltroStatusSolicitacaoService = {
    listar: () => {
        const url = `${baseUrl}/StatusSolicitacao`;
        return requestApi.get<FiltroStatusSolicitacao[]>(url);
    },
};
