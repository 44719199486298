import { ActionFeedback, OmnijusCard } from "@omnijus/common";
import { Form, Formik } from "formik";
import React from "react";
import { OmnijusCampoNumeroProcesso } from "../../shared/form/custom-fields/omnijus-campo-numero-processo";
import { object as YupObject, string as YupString } from "yup";
import { ReactComponent as SearchIcon } from "../../assets/images/search-icon.svg";
import { ProcessoService } from "../../services/processo/processo-service";
import styles from "./listar-peticionamentos.module.scss";
import { DetalheProcesso } from "../../services/processo/models/detalhe-processo";

export const FormBuscarDetalhesProcesso = ({
    setDetalheProcesso,
}: {
    setDetalheProcesso: React.Dispatch<React.SetStateAction<DetalheProcesso | undefined>>;
}) => {
    return (
        <Formik
            initialValues={{
                numeroProcesso: "",
            }}
            validationSchema={YupObject().shape({
                numeroProcesso: YupString().min(25, "O número de processo deve conter exatamente 20 dígitos"),
            })}
            onSubmit={async (values) => {
                const _detalheProcesso = await ActionFeedback.processing({
                    title: "Buscando informações do processo",
                    execution: ProcessoService.obterDetalhesProcesso(values.numeroProcesso),
                    onError: async (error: any) => {
                        setDetalheProcesso(undefined);
                        return await ActionFeedback.error({
                            icon: "error",
                            html: error?.json?.erros
                                ? error.json.erros.join("<br />")
                                : error?.json?.title
                                ? error?.json?.title
                                : "Não foi possível obter os dados do processo",
                        });
                    },
                });

                setDetalheProcesso(_detalheProcesso);
            }}
        >
            {(formik) => (
                <Form>
                    <OmnijusCard
                        body={
                            <div className={styles.formBuscarDetalhesProcesso}>
                                <OmnijusCampoNumeroProcesso
                                    placeholder="Comece pesquisando por um processo"
                                    name="numeroProcesso"
                                    label="Número do Processo"
                                    actionIcon={<SearchIcon />}
                                    onAction={formik.submitForm}
                                />
                            </div>
                        }
                    />
                </Form>
            )}
        </Formik>
    );
};
