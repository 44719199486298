import { NomesTiposParametros, RequestApi } from "@omnijus/common";

export interface FiltroMovimentoRotulo {
    numeroProcesso?: string;
    dataHoraDistribuicaoDe?: Date;
    dataHoraDistribuicaoAte?: Date;
    dataHoraMovimentoDe?: Date;
    dataHoraMovimentoAte?: Date;
    idStatusDocumentoRotulo?: number;
    idRotuloMovimento?: string;
    usuarioAprovacao?: string;
    dataHoraAprovacaoDe?: Date;
    dataHoraAprovacaoAte?: Date;
    idCliente?: string;
    siglaUf?: string;
    idOrgao?: string;
}

const tiposParametros: NomesTiposParametros<Required<FiltroMovimentoRotulo>> = {
    dataHoraAprovacaoAte: "Date",
    dataHoraAprovacaoDe: "Date",
    dataHoraDistribuicaoAte: "Date",
    dataHoraDistribuicaoDe: "Date",
    dataHoraMovimentoAte: "Date",
    dataHoraMovimentoDe: "Date",
    idRotuloMovimento: "string",
    idCliente: "string",
    idStatusDocumentoRotulo: "number",
    numeroProcesso: "string",
    usuarioAprovacao: "string",
    idOrgao: "string",
    siglaUf: "string",
};

export const parseFiltroMovimentoRotulo = (queryString: string) =>
    RequestApi.parseQueryString(queryString, tiposParametros);
