import DataTable, { IDataTableColumn } from "react-data-table-component";
import React from "react";
import { Exportar } from "../../../shared/buttons/button-export-csv/button-export-csv";
import { RowDetalheProcesso } from "../../../pages/processos/row-detalhe-processo";
import { DetalheProcesso } from "../../../services/processo/models/detalhe-processo";
import { Rotulo } from "../../../shared/rotulo-movimentacao/rotulo-movimentacao";
import { arrayObjectToCSV, TableHeader, workbookToBase64 } from "../../../lib/export-format";
import { TriggerDownload } from "../../../lib/trigger-download";
import { dateToDDMMYYYY } from "@omnijus/common";
import { CreateWorkbook } from "../../../pages/processos/processo-excel-helper";
import { FiltroAplicado, FiltrosAplicados } from "../../../shared/form/filtros-aplicados/filtros-aplicados";

const dataTableColumns: IDataTableColumn<DetalheProcesso>[] = [
    {
        name: "Nº do processo",
        selector: (row) => row.numeroProcesso,
        sortable: true,
        minWidth: "15rem",
    },
    {
        name: "Última movimentação",
        selector: (row) => row.descricaoUltimoProcessoDocumento,
        sortable: true,
        maxWidth: "20rem",
    },
    {
        name: "Rótulos do Movimento",
        selector: (p) => (
            <>
                {p.rotulosUltimoMovimento?.map((mov) => (
                    <Rotulo key={mov.nome} {...mov} compacto />
                ))}
            </>
        ),
        maxWidth: "12rem",
        minWidth: "12rem",
    },
    {
        name: "Autor",
        selector: (row) => row.autor?.nome || "-",
        sortable: true,
        wrap: true,
    },
    {
        name: "Réu",
        selector: (row) => row.reu?.nome || "-",
        sortable: true,
    },
    {
        name: "Tipo de ação",
        selector: (row) => row.descricaoTipoAcao,
        sortable: true,
        wrap: true,
    },
    {
        name: "Comarca",
        selector: (row) => row.comarca,
        sortable: true,
    },
];

const downloadExportar = async (listaProcessos: DetalheProcesso[], type: "xlsx" | "csv") => {
    const headers: TableHeader[] = [
        { label: "Nº do processo", selector: "numeroProcesso", width: 25 },
        { label: "Última movimentação", selector: "descricaoUltimoProcessoDocumento", width: 128 },
        { label: "Autor", selector: "autor", width: 43 },
        { label: "Réu", selector: "reu", width: 32 },
        { label: "Tipo de ação", selector: "descricaoTipoAcao", width: 22 },
        { label: "Tribunal", selector: "tribunal", width: 14 },
        { label: "Comarca", selector: "comarca", width: 20 },
        { label: "Foro", selector: "foro", width: 20 },
        { label: "Vara", selector: "vara", width: 22 },
        { label: "Data da distribuição", selector: "dataHoraDistribuicao", width: 18 },
        { label: "Julgador", selector: "juiz", width: 29 },
        { label: "Assunto", selector: "assunto", width: 58 },
        { label: "Valor da ação", selector: "valorAcao", reais: true, width: 14 },
        { label: "Valor do risco", selector: "valorRisco", reais: true, width: 14 },
        { label: "Status", selector: "descricaoStatusProcesso", width: 10 },
    ];

    const data = listaProcessos.map((processo) => {
        const obj: any = {
            ...processo,
            autor: processo.autor?.nome,
            reu: processo.reu?.nome,
            assunto: processo.assunto?.join(),
            dataHoraDistribuicao: new Date(processo.dataHoraDistribuicao),
        };

        return obj;
    });
    if (type === "csv") {
        TriggerDownload.csv(arrayObjectToCSV(data, headers), `relatorio-processos-${dateToDDMMYYYY(new Date())}.csv`);
    } else {
        // type === 'xlsx'
        TriggerDownload.xlsx(
            await workbookToBase64(CreateWorkbook(data, headers)),
            `relatorio-processos-${dateToDDMMYYYY(new Date())}.xlsx`
        );
    }
};

export interface GridListagemProcessosProps {
    listaProcessos: DetalheProcesso[];
    filtrosAplicados: FiltroAplicado[];
    onRowClicked?: (p: DetalheProcesso) => void;
}

export const GridListagemProcessos = ({
    listaProcessos,
    filtrosAplicados,
    onRowClicked,
}: GridListagemProcessosProps) => {
    return (
        <DataTable
            customStyles={{
                rows: {
                    style: {
                        cursor: "pointer",
                    },
                },
            }}
            title={
                <React.Fragment>
                    <p>Processos</p>
                    <FiltrosAplicados tags={filtrosAplicados} />
                </React.Fragment>
            }
            pagination={true}
            paginationPerPage={20}
            noDataComponent={<p>Nenhum registro encontrado!</p>}
            actions={
                listaProcessos && listaProcessos.length > 0 ? (
                    <>
                        <label>Exportar: </label>
                        <Exportar onClick={() => downloadExportar(listaProcessos, "xlsx")} texto="Excel (xlsx)" />
                        <Exportar onClick={() => downloadExportar(listaProcessos, "csv")} texto="csv" />
                    </>
                ) : null
            }
            columns={dataTableColumns}
            onRowClicked={(p) => onRowClicked && onRowClicked(p)}
            data={listaProcessos || []}
            expandableRows={true}
            expandableRowsComponent={<RowDetalheProcesso />}
        />
    );
};
