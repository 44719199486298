import { Loading } from "@omnijus/common";
import DownloadIcon from "../../../../assets/images/download-icon.svg";
import { ActionFeedback } from "@omnijus/common";
import React, { useEffect, useState } from "react";
import { DocumentosAutomatizadosService } from "../../../../services/processo/documentos-automatizados-service";
import { DocumentoAutomatizado } from "../../../../services/processo/models/documento-automatizado";
import { ProcessoDetalheService } from "../../../../services/processo/processo-detalhe-service";

export const DocumentosAutomatizados = (props: { idProcesso: string }) => {
    const [promise, setPromise] = useState<Promise<DocumentoAutomatizado[] | undefined>>();
    const [nDocumentos, setNDocumentos] = useState(0);

    useEffect(
        () =>
            setPromise(
                ProcessoDetalheService.getDocumentosAutomatizados(props.idProcesso).then((res) => {
                    setNDocumentos(res?.length || 0);
                    return res;
                })
            ),
        [props.idProcesso]
    );

    const downloadDocumento = async (idProcessoDocumento: string, filename: string) => {
        await ActionFeedback.processing({
            title: "Gerando arquivo para download",
            execution: DocumentosAutomatizadosService.downloadDocumentoAutomatizado(idProcessoDocumento, filename),
        });
    };
    const downloadTodosDocumentos = async (filename: string) => {
        await ActionFeedback.processing({
            title: "Gerando arquivo para download",
            execution: DocumentosAutomatizadosService.downloadDocumentosAutomatizadosProcesso(
                props.idProcesso,
                filename
            ),
        });
    };

    return (
        <div className="card-processo-detalhe documentos-processo">
            <header>
                <h3>Documentos automatizados</h3>
                {nDocumentos > 0 ? (
                    <button onClick={() => downloadTodosDocumentos(`${props.idProcesso}.zip`)}>
                        <img className="download-icon" alt="Ícone de download" src={DownloadIcon} />
                    </button>
                ) : (
                    <></>
                )}
            </header>
            <Loading promise={promise}>
                {(documentos) => {
                    if (documentos) {
                        return (
                            <div className="card-body-scrollable">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Data/Hora Geração</th>
                                            <th>Descrição</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {documentos.length === 0 ? (
                                            <tr>
                                                <td className="center" colSpan={3}>
                                                    Nenhum documento automatizado.
                                                </td>
                                            </tr>
                                        ) : (
                                            documentos.map((doc, i) => (
                                                <tr key={doc.idDocumento}>
                                                    <td className="col-data">
                                                        {new Date(doc.dataGeracaoDocumento).toLocaleDateString("pt-BR")}
                                                    </td>
                                                    <td>{doc.descricaoDocumento}</td>
                                                    <td className="col-download">
                                                        <button
                                                            onClick={() =>
                                                                downloadDocumento(
                                                                    doc.idDocumento,
                                                                    `${doc.descricaoDocumento}.zip`
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                className="download-icon"
                                                                alt="Ícone de download"
                                                                src={DownloadIcon}
                                                            />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        );
                    } else {
                        return <></>;
                    }
                }}
            </Loading>
        </div>
    );
};
