import { ActionFeedback, OmnijusCard } from "@omnijus/common";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { OmnijusCampoNumeroProcesso } from "../../shared/form/custom-fields/omnijus-campo-numero-processo";
import styles from "./agendamentos.module.scss";
import { ReactComponent as SearchIcon } from "../../assets/images/search-icon.svg";
import { AgendamentoProcessoService } from "../../services/agendamento/agendamento-processo-service";
import { ProcessoService } from "../../services/processo/processo-service";
import { DetalheProcesso } from "../../services/processo/models/detalhe-processo";
import { object as YupObject, string as YupString } from "yup";
import { PastaCliente } from "../../services/agendamento/models/pasta-cliente";
import { MovimentosProcesso } from "../../shared/movimentos-processo/movimentos-processo";
import { FormDadosAgendamento } from "./form-dados-agendamento";
import { Providencia } from "../../services/providencia/models/providencia";
import { Movimento } from "../../services/agendamento/models/movimento";
import { CabecalhoProcesso } from "../../pages/processos/detalhes-processo/detalhes-cards/cabecalho-processo";
import { ProvidenciaService } from "../../services/providencia/providencia-service";
interface ModalCriarAgendamentosProps {
    isModalOpen: boolean;
    onCloseModal: () => void;
    numeroProcesso?: string;
    idProcessoMovimento?: string;
}

export const ModalCriarAgendamentos = (props: ModalCriarAgendamentosProps) => {
    const [detalheProcesso, setDetalheProcesso] = useState<DetalheProcesso>();
    const [providencias, setProvidencias] = useState<Providencia[]>();
    const [pastaCliente, setPastaCliente] = useState<PastaCliente>();
    const [movimentos, setMovimentos] = useState<Movimento[]>([]);

    useEffect(() => {
        (async () => {
            setProvidencias((await ProvidenciaService.listarProvidencia(true, undefined)) || []);
        })();
    }, []);

    useEffect(() => {
        if (detalheProcesso) {
            (async () => {
                const _movimentos = await ProcessoService.obterMovimentos(detalheProcesso.idProcesso);

                if (_movimentos) {
                    if (props.idProcessoMovimento) {
                        setMovimentos(_movimentos.filter((x) => x.idProcessoMovimento === props.idProcessoMovimento));
                    } else {
                        setMovimentos(_movimentos);
                    }
                } else {
                    setMovimentos([]);
                }
            })();
        }
    }, [detalheProcesso, props.idProcessoMovimento]);

    useEffect(() => {
        if (detalheProcesso) {
            (async () => {
                const _pastaCliente = await ActionFeedback.processing({
                    title: "Buscando Pasta Cliente",
                    execution: AgendamentoProcessoService.obterPastaCliente(detalheProcesso.idProcesso),
                    onError: async (error: any) => {
                        setPastaCliente(undefined);
                        return await ActionFeedback.error({
                            icon: "error",
                            html: error?.json?.erros
                                ? error.json.erros.join("<br />")
                                : error?.json?.title
                                ? error?.json?.title
                                : "Não foi possível obter a Pasta Cliente",
                        });
                    },
                });

                setPastaCliente(_pastaCliente);
            })();
        }
    }, [detalheProcesso]);

    return (
        <Modal
            style={{
                content: {
                    margin: "auto",
                },
            }}
            isOpen={props.isModalOpen}
            onRequestClose={props.onCloseModal}
            shouldCloseOnEsc
            ariaHideApp={false}
            shouldCloseOnOverlayClick={false}
        >
            <div className={styles.agendamentos}>
                <h2>Criar Agendamentos</h2>
                <Formik
                    initialValues={{
                        numeroProcesso: props.numeroProcesso || "",
                    }}
                    validationSchema={YupObject().shape({
                        numeroProcesso: YupString().min(25, "O número de processo deve conter exatamente 20 dígitos"),
                    })}
                    onSubmit={async (values) => {
                        const _detalheProcesso = await ActionFeedback.processing({
                            title: "Buscando informações do processo",
                            execution: ProcessoService.obterDetalhesProcesso(values.numeroProcesso),
                            onError: async (error: any) => {
                                setDetalheProcesso(undefined);
                                return await ActionFeedback.error({
                                    icon: "error",
                                    html: error?.json?.erros
                                        ? error.json.erros.join("<br />")
                                        : error?.json?.title
                                        ? error?.json?.title
                                        : "Não foi possível obter os dados do processo",
                                });
                            },
                        });

                        setDetalheProcesso(_detalheProcesso);
                    }}
                >
                    {(formik) => (
                        <Form>
                            <OmnijusCard
                                body={
                                    <div className={styles.modalCriarAgendamentos}>
                                        <OmnijusCampoNumeroProcesso
                                            disabled={props.numeroProcesso !== undefined && props.numeroProcesso !== ""}
                                            placeholder="Comece pesquisando por um processo"
                                            name="numeroProcesso"
                                            label="Número do Processo"
                                            actionIcon={<SearchIcon />}
                                            onAction={formik.submitForm}
                                        />
                                    </div>
                                }
                            />
                        </Form>
                    )}
                </Formik>

                <div className={styles.criarAgendamentos}>
                    {detalheProcesso && <CabecalhoProcesso processo={detalheProcesso} />}
                    {detalheProcesso && movimentos && (
                        <MovimentosProcesso idProcesso={detalheProcesso.idProcesso} movimentos={movimentos} />
                    )}
                </div>

                <FormDadosAgendamento
                    detalheProcesso={detalheProcesso}
                    providencias={providencias}
                    setPastaCliente={setPastaCliente}
                    pastaCliente={pastaCliente}
                    setDetalheProcesso={setDetalheProcesso}
                    onCloseModal={props.onCloseModal}
                    idProcessoMovimento={props.idProcessoMovimento}
                    movimentos={movimentos}
                />
            </div>
        </Modal>
    );
};
