import { ActionFeedback, ButtonPrimary, OmnijusCard } from "@omnijus/common";
import { Form, Formik } from "formik";
import React from "react";
import { AtualizacaoProcessoService } from "../../services/atualizacao-processo/atualizacao-processo-service";
import { AtualizarStatusProcessoCommand } from "../../services/atualizacao-processo/models/atualizar-status-command";
import { DetalheProcesso } from "../../services/processo/models/detalhe-processo";
import { OmnijusFiltroStatusProcesso } from "../../shared/form/custom-fields/omnijus-filtro-status-processo";
import { OmnijusDateField } from "../../shared/form/fields/omnijus-date-field";
import styles from "./atualizacao-processo.module.scss";
import { date as YupDate, number as YupNumber, object as YupObject } from "yup";
import { StatusProcesso } from "../../services/processo/models/status-processo";

export interface FormAtualizacaoStatusProps {
    detalheProcesso: DetalheProcesso;
    onSubmit?: () => void;
}

export const FormAtualizacaoStatus = ({ detalheProcesso, onSubmit }: FormAtualizacaoStatusProps) => {
    return (
        <OmnijusCard
            header={<h2>Atualização de status</h2>}
            body={
                <Formik
                    onSubmit={async (values) => {
                        const confirmou = await ActionFeedback.confirm(
                            "Atenção!",
                            "Sim",
                            "Não",
                            "Confirma a alteração de status?",
                            "warning"
                        );

                        if (confirmou) {
                            const command: AtualizarStatusProcessoCommand = {
                                idProcesso: detalheProcesso.idProcesso,
                                ...values,
                            };

                            await ActionFeedback.processing({
                                title: "Processando...",
                                execution: AtualizacaoProcessoService.atualizarStatus(command),
                            });

                            await ActionFeedback.info({
                                text: "Atualização de status realizada com sucesso!",
                            });

                            onSubmit && onSubmit();
                        }
                    }}
                    initialValues={{
                        dataStatus: new Date(),
                        idStatusProcesso:
                            detalheProcesso.idStatusProcesso === StatusProcesso.Encerrado ||
                            detalheProcesso.idStatusProcesso === StatusProcesso.RetiradoPeloCliente
                                ? detalheProcesso.idStatusProcesso
                                : 0,
                    }}
                    validationSchema={YupObject().shape({
                        dataStatus: YupDate().required("Informe uma data").typeError("Data inválida"),
                        idStatusProcesso: YupNumber().required("Selecione um status").positive("Selecione um status"),
                    })}
                >
                    <Form>
                        <div className={styles.formLinha1}>
                            <OmnijusDateField name="dataStatus" label="Data Status" />
                            <OmnijusFiltroStatusProcesso
                                filtrarStatus={[
                                    StatusProcesso.Encerrado,
                                    StatusProcesso.RetiradoPeloCliente,
                                    StatusProcesso.AssumidoPeloCedente,
                                    StatusProcesso.NaoProcessadoNaPlataforma,
                                ]}
                            />
                        </div>
                        <div className={`${styles.alignRight} ${styles.marginTop}`}>
                            <ButtonPrimary type="submit">Salvar</ButtonPrimary>
                        </div>
                    </Form>
                </Formik>
            }
        />
    );
};
