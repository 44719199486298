import { RequestApi } from "@omnijus/common";
import { CLIENT_ID } from "../../services/client-id";
import { PrestadorProfile } from "./prestador-profile";

const requestApi = new RequestApi(CLIENT_ID);

const prestadorServicoUsuarioUrl =
    process.env.REACT_APP_BFF_OPERACIONAL_API_URL + "/api/Processo/v1/PrestadorServicoUsuario";

export const PrestadorServicoUsuarioService = {
    getProfile: async (idUsuario: string) => {
        let url = new URL(`${prestadorServicoUsuarioUrl}/${idUsuario}/ObterTextoPerfilPrestador`);
        return await requestApi.get<PrestadorProfile>(url.toString());
    },
};
