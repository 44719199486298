import { RequestApi } from "@omnijus/common";
import { CLIENT_ID } from "../../services/client-id";

const reportUrl = process.env.REACT_APP_BFF_OPERACIONAL_API_URL + "/api/Processo/v1/ProcessoAudiencia";

const requestApi = new RequestApi(CLIENT_ID);

export interface ResponseRelatorioAudiencias {
    base64: string;
}

export const RelatorioAudienciasService = {
    relatorioAudiencias: async (body: any) => {
        const url = `${reportUrl}/RelatorioAudiencias`;
        return await requestApi.post<ResponseRelatorioAudiencias | undefined>(url, body);
    },
};
