import React, { useEffect, useState } from "react";
import { OmnijusSelectField } from "../fields/omnijus-select-field";
import { Loading } from "@omnijus/common";
import { ProcessoFiltroService } from "../../../services/filtros/filtros-processo-service";
import { FiltroStatus } from "../../../services/filtros/models/filtro-status";

interface OmnijusFiltroStatusProcessoProps {
    hideLabel?: boolean;
    name?: string;
    label?: string;
    isDisabled?: boolean;
    isMulti?: boolean;
    filtrarStatus?: number[];
}

const ObterResultadoFiltrado = (opcoes: { id: number; value: string }[] | undefined, filtro: number[] | undefined) => {
    if (!opcoes) {
        return undefined;
    }

    if (filtro) {
        return opcoes.filter((o) => filtro.includes(o.id));
    }

    return opcoes;
};

export const OmnijusFiltroStatusProcesso = (props: OmnijusFiltroStatusProcessoProps) => {
    const [promise, setPromise] = useState<Promise<FiltroStatus[] | undefined>>();

    useEffect(() => {
        setPromise(ProcessoFiltroService.listarStatus());
    }, []);

    return (
        <Loading promise={promise}>
            {(opcoes) => (
                <OmnijusSelectField
                    isMulti={props.isMulti}
                    name={props.name || "idStatusProcesso"}
                    label={props.label || "Status Processo"}
                    options={ObterResultadoFiltrado(opcoes, props.filtrarStatus)
                        ?.map((o) => ({
                            value: o.id.toString(),
                            label: o.value.toString(),
                        }))
                        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
                />
            )}
        </Loading>
    );
};
