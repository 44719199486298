import { ProcessoAcordoQuantificado } from "./models/processo-acordo-quantificado";
import { QuantidadeProcessosResultadoSentenca } from "./models/qnt-processos-resultado-sentenca";
import { ProcessoRiscoRangeValor } from "./models/valores-risco";
import { SumarioProcessosAtivos } from "./models/sumario-processos-ativos";
import { QntProcessosPorPedido } from "./models/qnt-processos-pedido";
import { QntProcessosPorAdvogado } from "./models/qnt-processos-advogado";
import { QntProcessosPorComarca } from "./models/qnt-processos-comarca";
import { QntProcessosPorForo } from "./models/qnt-processos-foro";
import { QntProcessosPorJuiz } from "./models/qnt-processos-juiz";
import { QntProcessosPorUF } from "./models/qnt-processos-uf";
import { QntProcessosPorVara } from "./models/qnt-processos-vara";
import { QuantificarProcessoPorTempoResponse } from "./models/quantificar-processo-por-tempo-response";
import { ResumoEntradaSaidaRespose } from "./models/resumo-entrada-saida-response";
import { ResumoValorCausaResponse } from "./models/resumo-valor-causa-response";
import { RequestApi } from "@omnijus/common";
import { CLIENT_ID } from "../../services/client-id";

const requestApi = new RequestApi(CLIENT_ID);

const dashboardUrl = process.env.REACT_APP_BFF_OPERACIONAL_API_URL + "/api/Dashboard/v1/ProcessoQuantificado";

export const DashboardService = {
    getAcordos: async () => {
        const url = `${dashboardUrl}/QuantificarProcessosAcordo`;
        return await requestApi.get<ProcessoAcordoQuantificado>(url);
    },
    getQuantificarProcessoPorTempo: async () => {
        const url = `${dashboardUrl}/QuantificarProcessoPorTempo`;
        return await requestApi.get<QuantificarProcessoPorTempoResponse[]>(url).then((res) => {
            return res?.map((r) => ({
                ...r,
                mesAnoInicial: new Date(r.mesAnoInicial),
                mesAnoFinal: new Date(r.mesAnoFinal),
            }));
        });
    },
    getResumoEntradaSaida: async (numeroMeses: number) => {
        const url = `${dashboardUrl}/ResumoEntradaSaida?numeroMeses=${numeroMeses}`;
        const res = await requestApi.get<ResumoEntradaSaidaRespose[]>(url);
        res?.forEach((r) => (r.anoMesReferencia = new Date(r.anoMesReferencia)));
        return res;
    },
    getValoresRisco: async () => {
        const url = `${dashboardUrl}/QuantificarProcessoRiscoRangeValor`;
        return await requestApi.get<ProcessoRiscoRangeValor[]>(url);
    },
    getValoresCausa: async () => {
        let url = `${dashboardUrl}/QuantificarProcessoRangeValor`;
        return await requestApi.get<ResumoValorCausaResponse>(url);
    },
    getQuantificarProcessoResultadoSentenca: async () => {
        const url = `${dashboardUrl}/QuantificarProcessoResultadoSentenca`;
        return await requestApi.get<QuantidadeProcessosResultadoSentenca[]>(url);
    },
    quantificarProcessoAtivo: async () => {
        let url = `${dashboardUrl}/QuantificarProcessoAtivo`;
        return await requestApi.get<SumarioProcessosAtivos>(url);
    },
    quantificarProcessoAtivoPorCliente: async (idCliente: string) => {
        let url = new URL(`${dashboardUrl}/QuantificarProcessoAtivoPorCliente`);

        url.searchParams.append("idCliente", idCliente);

        return await requestApi.get<SumarioProcessosAtivos>(url.toString());
    },
    getProcessosPorPedido: async (idStatusProcesso: number, quantidadePedido: number) => {
        const url = `${dashboardUrl}/StatusProcesso/${idStatusProcesso}/Pedido/${quantidadePedido}/QuantificarProcessosPorPedido`;
        return await requestApi.get<QntProcessosPorPedido[]>(url);
    },
    getProcessosPorAdvogado: async (idStatusProcesso: number, poloAtivo: boolean, quantidadeAdvogado?: number) => {
        const url = new URL(
            `${dashboardUrl}/StatusProcesso/${idStatusProcesso}/Parte/${poloAtivo}/Advogado/QuantificarProcessosPorAdvogado`
        );

        if (quantidadeAdvogado !== undefined) {
            url.searchParams.set("quantidadeAdvogado", quantidadeAdvogado.toString());
        }

        return await requestApi.get<QntProcessosPorAdvogado[]>(url.toString());
    },
    getQntProcessosPorUF: async (idStatusProcesso: number) => {
        const url = `${dashboardUrl}/StatusProcesso/${idStatusProcesso}/QuantificarProcessosPorUF`;
        return await requestApi.get<QntProcessosPorUF[]>(url);
    },
    getQntProcessosPorComarca: async (idStatusProcesso: number, siglaUf: string) => {
        const url = `${dashboardUrl}/StatusProcesso/${idStatusProcesso}/UF/${siglaUf}/QuantificarProcessosPorComarca`;
        return await requestApi.get<QntProcessosPorComarca[]>(url);
    },
    getQntProcessosPorForo: async (idStatusProcesso: number, siglaUf: string, nomeComarca: string) => {
        const url = `${dashboardUrl}/StatusProcesso/${idStatusProcesso}/UF/${siglaUf}/Comarca/${nomeComarca}/QuantificarProcessosPorForo`;
        return await requestApi.get<QntProcessosPorForo[]>(url);
    },
    getQntProcessosPorVara: async (idStatusProcesso: number, idOrgaoUnidade: string) => {
        const url = `${dashboardUrl}/StatusProcesso/${idStatusProcesso}/OrgaoUnidade/${idOrgaoUnidade}/QuantificarProcessosPorVara`;
        return await requestApi.get<QntProcessosPorVara[]>(url);
    },
    getQntProcessosPorJuiz: async (idStatusProcesso: number, idOrgaoUnidadeSecao: string) => {
        const url = `${dashboardUrl}/StatusProcesso/${idStatusProcesso}/OrgaoUnidadeSecao/${idOrgaoUnidadeSecao}/QuantificarProcessosPorJuiz`;
        return await requestApi.get<QntProcessosPorJuiz[]>(url);
    },
};
