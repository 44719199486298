import { useFormikContext } from "formik";
import React, { useEffect, useMemo } from "react";
import { ModuloEnum } from "../../../../services/configuracoes/modulo/modulo-viewmodel";
import { PerfilService } from "../../../../services/configuracoes/perfil/perfil-service";
import { PerfilViewModel } from "../../../../services/configuracoes/perfil/perfil-viewmodel";
import { ButtonOutlined } from "../../../../shared/buttons/button-outlined/button-outlined";
import { OmnijusFiltroCliente } from "../../../../shared/form/custom-fields/omnijus-filtro-cliente";
import { OmnijusFiltroEscritorio } from "../../../../shared/form/custom-fields/omnijus-filtro-escritorio";
import { OmnijusFiltroModulo } from "../../../../shared/form/custom-fields/omnijus-filtro-modulo";
import { OmnijusFiltroPerfil } from "../../../../shared/form/custom-fields/omnijus-filtro-perfil";

import styles from "../usuarios.module.scss";

export interface ItemFormPerfilProps {
    hasBtnAdd: boolean;
}

export const ItemFormPerfil = (props: ItemFormPerfilProps) => {
    const { values, setFieldValue, isValid } = useFormikContext<PerfilViewModel>();

    useEffect(() => {
        values.idModulo !== ModuloEnum.CLIENTE && setFieldValue("idCliente", "");
        values.idModulo !== ModuloEnum.ESCRITORIO && setFieldValue("idEscritorio", "");
    }, [values.idModulo, setFieldValue]);

    useEffect(() => {
        setFieldValue("idPerfil", undefined);
    }, [values.idEscritorio, values.idCliente, setFieldValue]);

    const possuiModuloValido = useMemo(() => {
        if (!values.idModulo) {
            return false;
        }

        if (values.idModulo === ModuloEnum.CLIENTE && !values.idCliente) {
            return false;
        }

        if (values.idModulo === ModuloEnum.ESCRITORIO && !values.idEscritorio) {
            return false;
        }

        return true;
    }, [values.idModulo, values.idCliente, values.idEscritorio]);

    const promisePerfil = useMemo(async () => {
        if (!possuiModuloValido) {
            return [];
        }

        const perfis = await PerfilService.listar(
            {
                idModulo: values.idModulo,
                idCliente: values.idCliente,
                idEscritorio: values.idEscritorio,
            },
            {
                rowsPerPage: 9999,
            }
        );

        return perfis
            ? perfis.results.map((p) => ({
                  value: p.id,
                  label: p.descricao,
              }))
            : [];
    }, [values.idModulo, values.idCliente, values.idEscritorio, possuiModuloValido]);

    return (
        <div className={styles.gridForm}>
            <OmnijusFiltroModulo />
            {values.idModulo === ModuloEnum.CLIENTE && <OmnijusFiltroCliente name="idCliente" />}
            {values.idModulo === ModuloEnum.ESCRITORIO && <OmnijusFiltroEscritorio name="idEscritorio" />}
            {possuiModuloValido && <OmnijusFiltroPerfil promise={promisePerfil} />}
            {props.hasBtnAdd && (
                <div className={styles.itemAdd}>
                    <ButtonOutlined disabled={!isValid} type="submit">
                        Adicionar
                    </ButtonOutlined>
                </div>
            )}
        </div>
    );
};
