import { RequestApi } from "@omnijus/common";
import { CLIENT_ID } from "../../services/client-id";
import { AtualizacaoProcesso } from "./models/atualizacao-processo";
import { FiltroAtualizacaoProcesso } from "./models/filtro-atualizacao-processo";
import { AtualizarFaseMomentoProcessoCommand } from "./models/atualizar-fase-momento-command";
import { AtualizarStatusProcessoCommand } from "./models/atualizar-status-command";
import { AdicaoMovimento } from "./models/adicao-movimento";

const requestApi = new RequestApi(CLIENT_ID);

const atualizacaoProcessoUrl = process.env.REACT_APP_BFF_OPERACIONAL_API_URL + "/api/Processo/v1/AtualizacaoProcesso";

export const AtualizacaoProcessoService = {
    listar: async (filtro: FiltroAtualizacaoProcesso) => {
        const url = new URL(`${atualizacaoProcessoUrl}/Listar`);
        url.search = RequestApi.objectToQueryString(filtro);
        return await requestApi.get<AtualizacaoProcesso[]>(url.toString());
    },
    atualizarFaseMomento: async (command: AtualizarFaseMomentoProcessoCommand) => {
        const url = `${atualizacaoProcessoUrl}/AtualizarFaseMomento`;
        return await requestApi.patch(url, command);
    },
    atualizarStatus: async (command: AtualizarStatusProcessoCommand) => {
        const url = `${atualizacaoProcessoUrl}/AtualizarStatus`;
        return await requestApi.patch(url, command);
    },
    adicionarDocumentosAoProcessoMovimento: async (
        idProcessoMovimento: string,
        command: {
            idProcesso: string;
            numeroProcesso: string;
            documentos: (File | null)[];
            idsTipoDocumento: (number | undefined)[];
            descricoesTipoDocumento: (string | undefined)[];
        }
    ) => {
        const url = `${atualizacaoProcessoUrl}/${idProcessoMovimento}/AdicionarDocumentos`;
        return await requestApi.postFormdata(url, command);
    },
    adicionarMovimento: async (adicaoMovimento: AdicaoMovimento) => {
        const url = `${atualizacaoProcessoUrl}/AdicionarMovimento`;
        return await requestApi.post(url, adicaoMovimento);
    },
};
