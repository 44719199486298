import { Movimento } from "./../agendamento/models/movimento";
import { HistoricoSolicitacaoPeticionamentoDocumentoViewModel } from "./models/historico-solicitacao-peticionamento-documento";
import { SolicitacaoPeticionamentoDocumentoViewModel } from "./models/solicitacao-peticionamento-documento";
import { SolicitacaoPeticionamentoListada } from "./models/solicitacao-peticionamento-listada";
import { CLIENT_ID } from "../../services/client-id";
import { RequestApi } from "@omnijus/common";
import { CriarSolicitacaoPeticionamentoCommand } from "./models/criar-peticionamento";
import { MotivoRessalvaAprovacaoPeticionamento } from "./models/motivo-ressalva-aprovacao-peticionamento";
import { SolicitacaoPeticionamentoAprovarComRessalvaCommand } from "./models/aprovar-com-ressalva";
import { MotivoReprovacaoPeticionamento } from "./models/motivo-reprovacao-peticionamento";
import { SolicitacaoPeticionamentoReprovarCommand } from "./models/reprovar";
import { SolicitacaoPeticionamentoMotivoReprovacao } from "./models/solicitacao-peticionamento-motivo-reprovacao";

const solicitacaoPeticionamentoUrl =
    process.env.REACT_APP_BFF_OPERACIONAL_API_URL + "/api/Processo/v1/SolicitacaoPeticionamento";

const processoUrl = process.env.REACT_APP_BFF_OPERACIONAL_API_URL + "/api/Processo/v1/Processo";

const requestApi = new RequestApi(CLIENT_ID);

export interface ListarPeticionamentosParametros {
    DataSolicitacaoInicial?: Date;
    DataSolicitacaoFinal?: Date;
    IdCliente?: string;
    IdStatusPeticionamento?: number;
    numeroProcesso?: string;
    idProvidencia?: number;
    nomeUserAprovacaoReprovacao?: string;
    dataHoraAprovacaoReprovacaoInicial?: Date;
    dataHoraAprovacaoReprovacaoFinal?: Date;
    idEscritorio?: string;
}

export const listarPeticionamentos = async (
    parametros?: ListarPeticionamentosParametros
): Promise<SolicitacaoPeticionamentoListada[] | undefined> => {
    const url = new URL(solicitacaoPeticionamentoUrl);
    if (parametros) {
        url.search = RequestApi.objectToQueryString(parametros);
    }
    const res = await requestApi.get<SolicitacaoPeticionamentoListada[]>(url.toString());
    return (
        res &&
        res.map((peticionamento) => ({
            ...peticionamento,
            dataDistribuicao: peticionamento.dataDistribuicao && new Date(peticionamento.dataDistribuicao),
            dataPrevistaPeticionamento:
                peticionamento.dataPrevistaPeticionamento && new Date(peticionamento.dataPrevistaPeticionamento),
        }))
    );
};

export const responderSolicitacaoPeticionamento = (idSolicitacaoPeticionamento: string, aprovar: boolean) => {
    const url = new URL(solicitacaoPeticionamentoUrl);
    url.searchParams.set("idSolicitacaoPeticionamento", idSolicitacaoPeticionamento);
    url.searchParams.set("aprovar", String(aprovar));

    return requestApi.put(url.toJSON(), null, (res, json) => ({
        res: res,
        json: json,
    }));
};

export const downloadDocumentoProtocoloPeticionamento = (idDocumento: string) => {
    const url = `${processoUrl}/DownloadDocumento/${idDocumento}`;
    return requestApi.download(url, "recibo.zip");
};

export const downloadDocumentosPeticionamento = (idSolicitacaoPeticionamento: string) => {
    const url = new URL(`${solicitacaoPeticionamentoUrl}/Documentos`);
    url.searchParams.set("idSolicitacaoPeticionamento", idSolicitacaoPeticionamento);
    return requestApi.download(url.toString(), "documentos-peticionamento.zip");
};

export const listarDocumentos = async (
    idSolicitacaoPeticionamento: string
): Promise<SolicitacaoPeticionamentoDocumentoViewModel[] | undefined> => {
    const url = new URL(`${solicitacaoPeticionamentoUrl}/${idSolicitacaoPeticionamento}/ListarDocumentos`);

    return await requestApi.get<SolicitacaoPeticionamentoDocumentoViewModel[]>(url.toString());
};

export const removerDocumento = async (idSolicitacaoPeticionamentoDocumento: string) => {
    const url = new URL(`${solicitacaoPeticionamentoUrl}/RemoverDocumento/${idSolicitacaoPeticionamentoDocumento}`);

    return await requestApi.delete(url.toString());
};

export const listarHistoricoSolicitacaoPeticionamentoDocumento = async (idSolicitacaoPeticionamento: string) => {
    const url = new URL(
        `${solicitacaoPeticionamentoUrl}/${idSolicitacaoPeticionamento}/HistoricoSolicitacaoPeticionamentoDocumento`
    );

    return await requestApi.get<HistoricoSolicitacaoPeticionamentoDocumentoViewModel[]>(url.toString());
};

export const substituirDocumento = async (idSolicitacaoPeticionamentoDocumento: string, novoDocumento: File) => {
    const url = new URL(`${solicitacaoPeticionamentoUrl}/SubstituirDocumento/${idSolicitacaoPeticionamentoDocumento}`);

    return await requestApi.postFormdata(url.toString(), { novoDocumento }, (res, json) => ({
        res: res,
        json: json,
    }));
};

export const alterarDataPrevistaPeticionamento = async (
    idSolicitacaoPeticionamento: string,
    novaDataPrevistaPeticionamento: Date
) => {
    const url = new URL(
        `${solicitacaoPeticionamentoUrl}/${idSolicitacaoPeticionamento}/AlterarDataPrevistaPeticionamento`
    );

    return await requestApi.patch(url.toString(), { novaDataPrevistaPeticionamento }, (res, json) => ({
        res: res,
        json: json,
    }));
};

export const adicionarDocumentosAoPeticionamento = async (
    idSolicitacaoPeticionamento: string,
    documentos: {
        documentos: (File | null)[];
        idsTipoDocumento: (number | undefined)[];
        descricoesTipoDocumento: (string | undefined)[];
    }
) => {
    const url = `${solicitacaoPeticionamentoUrl}/${idSolicitacaoPeticionamento}/AdicionarDocumentos`;

    return await requestApi.postFormdata(url, documentos, (res, json) => ({
        res: res,
        json: json,
    }));
};

export const obterSolicitacaoPeticionamento = async (idSolicitacaoPeticionamento: string) => {
    const url = `${solicitacaoPeticionamentoUrl}/${idSolicitacaoPeticionamento}`;

    return requestApi.get<SolicitacaoPeticionamentoListada>(url, (res, json) => ({
        res: res,
        json: json,
    }));
};

export const criarSolicitacaoPeticionamento = async (command: CriarSolicitacaoPeticionamentoCommand) => {
    const url = solicitacaoPeticionamentoUrl;

    return requestApi.post(url, command, (res, json) => ({
        res: res,
        json: json,
    }));
};

export const obterMovimentoGerador = async (idProcessoMovimento: string) => {
    const url = `${solicitacaoPeticionamentoUrl}/ObterMovimentoGerador/${idProcessoMovimento}`;

    return await requestApi.get<Movimento>(url, (res, json) => ({
        res: res,
        json: json,
    }));
};

export const listarMotivosRessalva = async () => {
    const url = `${solicitacaoPeticionamentoUrl}/MotivosRessalva`;

    return await requestApi.get<MotivoRessalvaAprovacaoPeticionamento[] | undefined>(url, (res, json) => ({
        res: res,
        json: json,
    }));
};

export const aprovarComRessalva = async (command: SolicitacaoPeticionamentoAprovarComRessalvaCommand) => {
    const url = `${solicitacaoPeticionamentoUrl}/AprovarComRessalva`;

    return await requestApi.post(url, command, (res, json) => ({
        res: res,
        json: json,
    }));
};

export const listarMotivosReprovacao = async (idProvidencia: number) => {
    const url = new URL(`${solicitacaoPeticionamentoUrl}/MotivosReprovacao`);

    url.searchParams.append("idProvidencia", idProvidencia.toString());

    return await requestApi.get<MotivoReprovacaoPeticionamento[] | undefined>(url.toString(), (res, json) => ({
        res: res,
        json: json,
    }));
};

export const reprovar = async (command: SolicitacaoPeticionamentoReprovarCommand) => {
    const url = `${solicitacaoPeticionamentoUrl}/Reprovar`;

    return await requestApi.post(url, command, (res, json) => ({
        res: res,
        json: json,
    }));
};

export const listarSolicitacaoPeticionamentoMotivoReprovacao = async (idSolicitacaoPeticionamento: string) => {
    const url = `${solicitacaoPeticionamentoUrl}/${idSolicitacaoPeticionamento}/MotivosReprovacao`;

    return await requestApi.get<SolicitacaoPeticionamentoMotivoReprovacao[] | undefined>(url, (res, json) => ({
        res: res,
        json: json,
    }));
};

export const encaminharAnaliseEscritorio = async (idSolicitacaoPeticionamento: string) => {
    const url = `${solicitacaoPeticionamentoUrl}/${idSolicitacaoPeticionamento}/EncaminharAnaliseEscritorio`;

    return await requestApi.patch(url, undefined, (res, json) => ({
        res: res,
        json: json,
    }));
};
