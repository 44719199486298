import { array as YupArray, date as YupDate, object as YupObject, string as YupString } from "yup";
import { FieldArray, Form, FormikProps } from "formik";
import { OmnijusDateField } from "../../../shared/form/fields/omnijus-date-field";
import { ActionFeedback, ButtonPrimary, OmnijusTextField, showModalForm } from "@omnijus/common";
import { ReactComponent as TrashIcon } from "../../../assets/images/trash-icon.svg";
import React from "react";
import styles from "./modal-adicao-movimento.module.scss";
import { AtualizacaoProcessoService } from "../../../services/atualizacao-processo/atualizacao-processo-service";
import { MovimentoAdicaoMovimento } from "../../../services/atualizacao-processo/models/adicao-movimento";

const validationSchema = YupObject().shape({
    movimentos: YupArray()
        .of(
            YupObject().shape({
                data: YupDate().typeError("Insira a data do movimento").required("Insira a data do movimento"),
                descricao: YupString().required("Insira a descrição do movimento"),
            })
        )
        .required("É preciso adicionar pelo menos um movimento")
        .min(1),
});

interface AdicaoMovimentoFormFields {
    movimentos: MovimentoAdicaoMovimento[];
}

const AdicaoMovimentoForm = ({ values, errors }: FormikProps<AdicaoMovimentoFormFields>) => {
    return (
        <Form>
            <FieldArray name="movimentos">
                {(arrayHelpers) => (
                    <>
                        <div className={styles.listaNovosMovimentos}>
                            {values.movimentos &&
                                values.movimentos.map((movimento, index) => (
                                    <div key={index} className={styles.linhaForm}>
                                        <OmnijusDateField name={`movimentos[${index}].data`} label="Data" />
                                        <OmnijusTextField name={`movimentos[${index}].descricao`} label="Descrição" />
                                        {values.movimentos.length > 1 && (
                                            <TrashIcon
                                                className={styles.trashIcon}
                                                onClick={() => arrayHelpers.remove(index)}
                                                style={{ cursor: "pointer" }}
                                            />
                                        )}
                                    </div>
                                ))}
                        </div>
                        {typeof errors.movimentos === "string" && <div>{errors.movimentos}</div>}
                        <div className={styles.botoes}>
                            <ButtonPrimary
                                type="button"
                                onClick={() => arrayHelpers.push({ data: new Date(), descricao: "" })}
                                className={styles.botaoAdicionarMovimento}
                            >
                                Adicionar movimento
                            </ButtonPrimary>
                            <ButtonPrimary type="submit">Concluir</ButtonPrimary>
                        </div>
                    </>
                )}
            </FieldArray>
        </Form>
    );
};

export const exibirModalAdicaoMovimento = async (numeroProcesso: string): Promise<boolean> => {
    const listaMovimentacao = (
        await showModalForm(
            "Adicionar Movimentos",
            { movimentos: [{ data: new Date(), descricao: "" }] as MovimentoAdicaoMovimento[] },
            AdicaoMovimentoForm,
            "48rem",
            validationSchema
        )
    )?.movimentos;
    if (!listaMovimentacao) {
        return false;
    }
    await ActionFeedback.processing({
        title: "Adicionando Movimentos",
        execution: AtualizacaoProcessoService.adicionarMovimento({ numeroProcesso, listaMovimentacao }),
    });
    return true;
};
