import React from "react";
import styles from "./listar-peticionamentos.module.scss";
import Modal from "react-modal";
import { CardInfoSolicitacaoPeticionamento } from "./card-info-solicitacao-peticionamento";
import { Form, Formik } from "formik";
import { OmnijusDateField } from "../../shared/form/fields/omnijus-date-field";
import { date as YupDate, object as YupObject } from "yup";
import { ActionFeedback, ButtonSecondary, ButtonPrimary, OmnijusCard } from "@omnijus/common";
import { alterarDataPrevistaPeticionamento } from "../../services/peticionamento/peticionamento-service";
import { format } from "date-fns";
import MaskedInput from "react-maskedinput";

interface ModalDataPrevistaPeticionamentoProps {
    idSolicitacaoPeticionamento: string;
    nomeCliente: string | null;
    numeroProcesso: string | null;
    nomeEscritorio?: string;
    dataPrevistaPeticionamento: string | null;
    statusSolicitacaoPeticionamento: string | null;
    isModalOpen: boolean;
    onCloseModal: () => void;
}

export const ModalDataPrevistaPeticionamento = (props: ModalDataPrevistaPeticionamentoProps) => {
    return (
        <>
            <Modal
                style={{
                    content: {
                        width: "50vw",
                        margin: "auto",
                    },
                }}
                isOpen={props.isModalOpen}
                onRequestClose={props.onCloseModal}
                shouldCloseOnEsc
                ariaHideApp={false}
            >
                <h2>Alterar Data Prevista do Peticionamento</h2>

                <CardInfoSolicitacaoPeticionamento {...props} modal="2Colunas" />

                <Formik
                    initialValues={{
                        novaDataPrevistaPeticionamento: undefined as Date | undefined,
                    }}
                    validationSchema={YupObject().shape({
                        novaDataPrevistaPeticionamento: YupDate()
                            .required("Informe a nova data prevista para o peticionamento")
                            .typeError("Data inválida")
                            .min(
                                new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
                                "A nova data deve ser maior que a data de ontem"
                            ),
                    })}
                    onSubmit={async (values) => {
                        const alteracaoConfirmada = await ActionFeedback.confirm(
                            "",
                            "Sim",
                            "Cancelar",
                            `Confirma a alteração da data prevista do peticionamento para o dia ${format(
                                values.novaDataPrevistaPeticionamento!,
                                "dd/MM/yyyy"
                            )}?`,
                            "question"
                        );

                        if (alteracaoConfirmada) {
                            await ActionFeedback.processing({
                                title: "Processando...",
                                execution: alterarDataPrevistaPeticionamento(
                                    props.idSolicitacaoPeticionamento,
                                    values.novaDataPrevistaPeticionamento!
                                ),
                                onError: async (error: any) =>
                                    await ActionFeedback.error({
                                        icon: "error",
                                        html: error?.json?.erros
                                            ? error.json.erros.join("<br />")
                                            : error?.json?.title
                                            ? error?.json?.title
                                            : "",
                                    }),
                            });

                            await ActionFeedback.info({
                                text: "Data alterada com sucesso!",
                            });

                            props.onCloseModal();
                            window.location.reload();
                        }
                    }}
                >
                    <Form className={styles.marginTop}>
                        <OmnijusCard
                            body={
                                <OmnijusDateField
                                    name="novaDataPrevistaPeticionamento"
                                    label="Nova data prevista para o peticionamento"
                                    customInput={<MaskedInput mask="11/11/1111" />}
                                    className="inputWithoutBorder"
                                />
                            }
                        />
                        <div className={styles.alignRight}>
                            <ButtonPrimary className={`${styles.marginRight} ${styles.marginTop}`} type="submit">
                                Salvar
                            </ButtonPrimary>
                            <ButtonSecondary className={styles.marginTop} onClick={props.onCloseModal}>
                                Fechar
                            </ButtonSecondary>
                        </div>
                    </Form>
                </Formik>
            </Modal>
        </>
    );
};
