import React from "react";
import styles from "./button-outlined.module.scss";

interface ButtonOutlinedProps {
    value?: string;
    onClick?: () => void;
    children?: JSX.Element | string;
    type?: "button" | "submit" | "reset";
    disabled?: boolean;
}

export const ButtonOutlined = (props: ButtonOutlinedProps) => {
    return (
        <button
            type={props.type || "button"}
            onClick={props.onClick}
            className={styles["btn-outlined"]}
            value={props.value}
            disabled={props.disabled}
        >
            {props.children}
        </button>
    );
};
