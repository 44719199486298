import { Loading } from "@omnijus/common";
import React, { useState } from "react";
import { EdicaoAgendamentoService } from "../../../services/agendamento/edicao-agendamento-service";
import { AgendamentoProcesso } from "../../../services/agendamento/models/agendamento-processo";
import { OmnijusDateField } from "../../../shared/form/fields/omnijus-date-field";

interface EdicaoPrazoSegurancaProps {
    agendamento: AgendamentoProcesso;
}

export const EdicaoPrazoSeguranca = (props: EdicaoPrazoSegurancaProps) => {
    const [promise, setPromise] = useState(Promise.resolve());
    const [value, setValue] = useState<Date | null>(new Date(props.agendamento.dataPrazoSeguranca));

    return (
        <Loading promise={promise}>
            {() => (
                <OmnijusDateField
                    label="Prazo Segurança"
                    name="prazoSeguranca"
                    isClearable={false}
                    value={value}
                    onChange={(value) => {
                        if (value) {
                            setValue(value);
                            setPromise(
                                EdicaoAgendamentoService.atualizarPrazoSeguranca(
                                    props.agendamento.idAgendamentoProcesso,
                                    value
                                )
                            );
                        } else {
                            setValue(null);
                        }
                    }}
                />
            )}
        </Loading>
    );
};
