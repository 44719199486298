import { Loading } from "@omnijus/common";
import React, { useEffect, useState } from "react";
import { listarStatusPeticionamento } from "../../../services/filtros/filtros-peticionamento-service";
import { StatusSolicitacaoPeticionamento } from "../../../services/filtros/models/status-solicitacao-peticionamento";
import { OmnijusSelectField } from "../../../shared/form/fields/omnijus-select-field";

export const OmnijusCampoStatusPeticionamento = (props: { hideLabel?: boolean; name?: string; label?: string }) => {
    const [promiseOpcoes, setPromiseOpcoes] = useState<Promise<StatusSolicitacaoPeticionamento[] | undefined>>();
    const name = props.name || "status";

    useEffect(() => {
        setPromiseOpcoes(listarStatusPeticionamento());
    }, []);

    return (
        <Loading promise={promiseOpcoes}>
            {(opcoes) => (
                <OmnijusSelectField
                    name={name}
                    label={props.hideLabel ? undefined : props.label || "Status"}
                    options={opcoes
                        ?.map((o) => ({
                            value: String(o.id),
                            label: o.value || "",
                        }))
                        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
                />
            )}
        </Loading>
    );
};
