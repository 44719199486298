import { ActionFeedback, Loading, OmnijusCard } from "@omnijus/common";
import { arrayObjectToXLSX } from "../../../lib/export-format";
import { TriggerDownload } from "../../../lib/trigger-download";
import "./escritorio.scss";
import React, { useCallback, useMemo } from "react";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import { Link, useHistory } from "react-router-dom";
import { Exportar } from "../../../shared/buttons/button-export-csv/button-export-csv";
import { ReactComponent as TrashIcon } from "../../../assets/images/trash-icon.svg";
import { ReactComponent as GreenCheckMarkIcon } from "../../../assets/images/green-checkmark-icon.svg";
import { ReactComponent as EditIcon } from "../../../assets/images/edit-pen.svg";
import { ButtonPrimarySmall } from "../../../shared/buttons/button-primary/button-primary";
import { FiltrosAplicados } from "../../../shared/form/filtros-aplicados/filtros-aplicados";
import { useFiltroAplicado } from "../../../shared/form/filtros-aplicados/filtros-aplicados-context";
import { format } from "date-fns";
import { EscritorioViewModel } from "../../../services/configuracoes/escritorio/escritorio-viewmodel";
import { EscritorioService } from "../../../services/configuracoes/escritorio/escritorio-service";

const ObterTituloGrid = (listaEscritorios: EscritorioViewModel[] | undefined) => {
    if (listaEscritorios) {
        const total = listaEscritorios.length;
        const ativos = listaEscritorios.filter((p) => p.ativo).length;

        return `Escritórios: ${total} (ativos: ${ativos})`;
    }

    return "Escritórios";
};

const detalheEscritorioUrl = "escritorio/detalhes/";

export const EscritoriosListagem = ({
    promise,
    onDelete,
}: {
    promise: Promise<EscritorioViewModel[] | undefined>;
    onDelete: VoidFunction;
}) => {
    const filtrosAplicados = useFiltroAplicado().getValues();
    const history = useHistory();

    const dataTableColumns = useMemo<IDataTableColumn<EscritorioViewModel>[]>(
        () => [
            {
                name: "Nome",
                selector: (x) => x.nome,
                sortable: true,
            },
            {
                name: "Ativo",
                sortable: true,
                selector: (p) => p.ativo && <GreenCheckMarkIcon />,
                maxWidth: "5rem",
            },
            {
                name: "Editar",
                sortable: false,
                selector: (p) => (
                    <Link to={`escritorio/editar/${p.id}`}>
                        <EditIcon width="1.2rem" title="Editar"></EditIcon>
                    </Link>
                ),
                width: "5rem",
            },
            {
                name: "Remover",
                sortable: false,
                selector: (p) => (
                    <TrashIcon
                        style={{ cursor: "pointer" }}
                        title="Excluir"
                        onClick={async () => {
                            const resposta = await ActionFeedback.confirm(
                                `Confirmação a exclusão do escritório ${p.nome}?`,
                                "Ok"
                            );

                            if (resposta) {
                                await ActionFeedback.processing({
                                    title: "Excluindo...",
                                    execution: EscritorioService.remover(p.id!),
                                });

                                onDelete();

                                await ActionFeedback.info({
                                    text: "Escritório excluído com sucesso!",
                                });
                            }
                        }}
                    />
                ),
                width: "5rem",
            },
        ],
        [onDelete]
    );

    const downloadExportar = useCallback(
        async (listaEscritorios: EscritorioViewModel[]) => {
            const columnsForExcel: IDataTableColumn<any>[] = [...dataTableColumns];

            TriggerDownload.xlsx(
                await arrayObjectToXLSX(listaEscritorios, columnsForExcel),
                `perfis-${format(new Date(), "yyyyMMddHHmmss")}.xlsx`
            );
        },
        [dataTableColumns]
    );

    return (
        <div className="perfis">
            <OmnijusCard
                body={
                    promise ? (
                        <Loading promise={promise}>
                            {(listaEscritorios) => (
                                <DataTable
                                    customStyles={{
                                        rows: {
                                            style: {
                                                cursor: "pointer",
                                            },
                                        },
                                    }}
                                    title={
                                        <>
                                            <p>{ObterTituloGrid(listaEscritorios)}</p>
                                            <FiltrosAplicados tags={filtrosAplicados} />
                                        </>
                                    }
                                    pagination={true}
                                    paginationPerPage={20}
                                    noDataComponent={<p>Nenhum registro encontrado!</p>}
                                    actions={
                                        <>
                                            <ButtonPrimarySmall
                                                onClick={() => {
                                                    history.push("escritorio/novo");
                                                }}
                                            >
                                                Novo
                                            </ButtonPrimarySmall>

                                            {listaEscritorios && listaEscritorios.length > 0 && (
                                                <>
                                                    <Exportar
                                                        onClick={() => downloadExportar(listaEscritorios)}
                                                        texto="Excel (xlsx)"
                                                    />
                                                    <Exportar
                                                        onClick={() => downloadExportar(listaEscritorios)}
                                                        texto="csv"
                                                    />
                                                </>
                                            )}
                                        </>
                                    }
                                    columns={dataTableColumns}
                                    onRowClicked={(p) => history.push(detalheEscritorioUrl + p.id)}
                                    data={listaEscritorios || []}
                                />
                            )}
                        </Loading>
                    ) : (
                        <p className="text-center">Utilize os filtros para realizar uma nova busca!</p>
                    )
                }
            />
        </div>
    );
};
