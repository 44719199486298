import { RequestApi } from "@omnijus/common";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
    ParametrosPesquisarEscritorio,
    parseParametrosPesquisarEscritorio,
} from "../../../services/configuracoes/escritorio/escritorio-parametros";
import { EscritorioService } from "../../../services/configuracoes/escritorio/escritorio-service";
import { EscritorioViewModel } from "../../../services/configuracoes/escritorio/escritorio-viewmodel";
import { FiltrosEscritorio } from "../../../services/configuracoes/escritorio/filtros-escritorio";
import "./escritorio.scss";
import { EscritoriosListagem } from "./escritorios-listagem";

export const Escritorios = () => {
    const query = useLocation().search;
    const [promise, setPromise] = useState<Promise<EscritorioViewModel[] | undefined>>();
    const history = useHistory();
    const filtro = useMemo<ParametrosPesquisarEscritorio>(() => parseParametrosPesquisarEscritorio(query), [query]);
    const [dataBusca, setDataBusca] = useState<Date | undefined>();

    const buscar = useCallback(
        (filtros: ParametrosPesquisarEscritorio) => {
            const search = RequestApi.objectToQueryString(filtros);
            history.replace({
                search,
            });
        },
        [history]
    );

    useEffect(() => {
        setPromise(EscritorioService.listar(filtro));
    }, [filtro, dataBusca]);

    return (
        <div className="perfis">
            <h2>Escritórios</h2>

            <FiltrosEscritorio
                filtros={filtro}
                onBuscar={async (filtros) => {
                    buscar(filtros);
                    setDataBusca(new Date());
                }}
            />
            {promise && <EscritoriosListagem promise={promise} onDelete={() => setDataBusca(new Date())} />}
        </div>
    );
};
