import { RequestApi } from "@omnijus/common";
import { PagedResult } from "../../../lib/paged-result";
import { PaginationInfo } from "../../../lib/pagination-info";
import { CLIENT_ID } from "../../../services/client-id";
import { UsuarioCommand } from "./usuario-command";
import { UsuarioFilter } from "./usuario-filter";
import { UsuarioViewModel } from "./usuario-viewmodel";

const usuarioUrl = process.env.REACT_APP_BFF_OPERACIONAL_API_URL + "/api/ConfiguracaoControleAcesso";

const request = new RequestApi(CLIENT_ID);

export const UsuarioService = {
    Listar: async (filtro?: UsuarioFilter, paginationInfo?: PaginationInfo) => {
        const url = new URL(`${usuarioUrl}/v1/Usuario`);
        url.search = RequestApi.objectToQueryString({ ...filtro, ...paginationInfo });
        return await request.get<PagedResult<UsuarioViewModel>>(url.toString());
    },
    Obter: async (id: string) => {
        const url = `${usuarioUrl}/v1/Usuario/${id}`;
        return await request.get<UsuarioViewModel>(url);
    },
    ObterPorCpf: async (cpf: string) => {
        const url = `${usuarioUrl}/v1/Usuario/cpf/${cpf}`;
        return await request.get<UsuarioViewModel>(url);
    },
    Salvar: async (usuario: UsuarioCommand) => {
        let url = new URL(`${usuarioUrl}/v1/Usuario`);
        return await request.post<UsuarioViewModel>(url.toString(), usuario);
    },
    Atualizar: async (usuario: UsuarioCommand) => {
        let url = new URL(`${usuarioUrl}/v1/Usuario`);
        return await request.put<UsuarioViewModel>(url.toString(), usuario);
    },
    Remover: async (id: string) => {
        let url = new URL(`${usuarioUrl}/v1/Usuario/${id}`);

        return await request.delete(url.toString());
    },
    Ativar: async (id: string) => {
        let url = new URL(`${usuarioUrl}/v1/Usuario/${id}/Unlock`);

        await request.put(url.toString());
    },
    Inativar: async (id: string) => {
        let url = new URL(`${usuarioUrl}/v1/Usuario/${id}/Lock`);

        await request.put(url.toString());
    },
};
