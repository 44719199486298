import { Loading, OmnijusSelectField } from "@omnijus/common";
import React, { useEffect, useState } from "react";
import { ProcessoService } from "../../../services/processo/processo-service";

export const OmnijusMovimentosProcesso = (props: {
    idProcesso: string;
    idProcessoMovimento?: string;
    name?: string;
    isMulti?: boolean;
    label?: string;
    disabled?: boolean;
    comboMovimentos?: { id: string; value: string }[] | undefined;
}) => {
    const [promise, setPromise] = useState<Promise<{ id: string; value: string }[] | undefined>>();

    useEffect(() => {
        if (props.comboMovimentos) {
            setPromise(Promise.resolve(props.comboMovimentos));
        } else {
            setPromise(ProcessoService.obterComboMovimentos(props.idProcesso, props.idProcessoMovimento));
        }
    }, [props.comboMovimentos, props.idProcesso, props.idProcessoMovimento]);

    return (
        <Loading promise={promise}>
            {(opcoes) => (
                <OmnijusSelectField
                    isDisabled={props.disabled}
                    isMulti={props.isMulti}
                    name={props.name || "idProcessoMovimento"}
                    label={props.label}
                    options={opcoes?.map((o) => ({
                        value: o.id,
                        label: o.value,
                    }))}
                />
            )}
        </Loading>
    );
};
