import React, { PropsWithChildren } from "react";
import "./card.scss";

export interface CardsProps {
    inline?: boolean;
}

interface CardProps extends CardsProps {
    icon: string;
    iconBackground: string;
    title: string | JSX.Element;
    value?: React.ReactNode;
}

export const Card = (props: PropsWithChildren<CardProps>) => {
    return (
        <div className={props.inline ? "card-inline" : "card"}>
            <div className="header">
                <div className={"icon " + props.iconBackground}>
                    <img alt={"Ícone de " + props.title} src={props.icon} />
                </div>
                <div className="info">
                    <div className="title">{props.title}</div>
                    {props?.value || <p className="value">{props?.value}</p>}
                </div>
            </div>
            {props?.children}
        </div>
    );
};
