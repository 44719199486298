import { OmnijusCard } from "@omnijus/common";
import React from "react";
import styles from "./movimentacao-assistida.module.scss";
import { ReactComponent as FolderIcon } from "../../assets/images/folder.svg";

export const InformacoesCardSuperior = (props: {
    numeroProcesso: string;
    descricaoArea: string;
    descricaoRito: string;
    descricaoFase: string;
    descricaoMomento: string;
}) => {
    return (
        <OmnijusCard
            body={
                <>
                    <div className={styles.folder}>
                        <div className={styles.folderIcon}>
                            <FolderIcon />
                        </div>
                        <div>
                            <h3>Processo nº: {props.numeroProcesso}</h3>
                            <h4>Área: {props.descricaoArea}</h4>
                            <strong>Rito / Fase / Momento:</strong>{" "}
                            <span>{`${props.descricaoRito} / ${props.descricaoFase} / ${props.descricaoMomento}`}</span>
                        </div>
                    </div>
                </>
            }
        />
    );
};
