import { useHistory } from "react-router-dom";
import { ButtonPrimary } from "../../../shared/form/fields/omnijus-button-primary";
import styles from "../movimentacao-assistida.module.scss";
import { MovimentacaoAssistidaService } from "../../../services/movimentacao-assistida/movimentacao-assistida-service";
import { AnaliseRotuloAcao } from "../../../services/movimentacao-assistida/models/registrar-historico-analise-rotulo";
import React from "react";
import { analiseRotuloEditavel } from "./analise-rotulo";
import { ActionFeedback } from "@omnijus/common";

export const AnaliseRotuloBotaoSair = (props: {
    idStatusDocumentoRotulo: number;
    idProcessoMovimentoRotulo: string;
}) => {
    const history = useHistory();
    return (
        <ButtonPrimary
            className={styles.botaoSair}
            onClick={() => {
                (async () => {
                    if (analiseRotuloEditavel(props.idStatusDocumentoRotulo)) {
                        await ActionFeedback.processing({
                            execution: MovimentacaoAssistidaService.registrarHistoricoAnaliseRotulo({
                                idProcessoMovimentoRotulo: props.idProcessoMovimentoRotulo,
                                analiseRotuloAcao: AnaliseRotuloAcao.Sair,
                            }),
                            title: "Processando",
                        });
                    }

                    history.push("/movimentacao-assistida/movimentos");
                })();
            }}
        >
            Sair
        </ButtonPrimary>
    );
};
