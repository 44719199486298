import { Loading, OmnijusCard } from "@omnijus/common";
import React, { useEffect, useState } from "react";
import styles from "../movimentacao-assistida.module.scss";
import { useHistory, useParams } from "react-router-dom";
import { AnaliseRotuloViewModel } from "../../../services/movimentacao-assistida/models/analise-rotulo";
import { MovimentacaoAssistidaService } from "../../../services/movimentacao-assistida/movimentacao-assistida-service";
import { RadioSelecaoDocumentos } from "../radio-selecao-documentos";
import { AnaliseRotuloAcao } from "../../../services/movimentacao-assistida/models/registrar-historico-analise-rotulo";
import { IdStatusDocumentoRotulo } from "../../../services/movimentacao-assistida/models/id-status-documento-rotulo";
import { InformacoesCardSuperior } from "../informacoes-card-superior";
import { InformacoesCardMovimento } from "../informacoes-card-movimento";
import { CardRotulos } from "./card-rotulos";
import { CardEntrevistaGuiadaMovimentacaoAssistida } from "../card-entrevistas-guiadas";
import { RedirectWithErrorMessage } from "../../../shared/redirect-with-error-message/redirect-with-error-message";
import { RotuloMovimento } from "../../../services/movimentacao-assistida/models/rotulo-movimento";

export const analiseRotuloEditavel = (idStatusDocumentoRotulo: IdStatusDocumentoRotulo) =>
    idStatusDocumentoRotulo !== IdStatusDocumentoRotulo.RotuloAnalisado &&
    idStatusDocumentoRotulo !== IdStatusDocumentoRotulo.Concluido &&
    idStatusDocumentoRotulo !== IdStatusDocumentoRotulo.Cancelado;

export const AnaliseRotulo = () => {
    const history = useHistory();
    const { id } = useParams<{ id: string }>();

    const [promiseProcessoMovimentoRotulo, setPromiseProcessoMovimentoRotulo] =
        useState<Promise<AnaliseRotuloViewModel | undefined>>();

    useEffect(() => {
        if (id) {
            setPromiseProcessoMovimentoRotulo(MovimentacaoAssistidaService.obterInformacoesParaAnaliseDeRotulo(id));
        } else {
            history.push("/movimentacao-assistida/movimentos");
        }
    }, [id, history]);

    return (
        <>
            <Loading promise={promiseProcessoMovimentoRotulo}>
                {(analiseRotulo) =>
                    analiseRotulo ? (
                        <AnaliseRotuloConteudo analiseRotulo={analiseRotulo} />
                    ) : (
                        <RedirectWithErrorMessage
                            errorMessage={"Erro obtendo informações do movimento"}
                            to={"/movimentacao-assistida/movimentos"}
                        />
                    )
                }
            </Loading>
        </>
    );
};

const rotuloFromAnalise = (analiseRotulo: AnaliseRotuloViewModel) => ({
    id: analiseRotulo.idRotuloMovimento,
    nome: analiseRotulo.nome,
    corFundoDisplay: analiseRotulo.corFundo,
});

const AnaliseRotuloConteudo = ({ analiseRotulo }: { analiseRotulo: AnaliseRotuloViewModel }) => {
    const idProcesso = analiseRotulo.processoMovimento.processo.idProcesso;
    const idStatusDocumentoRotulo = analiseRotulo.processoMovimento.processoDocumento
        .flatMap((x) => x.processoDocumentoRotulo)
        .flatMap((x) => x.idStatusDocumentoRotulo)[0];
    const [rotulo, setRotulo] = useState<RotuloMovimento>(rotuloFromAnalise(analiseRotulo));

    useEffect(() => {
        if (idStatusDocumentoRotulo && analiseRotuloEditavel(idStatusDocumentoRotulo)) {
            MovimentacaoAssistidaService.registrarHistoricoAnaliseRotulo({
                idProcessoMovimentoRotulo: analiseRotulo.idProcessoMovimentoRotulo,
                analiseRotuloAcao: AnaliseRotuloAcao.Acessar,
            }).then();
        }
    }, [idStatusDocumentoRotulo, analiseRotulo.idProcessoMovimentoRotulo]);

    useEffect(() => {
        setRotulo(rotuloFromAnalise(analiseRotulo));
    }, [analiseRotulo]);

    return (
        <>
            <h2>Análise de Rótulo</h2>

            <InformacoesCardSuperior {...analiseRotulo.processoMovimento.processo} />

            <div className={styles.analise}>
                <RadioSelecaoDocumentos
                    documentosMovimento={analiseRotulo.processoMovimento.processoDocumento}
                    documentosProcesso={analiseRotulo.processoMovimento.processo.processoDocumento}
                />

                <div>
                    <InformacoesCardMovimento {...analiseRotulo} />

                    {idProcesso && (
                        <CardEntrevistaGuiadaMovimentacaoAssistida
                            idProcesso={idProcesso}
                            idRotuloMovimento={analiseRotulo.idRotuloMovimento}
                        />
                    )}

                    {idStatusDocumentoRotulo && (
                        <OmnijusCard
                            body={
                                <>
                                    <CardRotulos
                                        rotuloMovimento={rotulo}
                                        idStatusDocumentoRotulo={idStatusDocumentoRotulo}
                                        idProcessoMovimento={analiseRotulo.processoMovimento.idProcessoMovimento}
                                        idProcessoMovimentoRotulo={analiseRotulo.idProcessoMovimentoRotulo}
                                        onChangeRotulo={(rotulo: RotuloMovimento) => setRotulo(rotulo)}
                                    />
                                </>
                            }
                        />
                    )}
                </div>
            </div>
        </>
    );
};
