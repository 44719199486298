import { Back, Loading, OmnijusCard } from "@omnijus/common";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AgendamentoProcessoService } from "../../../services/agendamento/agendamento-processo-service";
import { AgendamentoProcesso } from "../../../services/agendamento/models/agendamento-processo";
import { CampoDetalhamento } from "../../../shared/campo-detalhamento/campo-detalhamento";
import { SpacerV } from "../../../shared/layout/spacer";
import styles from "./edicao-agendamento.module.scss";
import { EdicaoPrazoFatal } from "./edicao-prazo-fatal";
import { EdicaoPrazoSeguranca } from "./edicao-prazo-seguranca";

export const EdicaoAgendamento = () => {
    const { id } = useParams<{ id: string }>();
    const [promiseAgendamento, setPromiseAgendamento] = useState<Promise<AgendamentoProcesso | undefined>>();

    useEffect(() => {
        setPromiseAgendamento(AgendamentoProcessoService.obter(id));
    }, [id]);

    return (
        <>
            <Back />
            <SpacerV />
            <OmnijusCard
                header={<h3>Agendamento</h3>}
                body={
                    <Loading promise={promiseAgendamento}>
                        {(agendamento) =>
                            agendamento ? (
                                <div className={styles["agendamento"]}>
                                    <CampoDetalhamento label="N° do Processo" value={agendamento.numeroProcesso} />
                                    <CampoDetalhamento label="Status" value={agendamento.descricaoStatusAgendamento} />
                                    <CampoDetalhamento label="Providência" value={agendamento.descricaoProvidencia} />
                                    <CampoDetalhamento
                                        label="Pasta Cliente"
                                        value={agendamento.descricaoPastaCliente}
                                    />
                                    <CampoDetalhamento label="Data Criação" value={agendamento.dataCriacao} />
                                    <CampoDetalhamento label="Usuário Criação" value={agendamento.nomeUsuarioCriacao} />
                                    <CampoDetalhamento
                                        label="Data Protocolo"
                                        value={agendamento.dataProtocolo || "-"}
                                    />
                                    <EdicaoPrazoSeguranca agendamento={agendamento} />
                                    <EdicaoPrazoFatal agendamento={agendamento} />
                                </div>
                            ) : (
                                <p>Não encontrado</p>
                            )
                        }
                    </Loading>
                }
            />
        </>
    );
};
