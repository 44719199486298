export class OperandNode {
    public children: OperandNode[];

    constructor(public id: string, public name: string) {
        this.children = [];
    }
}

export class OperandTree {
    private root: OperandNode[] = [];

    private hasNode(node: OperandNode[], id: string): boolean {
        let result = false;
        let i = 0;

        while (!result && i < node.length) {
            if (node[i].id === id) {
                result = true;
            } else {
                result = this.hasNode(node[i].children, id);
            }

            i++;
        }

        return result;
    }

    addNode(node: OperandNode, parent?: OperandNode) {
        if (!this.hasNode(this.root, node.id)) {
            if (parent) {
                parent.children.push(node);
            } else {
                this.root.push(node);
            }
        }
    }

    toArray() {
        let result = [];

        for (let node of this.root) {
            result.push(node);
        }

        return result;
    }
}
