import { PromiseState } from "../../../lib/promise-state";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { DetalheProcesso } from "../../../services/processo/models/detalhe-processo";
import { ProcessoService } from "../../../services/processo/processo-service";
import Swal from "sweetalert2";
import "../../../pages/processos/listagem-processos/listagem-processos.scss";
import { Loading, OmnijusCard } from "@omnijus/common";
import { FiltrosAplicadosProvider } from "../../../shared/form/filtros-aplicados/filtros-aplicados-context";
import { FiltroAplicado } from "../../../shared/form/filtros-aplicados/filtros-aplicados";
import { FiltrosProcessos } from "../../../shared/form/filtros/filtros-processo/filtros-processos";
import { GridListagemProcessos } from "../../../pages/processos/listagem-processos/grid-listagem-processos";

const detalheProcessoUrl = "/processos/detalhes/";

export const ListagemProcessos = () => {
    const [promiseProcessos, setPromiseProcessos] = useState<Promise<DetalheProcesso[]>>();
    const [filtrosAplicados, setFiltrosAplicados] = useState<Array<FiltroAplicado>>([]);
    const query = useLocation().search;
    const history = useHistory();

    useEffect(() => {
        if (query) {
            setPromiseProcessos(ProcessoService.listarProcessos(new URLSearchParams(query)));
        }
    }, [query]);

    return (
        <div className="processos">
            <h2 className="titulo-destaque">Processos</h2>
            <FiltrosAplicadosProvider>
                <FiltrosProcessos
                    collapsedByDefault={Boolean(query)}
                    onBuscar={async (filtros, filtrosAplicados) => {
                        if (promiseProcessos) {
                            if (!(await PromiseState.isPending(promiseProcessos))) {
                                setPromiseProcessos(ProcessoService.listarProcessos(filtros));
                                setFiltrosAplicados(filtrosAplicados);
                            } else {
                                await Swal.fire({
                                    icon: "warning",
                                    text: "Uma busca já está sendo realizada no momento! Aguarde...",
                                });
                            }
                        } else {
                            setPromiseProcessos(ProcessoService.listarProcessos(filtros));
                            setFiltrosAplicados(filtrosAplicados);
                        }
                    }}
                />
            </FiltrosAplicadosProvider>
            <OmnijusCard
                body={
                    promiseProcessos ? (
                        <Loading promise={promiseProcessos}>
                            {(listaProcessos) =>
                                listaProcessos && (
                                    <GridListagemProcessos
                                        filtrosAplicados={filtrosAplicados}
                                        listaProcessos={listaProcessos}
                                        onRowClicked={(p) => history.push(detalheProcessoUrl + p.idProcesso)}
                                    />
                                )
                            }
                        </Loading>
                    ) : (
                        <p className="text-center">Utilize os filtros para realizar uma nova busca!</p>
                    )
                }
            />
        </div>
    );
};
