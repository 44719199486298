import { InformacoesPoliticaEProcesso } from "../../../services/audiencia/models/informacoes-politica-processo";
import { ModalidadeAudiencia } from "../../../services/audiencia/models/modalidade-audiencia";
import * as Yup from "yup";

export const validationSchema = (informacoesCriacaoAudiencia: InformacoesPoliticaEProcesso) => Yup.object().shape({
    quantidadeAudiencista: Yup.number()
        .test(
            "politica-exige-audiencista",
            "A política de audiência exige audiencistas",
            (value) => (
                informacoesCriacaoAudiencia?.politicaAudiencia?.solicitaAudiencista ?
                    value !== undefined && value !== null && value > 0
                    :
                    true
            )
        )
        .max(10, "Máximo de 10 audiencistas"),
    quantidadePreposto: Yup.number()
        .test(
            "politica-exige-preposto",
            "A política de audiência exige preposto",
            (value) => (
                informacoesCriacaoAudiencia?.politicaAudiencia?.solicitaPreposto ?
                    value !== undefined && value !== null && value > 0
                    :
                    true
            )
        )
        .max(10, "Máximo de 10 prepostos"),
    linkAudiencia: Yup.string()
        .when(
            "modalidade",
            {
                is: ModalidadeAudiencia.Teleaudiencia,
                then: Yup.string()
                    .url("Deve ser uma url válida")
                    .max(2000, "O link não pode ter mais que 2000 caracteres"),
                otherwise: Yup.string()
            }
        ),
    nomeLocal: Yup.string()
        .when(
            "modalidade",
            {
                is: ModalidadeAudiencia.Teleaudiencia,
                then: Yup.string(),
                otherwise: Yup.string().required("O local da audiência é obrigatório")
                .max(2000, "O nome do local não pode ter mais que 2000 caracteres")
            }
        ),
    observacoes: Yup.string()
        .max(500, "As observações não pode ter mais que 500 caracteres"),
    observacoesParceiro: Yup.string()
        .max(500, "As observações não pode ter mais que 500 caracteres"),
    propostaAcordo: Yup.string()
        .max(500, "A proposta de acordo não pode ter mais que 500 caracteres"),
    instrucoesTeleaudiencia: Yup.string()
        .max(500, "As instruções de teleaudiência não pode ter mais que 500 caracteres"),
});