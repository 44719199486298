import { RequestApi } from "@omnijus/common";
import { CLIENT_ID } from "../../services/client-id";
import { StatusSolicitacaoPeticionamento } from "./models/status-solicitacao-peticionamento";

const requestApi = new RequestApi(CLIENT_ID);

const solicitacaoPeticionamentoUrl =
    process.env.REACT_APP_BFF_OPERACIONAL_API_URL + "/api/Processo/v1/SolicitacaoPeticionamento";

export const listarStatusPeticionamento = () => {
    const url = `${solicitacaoPeticionamentoUrl}/ListarStatus`;
    return requestApi.get<StatusSolicitacaoPeticionamento[]>(url);
};
