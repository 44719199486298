import { ActionFeedback, OmnijusFileField, TextButton } from "@omnijus/common";
import { ReactComponent as Checked } from "../../../assets/images/checkmark-icon.svg";
import { format } from "date-fns";
import { Field, Form, Formik } from "formik";
import React from "react";
import { AnomaliaService } from "../../../services/anomalia/anomalia-service";
import { DetalheAnomaliaFaltaPedidoLiminar } from "../../../services/anomalia/models/detalhe-anomalia-falta-pedido-liminar";
import { ButtonPrimarySmall } from "../../../shared/form/fields/omnijus-button-primary";
import * as Yup from "yup";
import styles from "./detalhamento-anomalia.module.scss";

interface DetalhamentoFaltaPedidoLiminarProps {
    detalhe: DetalheAnomaliaFaltaPedidoLiminar;
    onResolved: () => void;
}

export const DetalhamentoFaltaPedidoLiminar = (props: DetalhamentoFaltaPedidoLiminarProps) => {
    return (
        <div className={styles["acao-anomalia"]}>
            <h4>Sobre Fluxo</h4>
            {props.detalhe.dataHoraResolucao ? (
                <div className={styles["titulo-acao"]}>
                    <Checked className={styles["icone-acao-tomada"]} />
                    <p className={styles["texto"]}>
                        O usuário{" "}
                        <span className={styles["highlight"]}>{props.detalhe.nomeUserResolucao || "Sistema"}</span>
                        &nbsp; tomou a ação de{" "}
                        <span className={styles["highlight"]}>
                            {props.detalhe.seguirFluxo ? "Seguir fluxo" : "Cancelar fluxo"}
                        </span>
                        &nbsp; em{" "}
                        <span className={styles["highlight"]}>
                            {format(new Date(props.detalhe.dataHoraResolucao), "dd/MM/yyyy HH:mm")}
                        </span>
                    </p>
                </div>
            ) : (
                <div className={styles["tomar-acao"]}>
                    <Formik
                        initialValues={{
                            seguirFluxo: String(props.detalhe.seguirFluxo),
                            pedidoLiminar: props.detalhe.pathArquivo,
                            filePedidoLiminar: undefined,
                        }}
                        validationSchema={Yup.object().shape({
                            filePedidoLiminar: Yup.mixed().test(
                                "testPedidoLiminar",
                                "Obrigatório",
                                function (filePedidoLiminar) {
                                    const { pedidoLiminar } = this.parent;
                                    return !pedidoLiminar && filePedidoLiminar;
                                }
                            ),
                            seguirFluxo: Yup.string().required(),
                        })}
                        onSubmit={async (values) => {
                            if (!props.detalhe.pathArquivo) {
                                await ActionFeedback.processing({
                                    title: "Resolvendo fluxo",
                                    execution: AnomaliaService.atualizarPedidoLiminar(props.detalhe.id, {
                                        pedidoLiminar: values.filePedidoLiminar!,
                                    }),
                                });
                            }

                            await ActionFeedback.processing({
                                title: "Resolvendo fluxo",
                                execution: AnomaliaService.resolverFaltaPedidoLiminar(props.detalhe.id, {
                                    seguirFluxo: values.pedidoLiminar === "true",
                                }),
                            });

                            props.onResolved();
                        }}
                    >
                        {(formik) => (
                            <Form className={styles["campos-resolucao"]}>
                                <div className={styles["resolucao-arquivo"]}>
                                    {formik.values.pedidoLiminar ? (
                                        <>
                                            <label>{formik.values.pedidoLiminar.split("/").pop()}</label>
                                            <TextButton
                                                onClick={() =>
                                                    formik.setValues({
                                                        ...formik.values,
                                                        pedidoLiminar: undefined,
                                                    })
                                                }
                                            >
                                                Trocar arquivo
                                            </TextButton>
                                        </>
                                    ) : (
                                        <>
                                            <OmnijusFileField name="filePedidoLiminar" />
                                            {props.detalhe.pathArquivo ? (
                                                <TextButton
                                                    onClick={() =>
                                                        formik.setValues({
                                                            ...formik.values,
                                                            pedidoLiminar: props.detalhe.pathArquivo,
                                                        })
                                                    }
                                                >
                                                    Cancelar troca de arquivo
                                                </TextButton>
                                            ) : null}
                                        </>
                                    )}
                                </div>
                                <div className={styles["radio-group"]}>
                                    <label>
                                        <Field
                                            name="seguirFluxo"
                                            type="radio"
                                            value="true"
                                            className={styles["no-margin"]}
                                        />
                                        Seguir fluxo
                                    </label>
                                    <label>
                                        <Field
                                            name="seguirFluxo"
                                            type="radio"
                                            value="false"
                                            className={styles["no-margin"]}
                                        />
                                        Cancelar fluxo
                                    </label>
                                </div>
                                <div className={`${styles["acoes"]} ${styles["acao-coluna"]}`}>
                                    <ButtonPrimarySmall type="submit">Resolver</ButtonPrimarySmall>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            )}
        </div>
    );
};
