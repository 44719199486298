import { Loading } from "@omnijus/common";
import React, { useEffect, useState } from "react";
import { FiltroEntrevistaGuiada } from "../../../services/filtros/filtro-entrevista-guiada";
import { FiltroEntrevistaGuiadaService } from "../../../services/filtros/filtros-entrevista-guiada";
import { OmnijusSelectField } from "../../../shared/form/fields/omnijus-select-field";

interface OmnijusFiltroTemplateProps {
    hideLabel?: boolean;
    name?: string;
    label?: string;
    isMulti?: boolean;
}

export const OmnijusFiltroTemplate = (props: OmnijusFiltroTemplateProps) => {
    const [promiseEntrevistaGuiada, setPromiseEntrevistaGuiada] = useState<
        Promise<FiltroEntrevistaGuiada[] | undefined>
    >();

    useEffect(() => {
        setPromiseEntrevistaGuiada(FiltroEntrevistaGuiadaService.listarEntrevistaGuiada());
    }, []);

    return (
        <Loading promise={promiseEntrevistaGuiada}>
            {(opcoesEntrevistaGuiada) => (
                <OmnijusSelectField
                    isMulti={props.isMulti}
                    name={props.name || "IdEntrevistaGuiada"}
                    label={props.hideLabel ? undefined : props.label || "Template"}
                    options={opcoesEntrevistaGuiada
                        ?.map((o) => ({
                            value: o.id.toString(),
                            label: o.value.toString(),
                        }))
                        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
                />
            )}
        </Loading>
    );
};
