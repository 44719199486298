import { Loading } from "@omnijus/common";
import { useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { FiltroSecao } from "../../../services/filtros/models/filtro-secao";
import { DominioService } from "../../../services/processo/dominio-service";
import { OmnijusSelectField } from "../fields/omnijus-select-field";

const filtroOrgaoName = "IdOrgaoUnidade";

interface OmnijusFiltroVaraProps {
    hideLabel?: boolean;
    name?: string;
    label?: string;
    reloadOnChangeName?: string;
    exigirOrgaoUnidade?: boolean;
}

export const OmnijusFiltroVara = (props: OmnijusFiltroVaraProps) => {
    const formikContext = useFormikContext<any>();
    const { setFieldValue } = formikContext;
    const isFirstRun = useRef(true);
    const name = props.name || "IdOrgaoUnidadeSecao";
    const idUnidade = useFormikContext<any>().values[props.reloadOnChangeName || filtroOrgaoName];
    const [promiseOpcoesVara, setPromiseOpcoesVara] = useState<Promise<FiltroSecao[] | undefined>>();

    useEffect(() => {
        isFirstRun.current || setFieldValue(name, undefined);
        isFirstRun.current = false;

        if (props.exigirOrgaoUnidade && !idUnidade) {
            setPromiseOpcoesVara(Promise.resolve([]));
            return;
        }

        setPromiseOpcoesVara(
            DominioService.consultarOrgaoUnidadeSecao({
                idsOrgaosUnidades: idUnidade ? [idUnidade] : [],
            })
        );
    }, [idUnidade, props.reloadOnChangeName, name, setFieldValue, props.exigirOrgaoUnidade]);

    return (
        <Loading promise={promiseOpcoesVara}>
            {(opcoesVara) => (
                <OmnijusSelectField
                    name={name}
                    label={!props.hideLabel ? props.label || "Vara" : undefined}
                    options={opcoesVara
                        ?.map((o) => ({
                            value: o.id.toString(),
                            label: o.value.toString(),
                        }))
                        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
                />
            )}
        </Loading>
    );
};
