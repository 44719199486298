import { Loading } from "@omnijus/common";
import { useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { ProcessoFiltroService } from "../../../services/filtros/filtros-processo-service";
import { FiltroAlegacao } from "../../../services/filtros/models/filtro-alegacao";
import { OmnijusSelectField } from "../fields/omnijus-select-field";

const filtroRitoName = "IdRitoProcesso";

interface OmnijusFiltroAssuntoProps {
    hideLabel?: boolean;
    name?: string;
    label?: string;
    reloadOnChangeName?: string;
}

export const OmnijusFiltroAssunto = (props: OmnijusFiltroAssuntoProps) => {
    const formikContext = useFormikContext<any>();
    const { setFieldValue } = formikContext;
    const isFirstRun = useRef(true);
    const name = props.name || "IdAlegacaoMerito";
    const idRito = formikContext.values[props.reloadOnChangeName || filtroRitoName];
    const [promiseOpcoesAssunto, setPromiseOpcoesAssunto] = useState<Promise<FiltroAlegacao[] | undefined>>();

    useEffect(() => {
        isFirstRun.current || setFieldValue(name, undefined);
        isFirstRun.current = false;
        setPromiseOpcoesAssunto(ProcessoFiltroService.listarAlegacao(idRito));
    }, [idRito, props.reloadOnChangeName, name, setFieldValue]);

    return (
        <Loading promise={promiseOpcoesAssunto}>
            {(opcoesAssunto) => (
                <OmnijusSelectField
                    name={name}
                    label={props.hideLabel ? undefined : props.label || "Assunto"}
                    options={opcoesAssunto
                        ?.map((o) => ({
                            value: o.id.toString(),
                            label: o.value.toString(),
                        }))
                        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
                />
            )}
        </Loading>
    );
};
