import { CardFiltros, Loading, OmnijusCard, OmnijusRangeDateField } from "@omnijus/common";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import { FiltroEntrevistasGuiadas } from "../../services/filtros/filtro-entrevistas-guiadas";
import { EntrevistaGuiadaViewModel } from "../../services/movimentacao-assistida/models/entrevista-guiada";
import { MovimentacaoAssistidaService } from "../../services/movimentacao-assistida/movimentacao-assistida-service";
import { OmnijusCampoNumeroProcesso } from "../../shared/form/custom-fields/omnijus-campo-numero-processo";
import { OmnijusFiltroRotuloMovimento } from "../../shared/form/custom-fields/omnijus-filtro-rotulo-movimento";
import { OmnijusFiltroStatusDocumentoRotulo } from "../../shared/form/custom-fields/omnijus-filtro-status-documento-rotulo";
import Swal from "sweetalert2";
import { date as YupDate, object as YupObject, string as YupString } from "yup";
import styles from "./movimentacao-assistida.module.scss";

export const EntrevistasGuiadas = () => {
    const history = useHistory();
    const [promise, setPromise] = useState<Promise<EntrevistaGuiadaViewModel[] | undefined>>();

    useEffect(() => {
        setPromise(MovimentacaoAssistidaService.consultarEntrevistasGuiadas({}));
    }, []);

    return (
        <div className={styles["consulta-movimentos"]}>
            <h2>Entrevistas Guiadas</h2>
            <CardFiltros
                textoBotao="Filtrar"
                onBuscar={async (values) => {
                    let algumFiltroInformado = Object.values(values).some((value) => value);

                    if (!algumFiltroInformado) {
                        Swal.fire({
                            icon: "warning",
                            text: "É necessário definir ao menos um dos critérios de consulta",
                        });

                        return;
                    }

                    setPromise(MovimentacaoAssistidaService.consultarEntrevistasGuiadas(values));
                }}
                initialValues={
                    {
                        numeroProcesso: undefined,
                        dataHoraDistribuicaoDe: undefined as Date | undefined,
                        dataHoraDistribuicaoAte: undefined as Date | undefined,
                        dataHoraMovimentoDe: undefined as Date | undefined,
                        dataHoraMovimentoAte: undefined as Date | undefined,
                        idRotuloMovimento: undefined,
                        idStatusDocumentoRotulo: undefined,
                        dataHoraAprovacaoDe: undefined as Date | undefined,
                        dataHoraAprovacaoAte: undefined as Date | undefined,
                        usuarioAprovacao: undefined,
                    } as FiltroEntrevistasGuiadas
                }
                botaoFiltrarInline
                validationSchema={YupObject().shape({
                    numeroProcesso: YupString().min(25, "O número de processo deve conter exatamente 20 dígitos"),
                    dataHoraDistribuicaoDe: YupDate().nullable().typeError("Data inválida"),
                    dataHoraDistribuicaoAte: YupDate()
                        .nullable()
                        .typeError("Data inválida")
                        .test("maior", "A data final não pode ser anterior à data inicial", function (dataAte) {
                            const { dataHoraDistribuicaoDe } = this.parent;

                            if (dataAte && dataHoraDistribuicaoDe && dataAte < dataHoraDistribuicaoDe) {
                                return false;
                            }

                            return true;
                        }),
                    dataHoraMovimentoDe: YupDate().nullable().typeError("Data inválida"),
                    dataHoraMovimentoAte: YupDate()
                        .nullable()
                        .typeError("Data inválida")
                        .test("maior", "A data final não pode ser anterior à data inicial", function (dataAte) {
                            const { dataHoraMovimentoDe } = this.parent;

                            if (dataAte && dataHoraMovimentoDe && dataAte < dataHoraMovimentoDe) {
                                return false;
                            }

                            return true;
                        }),
                    dataHoraAprovacaoDe: YupDate().nullable().typeError("Data inválida"),
                    dataHoraAprovacaoAte: YupDate()
                        .nullable()
                        .typeError("Data inválida")
                        .test("maior", "A data final não pode ser anterior à data inicial", function (dataAte) {
                            const { dataHoraAprovacaoDe } = this.parent;

                            if (dataAte && dataHoraAprovacaoDe && dataAte < dataHoraAprovacaoDe) {
                                return false;
                            }

                            return true;
                        }),
                })}
            >
                <OmnijusCampoNumeroProcesso name="numeroProcesso" label="Nº do Processo" />
                <OmnijusRangeDateField
                    nameDe="dataHoraDistribuicaoDe"
                    nameAte="dataHoraDistribuicaoAte"
                    label="Data da Distribuição"
                />
                <OmnijusRangeDateField
                    nameDe="dataHoraMovimentoDe"
                    nameAte="dataHoraMovimentoAte"
                    label="Data do Movimento"
                />
                <OmnijusFiltroRotuloMovimento label="Rótulo" />
                <OmnijusRangeDateField nameDe="dataHoraAprovacaoDe" nameAte="dataHoraAprovacaoAte" label="Data Hora" />
                <OmnijusFiltroStatusDocumentoRotulo label="Status" />
            </CardFiltros>
            {promise ? (
                <Loading promise={promise}>
                    {(movimentos) => (
                        <OmnijusCard
                            body={
                                <DataTable
                                    noHeader={true}
                                    customStyles={{
                                        rows: {
                                            style: {
                                                cursor: "pointer",
                                            },
                                        },
                                    }}
                                    pagination={true}
                                    paginationPerPage={20}
                                    noDataComponent={<p>A consulta não retornou registros</p>}
                                    data={movimentos || []}
                                    onRowClicked={(row) =>
                                        history.push(
                                            `/movimentacao-assistida/analise-respostas/${row.idProcessoSolicitacaoAtividade}`
                                        )
                                    }
                                    columns={[
                                        {
                                            name: "Nº do Processo",
                                            minWidth: "200px",
                                            selector: (row) => row.numeroProcesso,
                                            sortable: true,
                                        },
                                        {
                                            name: "Entrevista Guiada",
                                            minWidth: "200px",
                                            selector: (row) => row.nomeEntrevistaGuiada,
                                            sortable: true,
                                        },
                                        {
                                            name: "Data Distribuição",
                                            minWidth: "200px",
                                            selector: (row) => format(new Date(row.dataHoraDistribuicao), "dd/MM/yyyy"),
                                            sortable: true,
                                        },
                                        {
                                            name: "Data Movimento",
                                            minWidth: "200px",
                                            selector: (row) =>
                                                format(new Date(row.dataHoraMovimento), "dd/MM/yyyy HH:mm"),
                                            sortable: true,
                                        },
                                        {
                                            name: "Rótulo",
                                            minWidth: "200px",
                                            selector: (row) => row.descricaoRotulo,
                                            sortable: true,
                                        },
                                        {
                                            name: "Status",
                                            minWidth: "200px",
                                            selector: (row) => row.descricaoStatusDocumentoRotulo,
                                            sortable: true,
                                        },
                                        {
                                            name: "Usuário",
                                            minWidth: "200px",
                                            selector: (row) => row.nomeUsuario || "-",
                                            sortable: true,
                                        },
                                        {
                                            name: "Data Hora",
                                            minWidth: "200px",
                                            selector: (row) =>
                                                (row.dataHora && format(new Date(row.dataHora), "dd/MM/yyyy HH:mm")) ||
                                                "-",
                                            sortable: true,
                                        },
                                    ]}
                                />
                            }
                        />
                    )}
                </Loading>
            ) : (
                <p className="text-center">Utilize os filtros para realizar uma nova busca!</p>
            )}
        </div>
    );
};
