import { Format } from "../../lib/format";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import DirectoryIcon from "../../assets/images/directory-icon.png";
import ArrowUp from "../../assets/images/arrow-up.png";
import ArrowDown from "../../assets/images/arrow-down.png";
import "./sumario-processos.scss";
import { DashboardService } from "../../services/dashboard/dashboard-service";
import { Loading } from "@omnijus/common";
import { IdStatusProcesso } from "../../services/processo/models/id-status-processo";
import { Area, Rito, SumarioProcessosAtivos } from "../../services/dashboard/models/sumario-processos-ativos";

const listaProcessoUrl = "/processos";

export const SumarioProcessos = ({ idCliente }: { idCliente: string }) => {
    const [promiseSumario, setPromiseSumario] = useState<Promise<SumarioProcessosAtivos | undefined>>();
    const [areaSelected, setAreaSelected] = useState<Area | undefined>();
    const [ritoSelected, setRitoSelected] = useState<Rito>();
    const ritoElement = useRef<HTMLDivElement>(null);
    const faseElement = useRef<HTMLDivElement>(null);
    const momentoElement = useRef<HTMLDivElement>(null);
    const ritoBackgroundClasses = [
        "cor-acento-1-gradient",
        "cor-acento-2-gradient",
        "cor-acento-3-gradient",
        "cor-acento-4-gradient",
    ];

    useEffect(() => {
        setPromiseSumario(
            DashboardService.quantificarProcessoAtivoPorCliente(idCliente).then((res) => {
                setAreaSelected(res?.area[0]);
                return res;
            })
        );
    }, [idCliente]);

    useEffect(() => {
        // Efeito de piscar
        ritoElement.current?.classList.remove("flash");
        faseElement.current?.classList.remove("flash");
        momentoElement.current?.classList.remove("flash");

        setTimeout(() => {
            ritoElement.current?.classList.add("flash");
            faseElement.current?.classList.add("flash");
            momentoElement.current?.classList.add("flash");
        }, 20);
    }, [areaSelected]);

    useEffect(() => {
        // Efeito de piscar
        faseElement.current?.classList.remove("flash");
        momentoElement.current?.classList.remove("flash");

        setTimeout(() => {
            faseElement.current?.classList.add("flash");
            momentoElement.current?.classList.add("flash");
        }, 20);
    }, [ritoSelected]);

    return (
        <Loading promise={promiseSumario}>
            {(res) =>
                res ? (
                    <div className="sumario-processos">
                        <div className="area">
                            {res.area.map((a) => (
                                <div
                                    key={a.nome}
                                    className={"item" + (a.nome === areaSelected?.nome ? " active" : "")}
                                    onClick={() => {
                                        setAreaSelected(a);
                                        setRitoSelected(undefined);
                                    }}
                                >
                                    <span className="rotulo">{a.nome}</span>
                                    <span className="valor">{Format.toNumberString(a.quantidade)}</span>
                                </div>
                            ))}
                        </div>
                        <div ref={ritoElement} className="rito">
                            {areaSelected?.rito.map((r, i) =>
                                r.quantidade > 0 ? (
                                    <div
                                        key={r.nome}
                                        className={"item" + (r.nome === ritoSelected?.nome ? " active" : "")}
                                    >
                                        <Link
                                            className="link link-rito"
                                            to={{
                                                pathname: listaProcessoUrl,
                                                search: new URLSearchParams({
                                                    IdRitoProcesso: String(r.id),
                                                    IdStatusProcesso: String(IdStatusProcesso.Ativo),
                                                    ComPedido: "True",
                                                }).toString(),
                                            }}
                                        >
                                            <div
                                                className={
                                                    "icone " + ritoBackgroundClasses[i % ritoBackgroundClasses.length]
                                                }
                                            >
                                                <img src={DirectoryIcon} alt="Ícone de diretório" />
                                            </div>
                                            <span className="rotulo">{r.nome}</span>
                                            <span className="valor">{r.quantidade}</span>
                                        </Link>
                                        <div
                                            className="dropdown-rito"
                                            onClick={() =>
                                                setRitoSelected(r.nome === ritoSelected?.nome ? undefined : r)
                                            }
                                        >
                                            <img
                                                alt="Ícone para expandir ritos"
                                                src={r.nome === ritoSelected?.nome ? ArrowUp : ArrowDown}
                                            />
                                        </div>
                                    </div>
                                ) : null
                            )}
                        </div>
                        <div ref={momentoElement} className="fases">
                            {ritoSelected?.fase.map((f) => (
                                <div key={f.nome} className="fase">
                                    <Link
                                        className="header link"
                                        to={{
                                            pathname: listaProcessoUrl,
                                            search: new URLSearchParams({
                                                IdFaseProcesso: String(f.id),
                                                IdRitoProcesso: String(ritoSelected.id),
                                                IdStatusProcesso: String(IdStatusProcesso.Ativo),
                                                ComPedido: "True",
                                            }).toString(),
                                        }}
                                    >
                                        <span className="rotulo">{f.nome}</span>
                                        <span className="valor">{f.quantidade}</span>
                                    </Link>
                                    <div className="momentos">
                                        {f.momento.map((m) => (
                                            <Link
                                                key={m.nome}
                                                className="momento link"
                                                to={{
                                                    pathname: listaProcessoUrl,
                                                    search: new URLSearchParams({
                                                        IdMomentoProcesso: String(m.id),
                                                        IdFaseProcesso: String(f.id),
                                                        IdRitoProcesso: String(ritoSelected.id),
                                                        IdStatusProcesso: String(IdStatusProcesso.Ativo),
                                                    }).toString(),
                                                }}
                                            >
                                                <span className="valor">{m.quantidade}</span>
                                                <span className="rotulo">{m.nome}</span>
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <></>
                )
            }
        </Loading>
    );
};
