import { addHours, getDay, getHours, getMinutes } from "date-fns";
import { ModalidadeAudiencia } from "../../../services/audiencia/models/modalidade-audiencia";
import { AreaProcesso } from "../../../services/audiencia/models/area-processo";
import * as Yup from "yup";
import { StatusAudiencia } from "../../../services/audiencia/models/status-audiencia";
import { InformacoesPoliticaEProcesso } from "../../../services/audiencia/models/informacoes-politica-processo";

export const validationSchema = (informacoesCriacaoAudiencia: InformacoesPoliticaEProcesso) =>
    Yup.object().shape({
        numeroProcesso: Yup.string()
            .required("Obrigatório informar o número de processo")
            .min(25, "O número de processo deve conter exatamente 20 dígitos"),
        idTipoAudiencia: Yup.number().required("Obrigatório informar o tipo da audiência"),
        dataHora: Yup.date()
            .nullable()
            .required("Data e hora obrigatórias")
            .when("idStatusAudiencia", {
                is: (id) => id === StatusAudiencia.Designada || id === StatusAudiencia.Redesignada,
                then: Yup.date().min(addHours(new Date(), 1), "A data deve ser pelo menos 1 hora no futuro"),
                otherwise: Yup.date(),
            })
            .when("idStatusAudiencia", {
                is: (id) => id === StatusAudiencia.Cancelada || id === StatusAudiencia.Realizada,
                then: Yup.date().max(new Date(), "A data deve ser no passado"),
                otherwise: Yup.date(),
            })
            .test(
                "e-dia-util",
                `A data precisa ser dia útil com horário entre ${
                    informacoesCriacaoAudiencia?.processo?.idArea === AreaProcesso.Trabalhista ? "8" : "6"
                } e ${informacoesCriacaoAudiencia?.processo?.idArea === AreaProcesso.Trabalhista ? "18" : "19"}`,
                (value) =>
                    value !== undefined &&
                    value !== null &&
                    getDay(value) > 0 &&
                    getDay(value) < 6 &&
                    (informacoesCriacaoAudiencia!.processo?.idArea === AreaProcesso.Trabalhista
                        ? getHours(value) > 7 &&
                          ((getHours(value) === 18 && getMinutes(value) === 0) || getHours(value) < 18)
                        : getHours(value) > 5 &&
                          ((getHours(value) === 19 && getMinutes(value) === 0) || getHours(value) < 19))
            ),
        quantidadeAudiencista: Yup.number()
            .nullable()
            .test("politica-exige-audiencista", "A política de audiência exige audiencistas", (value) =>
                informacoesCriacaoAudiencia?.politicaAudiencia?.solicitaAudiencista
                    ? value !== undefined && value !== null && value > 0
                    : true
            )
            .max(10, "Máximo de 10 audiencistas"),
        quantidadePreposto: Yup.number()
            .nullable()
            .test("politica-exige-preposto", "A política de audiência exige preposto", (value) =>
                informacoesCriacaoAudiencia?.politicaAudiencia?.solicitaPreposto
                    ? value !== undefined && value !== null && value > 0
                    : true
            )
            .max(10, "Máximo de 10 prepostos"),
        idStatusAudiencia: Yup.number().required("Obrigatório informar o status inicial da audiência"),
        linkAudiencia: Yup.string().when("modalidade", {
            is: ModalidadeAudiencia.Teleaudiencia,
            then: Yup.string().url("Deve ser uma url válida").max(2000, "O link não pode ter mais que 2000 caracteres"),
            otherwise: Yup.string(),
        }),
        nomeLocal: Yup.string().when("modalidade", {
            is: ModalidadeAudiencia.Teleaudiencia,
            then: Yup.string(),
            otherwise: Yup.string()
                .required("O local da audiência é obrigatório")
                .max(2000, "O nome do local não pode ter mais que 2000 caracteres"),
        }),
        observacoes: Yup.string().max(500, "As observações não pode ter mais que 500 caracteres"),
        observacoesParceiro: Yup.string().max(500, "As observações não pode ter mais que 500 caracteres"),
        propostaAcordo: Yup.string().max(500, "A proposta de acordo não pode ter mais que 500 caracteres"),
        instrucoesTeleaudiencia: Yup.string().max(
            500,
            "As instruções de teleaudiência não pode ter mais que 500 caracteres"
        ),
    });
