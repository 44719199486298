import { Loading } from "@omnijus/common";
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { ProcessoFiltroService } from "../../../services/filtros/filtros-processo-service";
import { FiltroMomento } from "../../../services/filtros/models/filtro-momento";
import { OmnijusSelectField } from "../fields/omnijus-select-field";

interface OmnijusFiltroMomentoProcessoProps {
    hideLabel?: boolean;
    name?: string;
    label?: string;
    idRito: number;
}

export const OmnijusFiltroMomentoProcesso = (props: OmnijusFiltroMomentoProcessoProps) => {
    const [promise, setPromise] = useState<Promise<FiltroMomento[] | undefined>>();
    const name = props.name || "IdMomentoProcesso";
    const formikContext = useFormikContext<any>();
    const { setFieldValue } = formikContext;
    const idFase = formikContext.values["idFaseProcesso"];

    useEffect(() => {
        setPromise(ProcessoFiltroService.listarMomento(props.idRito, idFase));

        return () => setFieldValue(name, undefined);
    }, [props.idRito, idFase, setFieldValue, name]);

    return (
        <Loading promise={promise}>
            {(opcoes) => (
                <OmnijusSelectField
                    name={name}
                    label={props.hideLabel ? undefined : props.label || "Momento"}
                    options={opcoes
                        ?.map((o) => ({
                            value: o.id.toString(),
                            label: o.value.toString(),
                        }))
                        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
                />
            )}
        </Loading>
    );
};
