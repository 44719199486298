import { RequestApi } from "@omnijus/common";
import { CLIENT_ID } from "../../services/client-id";

const processoDocumentoUrl = process.env.REACT_APP_BFF_OPERACIONAL_API_URL + "/api/Processo/v1/ProcessoDocumento";

const requestApi = new RequestApi(CLIENT_ID);

export const ProcessoDocumentoService = {
    downloadArquivosProcesso: async (idProcesso: string, filename: string) => {
        const url = `${processoDocumentoUrl}/Processo/${idProcesso}/DownloadDocumentos`;
        return await requestApi.download(url, filename);
    },
    downloadDocumento: async (idDocumento: string, filename: string) => {
        const url = `${processoDocumentoUrl}/${idDocumento}/Download`;
        return await requestApi.download(url, filename);
    },
};
