import React from "react";
import { PrestadorServicoUsuarioService } from "../../../../services/prestador/prestador-servico-usuario-service";
import Swal from "sweetalert2";
import PrestadorSemFoto from "../../../../assets/images/prestador-sem-foto.png";
import "./alert-prestador-profile.scss";

interface AlertPrestadorProfileProps {
    id: string;
    children?: string;
}

export const AlertPrestadorProfile = (props: AlertPrestadorProfileProps) => {
    return props.children ? (
        <button
            className="abrir-prestador-profile"
            onClick={async () => {
                Swal.fire({
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                });

                Swal.showLoading();
                let prestadorProfile = await PrestadorServicoUsuarioService.getProfile(props.id).catch(() => {});
                Swal.close();

                if (prestadorProfile && prestadorProfile?.prestadorServicoPerfil) {
                    Swal.fire({
                        showCloseButton: true,
                        showConfirmButton: false,
                        width: 600,
                        html: `
                                <div class="alert-prestador-profile">
                                    <header>
                                        <div class="foto">
                                            ${
                                                prestadorProfile.prestadorServicoPerfil.pathFoto
                                                    ? `<img src="${prestadorProfile.prestadorServicoPerfil.pathFoto}" alt="Prestador de serviço"/>`
                                                    : `<img src="${PrestadorSemFoto}" alt="Prestador de serviço"/>`
                                            }
                                        </div>
                                        <div class="info">
                                            <span class="nome">${
                                                prestadorProfile.prestadorServicoPerfil.nome || ""
                                            }</span>
                                            <span>${prestadorProfile.prestadorServicoPerfil.qualificacao || ""}</span>
                                            ${
                                                prestadorProfile.prestadorServicoPerfil.registroEntidadeClasse
                                                    ? `<span>
                                                    ${prestadorProfile.prestadorServicoPerfil.siglaEntidadeRegulamentadora}: ${prestadorProfile.prestadorServicoPerfil.registroEntidadeClasse}
                                                </span>`
                                                    : ``
                                            }
                                            ${
                                                prestadorProfile.prestadorServicoPerfil.urlRedeSocialProfissional
                                                    ? `<span>${prestadorProfile.prestadorServicoPerfil.urlRedeSocialProfissional}</span>`
                                                    : ``
                                            }
                                        </div>
                                    </header>
                                </div>
                            `,
                    });
                }
            }}
        >
            {props.children}
        </button>
    ) : null;
};
