import { RequestApi } from "@omnijus/common";
import { CLIENT_ID } from "../../services/client-id";
import { FiltroAgendamentoProcesso } from "../../services/filtros/filtro-agendamento-processo";
import { AgendamentoProcesso } from "./models/agendamento-processo";
import { IncluirAgendamentoProcessoCommand } from "./models/incluir-agendamento-processo";
import { PastaCliente } from "./models/pasta-cliente";

const requestApi = new RequestApi(CLIENT_ID);

const agendamentoProcessoUrl = process.env.REACT_APP_BFF_OPERACIONAL_API_URL + "/api/Processo/v1/AgendamentoProcesso";

export const AgendamentoProcessoService = {
    listar: async (filtro: FiltroAgendamentoProcesso) => {
        const url = new URL(`${agendamentoProcessoUrl}/Listar`);

        url.search = RequestApi.objectToQueryString(filtro);

        return await requestApi.get<AgendamentoProcesso[]>(url.toString(), (res, json) => ({
            res: res,
            json: json,
        }));
    },
    listarStatusAgendamento: async () => {
        const url = `${agendamentoProcessoUrl}/ListarStatusAgendamento`;

        return await requestApi.get<{ id: number; value: string }[]>(url, (res, json) => ({
            res: res,
            json: json,
        }));
    },
    protocolar: async (idAgendamentoProcesso: string) => {
        const url = `${agendamentoProcessoUrl}/${idAgendamentoProcesso}/Protocolar`;

        return await requestApi.patch(url, {}, (res, json) => ({
            res: res,
            json: json,
        }));
    },
    incluir: async (command: IncluirAgendamentoProcessoCommand) => {
        const url = `${agendamentoProcessoUrl}/Incluir`;

        return await requestApi.post(url, command, (res, json) => ({
            res: res,
            json: json,
        }));
    },
    obterPastaCliente: async (idProcesso: string) => {
        const url = new URL(`${agendamentoProcessoUrl}/ObterPastaCliente`);

        url.search = RequestApi.objectToQueryString({ idProcesso });

        return await requestApi.get<PastaCliente>(url.toString(), (res, json) => ({
            res: res,
            json: json,
        }));
    },
    excluir: async (command: { idAgendamentoProcesso: string; descricaoMotivoExclusao: string }) => {
        const url = `${agendamentoProcessoUrl}/Excluir`;

        return await requestApi.patch(url, command, (res, json) => ({
            res: res,
            json: json,
        }));
    },
    obter: async (id: string) => {
        return await requestApi.get<AgendamentoProcesso>(`${agendamentoProcessoUrl}/${id}`);
    },
};
