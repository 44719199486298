import { Loading } from "@omnijus/common";
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { FiltroOrgao } from "../../../services/filtros/models/filtro-orgao";
import { DominioService } from "../../../services/processo/dominio-service";
import { OmnijusSelectField } from "../fields/omnijus-select-field";

const filtroAreaName = "IdAreaAtuacaoOrgao";
const filtroUfName = "SiglaUF";

interface OmnijusFiltroTribunalProps {
    hideLabel?: boolean;
    name?: string;
    label?: string;
    reloadOnChangeNameArea?: string;
    reloadOnChangeNameUf?: string;
}

export const OmnijusFiltroTribunal = (props: OmnijusFiltroTribunalProps) => {
    const idArea = useFormikContext<any>().values[props.reloadOnChangeNameArea || filtroAreaName];
    const idEstado = useFormikContext<any>().values[props.reloadOnChangeNameUf || filtroUfName];
    const [promiseOpcoesTribunal, setPromiseOpcoesTribunal] = useState<Promise<FiltroOrgao[] | undefined>>();

    useEffect(() => {
        setPromiseOpcoesTribunal(
            DominioService.consultarOrgao({
                idsAreas: idArea ? [idArea] : undefined,
                uf: idEstado ?? undefined,
            })
        );
    }, [idArea, idEstado, props.reloadOnChangeNameArea, props.reloadOnChangeNameUf]);

    return (
        <Loading promise={promiseOpcoesTribunal}>
            {(opcoesTribunal) => (
                <OmnijusSelectField
                    name={props.name || "idOrgao"}
                    label={props.hideLabel ? undefined : props.label || "Tribunal"}
                    options={opcoesTribunal
                        ?.map((o) => ({
                            value: o.id.toString(),
                            label: o.value.toString(),
                        }))
                        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
                />
            )}
        </Loading>
    );
};
