export interface InformacaoAuxiliarRevisao {
    link: string;
    respostasHumanizadas?: RespostaHumanizada[];
    descricaoAuxiliar: string;
}

export interface RespostaHumanizada {
    descricao: string;
    fields: Resposta[];
}

export interface Resposta {
    alert: boolean;
    error: boolean;
    fields?: Resposta[];
    value?: string;
    format: string;
    disable: boolean;
    message?: any;
    operand: RespostaOperand;
    filePath?: any;
    question: string;
    operandId: string;
    description: string;
    operandName: string;
}

export enum RespostaOperand {
    VARIABLE = "VARIABLE",
    OBJECT = "OBJECT",
    FIELD = "FIELD",
}
