import { RequestApi } from "@omnijus/common";
import { CLIENT_ID } from "../../services/client-id";
import { AnomaliaListada } from "./models/anomalia-listada";
import { AtualizacaoAnotacao } from "./models/atualizacao-anotacao";
import { DetalheAnomalia } from "./models/detalhe-anomalia";
import { FiltroConsultaAnomalias } from "./models/filtro-consulta-anomalias";
import { ResolucaoFaltaPedidoLiminar } from "./models/resolucao-falta-pedido-liminar";
import { ResolucaoOutrosPedidosLiminar } from "./models/resolucao-outros-pedidos-liminar";
import { ResolucaoPedidoLiminar } from "./models/resolucao-pedido-liminar";

const bffUrl = process.env.REACT_APP_BFF_OPERACIONAL_API_URL + "/api/Anomalia/v1";
const requestApi = new RequestApi(CLIENT_ID);

export const AnomaliaService = {
    consultar: async (filtro?: FiltroConsultaAnomalias) => {
        let url = new URL(bffUrl);

        if (filtro) {
            url.search = RequestApi.objectToQueryString(filtro);
        }

        return await requestApi.get<Array<AnomaliaListada>>(url.toString());
    },
    obter: async (id: string) => {
        return await requestApi.get<DetalheAnomalia>(`${bffUrl}/${id}`);
    },
    finalizacao: async (id: string) => {
        await requestApi.patch(`${bffUrl}/${id}/Finalizacao`);
    },
    atualizarAnotacao: async (id: string, atualizacao: AtualizacaoAnotacao) => {
        await requestApi.patch(`${bffUrl}/${id}/Anotacao`, atualizacao);
    },
    atualizarPedidoLiminar: async (id: string, resolucao: ResolucaoPedidoLiminar) => {
        await requestApi.postFormdata(`${bffUrl}/FaltaPedidoLiminar/${id}/Pedido`, resolucao);
    },
    resolverFaltaPedidoLiminar: async (id: string, resolucao: ResolucaoFaltaPedidoLiminar) => {
        await requestApi.patch(`${bffUrl}/FaltaPedidoLiminar/${id}`, resolucao);
    },
    resolverOutrosPedidosLiminar: async (id: string, resolucao: ResolucaoOutrosPedidosLiminar) => {
        await requestApi.patch(`${bffUrl}/OutrosPedidosLiminar/${id}`, resolucao);
    },
};
