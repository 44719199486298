import { OmnijusCard } from "@omnijus/common";
import React from "react";
import styles from "./atualizacao-processo.module.scss";

interface CardInfoMovimentoProps {
    fase: string;
    movimentacao: string;
}

export const CardInfoMovimento = (props: CardInfoMovimentoProps) => {
    return (
        <div className={styles.marginTop}>
            <OmnijusCard
                body={
                    <div className={styles.cabecalho}>
                        <p>
                            <strong>Fase:</strong> {props.fase}
                        </p>

                        <p>
                            <strong>Movimentação:</strong> {props.movimentacao}
                        </p>
                    </div>
                }
            />
        </div>
    );
};
