import { ActionFeedback, Loading } from "@omnijus/common";
import React, { useEffect, useState } from "react";
import styles from "./movimentacao-assistida.module.scss";
import { useHistory, useParams } from "react-router";
import { MovimentacaoAssistidaService } from "../../services/movimentacao-assistida/movimentacao-assistida-service";
import { RadioSelecaoDocumentos } from "./radio-selecao-documentos";
import { InformacoesCardSuperior } from "./informacoes-card-superior";
import { InformacoesCardMovimento } from "./informacoes-card-movimento";
import { ButtonPrimary } from "../../shared/form/fields/omnijus-button-primary";
import { AnaliseRespostasEntrevistaGuiadaViewModel } from "../../services/movimentacao-assistida/models/analise-respostas-entrevista-guiada";
import { CardEntrevistaGuiada } from "./card-entrevistas-guiadas";
import { RespostasRevisao } from "./resposta-revisao/respostas-revisao";
import { AnaliseRespostasAcao } from "../../services/movimentacao-assistida/models/registrar-historico-analise-respostas";
import { IdStatusProcessoSolicitacaoAtividade } from "../../services/movimentacao-assistida/models/id-status-processo-solicitacao-atividade";

export const AnaliseRespostasEntrevistaGuiada = () => {
    const history = useHistory();
    const { id } = useParams<{ id: string }>();

    const [promise, setPromise] = useState<Promise<AnaliseRespostasEntrevistaGuiadaViewModel | undefined>>();

    useEffect(() => {
        if (id) {
            (async () => {
                const analiseRespostas = await MovimentacaoAssistidaService.obterInformacoesParaAnaliseDeRespostas(id);

                if (analiseRespostas) {
                    setPromise(Promise.resolve(analiseRespostas));

                    if (
                        analiseRespostas.idStatusSolicitacaoAtividade ===
                        IdStatusProcessoSolicitacaoAtividade.PendenteAnaliseResposta
                    ) {
                        MovimentacaoAssistidaService.registrarHistoricoAnaliseRespostas({
                            idProcessoSolicitacaoAtividade: id,
                            analiseRespostasAcao: AnaliseRespostasAcao.Acessar,
                        });
                    }
                }
            })();
        } else {
            history.push("/movimentacao-assistida/analise-respostas");
        }
    }, [id, history]);

    return (
        <>
            <Loading promise={promise}>
                {(analiseRespostas) => {
                    if (analiseRespostas) {
                        window.history.replaceState(
                            null,
                            "",
                            `/movimentacao-assistida/analise-respostas/${analiseRespostas.idProcessoSolicitacaoAtividade}`
                        );
                    } else {
                        history.push("/movimentacao-assistida/analise-respostas");
                    }

                    return (
                        analiseRespostas && (
                            <>
                                <h2>Análise de Respostas</h2>

                                <InformacoesCardSuperior {...analiseRespostas.processoMovimento.processo} />

                                <div className={styles.analise}>
                                    <RadioSelecaoDocumentos
                                        documentosMovimento={analiseRespostas.processoMovimento.processoDocumento}
                                        documentosProcesso={
                                            analiseRespostas.processoMovimento.processo.processoDocumento
                                        }
                                    />

                                    <div>
                                        <InformacoesCardMovimento {...analiseRespostas} />

                                        <CardEntrevistaGuiada
                                            nomeEntrevistaGuiada={analiseRespostas.nomeEntrevistaGuiada}
                                        />

                                        {analiseRespostas.respostaHumanizadaFormulario && (
                                            <RespostasRevisao
                                                respostas={analiseRespostas.respostaHumanizadaFormulario}
                                            />
                                        )}

                                        <div className={styles.botoesAnaliseRespostas}>
                                            <AnaliseRespostasBotaoSair
                                                idProcessoSolicitacaoAtividade={
                                                    analiseRespostas.idProcessoSolicitacaoAtividade
                                                }
                                                idStatusProcessoSolicitacaoAtividade={
                                                    analiseRespostas.idStatusSolicitacaoAtividade
                                                }
                                            />

                                            <AnaliseRespostasBotaoPular
                                                idProcessoSolicitacaoAtividade={
                                                    analiseRespostas.idProcessoSolicitacaoAtividade
                                                }
                                                idStatusProcessoSolicitacaoAtividade={
                                                    analiseRespostas.idStatusSolicitacaoAtividade
                                                }
                                                dataMovimento={analiseRespostas.processoMovimento.data}
                                            />

                                            <AnaliseRespostasBotaoAprovar
                                                idProcessoSolicitacaoAtividade={
                                                    analiseRespostas.idProcessoSolicitacaoAtividade
                                                }
                                                idStatusProcessoSolicitacaoAtividade={
                                                    analiseRespostas.idStatusSolicitacaoAtividade
                                                }
                                                dataMovimento={analiseRespostas.processoMovimento.data}
                                            />

                                            <AnaliseRespostasBotaoReprovar
                                                idProcessoSolicitacaoAtividade={
                                                    analiseRespostas.idProcessoSolicitacaoAtividade
                                                }
                                                idStatusProcessoSolicitacaoAtividade={
                                                    analiseRespostas.idStatusSolicitacaoAtividade
                                                }
                                                dataMovimento={analiseRespostas.processoMovimento.data}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    );
                }}
            </Loading>
        </>
    );
};

const AnaliseRespostasBotaoReprovar = (props: {
    idProcessoSolicitacaoAtividade: string;
    idStatusProcessoSolicitacaoAtividade: number;
    dataMovimento: Date;
}) => {
    const history = useHistory();
    return (
        <ButtonPrimary
            disabled={
                props.idStatusProcessoSolicitacaoAtividade !==
                IdStatusProcessoSolicitacaoAtividade.PendenteAnaliseResposta
            }
            className={
                props.idStatusProcessoSolicitacaoAtividade !==
                IdStatusProcessoSolicitacaoAtividade.PendenteAnaliseResposta
                    ? styles.botaoEsmaecido
                    : styles.botaoReprovar
            }
            onClick={async () => {
                if (
                    props.idStatusProcessoSolicitacaoAtividade !==
                    IdStatusProcessoSolicitacaoAtividade.PendenteAnaliseResposta
                ) {
                    return;
                }

                await ActionFeedback.processing({
                    title: "Reprovando...",
                    execution: MovimentacaoAssistidaService.reprovarAnaliseRespostas({
                        idProcessoSolicitacaoAtividade: props.idProcessoSolicitacaoAtividade,
                    }),
                    onError: async (error: any) =>
                        await ActionFeedback.error({
                            icon: "error",
                            html: error?.json?.erros
                                ? error.json.erros.join("<br />")
                                : error?.json?.title
                                ? error?.json?.title
                                : "",
                        }),
                });

                const continuar = await ActionFeedback.confirm(
                    "",
                    "Sim",
                    "Não",
                    "Deseja ir para a próxima análise?",
                    "question"
                );

                if (continuar) {
                    const idProcessoSolicitacaoAtividade = await ActionFeedback.processing({
                        title: "Processando...",
                        execution: MovimentacaoAssistidaService.obterIdProximaAnaliseDeRespostas(props.dataMovimento),
                        onError: async (error: any) =>
                            await ActionFeedback.error({
                                icon: "error",
                                html: error?.json?.erros
                                    ? error.json.erros.join("<br />")
                                    : error?.json?.title
                                    ? error?.json?.title
                                    : "",
                            }),
                    });

                    if (idProcessoSolicitacaoAtividade) {
                        history.push(`/movimentacao-assistida/analise-respostas/${idProcessoSolicitacaoAtividade}`);
                    } else {
                        await ActionFeedback.info({
                            text: "Não foram encontrados novos registros. Você será redirecionado para a lista de entrevistas a serem analisadas.",
                        });

                        history.push("/movimentacao-assistida/analise-respostas");
                    }
                } else {
                    history.push("/movimentacao-assistida/analise-respostas");
                }
            }}
        >
            Reprovar
        </ButtonPrimary>
    );
};

const AnaliseRespostasBotaoAprovar = (props: {
    idProcessoSolicitacaoAtividade: string;
    idStatusProcessoSolicitacaoAtividade: number;
    dataMovimento: Date;
}) => {
    const history = useHistory();
    return (
        <ButtonPrimary
            disabled={
                props.idStatusProcessoSolicitacaoAtividade !==
                IdStatusProcessoSolicitacaoAtividade.PendenteAnaliseResposta
            }
            className={
                props.idStatusProcessoSolicitacaoAtividade !==
                IdStatusProcessoSolicitacaoAtividade.PendenteAnaliseResposta
                    ? styles.botaoEsmaecido
                    : styles.botaoAprovar
            }
            onClick={async () => {
                if (
                    props.idStatusProcessoSolicitacaoAtividade !==
                    IdStatusProcessoSolicitacaoAtividade.PendenteAnaliseResposta
                ) {
                    return;
                }

                await ActionFeedback.processing({
                    title: "Aprovando...",
                    execution: MovimentacaoAssistidaService.aprovarAnaliseRespostas({
                        idProcessoSolicitacaoAtividade: props.idProcessoSolicitacaoAtividade,
                    }),
                    onError: async (error: any) =>
                        await ActionFeedback.error({
                            icon: "error",
                            html: error?.json?.erros
                                ? error.json.erros.join("<br />")
                                : error?.json?.title
                                ? error?.json?.title
                                : "",
                        }),
                });

                const continuar = await ActionFeedback.confirm(
                    "",
                    "Sim",
                    "Não",
                    "Deseja ir para a próxima análise?",
                    "question"
                );

                if (continuar) {
                    const idProcessoSolicitacaoAtividade = await ActionFeedback.processing({
                        title: "Processando...",
                        execution: MovimentacaoAssistidaService.obterIdProximaAnaliseDeRespostas(props.dataMovimento),
                        onError: async (error: any) =>
                            await ActionFeedback.error({
                                icon: "error",
                                html: error?.json?.erros
                                    ? error.json.erros.join("<br />")
                                    : error?.json?.title
                                    ? error?.json?.title
                                    : "",
                            }),
                    });

                    if (idProcessoSolicitacaoAtividade) {
                        history.push(`/movimentacao-assistida/analise-respostas/${idProcessoSolicitacaoAtividade}`);
                    } else {
                        await ActionFeedback.info({
                            text: "Não foram encontrados novos registros. Você será redirecionado para a lista de entrevistas a serem analisadas.",
                        });

                        history.push("/movimentacao-assistida/analise-respostas");
                    }
                } else {
                    history.push("/movimentacao-assistida/analise-respostas");
                }
            }}
        >
            Aprovar
        </ButtonPrimary>
    );
};

const AnaliseRespostasBotaoPular = (props: {
    idProcessoSolicitacaoAtividade: string;
    idStatusProcessoSolicitacaoAtividade: number;
    dataMovimento: Date;
}) => {
    const history = useHistory();
    return (
        <ButtonPrimary
            disabled={
                props.idStatusProcessoSolicitacaoAtividade !==
                IdStatusProcessoSolicitacaoAtividade.PendenteAnaliseResposta
            }
            className={
                props.idStatusProcessoSolicitacaoAtividade !==
                IdStatusProcessoSolicitacaoAtividade.PendenteAnaliseResposta
                    ? styles.botaoEsmaecido
                    : ""
            }
            onClick={async () => {
                if (
                    props.idStatusProcessoSolicitacaoAtividade !==
                    IdStatusProcessoSolicitacaoAtividade.PendenteAnaliseResposta
                ) {
                    return;
                }

                MovimentacaoAssistidaService.registrarHistoricoAnaliseRespostas({
                    idProcessoSolicitacaoAtividade: props.idProcessoSolicitacaoAtividade,
                    analiseRespostasAcao: AnaliseRespostasAcao.Pular,
                });

                const idProcessoSolicitacaoAtividade = await ActionFeedback.processing({
                    title: "Processando...",
                    execution: MovimentacaoAssistidaService.obterIdProximaAnaliseDeRespostas(props.dataMovimento),
                    onError: async (error: any) =>
                        await ActionFeedback.error({
                            icon: "error",
                            html: error?.json?.erros
                                ? error.json.erros.join("<br />")
                                : error?.json?.title
                                ? error?.json?.title
                                : "",
                        }),
                });

                if (idProcessoSolicitacaoAtividade) {
                    history.push(`/movimentacao-assistida/analise-respostas/${idProcessoSolicitacaoAtividade}`);
                } else {
                    await ActionFeedback.info({
                        text: "Não foram encontrados novos registros. Você será redirecionado para a lista de entrevistas a serem analisadas.",
                    });

                    history.push("/movimentacao-assistida/analise-respostas");
                }
            }}
        >
            Pular
        </ButtonPrimary>
    );
};

const AnaliseRespostasBotaoSair = (props: {
    idProcessoSolicitacaoAtividade: string;
    idStatusProcessoSolicitacaoAtividade: number;
}) => {
    const history = useHistory();
    return (
        <ButtonPrimary
            className={styles.botaoSair}
            onClick={() => {
                if (
                    props.idStatusProcessoSolicitacaoAtividade ===
                    IdStatusProcessoSolicitacaoAtividade.PendenteAnaliseResposta
                ) {
                    MovimentacaoAssistidaService.registrarHistoricoAnaliseRespostas({
                        idProcessoSolicitacaoAtividade: props.idProcessoSolicitacaoAtividade,
                        analiseRespostasAcao: AnaliseRespostasAcao.Sair,
                    });
                }

                history.push("/movimentacao-assistida/analise-respostas");
            }}
        >
            Sair
        </ButtonPrimary>
    );
};
