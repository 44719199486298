import { Loading, OmnijusSelectField } from "@omnijus/common";
import React, { useEffect, useState } from "react";
import { EscritorioService } from "../../../services/configuracoes/escritorio/escritorio-service";
import { EscritorioViewModel } from "../../../services/configuracoes/escritorio/escritorio-viewmodel";

interface OmnijusFiltroEscritorioProps {
    hideLabel?: boolean;
    name?: string;
    label?: string;
    isDisabled?: boolean;
    isMulti?: boolean;
    options?: EscritorioViewModel[] | undefined;
}

export const OmnijusFiltroEscritorio = (props: OmnijusFiltroEscritorioProps) => {
    const name = props.name || "idEscritorio";
    const [promiseOpcoes, setPromiseOpcoes] = useState<Promise<EscritorioViewModel[] | undefined>>();

    useEffect(() => {
        setPromiseOpcoes((props.options && Promise.resolve(props.options)) || EscritorioService.listar());
    }, [props.options]);

    return (
        <Loading promise={promiseOpcoes}>
            {(opcoes) => (
                <OmnijusSelectField
                    isMulti={props.isMulti}
                    name={name}
                    label={props.label || "Escritório Parceiro"}
                    isDisabled={props.isDisabled}
                    options={opcoes?.map((o) => ({
                        value: o.id!.toString(),
                        label: o.nome,
                    }))}
                />
            )}
        </Loading>
    );
};
