import { RequestApi } from "@omnijus/common";
import { CLIENT_ID } from "../../services/client-id";
import { Providencia } from "./models/providencia";

const requestApi = new RequestApi(CLIENT_ID);

const providenciaUrl = process.env.REACT_APP_BFF_OPERACIONAL_API_URL + "/api/Processo/v1/Providencia";

export const ProvidenciaService = {
    listarProvidencia: async (somenteExibirInclusaoAgendamento?: boolean, somenteIncluirPeticionamento?: boolean) => {
        const url = new URL(`${providenciaUrl}/ListarProvidencia`);

        url.search = RequestApi.objectToQueryString({ somenteExibirInclusaoAgendamento, somenteIncluirPeticionamento });

        return await requestApi.get<Providencia[]>(url.toString(), (res, json) => ({
            res: res,
            json: json,
        }));
    },
    listar: async () => {
        const url = new URL(`${providenciaUrl}/Listar`);
        return await requestApi.get<Providencia[]>(url.toString());
    },
};
