import {
    ButtonPrimary,
    Loading,
    OmnijusCard,
    OmnijusRangeDateField,
    OmnijusSelectField,
    OmnijusTextField,
    OmnijusRangeNumberField,
} from "@omnijus/common";
import { isDate } from "date-fns";
import { Form, Formik } from "formik";
import { Validation } from "../../../../lib/validation";
import React, { useEffect, useState } from "react";
import { ProcessoFiltroService } from "../../../../services/filtros/filtros-processo-service";
import { FiltroEstado } from "../../../../services/filtros/models/filtro-estado";
import { FiltroStatus } from "../../../../services/filtros/models/filtro-status";
import { ProcessoParametros } from "../../../../services/processo/models/processo-parametros";
import { OmnijusFiltroArea } from "../../../../shared/form/custom-fields/omnijus-filtro-area";
import { OmnijusFiltroAssunto } from "../../../../shared/form/custom-fields/omnijus-filtro-assunto";
import { OmnijusFiltroComarca } from "../../../../shared/form/custom-fields/omnijus-filtro-comarca";
import { OmnijusFiltroCpfCnpj } from "../../../../shared/form/custom-fields/omnijus-filtro-cpf-cnpj";
import { OmnijusFiltroFase } from "../../../../shared/form/custom-fields/omnijus-filtro-fase";
import { OmnijusFiltroForo } from "../../../../shared/form/custom-fields/omnijus-filtro-foro";
import { OmnijusFiltroMomento } from "../../../../shared/form/custom-fields/omnijus-filtro-momento";
import { OmnijusFiltroOab } from "../../../../shared/form/custom-fields/omnijus-filtro-oab";
import { OmnijusFiltroPedido } from "../../../../shared/form/custom-fields/omnijus-filtro-pedido";
import { OmnijusFiltroRito } from "../../../../shared/form/custom-fields/omnijus-filtro-rito";
import { OmnijusFiltroTipoAcao } from "../../../../shared/form/custom-fields/omnijus-filtro-tipo-acao";
import { OmnijusFiltroTipoResultadoSentenca } from "../../../../shared/form/custom-fields/omnijus-filtro-tipo-resultado-sentenca";
import { OmnijusFiltroTribunal } from "../../../../shared/form/custom-fields/omnijus-filtro-tribunal";
import { OmnijusFiltroVara } from "../../../../shared/form/custom-fields/omnijus-filtro-vara";
import { FiltroAplicado } from "../../../../shared/form/filtros-aplicados/filtros-aplicados";
import { useFiltroAplicado } from "../../../../shared/form/filtros-aplicados/filtros-aplicados-context";
import Swal from "sweetalert2";
import * as Yup from "yup";
import FilterIcon from "../../../../assets/images/filter-icon.png";
import "../filtro-card.scss";
import "./filtros-processos.scss";
import { OmnijusFiltroCliente } from "../../../../shared/form/custom-fields/omnijus-filtro-cliente";
import { OmnijusCampoNumeroProcesso } from "../../../../shared/form/custom-fields/omnijus-campo-numero-processo";
import { OmnijusFiltroEscritorio } from "../../../../shared/form/custom-fields/omnijus-filtro-escritorio";

interface FiltroRelatorioProps {
    onBuscar: (filtros: ProcessoParametros, filtrosAplicados: Array<FiltroAplicado>) => void;
    collapsedByDefault?: boolean;
}

const initialValues: ProcessoParametros = {
    NumeroProcesso: "",
    IdAreaAtuacaoOrgao: "",
    IdRitoProcesso: "",
    IdFaseProcesso: "",
    IdMomentoProcesso: "",
    IdClasseProcesso: "",
    IdAlegacaoMerito: "",
    IdTipoPedidoProcesso: "",
    DataHoraDistribuicaoInicio: null,
    DataHoraDistribuicaoFim: null,
    ValorAcaoInicio: "",
    ValorAcaoFim: "",
    DocumentoParteAutor: "",
    DocumentoParteReu: "",
    DocumentoParteAdvogado: "",
    SiglaUF: "",
    IdOrgao: "",
    NomeComarcaOrgaoUnidade: "",
    IdOrgaoUnidade: "",
    IdOrgaoUnidadeSecao: "",
    NomeJulgador: "",
    IdStatusProcesso: undefined,
    DataEncerramentoInicio: null,
    DataEncerramentoFim: null,
    DataEntradaInicio: null,
    DataEntradaFim: null,
    NomeAutor: "",
    NomeReu: "",
    IdEscritorio: "",
};

const validationSchema = Yup.object().shape({
    NumeroProcesso: Yup.string().min(25, "O número de processo deve conter exatamente 20 dígitos"),
    IdAreaAtuacaoOrgao: Yup.string(),
    IdRitoProcesso: Yup.string(),
    IdFaseProcesso: Yup.string(),
    IdMomentoProcesso: Yup.string(),
    IdClasseProcesso: Yup.string(),
    IdAlegacaoMerito: Yup.string(),
    IdTipoPedidoProcesso: Yup.string(),
    DataHoraDistribuicaoInicio: Yup.date()
        .nullable()
        .test("date", "A distribuição deve ser uma data válida", (v) => {
            return !v || isDate(v);
        })
        .when("DataHoraDistribuicaoFim", {
            is: (DataHoraDistribuicaoFim) => !!DataHoraDistribuicaoFim,
            then: Yup.date().max(
                Yup.ref("DataHoraDistribuicaoFim"),
                "A data da distribuição inicial deve ser menor que a data de distribuição final"
            ),
        })
        .max(new Date(), "A data da distribuição inicial deve ser menor que a data atual"),
    DataHoraDistribuicaoFim: Yup.date()
        .nullable()
        .test("date", "A distribuição deve ser uma data válida", (v) => {
            return !v || isDate(v);
        })
        .max(new Date(), "A data da distribuição final deve ser menor que a data atual"),
    ValorAcaoInicio: Yup.number()
        .positive("O valor da causa deve ser maior que zero")
        .max(9999999999999.99, "Valor máximo excedido")
        .typeError("O valor da causa deve ser um número"),
    ValorAcaoFim: Yup.number()
        .positive("O valor da causa deve ser maior que zero")
        .max(9999999999999.99, "Valor máximo excedido")
        .test("até<de", "Valor deve ser maior ou igual ao valor informado no campo anterior", function (valorAte) {
            return (
                !(typeof valorAte === "number") ||
                !(typeof this.parent.ValorAcaoInicio === "number") ||
                !(valorAte < this.parent.ValorAcaoInicio)
            );
        })
        .typeError("O valor da causa deve ser um número"),
    DocumentoParteAutor: Yup.string().test("CPF", "O CPF / CNPJ é inválido", (v) => {
        if (v !== undefined && v !== null) {
            return Validation.validateCPF(v) || Validation.validateCNPJ(v);
        } else {
            return true;
        }
    }),
    DocumentoParteReu: Yup.string().test("CPF", "O CPF / CNPJ é inválido", (v) => {
        if (v !== undefined && v !== null) {
            return Validation.validateCPF(v) || Validation.validateCNPJ(v);
        } else {
            return true;
        }
    }),
    DocumentoParteAdvogado: Yup.string().test("OAB", "Registro OAB inválido", (v) => {
        if (v !== undefined && v !== null) {
            return Validation.validateOAB(v);
        } else {
            return true;
        }
    }),
    SiglaUF: Yup.string(),
    IdOrgao: Yup.string(),
    NomeComarcaOrgaoUnidade: Yup.string(),
    IdOrgaoUnidade: Yup.string(),
    IdOrgaoUnidadeSecao: Yup.string(),
    NomeJulgador: Yup.string()
        .max(100, "Máximo 100 caracteres")
        .matches(/^[^0-9]*$/, "Nome inválido"),
    NomeAutor: Yup.string()
        .max(100, "Máximo 100 caracteres")
        .matches(/^[^0-9]*$/, "Nome inválido"),
    NomeReu: Yup.string()
        .max(100, "Máximo 100 caracteres")
        .matches(/^[^0-9]*$/, "Nome inválido"),
    IdStatusProcesso: Yup.string(),
    DataEncerramentoInicio: Yup.date()
        .nullable()
        .test("date", "O encerramento deve ser uma data válida", (v) => {
            return !v || isDate(v);
        })
        .when("DataEncerramentoFim", {
            is: (DataEncerramentoFim) => !!DataEncerramentoFim,
            then: Yup.date().max(
                Yup.ref("DataEncerramentoFim"),
                "A data da encerramento inicial deve ser menor que a data de encerramento final"
            ),
        })
        .max(new Date(), "A data da encerramento inicial deve ser menor que a data atual"),
    DataEncerramentoFim: Yup.date()
        .nullable()
        .test("date", "O encerramento deve ser uma data válida", (v) => {
            return !v || isDate(v);
        })
        .max(new Date(), "A data da encerramento final deve ser menor que a data atual"),
    DataEntradaInicio: Yup.date()
        .nullable()
        .test("date", "A captura deve ser uma data válida", (v) => {
            return !v || isDate(v);
        })
        .when("DataEntradaFim", {
            is: (DataEntradaFim) => !!DataEntradaFim,
            then: Yup.date().max(
                Yup.ref("DataEntradaFim"),
                "A data da captura inicial deve ser menor que a data de captura final"
            ),
        })
        .max(new Date(), "A data da captura inicial deve ser menor que a data atual"),
    DataEntradaFim: Yup.date()
        .nullable()
        .test("date", "A captura deve ser uma data válida", (v) => {
            return !v || isDate(v);
        })
        .max(new Date(), "A data da captura final deve ser menor que a data atual"),
    IdEscritorio: Yup.string(),
});

export const FiltrosProcessos = (props: FiltroRelatorioProps) => {
    const [promiseOpcoesEstado, setPromiseOpcoesEstado] = useState<Promise<FiltroEstado[] | undefined>>();
    const [promiseOpcoesStatus, setPromiseOpcoesStatus] = useState<Promise<FiltroStatus[] | undefined>>();
    const { getValues } = useFiltroAplicado();
    const { onChangeFiltroAplicado } = useFiltroAplicado();

    useEffect(() => {
        setPromiseOpcoesEstado(ProcessoFiltroService.listarEstado());
        setPromiseOpcoesStatus(ProcessoFiltroService.listarStatus());
    }, []);

    return (
        <OmnijusCard
            className="filtro-card filtros-processos"
            collapsable={true}
            collapsedByDefault={props.collapsedByDefault}
            header={
                <React.Fragment>
                    <img alt="Ícone de filtros" src={FilterIcon} />
                    <span className="titulo">Filtros</span>
                </React.Fragment>
            }
            body={
                <div className="filtros-wrapper">
                    <Formik
                        onSubmit={(filtros) => {
                            let existeFiltroPreenchido = Object.values(filtros).filter((v) => v).length > 0;

                            if (existeFiltroPreenchido) {
                                props.onBuscar(filtros, getValues());
                            } else {
                                Swal.fire({
                                    icon: "warning",
                                    text: "Deve ser utilizado pelo menos um filtro para buscar",
                                });
                            }
                        }}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                    >
                        <Form className="filtros">
                            <OmnijusCampoNumeroProcesso
                                name="NumeroProcesso"
                                label="Nº do Processo"
                                placeholder="Processo"
                                onChange={(e) => {
                                    onChangeFiltroAplicado({ label: "Nº do Processo", value: e.target.value });
                                }}
                            />
                            <OmnijusFiltroArea />
                            <OmnijusFiltroRito reloadOnChangeName="IdAreaAtuacaoOrgao" />
                            <OmnijusFiltroFase reloadOnChangeName="IdRitoProcesso" />
                            <OmnijusFiltroMomento
                                reloadOnChangeNameFase="IdFaseProcesso"
                                reloadOnChangeNameRito="IdRitoProcesso"
                            />
                            <OmnijusFiltroTipoAcao reloadOnChangeName="IdRitoProcesso" />
                            <OmnijusFiltroAssunto reloadOnChangeName="IdRitoProcesso" />
                            <OmnijusFiltroPedido />
                            <OmnijusRangeDateField
                                label="Distribuição"
                                nameDe="DataHoraDistribuicaoInicio"
                                nameAte="DataHoraDistribuicaoFim"
                                placeholderDe="--/--/----"
                                placeholderAte="--/--/----"
                            />
                            <OmnijusRangeNumberField
                                label="Valor da causa"
                                nameDe="ValorAcaoInicio"
                                nameAte="ValorAcaoFim"
                                placeholderDe="0"
                            />
                            <OmnijusTextField
                                name="NomeAutor"
                                label="Autor (Nome)"
                                keepMask={true}
                                masks={[
                                    {
                                        charType: "alphabet-text",
                                        case: "uppercase",
                                    },
                                ]}
                            />
                            <OmnijusFiltroCpfCnpj name="DocumentoParteAutor" label="Autor (CPF/CNPJ)" />
                            <OmnijusTextField
                                name="NomeReu"
                                label="Réu (Nome)"
                                keepMask={true}
                                masks={[
                                    {
                                        charType: "alphabet-text",
                                        case: "uppercase",
                                    },
                                ]}
                            />
                            <OmnijusFiltroCpfCnpj name="DocumentoParteReu" label="Réu (CPF/CNPJ)" />
                            <OmnijusFiltroOab name="DocumentoParteAdvogado" label="Advogado Adverso (nº OAB)" />
                            <Loading promise={promiseOpcoesEstado}>
                                {(opcoesEstado) => (
                                    <OmnijusSelectField
                                        name="SiglaUF"
                                        label="Estado"
                                        options={opcoesEstado
                                            ?.map((o) => ({
                                                value: o.id.toString(),
                                                label: o.value.toString(),
                                            }))
                                            .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
                                    />
                                )}
                            </Loading>
                            <OmnijusFiltroTribunal
                                reloadOnChangeNameArea="IdAreaAtuacaoOrgao"
                                reloadOnChangeNameUf="SiglaUF"
                            />
                            <OmnijusFiltroComarca reloadOnChangeName="IdOrgao" />
                            <OmnijusFiltroForo
                                reloadOnChangeNameOrgao="IdOrgao"
                                reloadOnChangeNameComarca="NomeComarcaOrgaoUnidade"
                                exigirOrgaoEComarca={true}
                            />
                            <OmnijusFiltroVara reloadOnChangeName="IdOrgaoUnidade" exigirOrgaoUnidade={true} />
                            <OmnijusFiltroCliente name="idCliente" label="Cliente" />
                            <OmnijusFiltroTipoResultadoSentenca />
                            <Loading promise={promiseOpcoesStatus}>
                                {(opcoesStatus) => (
                                    <OmnijusSelectField
                                        name="IdStatusProcesso"
                                        label="Status"
                                        options={opcoesStatus?.map((o) => ({
                                            value: o.id.toString(),
                                            label: o.value.toString(),
                                        }))}
                                    />
                                )}
                            </Loading>
                            <OmnijusRangeDateField
                                label="Período de captura"
                                nameDe="DataEntradaInicio"
                                nameAte="DataEntradaFim"
                                placeholderDe="--/--/----"
                                placeholderAte="--/--/----"
                            />
                            <OmnijusRangeDateField
                                label="Período de encerramento"
                                nameDe="DataEncerramentoInicio"
                                nameAte="DataEncerramentoFim"
                                placeholderDe="--/--/----"
                                placeholderAte="--/--/----"
                            />

                            <OmnijusTextField
                                label="Julgador"
                                name="NomeJulgador"
                                placeholder="Nome do julgador"
                                keepMask={true}
                                masks={[
                                    {
                                        charType: "alphabet-text",
                                        case: "uppercase",
                                    },
                                ]}
                            />

                            <OmnijusFiltroEscritorio />
                            <div className="acoes">
                                <ButtonPrimary type="submit">Filtrar</ButtonPrimary>
                            </div>
                        </Form>
                    </Formik>
                </div>
            }
        />
    );
};
