import { ActionFeedback, Back, ButtonPrimary, OmnijusCard } from "@omnijus/common";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { DetalheProcesso } from "../../services/processo/models/detalhe-processo";
import { object as YupObject, date as YupDate } from "yup";
import styles from "./listar-peticionamentos.module.scss";
import { OmnijusDateField } from "../../shared/form/fields/omnijus-date-field";
import { OmnijusCheckboxFieldSingle } from "../../shared/form/fields/omnijus-checkbox-field-single";
import { criarSolicitacaoPeticionamento } from "../../services/peticionamento/peticionamento-service";
import { CriarSolicitacaoPeticionamentoCommand } from "../../services/peticionamento/models/criar-peticionamento";
import { FormBuscarDetalhesProcesso } from "./form-buscar-detalhes-processo";
import { OmnijusFiltroProvidencia } from "../../shared/form/custom-fields/omnijus-filtro-providencia";
import { CabecalhoProcesso } from "../../pages/processos/detalhes-processo/detalhes-cards/cabecalho-processo";

export const CriarPeticionamento = () => {
    const [detalheProcesso, setDetalheProcesso] = useState<DetalheProcesso>();
    return (
        <>
            <Back />
            <h2>Nova Solicitação de Peticionamento</h2>
            <FormBuscarDetalhesProcesso setDetalheProcesso={setDetalheProcesso} />
            <div className={styles.criarPeticionamento}>
                {detalheProcesso && <CabecalhoProcesso processo={detalheProcesso} />}

                {detalheProcesso && (
                    <div>
                        <Formik
                            initialValues={
                                {
                                    idProcesso: detalheProcesso.idProcesso,
                                    dataPrevistaPeticionamento: undefined as Date | undefined,
                                    prazoPreclusivo: false,
                                } as CriarSolicitacaoPeticionamentoCommand
                            }
                            onSubmit={async (values) => {
                                await ActionFeedback.processing({
                                    title: "Processando...",
                                    execution: criarSolicitacaoPeticionamento(values),
                                    onError: async (error: any) =>
                                        await ActionFeedback.error({
                                            icon: "error",
                                            html: error?.json?.erros
                                                ? error.json.erros.join("<br />")
                                                : error?.json?.title
                                                ? error?.json?.title
                                                : "Não foi possível criar a solicitação de peticionamento",
                                        }),
                                });

                                await ActionFeedback.info({
                                    text: "Solicitação de peticionamento criada com sucesso!",
                                });

                                window.location.reload();
                            }}
                            validationSchema={YupObject().shape({
                                dataPrevistaPeticionamento: YupDate()
                                    .required("Informe a data prevista do peticionamento")
                                    .typeError("Data inválida")
                                    .min(
                                        new Date(
                                            new Date().getFullYear(),
                                            new Date().getMonth(),
                                            new Date().getDate(),
                                            0,
                                            0,
                                            0,
                                            0
                                        ),
                                        "Informe uma data maior ou igual a data de hoje"
                                    ),
                            })}
                        >
                            <Form>
                                <OmnijusCard
                                    body={
                                        <div className={styles.form}>
                                            <OmnijusDateField
                                                name="dataPrevistaPeticionamento"
                                                label="Data Prevista do Peticionamento"
                                            />
                                            <OmnijusFiltroProvidencia
                                                label="Providência"
                                                somenteIncluirPeticionamento
                                            />
                                            <OmnijusCheckboxFieldSingle
                                                name="prazoPreclusivo"
                                                label="Prazo Preclusivo"
                                            />
                                        </div>
                                    }
                                />
                                <div className={`${styles.alignRight} ${styles.marginTop}`}>
                                    <ButtonPrimary type="submit">Salvar</ButtonPrimary>
                                </div>
                            </Form>
                        </Formik>
                    </div>
                )}
            </div>
        </>
    );
};
