import { mdiTrashCan } from "@mdi/js";
import Icon from "@mdi/react";
import { ActionFeedback, Loading, OmnijusCard } from "@omnijus/common";
import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import { PerfilService } from "../../../../services/configuracoes/perfil/perfil-service";
import { AdicionarUsuarioPerfilCommand, UsuarioPerfilViewModel } from "../../../../services/configuracoes/perfil/perfil-viewmodel";
import { SpacerV } from "../../../../shared/layout/spacer";
import { object as YupObject, string as YupString } from "yup";
import styles from "../usuarios.module.scss";
import { ItemFormPerfil } from "./item-form-perfil";

const validationSchemaAddPerfil = YupObject().shape({
    idPerfil: YupString().required("É necessário informar pelo menos um perfil"),
});

const NovoPerfil = (props: { idUsuario: String; atualizarLista: () => void }) => {
    return (
        <OmnijusCard
            body={
                <>
                    <Formik
                        validationSchema={validationSchemaAddPerfil}
                        onSubmit={async (values) => {
                            await ActionFeedback.processing({
                                title: "Adicionando perfil ao usuário...",
                                execution: PerfilService.adicionarUsuario(values.idPerfil, {
                                    idUsuario: props.idUsuario,
                                    idEscritorio: values.idEscritorio,
                                } as AdicionarUsuarioPerfilCommand),
                            });

                            props.atualizarLista();
                        }}
                        initialValues={{ idPerfil: "", idEscritorio: "" }}
                    >
                        <Form>
                            <ItemFormPerfil hasBtnAdd={true} />
                        </Form>
                    </Formik>
                </>
            }
        />
    );
};

export const UsuarioPerfis = ({ idUsuario }: { idUsuario: String }) => {
    const [perfisPromise, setPerfisPromise] = useState<Promise<UsuarioPerfilViewModel[] | undefined>>();

    useEffect(() => {
        setPerfisPromise(PerfilService.listarPerfisPorUsuario(idUsuario));
    }, [idUsuario]);

    const remover = useCallback(
        async (idPerfil: string, idPerfilUsuario: string) => {
            const resposta = await ActionFeedback.confirm("Deseja remover o perfil?");

            if (!resposta) {
                return;
            }

            await ActionFeedback.processing({
                title: "Removendo perfil do usuário...",
                execution: PerfilService.removerUsuario(idPerfil, idPerfilUsuario),
            });

            setPerfisPromise(PerfilService.listarPerfisPorUsuario(idUsuario));
        },
        [setPerfisPromise, idUsuario]
    );

    const columns = useMemo<IDataTableColumn<UsuarioPerfilViewModel>[]>(
        () => [
            {
                name: "Descrição",
                selector: "descricaoPerfil",
                maxWidth: "30%",
                sortable: true,
                wrap: false,
            },
            {
                name: "Modulo",
                maxWidth: "20%",
                selector: "descricaoModulo",
                sortable: true,
                id: "descricaoModulo",
                wrap: false,
            },
            {
                name: "Cliente/Escritório",
                selector: "nomeEscritorioOuCliente",
                maxWidth: "40%",
                sortable: false,
            },
            {
                name: "",
                maxWidth: "10px",
                sortable: false,
                id: "excluir",

                cell: (row) => (
                    <span
                        onClick={async () => {
                            await remover(row.idPerfil, row.id);
                        }}
                    >
                        <Icon className={styles.actionButton} path={mdiTrashCan} size={1.5} />
                    </span>
                ),
            },
        ],
        [remover]
    );

    return (
        <div>
            <OmnijusCard
                header={<h3>Perfis</h3>}
                body={
                    <>
                        <NovoPerfil
                            idUsuario={idUsuario}
                            atualizarLista={() => setPerfisPromise(PerfilService.listarPerfisPorUsuario(idUsuario))}
                        />
                        <SpacerV />

                        <Loading promise={perfisPromise}>
                            {(data) => (
                                <DataTable
                                    noDataComponent={<p>A consulta não retornou registros</p>}
                                    data={data || []}
                                    paginationRowsPerPageOptions={[10, 20, 50]}
                                    columns={columns}
                                />
                            )}
                        </Loading>
                    </>
                }
            />
        </div>
    );
};
