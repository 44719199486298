import React, { useEffect, useState } from "react";
import styles from "./listar-peticionamentos.module.scss";
import Modal from "react-modal";
import { CardInfoSolicitacaoPeticionamento } from "./card-info-solicitacao-peticionamento";
import { Form, Formik } from "formik";
import {
    ActionFeedback,
    ButtonSecondary,
    ButtonPrimary,
    OmnijusCard,
    Loading,
    OmnijusTextAreaField,
} from "@omnijus/common";
import { aprovarComRessalva, listarMotivosRessalva } from "../../services/peticionamento/peticionamento-service";
import { MotivoRessalvaAprovacaoPeticionamento } from "../../services/peticionamento/models/motivo-ressalva-aprovacao-peticionamento";
import { SolicitacaoPeticionamentoAprovarComRessalvaCommand } from "../../services/peticionamento/models/aprovar-com-ressalva";

interface ModalAprovarComRessalvaProps {
    idSolicitacaoPeticionamento: string;
    nomeCliente: string | null;
    numeroProcesso: string | null;
    nomeEscritorio?: string;
    statusSolicitacaoPeticionamento: string | null;
    descricaoProvidencia?: string;
    isModalOpen: boolean;
    onCloseModal: () => void;
}

export const ModalAprovarComRessalva = (props: ModalAprovarComRessalvaProps) => {
    const [promise, setPromise] = useState<Promise<MotivoRessalvaAprovacaoPeticionamento[] | undefined>>();

    useEffect(() => {
        props.isModalOpen && setPromise(listarMotivosRessalva());
    }, [props.isModalOpen]);

    return (
        <Modal
            style={{
                content: {
                    width: "60vw",
                    margin: "auto",
                },
            }}
            isOpen={props.isModalOpen}
            onRequestClose={props.onCloseModal}
            shouldCloseOnEsc
            ariaHideApp={false}
            shouldCloseOnOverlayClick={false}
        >
            <h2>Aprovar com Ressalva</h2>

            <CardInfoSolicitacaoPeticionamento {...props} modal="2Colunas" />

            <Formik
                initialValues={{}}
                onSubmit={async (values) => {
                    const command: SolicitacaoPeticionamentoAprovarComRessalvaCommand = {
                        idSolicitacaoPeticionamento: props.idSolicitacaoPeticionamento,
                        justificativas: [],
                    };

                    Object.entries(values).forEach(([chave, valor]) => {
                        if (valor) {
                            command.justificativas.push({
                                idMotivoRessalvaAprovacaoPeticionamento: +chave,
                                justificativa: valor as string,
                            });
                        }
                    });

                    if (!command.justificativas.flatMap((x) => x.justificativa).some((x) => x)) {
                        await ActionFeedback.error({
                            text: "É necessário preencher pelo menos uma das justificativas",
                            icon: "warning",
                        });

                        return;
                    }

                    await ActionFeedback.processing({
                        title: "Processando...",
                        execution: aprovarComRessalva(command),
                        onError: async (error: any) =>
                            await ActionFeedback.error({
                                icon: "error",
                                html: error?.json?.erros
                                    ? error.json.erros.join("<br />")
                                    : error?.json?.title
                                    ? error?.json?.title
                                    : "Não foi possível realizar a aprovação com ressalva",
                            }),
                    });

                    await ActionFeedback.info({
                        text: "Aprovação com ressalva realizada com sucesso",
                    });

                    props.onCloseModal();
                    window.location.reload();
                }}
            >
                <Form className={styles.marginTop}>
                    <OmnijusCard
                        body={
                            <Loading promise={promise}>
                                {(motivos) =>
                                    motivos?.map((motivo) => {
                                        return (
                                            <div style={{ marginBottom: "3rem" }}>
                                                <OmnijusTextAreaField
                                                    key={motivo.id}
                                                    name={motivo.id.toString()}
                                                    label={motivo.motivo}
                                                />
                                            </div>
                                        );
                                    })
                                }
                            </Loading>
                        }
                    />
                    <div className={styles.alignRight}>
                        <ButtonPrimary className={`${styles.marginRight} ${styles.marginTop}`} type="submit">
                            Salvar
                        </ButtonPrimary>
                        <ButtonSecondary className={styles.marginTop} onClick={props.onCloseModal}>
                            Fechar
                        </ButtonSecondary>
                    </div>
                </Form>
            </Formik>
        </Modal>
    );
};
