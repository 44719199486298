import { Loading } from "@omnijus/common";
import React, { useEffect, useState } from "react";
import { FiltroArea } from "../../../services/filtros/models/filtro-area";
import { DominioService } from "../../../services/processo/dominio-service";
import { OmnijusSelectField } from "../fields/omnijus-select-field";

interface OmnijusFiltroAreaProps {
    hideLabel?: boolean;
    name?: string;
    label?: string;
    promiseOpcoesArea?: Promise<FiltroArea[] | undefined>;
}

export const OmnijusFiltroArea = (props: OmnijusFiltroAreaProps) => {
    const [promiseOpcoesArea, setPromiseOpcoesArea] = useState<Promise<FiltroArea[] | undefined>>();

    useEffect(() => {
        setPromiseOpcoesArea(props.promiseOpcoesArea || DominioService.consultarArea());
    }, [props.promiseOpcoesArea]);

    return (
        <Loading promise={promiseOpcoesArea}>
            {(opcoesArea) => (
                <OmnijusSelectField
                    name={props.name || "IdAreaAtuacaoOrgao"}
                    label={props.hideLabel ? undefined : props.label || "Área"}
                    options={opcoesArea
                        ?.map((o) => ({
                            value: o.id.toString(),
                            label: o.value.toString(),
                        }))
                        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
                />
            )}
        </Loading>
    );
};
