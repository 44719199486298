import { ActionFeedback, ButtonSecondary, OmnijusCard } from "@omnijus/common";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import Modal from "react-modal";
import styles from "./atualizacao-processo.module.scss";
import { object as YupObject, number as YupNumber, mixed as YupMixed } from "yup";
import { OmnijusFileField } from "../../shared/form/fields/omnijus-file-field";
import { OmnijusTipoDocumento } from "../../shared/form/custom-fields/omnijus-tipo-documento";
import DataTable from "react-data-table-component";
import { ReactComponent as TrashIcon } from "../../assets/images/trash-icon.svg";
import { ButtonPrimary } from "../../shared/form/fields/omnijus-button-primary";
import { DocumentoPeticionamento } from "../../services/peticionamento/models/documentos-peticionamento";
import { AtualizacaoProcessoService } from "../../services/atualizacao-processo/atualizacao-processo-service";
import { CardInfoMovimento } from "./card-info-movimento";

export const ModalIncluirDocumentosEmUmMovimento = (props: {
    tiposDocumento: { id: number; value: string }[];
    isModalOpen: boolean;
    onCloseModal: () => void;
    numeroProcesso: string;
    movimento: { fase: string; movimentacao: string; idProcessoMovimento: string };
    idProcesso: string;
}) => {
    const [documentos, setDocumentos] = useState<DocumentoPeticionamento[]>([]);
    return (
        <>
            <Modal
                style={{
                    content: {
                        width: "70vw",
                        margin: "auto",
                    },
                }}
                isOpen={props.isModalOpen}
                onRequestClose={props.onCloseModal}
                shouldCloseOnEsc
                ariaHideApp={false}
                shouldCloseOnOverlayClick={false}
            >
                <div className={styles.modalIncluirDocumentos}>
                    <h2>Adicionar documentos ao movimento</h2>

                    <CardInfoMovimento fase={props.movimento.fase} movimentacao={props.movimento.movimentacao} />

                    <Formik
                        enableReinitialize
                        initialValues={
                            {
                                documento: null,
                                idTipoDocumento: undefined,
                                descricaoTipoDocumento: undefined,
                            } as DocumentoPeticionamento
                        }
                        validationSchema={YupObject().shape({
                            idTipoDocumento: YupNumber().required("Informe o tipo do documento"),
                            documento: YupMixed().required("Selecione um documento"),
                        })}
                        onSubmit={(values, { resetForm }) => {
                            const descricaoTipoDocumento = props.tiposDocumento.find(
                                (x) => x.id === +values.idTipoDocumento!
                            )?.value;

                            setDocumentos([
                                {
                                    ...values,
                                    descricaoTipoDocumento,
                                },
                                ...documentos,
                            ]);

                            resetForm();
                        }}
                    >
                        <Form>
                            <OmnijusCard
                                body={
                                    <>
                                        <div className={styles.formIncluirDocumentos}>
                                            <OmnijusFileField
                                                label="Documento"
                                                name="documento"
                                                accept="application/pdf"
                                            />

                                            <OmnijusTipoDocumento label="Tipo" options={props.tiposDocumento} />
                                        </div>
                                        <div className={`${styles.alignRight} ${styles.marginTop}`}>
                                            <ButtonPrimary type="submit">Adicionar</ButtonPrimary>
                                        </div>
                                    </>
                                }
                            />
                        </Form>
                    </Formik>

                    <OmnijusCard
                        body={
                            <DataTable
                                noHeader={true}
                                pagination={true}
                                paginationPerPage={7}
                                noDataComponent={<p>Nenhum item adicionado</p>}
                                data={documentos || []}
                                columns={[
                                    {
                                        name: "Tipo Documento",
                                        selector: (row) => row.descricaoTipoDocumento,
                                        wrap: true,
                                    },
                                    {
                                        name: "Documento",
                                        selector: (row) => row.documento?.name,
                                        wrap: true,
                                    },
                                    {
                                        name: "",
                                        width: "5rem",
                                        selector: (row) => (
                                            <TrashIcon
                                                style={{ cursor: "pointer" }}
                                                className={styles.trashIcon}
                                                title="Clique para remover este documento"
                                                onClick={() => {
                                                    setDocumentos(documentos.filter((documento) => documento !== row));
                                                }}
                                            />
                                        ),
                                    },
                                ]}
                            />
                        }
                    />
                    <div className={styles.alignRight}>
                        {documentos.length > 0 && documentos.length <= 30 && (
                            <ButtonPrimary
                                className={`${styles.botaoSalvar} ${styles.marginRight}`}
                                type="button"
                                onClick={async () => {
                                    let command = {
                                        idProcesso: props.idProcesso,
                                        numeroProcesso: props.numeroProcesso,
                                        documentos: documentos.flatMap((x) => x.documento),
                                        idsTipoDocumento: documentos.flatMap((x) => x.idTipoDocumento),
                                        descricoesTipoDocumento: documentos.flatMap((x) => x.descricaoTipoDocumento),
                                    };

                                    await ActionFeedback.processing({
                                        title: "Processando...",
                                        execution: AtualizacaoProcessoService.adicionarDocumentosAoProcessoMovimento(
                                            props.movimento.idProcessoMovimento,
                                            command
                                        ),
                                    });

                                    await ActionFeedback.info({
                                        text: "Documentos incluídos com sucesso!",
                                    });

                                    window.location.reload();
                                }}
                            >
                                Salvar
                            </ButtonPrimary>
                        )}

                        <ButtonSecondary onClick={props.onCloseModal}>Fechar</ButtonSecondary>
                    </div>
                </div>
            </Modal>
        </>
    );
};
