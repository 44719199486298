import { Loading, OmnijusCard, useAuth } from "@omnijus/common";
import React, { useEffect, useState } from "react";
import styles from "./movimentacao-assistida.module.scss";
import { ReactComponent as EditIcon } from "../../assets/images/edit-pen.svg";
import { ReactComponent as SearchIcon } from "../../assets/images/search-icon.svg";
import { ReactComponent as ErrorIcon } from "../../assets/images/error-icon-filled.svg";
import { ReactComponent as CalendarIcon } from "../../assets/images/calendar-line.svg";
import { InformacoesDashboard } from "../../services/movimentacao-assistida/models/informacoes-dashboard";
import { MovimentacaoAssistidaService } from "../../services/movimentacao-assistida/movimentacao-assistida-service";
import { IdStatusDocumentoRotulo } from "../../services/movimentacao-assistida/models/id-status-documento-rotulo";
import { Link } from "react-router-dom";

const ObterIcone = (idStatusDocumentoRotulo: number) => {
    switch (idStatusDocumentoRotulo) {
        case IdStatusDocumentoRotulo.PendenteAnaliseRotulo:
            return <EditIcon className={styles.icon} height={16} width={16} />;
        case IdStatusDocumentoRotulo.PendenteResposta:
            return <ErrorIcon className={styles.icon} height={22} width={22} />;
        case IdStatusDocumentoRotulo.PendenteRevisaoInterna:
            return <CalendarIcon className={styles.icon} height={21} width={21} />;
        case IdStatusDocumentoRotulo.PendenteAnaliseResposta:
            return <SearchIcon className={styles.icon} height={18} width={18} />;
    }
};

export const DashboardMovimentacaoAssistida = () => {
    const userName = useAuth()?.user?.profile?.name;
    const [informacoes, setInformacoes] = useState<Promise<InformacoesDashboard[] | undefined>>();

    useEffect(() => {
        setInformacoes(MovimentacaoAssistidaService.obterInformacoesDashboard());
    }, []);

    return (
        <>
            {userName && <h2>Olá, {userName}</h2>}

            <Loading promise={informacoes}>
                {(informacoes) => {
                    return (
                        informacoes && (
                            <div className={styles.cards}>
                                {informacoes.map((info, index) => {
                                    return (
                                        <OmnijusCard
                                            key={index}
                                            body={
                                                <Link
                                                    style={{ textDecoration: "inherit", color: "inherit" }}
                                                    to={`/movimentacao-assistida/movimentos?status=${info.idStatusDocumentoRotulo}`}
                                                >
                                                    <div className={styles.card}>
                                                        {ObterIcone(info.idStatusDocumentoRotulo)}
                                                        <div>
                                                            <h3>{info.titulo}</h3>
                                                            <h3>{info.total}</h3>
                                                        </div>
                                                    </div>
                                                </Link>
                                            }
                                        />
                                    );
                                })}

                                {informacoes.map((info, index) => {
                                    return (
                                        <OmnijusCard
                                            key={index}
                                            body={
                                                <div className={styles.classificacoes}>
                                                    <Link
                                                        style={{ textDecoration: "inherit", color: "inherit" }}
                                                        to={`/movimentacao-assistida/movimentos?status=${info.idStatusDocumentoRotulo}&intervalo=48`}
                                                    >
                                                        <h3 className={styles.prioridade}>Acima de 48h</h3>
                                                    </Link>
                                                    <h3 className={styles.prioridadeAlignRight}>{info.acimaDe48h}</h3>

                                                    <Link
                                                        style={{ textDecoration: "inherit", color: "inherit" }}
                                                        to={`/movimentacao-assistida/movimentos?status=${info.idStatusDocumentoRotulo}&intervalo=24-48`}
                                                    >
                                                        <h3 className={styles.prioridade}>Acima de 24h</h3>
                                                    </Link>
                                                    <h3 className={styles.prioridadeAlignRight}>{info.acimaDe24h}</h3>

                                                    <Link
                                                        style={{ textDecoration: "inherit", color: "inherit" }}
                                                        to={`/movimentacao-assistida/movimentos?status=${info.idStatusDocumentoRotulo}&intervalo=0-24`}
                                                    >
                                                        <h3>Até 24h</h3>
                                                    </Link>
                                                    <h3 className={styles.alignRight}>{info.ate24h}</h3>
                                                </div>
                                            }
                                        />
                                    );
                                })}
                            </div>
                        )
                    );
                }}
            </Loading>
        </>
    );
};
