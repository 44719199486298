import { OmnijusCard, OmnijusTextAreaField } from "@omnijus/common";
import React from "react";
import styles from "./inclusao-audiencia.module.scss";

export const InclusaoAudienciaInformacoesParceiro = () => {
    return (
        <OmnijusCard
            header={<h3>Instruções para o parceiro</h3>}
            body={
                <div className={`${styles["grid-campos"]} ${styles["grid-campos-parceiro"]}`}>
                    <OmnijusTextAreaField
                        name="observacoesParceiro"
                        label="Observações para o parceiro"
                    />
                    <OmnijusTextAreaField
                        name="propostaAcordo"
                        label="Proposta de acordo"
                    />
                    <OmnijusTextAreaField
                        name="instrucoesTeleaudiencia"
                        label="Instruções para teleaudiência"
                    />
                </div>
            }
        />
    );
}