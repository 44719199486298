import { OmnijusCard, OmnijusTextField } from "@omnijus/common";
import { Form } from "formik";
import React from "react";
import { OmnijusFiltroCpfCnpj } from "../../../../shared/form/custom-fields/omnijus-filtro-cpf-cnpj";
import { SpacerV } from "../../../../shared/layout/spacer";

import styles from "../usuarios.module.scss";
import { ItemFormPerfil } from "./item-form-perfil";

export interface FormUsuarioProps {
    isNew: boolean | Boolean;
    isEditable?: boolean | Boolean;
    action: () => JSX.Element | null;
}

export const FormUsuario = (props: FormUsuarioProps) => {
    return (
        <Form>
            <OmnijusCard
                header={<h3>Usuário</h3>}
                body={
                    <>
                        <p className={styles.info}>
                            {props.isNew
                                ? "Cadastro não encontrado na plataforma. Informe o e-mail e nome para assoscia-lo como um de seus colaboradores."
                                : "Colaborador com cadastro ativo na plataforma."}
                        </p>

                        <div className={styles.gridForm}>
                            <OmnijusTextField name="nome" label="Nome" disabled={!props.isNew} />
                            <OmnijusTextField name="email" label="Email" disabled={!props.isNew} />
                            <OmnijusFiltroCpfCnpj name="cpf" label="CPF" disabled={true} />
                        </div>
                        {props.isNew && <ItemFormPerfil hasBtnAdd={false} />}
                    </>
                }
            />
            <SpacerV />
            <div className="text-right">{props.action()}</div>
        </Form>
    );
};
