import { RequestApi } from "@omnijus/common";
import { CLIENT_ID } from "../../../services/client-id";
import { ParametrosPesquisarEscritorio } from "./escritorio-parametros";
import { EscritorioViewModel } from "./escritorio-viewmodel";

const escritorioUrl = process.env.REACT_APP_BFF_OPERACIONAL_API_URL + "/api/ConfiguracaoControleAcesso";

const request = new RequestApi(CLIENT_ID);

export const EscritorioService = {
    listar: async (filtro?: ParametrosPesquisarEscritorio) => {
        let url = new URL(`${escritorioUrl}/v1/Escritorio`);

        if (filtro) {
            url.search = RequestApi.objectToQueryString(filtro);
        }

        return await request.get<EscritorioViewModel[]>(url.toString());
    },
    obter: async (id: string) => {
        const url = `${escritorioUrl}/v1/Escritorio/${id}`;

        return await request.get<EscritorioViewModel>(url);
    },
    remover: async (id: string) => {
        const url = `${escritorioUrl}/v1/Escritorio/${id}`;

        return await request.delete(url);
    },
    inserir: async (escritorio: EscritorioViewModel) => {
        const url = `${escritorioUrl}/v1/Escritorio`;
        return await request.post<string>(url, escritorio);
    },
    atualizar: async (id: string, escritorio: EscritorioViewModel) => {
        const url = `${escritorioUrl}/v1/Escritorio/${id}`;
        return await request.put<string>(url, escritorio);
    },
};
