export enum IdStatusSolicitacaoProcessoAudiencia {
    Nova = 1,
    PendenteDeIntegracao = 2,
    SolicitadosExecutores = 3,
    AudienciaRealizadaAguardandoAta = 4,
    AudienciaRealizadaAtaRecebida = 5,
    Cancelada = 6,
    AguardandoAvaliacaoExecutores = 7,
    AguardandoRealizacaoAudiencia = 8,
    AguardandoResolucaoPendencia = 9,
    SolicitacaoExecutoresCancelada = 10,
    FalhaNoServicoDoParceiro = 11,
}
