import { Back } from "@omnijus/common";
import React from "react";
import { useParams } from "react-router";
import { DetalhamentoAudienciaDadosParceiro } from "./detalhamento-audiencia-dados-parceiro";
import { DetalhamentoAudienciaDocumentos } from "./detalhamento-audiencia-documentos";
import { DetalhamentoAudienciaEnderecamento } from "./detalhamento-audiencia-enderecamento";
import { DetalhamentoAudienciaExecutores } from "./detalhamento-audiencia-executores";
import { DetalhamentoAudienciaLinhaTempo } from "./detalhamento-audiencia-linha-tempo";
import { DetalhamentoAudienciaPrincipal } from "./detalhamento-audiencia-principal";
import styles from "./detalhamento-audiencia.module.scss";

export const DetalhamentoAudiencia = () => {
    const { id } = useParams<{ id: string }>();

    return (
        <>
            <Back />
            <h2>Gestão de Audiência</h2>
            <div className={styles["dados-wrapper"]}>    
                <div className={styles["linha-tempo-wrapper"]}>
                    <DetalhamentoAudienciaLinhaTempo idAudiencia={id} />
                    {/* TODO Implementação adiada */}
                    {/* <DetalhamentoAudienciaStatusCancelamento idAudiencia={id} /> */}
                </div>
                <div className={styles["principal-wrapper"]}>
                    <DetalhamentoAudienciaEnderecamento idAudiencia={id} />
                    <DetalhamentoAudienciaPrincipal idAudiencia={id} />
                    <DetalhamentoAudienciaDadosParceiro idAudiencia={id} />
                    <DetalhamentoAudienciaDocumentos idAudiencia={id} />
                    <DetalhamentoAudienciaExecutores idAudiencia={id} />
                    {/* TODO Implementação adiada */}
                    {/* <DetalhamentoAudienciaComunicacaoParceiro idAudiencia={id} />
                    <DetalhamentoAudienciaAlteracoes idAudiencia={id} /> */}
                </div>
            </div>
        </>
    );
}