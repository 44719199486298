import { OmnijusCard } from "@omnijus/common";
import { format } from "date-fns";
import { ModalCriarAgendamentos } from "../../pages/agendamentos/modal-criar-agendamentos";
import React, { useEffect, useState } from "react";
import { AnaliseRotuloViewModel } from "../../services/movimentacao-assistida/models/analise-rotulo";
import styles from "./movimentacao-assistida.module.scss";
import { AnaliseRespostasEntrevistaGuiadaViewModel } from "../../services/movimentacao-assistida/models/analise-respostas-entrevista-guiada";
import { ReactComponent as UploadIcon } from "../../assets/images/upload-icon.svg";
import { ModalIncluirDocumentosEmUmMovimento } from "../../pages/atualizacao-processos/modal-incluir-documentos-movimento";
import { TipoDocumentoService } from "../../services/tipo-documento/tipo-documento-service";

export const InformacoesCardMovimento = (props: AnaliseRotuloViewModel | AnaliseRespostasEntrevistaGuiadaViewModel) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [isModalIncluirDocumentosOpen, setModalIncluirDocumentosOpen] = useState(false);
    const [tiposDocumento, setTiposDocumento] = useState<
        {
            id: number;
            value: string;
        }[]
    >();

    useEffect(() => {
        (async () => {
            setTiposDocumento(await TipoDocumentoService.listarCombo());
        })();
    }, []);

    return (
        <div className={styles.marginBottom}>
            <OmnijusCard
                body={
                    <>
                        <h2>Movimento</h2>

                        <div className={styles.cardMovimento}>
                            <div>
                                <p>
                                    {props.processoMovimento.data &&
                                        format(new Date(props.processoMovimento.data), "dd/MM/yyyy HH:mm")}
                                </p>

                                <p>
                                    <strong>{props.processoMovimento.movimentacao}</strong>
                                </p>

                                <p>{props.processoMovimento.fase}</p>
                            </div>
                            <div className={styles.uploadDocumento}>
                                {tiposDocumento && (
                                    <>
                                        <div className={styles.uploadIcon}>
                                            <UploadIcon
                                                cursor="pointer"
                                                width={17.5}
                                                title="Clique para fazer upload de documentos para o movimento"
                                                onClick={() => setModalIncluirDocumentosOpen(true)}
                                            />
                                        </div>

                                        <div>Upload Documento</div>
                                    </>
                                )}
                            </div>
                        </div>

                        <div className={styles.barraAcao}>
                            <div className={styles.botoesAcao}>
                                <button
                                    className={styles.botaoAcao}
                                    onClick={() => {
                                        setModalOpen(true);
                                    }}
                                >
                                    Criar Agendamentos
                                </button>
                            </div>
                        </div>
                    </>
                }
            />

            <ModalCriarAgendamentos
                numeroProcesso={props.processoMovimento.processo.numeroProcesso}
                isModalOpen={isModalOpen}
                onCloseModal={() => setModalOpen(false)}
                idProcessoMovimento={props.processoMovimento.idProcessoMovimento}
            />

            {tiposDocumento && (
                <ModalIncluirDocumentosEmUmMovimento
                    idProcesso={props.processoMovimento.processo.idProcesso}
                    isModalOpen={isModalIncluirDocumentosOpen}
                    onCloseModal={() => setModalIncluirDocumentosOpen(false)}
                    movimento={props.processoMovimento}
                    numeroProcesso={props.processoMovimento.processo.numeroProcesso}
                    tiposDocumento={tiposDocumento}
                />
            )}
        </div>
    );
};
