import { CardFiltros, Loading, OmnijusCard } from "@omnijus/common";
import { ReactComponent as CalendarIcon } from "../../../assets/images/calendar-line.svg";
import { ReactComponent as EditPenIcon } from "../../../assets/images/edit-pen.svg";
import { addYears, format } from "date-fns";
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useHistory } from "react-router-dom";
import { AudienciaService } from "../../../services/audiencia/audiencia-service";
import { DetalheConsultaAudiencias } from "../../../services/audiencia/models/detalhe-consulta-audiencias";
import { FiltroConsultaAudiencias } from "../../../services/audiencia/models/filtro-consulta-audiencias";
import { IdStatusProcessoAudiencia } from "../../../services/audiencia/models/id-status-processo-audiencia";
import { IdStatusSolicitacaoProcessoAudiencia } from "../../../services/audiencia/models/id-status-solicitacao-processo-audiencia";
import { OmnijusCampoNumeroProcesso } from "../../../shared/form/custom-fields/omnijus-campo-numero-processo";
import { OmnijusFiltroCliente } from "../../../shared/form/custom-fields/omnijus-filtro-cliente";
import { OmnijusFiltroStatusAudiencia } from "../../../shared/form/custom-fields/omnijus-filtro-status-audiencia";
import { OmnijusFiltroStatusSolicitacaoAudiencia } from "../../../shared/form/custom-fields/omnijus-filtro-status-solicitacao-audiencia";
import { OmnijusRangeDateField } from "../../../shared/form/fields/omnijus-range-date-field";
import Swal from "sweetalert2";
import { date as YupDate, object as YupObject, string as YupString } from "yup";
import { ModalRedesignacaoAudiencia } from "../redesignacao/modal-redesignacao";
import styles from "./consulta-audiencia.module.scss";

export const ConsultaAudiencia = () => {
    const history = useHistory();
    const [promise, setPromise] = useState<Promise<Array<DetalheConsultaAudiencias> | undefined>>();
    const [isModalRedesignacaoOpen, setModalRedesignacaoOpen] = useState(false);
    const [dadosAudiencia, setDadosAudiencia] = useState<{
        idProcessoAudiencia: string;
        numeroProcesso: string;
        descricaoStatusAudiencia: string;
        dataHoraAudiencia: string;
        dataHoraAudienciaSemFormatacao: string;
    }>({
        idProcessoAudiencia: "",
        numeroProcesso: "",
        descricaoStatusAudiencia: "",
        dataHoraAudiencia: "",
        dataHoraAudienciaSemFormatacao: "",
    });

    return (
        <div className={styles["consulta-audiencias"]}>
            <h2>Consulta Audiência</h2>
            <CardFiltros
                textoBotao="Filtrar"
                onBuscar={async (values) => {
                    const filtro = values as FiltroConsultaAudiencias;
                    const algumFiltroInformado =
                        filtro.dataDe ||
                        filtro.dataAte ||
                        filtro.idStatusProcessoAudiencia ||
                        filtro.idStatusSolicitacaoProcessoAudiencia ||
                        (filtro.idsCliente && filtro.idsCliente.length > 0) ||
                        filtro.numeroProcesso;

                    if (!algumFiltroInformado) {
                        await Swal.fire({
                            icon: "warning",
                            text: "É necessário definir ao menos um dos critérios de consulta",
                        });
                        return;
                    }

                    setPromise(AudienciaService.consultar(values));
                }}
                initialValues={
                    {
                        numeroProcesso: "",
                        dataDe: undefined,
                        dataAte: undefined,
                        idStatusSolicitacaoProcessoAudiencia: undefined,
                        idStatusProcessoAudiencia: undefined,
                        idsCliente: [],
                    } as FiltroConsultaAudiencias
                }
                botaoFiltrarInline
                validationSchema={YupObject().shape({
                    numeroProcesso: YupString().min(25, "O número de processo deve conter exatamente 20 dígitos"),
                    dataDe: YupDate()
                        .nullable()
                        .typeError("Data inválida")
                        .max(addYears(new Date(), 1), "A data não pode passar de 1 ano no futuro"),
                    dataAte: YupDate()
                        .nullable()
                        .typeError("Data inválida")
                        .max(addYears(new Date(), 1), "A data não pode passar de 1 ano no futuro")
                        .test("maior", "A data final não pode ser anterior à data inicial", function (dataAte) {
                            const { dataDe } = this.parent;

                            return !(dataAte && dataDe && dataAte < dataDe);
                        })
                        .test("datediff", "Diferença de datas deve ser de no máximo 6 meses", function (dataAte) {
                            const { dataDe } = this.parent;

                            if (dataAte && dataDe) {
                                const timediff = dataAte.getTime() - (dataDe as Date).getTime();
                                const diff = timediff / (1000 * 3600 * 24);
                                return diff <= 180;
                            }

                            return true;
                        }),
                })}
            >
                <OmnijusCampoNumeroProcesso name="numeroProcesso" label="Nº do Processo" />
                <OmnijusFiltroCliente name="idsCliente" label="Cliente" isMulti />
                <OmnijusRangeDateField nameDe="dataDe" nameAte="dataAte" label="Data da Audiência" />
                <OmnijusFiltroStatusAudiencia name="idStatusProcessoAudiencia" label="Status da Audiência" />
                <OmnijusFiltroStatusSolicitacaoAudiencia
                    name="idStatusSolicitacaoProcessoAudiencia"
                    label="Etapa do Fluxo"
                />
            </CardFiltros>
            <div className={styles["adicionar-audiencia"]}>
                <Link to="/audiencia/inclusao">Adicionar Audiência</Link>
            </div>
            {promise ? (
                <Loading promise={promise}>
                    {(audiencias) => (
                        <OmnijusCard
                            body={
                                <DataTable
                                    noHeader={true}
                                    customStyles={{
                                        rows: {
                                            style: {
                                                cursor: "pointer",
                                            },
                                        },
                                    }}
                                    pagination={true}
                                    paginationPerPage={20}
                                    noDataComponent={<p>A consulta não retornou registros</p>}
                                    data={audiencias || []}
                                    onRowClicked={(row) => history.push(`/audiencia/detalhamento/${row.idAudiencia}`)}
                                    columns={[
                                        {
                                            name: "Nº do Processo",
                                            minWidth: "200px",
                                            selector: (row) => row.numeroProcesso,
                                            sortable: true,
                                        },
                                        {
                                            name: "Cliente",
                                            minWidth: "200px",
                                            selector: (row) => row.nomeCliente,
                                            sortable: true,
                                        },
                                        {
                                            name: "Data da Audiência",
                                            minWidth: "200px",
                                            selector: (row) => row.dataHoraAudiencia,
                                            format: (row) => format(row.dataHoraAudiencia, "dd/MM/yyyy HH:mm"),
                                            sortable: true,
                                        },
                                        {
                                            name: "Tipo da Audiência",
                                            minWidth: "200px",
                                            selector: (row) => row.descricaoTipoProcessoAudiencia,
                                            sortable: true,
                                        },
                                        {
                                            name: "Status da Audiência",
                                            minWidth: "200px",
                                            selector: (row) => row.descricaoStatusAudiencia,
                                            sortable: true,
                                        },
                                        {
                                            name: "Etapa do Fluxo",
                                            minWidth: "235px",
                                            selector: (row) => row.descricaoStatusSolicitacaoAudiencia,
                                            format: (row) => row.descricaoStatusSolicitacaoAudiencia || "-",
                                            sortable: true,
                                            wrap: true,
                                        },
                                        {
                                            name: "Motivo Cancelamento",
                                            minWidth: "235px",
                                            selector: (row) => row.descricaoMotivoCancelamentoAudiencia,
                                            format: (row) => row.descricaoMotivoCancelamentoAudiencia || "-",
                                            sortable: true,
                                            wrap: true,
                                        },
                                        {
                                            name: "ID no Parceiro",
                                            minWidth: "200px",
                                            selector: (row) => row.idAudienciaParceiro || "-",
                                            sortable: true,
                                        },
                                        {
                                            name: "Origem",
                                            minWidth: "200px",
                                            selector: (row) => (row.audienciaManual ? "Manual" : "Movimento"),
                                            sortable: true,
                                        },
                                        {
                                            name: "Redesignar Audiência",
                                            minWidth: "200px",
                                            ignoreRowClick: true,
                                            selector: (row) => {
                                                const botaoAtivo =
                                                    row.idStatusProcessoAudiencia ===
                                                        IdStatusProcessoAudiencia.Designada ||
                                                    row.idStatusProcessoAudiencia ===
                                                        IdStatusProcessoAudiencia.Redesignada;

                                                return (
                                                    <button
                                                        id="btnRedesignar"
                                                        onClick={() => {
                                                            setModalRedesignacaoOpen(true);

                                                            setDadosAudiencia({
                                                                idProcessoAudiencia: row.idAudiencia,
                                                                numeroProcesso: row.numeroProcesso,
                                                                descricaoStatusAudiencia: row.descricaoStatusAudiencia,
                                                                dataHoraAudiencia: format(
                                                                    row.dataHoraAudiencia,
                                                                    "dd/MM/yyyy HH:mm"
                                                                ),
                                                                dataHoraAudienciaSemFormatacao:
                                                                    row.dataHoraAudiencia.toString(),
                                                            });
                                                        }}
                                                        className={styles["acao-audiencia"]}
                                                        disabled={!botaoAtivo}
                                                    >
                                                        <CalendarIcon className={styles["icon"]} />
                                                    </button>
                                                );
                                            },
                                            sortable: false,
                                            center: true,
                                        },
                                        {
                                            name: "Editar Audiência",
                                            minWidth: "200px",
                                            ignoreRowClick: true,
                                            selector: (row) => {
                                                return (
                                                    <button
                                                        className={styles["acao-audiencia"]}
                                                        disabled={
                                                            !(
                                                                (row.idStatusProcessoAudiencia ===
                                                                    IdStatusProcessoAudiencia.Designada ||
                                                                    row.idStatusProcessoAudiencia ===
                                                                        IdStatusProcessoAudiencia.Redesignada) &&
                                                                (row.idStatusSolicitacaoProcessoAudiencia ===
                                                                    IdStatusSolicitacaoProcessoAudiencia.Nova ||
                                                                    row.idStatusSolicitacaoProcessoAudiencia ===
                                                                        IdStatusSolicitacaoProcessoAudiencia.PendenteDeIntegracao ||
                                                                    row.idStatusSolicitacaoProcessoAudiencia ===
                                                                        IdStatusSolicitacaoProcessoAudiencia.SolicitadosExecutores ||
                                                                    row.idStatusSolicitacaoProcessoAudiencia ===
                                                                        IdStatusSolicitacaoProcessoAudiencia.AguardandoAvaliacaoExecutores ||
                                                                    row.idStatusSolicitacaoProcessoAudiencia ===
                                                                        IdStatusSolicitacaoProcessoAudiencia.AguardandoRealizacaoAudiencia ||
                                                                    row.idStatusSolicitacaoProcessoAudiencia ===
                                                                        IdStatusSolicitacaoProcessoAudiencia.AguardandoResolucaoPendencia ||
                                                                    row.idStatusSolicitacaoProcessoAudiencia ===
                                                                        IdStatusSolicitacaoProcessoAudiencia.SolicitacaoExecutoresCancelada ||
                                                                    row.idStatusSolicitacaoProcessoAudiencia ===
                                                                        IdStatusSolicitacaoProcessoAudiencia.FalhaNoServicoDoParceiro)
                                                            )
                                                        }
                                                        onClick={() =>
                                                            history.push(`/audiencia/edicao/${row.idAudiencia}`)
                                                        }
                                                    >
                                                        <EditPenIcon className={styles["icon"]} />
                                                    </button>
                                                );
                                            },
                                            sortable: false,
                                            center: true,
                                        },
                                    ]}
                                />
                            }
                        />
                    )}
                </Loading>
            ) : (
                <p className="text-center">Utilize os filtros para realizar uma nova busca!</p>
            )}

            {isModalRedesignacaoOpen && (
                <>
                    <ModalRedesignacaoAudiencia
                        isModalOpen={isModalRedesignacaoOpen}
                        onCloseModal={() => setModalRedesignacaoOpen(false)}
                        {...dadosAudiencia}
                    />
                </>
            )}
        </div>
    );
};
