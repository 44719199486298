import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { OmnijusRadioField } from "../../shared/form/fields/omnijus-radio-field";
import { Documento } from "../../services/movimentacao-assistida/models/analise-rotulo";
import { SelectDocumento } from "./select-documento";
import { OmnijusCard } from "@omnijus/common";

export const RadioSelecaoDocumentos = (props: {
    documentosProcesso: Documento[];
    documentosMovimento: Documento[];
}) => {
    const [documentos, SetDocumentos] = useState<Documento[]>();

    useEffect(() => {
        SetDocumentos(props.documentosMovimento);
    }, [props.documentosMovimento]);

    return (
        <OmnijusCard
            body={
                <>
                    <Formik
                        initialValues={{
                            radioTipoDocumento: "movimento",
                        }}
                        onSubmit={() => {}}
                    >
                        {(formik) => (
                            <Form>
                                <OmnijusRadioField
                                    name="radioTipoDocumento"
                                    label="Selecione quais documentos deseja visualizar"
                                    options={[
                                        {
                                            label: "Documentos do Movimento",
                                            value: "movimento",
                                        },
                                        {
                                            label: "Documentos do Processo",
                                            value: "processo",
                                        },
                                    ]}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        formik.setFieldValue("radioTipoDocumento", e.target.value);

                                        if (e.target.value === "movimento") {
                                            SetDocumentos(props.documentosMovimento);
                                        } else {
                                            SetDocumentos(props.documentosProcesso);
                                        }
                                    }}
                                />
                            </Form>
                        )}
                    </Formik>
                    {documentos && (
                        <SelectDocumento
                            documentos={documentos.map((d) => {
                                return {
                                    label: d.nome,
                                    value: d.path,
                                };
                            })}
                        />
                    )}
                </>
            }
        />
    );
};
