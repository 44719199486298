import {
    ActionFeedback,
    CardFiltros,
    Loading,
    OmnijusCard,
    OmnijusRangeDateField,
    OmnijusTextField,
    TextButton,
} from "@omnijus/common";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import { OmnijusCampoNumeroProcesso } from "../../shared/form/custom-fields/omnijus-campo-numero-processo";
import Swal from "sweetalert2";
import { date as YupDate, object as YupObject, string as YupString } from "yup";
import styles from "./agendamentos.module.scss";
import { AgendamentoProcesso } from "../../services/agendamento/models/agendamento-processo";
import { AgendamentoProcessoService } from "../../services/agendamento/agendamento-processo-service";
import { FiltroAgendamentoProcesso } from "../../services/filtros/filtro-agendamento-processo";
import { OmnijusFiltroStatusAgendamento } from "../../shared/form/custom-fields/omnijus-filtro-status-agendameto";
import { ModalCriarAgendamentos } from "./modal-criar-agendamentos";
import { OmnijusFiltroProvidencia } from "../../shared/form/custom-fields/omnijus-filtro-providencia";
import { ReactComponent as TrashIcon } from "../../assets/images/trash-icon.svg";
import VisibleIcon from "../../assets/images/visible-icon.png";
import { IdStatusAgendamento } from "../../services/agendamento/models/id-status-agendamento";
import { useHistory } from "react-router-dom";
import { OmnijusFiltroCliente } from "../../shared/form/custom-fields/omnijus-filtro-cliente";
import { arrayObjectToXLSX } from "../../lib/export-format";
import { TriggerDownload } from "../../lib/trigger-download";

const columnsExportacao: IDataTableColumn<AgendamentoProcesso>[] = [
    { name: "Número do Processo", selector: (p) => p.numeroProcesso, sortable: true },
    { name: "Autor do Processo", selector: (p) => p.nomeAutorProcesso, sortable: true },
    {
        name: "Prazo Segurança",
        selector: (p) => p.dataPrazoSeguranca,
        format: (p) => (p.dataPrazoSeguranca ? format(new Date(p.dataPrazoSeguranca), "dd/MM/yyyy") : ""),
        sortable: true,
    },
    {
        name: "Data de distribuição",
        selector: (p) => p.dataPrazoFatal,
        format: (p) => (p.dataPrazoFatal ? format(new Date(p.dataPrazoFatal), "dd/MM/yyyy") : ""),
        sortable: true,
    },
    { name: "Status", selector: (p) => p.descricaoStatusAgendamento, sortable: true },
    { name: "Cliente", selector: (p) => p.cliente?.toUpperCase(), sortable: true },
    { name: "Providência", selector: (p) => p.descricaoProvidencia, sortable: true },
];

const downloadExportar = async (agendamentos: AgendamentoProcesso[]) => {
    const columnsForExcel: IDataTableColumn[] = [...columnsExportacao];

    TriggerDownload.xlsx(
        await arrayObjectToXLSX(agendamentos, columnsForExcel),
        `relatorio-agendamentos-${format(new Date(), "yyyyMMddHHmmss")}.xlsx`
    );
};

export const Agendamentos = () => {
    const [promise, setPromise] = useState<Promise<AgendamentoProcesso[] | undefined>>();
    const [isModalOpen, setModalOpen] = useState(false);
    const history = useHistory();

    useEffect(() => {
        setPromise(AgendamentoProcessoService.listar({} as FiltroAgendamentoProcesso));
    }, []);

    return (
        <div className={styles["agendamentos"]}>
            <h2>Agendamentos</h2>
            <CardFiltros
                textoBotao="Filtrar"
                onBuscar={async (values) => {
                    setPromise(AgendamentoProcessoService.listar(values));
                }}
                initialValues={
                    {
                        numeroProcesso: "",
                        nomeAutorProcesso: undefined,
                        nomeUsuarioCriacao: "",
                        dataPrazoFatalAte: undefined,
                        dataPrazoFatalDe: undefined,
                        dataPrazoSegurancaAte: undefined,
                        dataPrazoSegurancaDe: undefined,
                        idProvidencia: undefined,
                        idStatusAgendamento: undefined,
                        dataCriacaoDe: undefined,
                        dataCriacaoAte: undefined,
                        dataProtocoloDe: undefined,
                        dataProtocoloAte: undefined,
                        idCliente: undefined,
                        nomeUsuarioProtocolo: "",
                    } as FiltroAgendamentoProcesso
                }
                botaoFiltrarInline
                validationSchema={YupObject().shape({
                    numeroProcesso: YupString().min(25, "O número de processo deve conter exatamente 20 dígitos"),
                    dataPrazoFatalDe: YupDate().nullable().typeError("Data inválida"),
                    dataPrazoFatalAte: YupDate()
                        .nullable()
                        .typeError("Data inválida")
                        .test("maior", "A data final não pode ser anterior à data inicial", function (dataAte) {
                            const { dataPrazoFatalDe } = this.parent;

                            if (dataAte && dataPrazoFatalDe && dataAte < dataPrazoFatalDe) {
                                return false;
                            }

                            return true;
                        }),
                    dataPrazoSegurancaDe: YupDate().nullable().typeError("Data inválida"),
                    dataPrazoSegurancaAte: YupDate()
                        .nullable()
                        .typeError("Data inválida")
                        .test("maior", "A data final não pode ser anterior à data inicial", function (dataAte) {
                            const { dataPrazoSegurancaDe } = this.parent;

                            if (dataAte && dataPrazoSegurancaDe && dataAte < dataPrazoSegurancaDe) {
                                return false;
                            }

                            return true;
                        }),
                    dataCriacaoDe: YupDate().nullable().typeError("Data inválida"),
                    dataCriacaoAte: YupDate()
                        .nullable()
                        .typeError("Data inválida")
                        .test("maior", "A data final não pode ser anterior à data inicial", function (dataAte) {
                            const { dataCriacaoDe } = this.parent;

                            if (dataAte && dataCriacaoDe && dataAte < dataCriacaoDe) {
                                return false;
                            }

                            return true;
                        }),
                    dataProtocoloDe: YupDate().nullable().typeError("Data inválida"),
                    dataProtocoloAte: YupDate()
                        .nullable()
                        .typeError("Data inválida")
                        .test("maior", "A data final não pode ser anterior à data inicial", function (dataAte) {
                            const { dataProtocoloDe } = this.parent;

                            if (dataAte && dataProtocoloDe && dataAte < dataProtocoloDe) {
                                return false;
                            }

                            return true;
                        }),
                })}
            >
                <OmnijusCampoNumeroProcesso name="numeroProcesso" label="Nº do Processo" />
                <OmnijusTextField
                    name="nomeAutorProcesso"
                    label="Autor (Nome)"
                    keepMask={true}
                    masks={[
                        {
                            charType: "alphabet-text",
                            case: "uppercase",
                        },
                    ]}
                />
                <OmnijusRangeDateField
                    nameDe="dataPrazoSegurancaDe"
                    nameAte="dataPrazoSegurancaAte"
                    label="Data Prazo Segurança"
                />
                <OmnijusRangeDateField nameDe="dataPrazoFatalDe" nameAte="dataPrazoFatalAte" label="Data Prazo Fatal" />
                <OmnijusFiltroStatusAgendamento label="Status" />
                <OmnijusFiltroProvidencia label="Providência" />

                <OmnijusRangeDateField nameDe="dataCriacaoDe" nameAte="dataCriacaoAte" label="Data Criação" />
                <OmnijusTextField name="nomeUsuarioCriacao" label="Usuário Criação" />
                <OmnijusRangeDateField nameDe="dataProtocoloDe" nameAte="dataProtocoloAte" label="Data Protocolo" />
                <OmnijusTextField name="nomeUsuarioProtocolo" label="Usuário Protocolo" />
                <OmnijusFiltroCliente name="idCliente" label="Cliente" />
            </CardFiltros>

            <div className={styles.barraAcao}>
                <div className={styles.botoesAcao}>
                    <button
                        className={styles.botaoAcao}
                        onClick={() => {
                            setModalOpen(true);
                        }}
                    >
                        Criar Agendamentos
                    </button>
                    <button
                        className={styles.botaoAcao}
                        onClick={async () => {
                            if (promise) {
                                const agendamentos = await ActionFeedback.processing({
                                    title: "Buscando registros...",
                                    execution: promise,
                                });

                                if (agendamentos) {
                                    await downloadExportar(agendamentos || []);
                                }
                            }
                        }}
                    >
                        Exportar para Excel (xlsx)
                    </button>
                </div>
            </div>

            {promise ? (
                <Loading promise={promise}>
                    {(agendamentos) => (
                        <OmnijusCard
                            body={
                                <DataTable
                                    customStyles={{
                                        rows: {
                                            style: {
                                                cursor: "pointer",
                                            },
                                        },
                                    }}
                                    noHeader={true}
                                    pagination={true}
                                    defaultSortField={"dataPrazoSeguranca"}
                                    paginationPerPage={20}
                                    noDataComponent={<p>A consulta não retornou registros</p>}
                                    data={agendamentos || []}
                                    onRowClicked={(row) =>
                                        window.open(`agendamentos/${row.idAgendamentoProcesso}`, "_blank", "noreferrer")
                                    }
                                    columns={[
                                        {
                                            name: "Nº do Processo",
                                            minWidth: "15rem",
                                            selector: (row) => row.numeroProcesso,
                                            sortable: true,
                                        },
                                        {
                                            name: "Autor do Processo",
                                            minWidth: "15rem",
                                            selector: (row) => row.nomeAutorProcesso,
                                            sortable: true,
                                        },
                                        {
                                            name: "Prazo Segurança",
                                            minWidth: "8rem",
                                            selector: "dataPrazoSeguranca",
                                            format: (row) => format(new Date(row.dataPrazoSeguranca), "dd/MM/yyyy"),
                                            sortable: true,
                                        },
                                        {
                                            name: "Prazo Fatal",
                                            minWidth: "8rem",
                                            selector: (row) => row.dataPrazoFatal,
                                            format: (row) => format(new Date(row.dataPrazoFatal), "dd/MM/yyyy"),
                                            sortable: true,
                                        },
                                        {
                                            name: "Status",
                                            minWidth: "13rem",
                                            selector: (row) => row.descricaoStatusAgendamento,
                                            sortable: true,
                                            wrap: true,
                                        },
                                        {
                                            name: "Providência",
                                            minWidth: "13rem",
                                            selector: (row) => row.descricaoProvidencia,
                                            sortable: true,
                                            wrap: true,
                                        },
                                        {
                                            name: "Pasta Cliente",
                                            minWidth: "13rem",
                                            selector: (row) => row.descricaoPastaCliente || "-",
                                            sortable: true,
                                            wrap: true,
                                        },
                                        {
                                            name: "Peticionamento",
                                            selector: (row) =>
                                                row.idSolicitacaoPeticionamento ? (
                                                    <TextButton
                                                        onClick={() => {
                                                            history.push(
                                                                `peticionamentos/${row.idSolicitacaoPeticionamento}/documentos`
                                                            );
                                                        }}
                                                    >
                                                        Visualizar
                                                    </TextButton>
                                                ) : (
                                                    "-"
                                                ),
                                            button: true,
                                            width: "7rem",
                                        },
                                        {
                                            name: "Cliente",
                                            minWidth: "14rem",
                                            selector: "cliente",
                                            format: (row) => row.cliente || "-",
                                        },
                                        {
                                            name: "Data Criação",
                                            minWidth: "8rem",
                                            selector: (row) => row.dataCriacao,
                                            format: (row) => format(new Date(row.dataCriacao), "dd/MM/yyyy"),
                                            sortable: true,
                                        },
                                        {
                                            name: "Usuário Criação",
                                            minWidth: "10rem",
                                            selector: (row) => row.nomeUsuarioCriacao,
                                            sortable: true,
                                            wrap: true,
                                        },
                                        {
                                            name: "Data Protocolo",
                                            minWidth: "8rem",
                                            selector: (row) => row.dataProtocolo,
                                            format: (row) =>
                                                (row.dataProtocolo &&
                                                    format(new Date(row.dataProtocolo), "dd/MM/yyyy")) ||
                                                "-",
                                            sortable: true,
                                        },
                                        {
                                            name: "Usuário Protocolo",
                                            minWidth: "10rem",
                                            selector: (row) => row.nomeUsuarioProtocolo || "-",
                                            sortable: true,
                                        },
                                        {
                                            name: "Motivo Exclusão",
                                            selector: (row) =>
                                                row.descricaoMotivoExclusao ? (
                                                    <img
                                                        alt=""
                                                        style={{ cursor: "pointer", width: "1.5rem" }}
                                                        title="Clique para ver o motivo da exclusão"
                                                        src={VisibleIcon}
                                                        onClick={() => {
                                                            Swal.fire("Motivo Exclusão", row.descricaoMotivoExclusao);
                                                        }}
                                                    />
                                                ) : (
                                                    "-"
                                                ),
                                            wrap: true,
                                            minWidth: "15rem",
                                            button: true,
                                        },
                                        {
                                            name: "Excluir",
                                            button: true,
                                            selector: (row) =>
                                                row.idStatusAgendamento === IdStatusAgendamento.EmAndamento ? (
                                                    <TrashIcon
                                                        style={{ cursor: "pointer" }}
                                                        className={styles.trashIcon}
                                                        title="Clique para remover este agendamento"
                                                        onClick={() => {
                                                            Swal.fire({
                                                                title: "Excluir Agendamento",
                                                                text: "Informe o motivo da exclusão deste agendamento",
                                                                input: "textarea",
                                                                showCancelButton: true,
                                                                confirmButtonText: "Ok",
                                                                cancelButtonText: "Cancelar",
                                                            }).then(async (result) => {
                                                                if (result.isConfirmed && result.value) {
                                                                    await ActionFeedback.processing({
                                                                        title: "Processando...",
                                                                        execution: AgendamentoProcessoService.excluir({
                                                                            idAgendamentoProcesso:
                                                                                row.idAgendamentoProcesso,
                                                                            descricaoMotivoExclusao: result.value,
                                                                        }),
                                                                        onError: async (error: any) =>
                                                                            await ActionFeedback.error({
                                                                                icon: "error",
                                                                                html: error?.json?.erros
                                                                                    ? error.json.erros.join("<br />")
                                                                                    : error?.json?.title
                                                                                    ? error?.json?.title
                                                                                    : "Não foi possível realizar a exclusão deste agendamento",
                                                                            }),
                                                                    });

                                                                    await ActionFeedback.info({
                                                                        text: "Agendamento excluído com sucesso!",
                                                                    });
                                                                }
                                                            });
                                                        }}
                                                    />
                                                ) : (
                                                    "-"
                                                ),
                                        },
                                    ]}
                                />
                            }
                        />
                    )}
                </Loading>
            ) : (
                <p className="text-center">Utilize os filtros para realizar uma nova busca!</p>
            )}

            <ModalCriarAgendamentos isModalOpen={isModalOpen} onCloseModal={() => setModalOpen(false)} />
        </div>
    );
};
