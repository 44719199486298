import React from "react";
import styles from "./button-primary.module.scss";

interface ButtonPrimaryProps {
    value?: string;
    onClick?: () => void;
    children?: JSX.Element | string;
    type?: "button" | "submit" | "reset";
    className?: string;
    disabled?: boolean;
}

export const ButtonPrimary = (props: ButtonPrimaryProps) => {
    return (
        <button
            type={props.type}
            onClick={props.onClick}
            className={props.className ? props.className : styles["btn-primary"]}
            value={props.value}
            disabled={props.disabled}
        >
            {props.children}
        </button>
    );
};

export const ButtonPrimarySmall = (props: ButtonPrimaryProps) => {
    return (
        <button
            disabled={props.disabled}
            type={props.type}
            onClick={props.onClick}
            className="btn-primary"
            value={props.value}
            style={{ display: "flex", alignItems: "center", fontSize: "1rem", padding: "0 2rem", height: "2.5rem" }}
        >
            {props.children}
        </button>
    );
};
