import React, { useEffect, useState } from "react";
import DownloadIcon from "../../../../assets/images/download-icon.svg";
import { ReactComponent as CopiarIcon } from "../../../../assets/images/copy.svg";
import styles from "./card-audiencia-processo.module.scss";
import { ActionFeedback, formatDateAndTime, Loading } from "@omnijus/common";
import { toast } from "react-toastify";
import ArrowRight from "../../../../assets/images/arrow-right.png";
import ArrowDown from "../../../../assets/images/arrow-down.png";
import { ProcessoDocumentoService } from "../../../../services/processo/processo-documento-service";
import { ProcessoAudienciaService } from "../../../../services/processo/processo-audiencia-service";
import { ProcessoAudiencia } from "../../../../services/processo/models/processo-audiencia";

const downloadDocumento = async (idDocumento: string, filename: string) => {
    return await ActionFeedback.processing({
        title: "Gerando arquivo para download",
        execution: ProcessoDocumentoService.downloadDocumento(idDocumento, filename),
    });
};

const copiarAreaTransferencia = async (textoNotificacao: string, informacaoCopiar: string) => {
    await navigator.clipboard.writeText(informacaoCopiar);

    toast.info(textoNotificacao, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
};

export const CardAudienciaProcesso = (props: { idProcesso: string }) => {
    const [promiseAudiencias, setPromiseAudiencias] = useState<Promise<ProcessoAudiencia[] | undefined>>();
    const [executoresVisible, setExecutoresVisible] = useState<boolean>(false);

    useEffect(() => {
        setPromiseAudiencias(ProcessoAudienciaService.getListarPendencia(props.idProcesso));
    }, [props.idProcesso]);

    return (
        <div className="card-processo-detalhe">
            <header>
                <h3>Audiências do processo</h3>
            </header>
            <Loading promise={promiseAudiencias}>
                {(audiencias) =>
                    audiencias ? (
                        <div className="card-body-scrollable">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Data e hora</th>
                                        <th>Tipo</th>
                                        <th>Local</th>
                                        <th>Ata</th>
                                        <th></th>
                                        <th>Audiencistas</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {audiencias.length === 0 ? (
                                        <tr>
                                            <td className="center" colSpan={4}>
                                                Nenhuma audiência.
                                            </td>
                                        </tr>
                                    ) : (
                                        audiencias.map((a) => (
                                            <>
                                                <tr key={a.idProcessoAudiencia}>
                                                    <td>{formatDateAndTime(a.dataHora)}</td>
                                                    <td>{a.descricaoTipo}</td>
                                                    <td className={styles.localAudiencia}>
                                                        {a.teleaudiencia ? (
                                                            <>
                                                                <span className={styles.overflowEllipsis}>
                                                                    {a.local}
                                                                </span>
                                                                <button
                                                                    title="Copiar para área de transferência"
                                                                    onClick={() =>
                                                                        copiarAreaTransferencia(
                                                                            "Local da audiência copiado para área de transferência",
                                                                            a.local
                                                                        )
                                                                    }
                                                                >
                                                                    <CopiarIcon />
                                                                </button>
                                                            </>
                                                        ) : (
                                                            <span>{a.local}</span>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {a.idProcessoDocumentoAta ? (
                                                            <img
                                                                alt="Ícone de download"
                                                                src={DownloadIcon}
                                                                className={styles.downloadAta}
                                                                onClick={() => {
                                                                    downloadDocumento(
                                                                        a.idProcessoDocumentoAta!,
                                                                        `${a.idProcessoDocumentoAta}.zip`
                                                                    );
                                                                }}
                                                            />
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </td>
                                                    <td className={styles.audienciaCancelada}>
                                                        {a.cancelada ? "Cancelada" : null}
                                                    </td>
                                                    <td>
                                                        {a.executores?.length > 0 ? (
                                                            <img
                                                                onClick={() => setExecutoresVisible(!executoresVisible)}
                                                                title="Ver executores"
                                                                alt="Ver executores"
                                                                style={{ cursor: "pointer" }}
                                                                src={executoresVisible ? ArrowDown : ArrowRight}
                                                            />
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </td>
                                                </tr>
                                                {a.executores?.length > 0 && executoresVisible && (
                                                    <tr className="card-processo-detalhe">
                                                        <td colSpan={6}>
                                                            <header>
                                                                <h3>Executores</h3>
                                                            </header>
                                                            <hr />
                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Nome</th>
                                                                        <th>Tipo</th>
                                                                        <th>OAB</th>
                                                                        <th>CPF</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {a.executores.map((e) => (
                                                                        <tr>
                                                                            <td>{e.nome}</td>
                                                                            <td>{e.tipoExecutor}</td>
                                                                            <td>{e.oab}</td>
                                                                            <td>{e.cpf}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                )}
                                            </>
                                        ))
                                    )}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <></>
                    )
                }
            </Loading>
        </div>
    );
};
