import { ActionFeedback, ButtonDownload, Format, Loading, OmnijusCard } from "@omnijus/common";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { AudienciaService } from "../../../services/audiencia/audiencia-service";
import { DetalheAudiencia } from "../../../services/audiencia/models/detalhe-audiencia";
import { ProcessoService } from "../../../services/processo/processo-service";
import { CampoDetalhamento } from "../../../shared/campo-detalhamento/campo-detalhamento";
import styles from "./detalhamento-audiencia.module.scss";

const downloadDocumento = async (idDocumento: string, filename: string) => {
    return await ActionFeedback.processing({
        title: "Gerando arquivo para download",
        execution: ProcessoService.downloadDocumento(idDocumento, filename),
    });
};

interface CampoDetalhamentoPrincipalProps {
    idAudiencia: string;
}

export const DetalhamentoAudienciaPrincipal = (props: CampoDetalhamentoPrincipalProps) => {
    const [promise, setPromise] = useState<Promise<DetalheAudiencia | undefined>>();

    useEffect(() => {
        setPromise(AudienciaService.obter(props.idAudiencia));
    }, [props.idAudiencia]);

    return (
        <OmnijusCard
            header={<h3>Audiência</h3>}
            body={
                <Loading promise={promise}>
                    {(detalhes) =>
                        detalhes === undefined ? (
                            <p>Não encontrado</p>
                        ) : (
                            <ul className={styles["principal"]}>
                                <li className={styles["item"]}>
                                    <CampoDetalhamento label="Tipo" value={detalhes.descricaoTipoAudiencia} />
                                </li>
                                <li className={styles["item"]}>
                                    <CampoDetalhamento
                                        label="Data e Hora"
                                        value={format(detalhes.dataHora, "dd/MM/yyyy HH:mm")}
                                    />
                                </li>
                                <li className={styles["item"]}>
                                    <CampoDetalhamento
                                        label="Quantidade de Audiencistas"
                                        value={Format.toNumberString(detalhes.quantidadeAudiencista)}
                                    />
                                </li>
                                <li className={styles["item"]}>
                                    <CampoDetalhamento
                                        label="Quantidade de Preposto"
                                        value={Format.toNumberString(detalhes.quantidadePreposto)}
                                    />
                                </li>
                                <li className={styles["item"]}>
                                    <CampoDetalhamento label="Status" value={detalhes.descricaoStatusAudiencia} />
                                </li>
                                <li className={styles["item"]}>
                                    <CampoDetalhamento
                                        label="Ata"
                                        value={
                                            detalhes.idDocumentoAta ? (
                                                <ButtonDownload
                                                    onClick={() =>
                                                        downloadDocumento(
                                                            detalhes.idDocumentoAta!,
                                                            `Ata_Audiencia_${detalhes.idDocumentoAta!}.zip`
                                                        )
                                                    }
                                                />
                                            ) : (
                                                "-"
                                            )
                                        }
                                    />
                                </li>
                                <li className={styles["item"]}>
                                    <CampoDetalhamento
                                        label="Etapa de Andamento"
                                        value={detalhes.descricaoStatusSolicitacaoAudiencia}
                                    />
                                </li>
                                <li className={styles["item"]}>
                                    <CampoDetalhamento
                                        label="Modalidade"
                                        value={detalhes.teleaudiencia ? "Teleaudiência" : "Presencial"}
                                    />
                                </li>
                                <li className={styles["item"]}>
                                    <CampoDetalhamento label="Descrição do Local" value={detalhes.nomeLocal || "-"} />
                                </li>
                                <li className={styles["item"]}>
                                    <CampoDetalhamento
                                        label="Motivo do cancelamento"
                                        value={detalhes.descricaoMotivoCancelamentoAudiencia || "-"}
                                    />
                                </li>
                                <li className={styles["item"]}>
                                    <CampoDetalhamento label="Observações" value={detalhes.observacoes} />
                                </li>
                            </ul>
                        )
                    }
                </Loading>
            }
        />
    );
};
