import { RequestApi } from "@omnijus/common";
import { PagedResult } from "../../../lib/paged-result";
import { PaginationInfo } from "../../../lib/pagination-info";
import { CLIENT_ID } from "../../../services/client-id";
import { PerfilFilter } from "./perfil-filter";
import {
    AdicionarUsuarioPerfilCommand,
    InserirAtualizarPerfilCommand,
    ListaPerfilComClienteViewModel,
    PerfilViewModel,
    UsuarioPerfilViewModel,
} from "./perfil-viewmodel";

const perfilUrl = process.env.REACT_APP_BFF_OPERACIONAL_API_URL + "/api/ConfiguracaoControleAcesso";

const request = new RequestApi(CLIENT_ID);

export const PerfilService = {
    listar: async (filtro?: PerfilFilter, paginationInfo?: PaginationInfo) => {
        const url = new URL(`${perfilUrl}/v1/Perfil`);
        url.search = RequestApi.objectToQueryString({ ...filtro, ...(paginationInfo || { rowsPerPage: 20 }) });
        return await request.get<PagedResult<ListaPerfilComClienteViewModel>>(url.toString());
    },
    listarPerfisPorUsuario: async (usuarioId: String) => {
        const url = new URL(`${perfilUrl}/v1/Perfil/ListarPorUsuario/${usuarioId}`);
        return await request.get<UsuarioPerfilViewModel[]>(url.toString());
    },
    obter: async (id: string) => {
        const url = `${perfilUrl}/v1/Perfil/${id}`;
        return await request.get<PerfilViewModel>(url);
    },
    inserir: async (command: InserirAtualizarPerfilCommand) => {
        const url = `${perfilUrl}/v1/Perfil`;
        return await request.post<string>(url, command);
    },
    atualizar: async (id: string, command: InserirAtualizarPerfilCommand) => {
        const url = `${perfilUrl}/v1/Perfil/${id}`;
        return await request.put<string>(url, command);
    },
    excluir: async (id: string) => {
        const url = `${perfilUrl}/v1/Perfil/${id}`;
        return await request.delete<string>(url);
    },
    listarUsuarios: async (idPerfil: string) => {
        const url = `${perfilUrl}/v1/Perfil/${idPerfil}/Usuarios`;
        return await request.get<UsuarioPerfilViewModel[]>(url);
    },
    adicionarUsuario: async (idPerfil: string, command: AdicionarUsuarioPerfilCommand) => {
        const url = `${perfilUrl}/v1/Perfil/${idPerfil}/Usuarios`;
        await request.post(url, command);
    },
    removerUsuario: async (idPerfil: string, idPerfilUsuario: string) => {
        const url = `${perfilUrl}/v1/Perfil/${idPerfil}/Usuarios/${idPerfilUsuario}`;
        await request.delete(url);
    },
};
