import { RequestApi } from "@omnijus/common";
import { CLIENT_ID } from "../../services/client-id";

const requestApi = new RequestApi(CLIENT_ID);
const baseUrl = process.env.REACT_APP_BFF_OPERACIONAL_API_URL + "/api/Processo/v1/AgendamentoProcesso";

export const EdicaoAgendamentoService = {
    atualizarPrazoSeguranca: async (id: string, novoPrazo: Date) => {
        await requestApi.patch(`${baseUrl}/${id}/PrazoSeguranca`, novoPrazo);
    },
    atualizarPrazoFatal: async (id: string, novoPrazo: Date) => {
        await requestApi.patch(`${baseUrl}/${id}/PrazoFatal`, novoPrazo);
    },
};
