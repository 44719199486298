import { Loading } from "@omnijus/common";
import DownloadIcon from "../../../../assets/images/download-icon.svg";
import { ActionFeedback } from "@omnijus/common";
import { Format } from "../../../../lib/format";
import React, { useEffect, useState } from "react";
import { AcordoProcesso } from "../../../../services/processo/models/acordo-processo";
import { ProcessoDetalheService } from "../../../../services/processo/processo-detalhe-service";
import { ProcessoDocumentoService } from "../../../../services/processo/processo-documento-service";
import "./tentativas-de-acordo.scss";

const dateToShort = (date: Date) =>
    date && "" + date.getDate() + "/" + (date.getMonth() + 1) + "/" + (date.getFullYear() % 100);

export const TentativasDeAcordo = (props: { idProcesso: string }) => {
    const [promise, setPromise] = useState<Promise<AcordoProcesso[] | undefined>>();

    useEffect(() => {
        setPromise(ProcessoDetalheService.getTentativasAcordo(props.idProcesso));
    }, [props.idProcesso]);

    const downloadDocumento = async (idDocumento: string, filename: string) => {
        await ActionFeedback.processing({
            title: "Gerando arquivo para download",
            execution: ProcessoDocumentoService.downloadDocumento(idDocumento, filename),
        });
    };

    return (
        <div className="card-processo-detalhe">
            <header>
                <h3>Tentativas de acordo</h3>
            </header>
            <div className="card-body-scrollable">
                <Loading promise={promise}>
                    {(acordos) =>
                        acordos ? (
                            <table>
                                <thead>
                                    <tr>
                                        <th>Data</th>
                                        <th>Política</th>
                                        <th>Proposta</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {acordos.length === 0 ? (
                                        <tr>
                                            <td className="center" colSpan={3}>
                                                Nenhuma tentativa de acordo.
                                            </td>
                                        </tr>
                                    ) : (
                                        acordos.map((tentativa, i) => (
                                            <tr key={`${i}${tentativa.nomePoliticaAcordo}${tentativa.valorAcordo}`}>
                                                <td className="col-data">
                                                    {tentativa.dataValidadeInicio?.toLocaleDateString("pt-BR")}
                                                </td>
                                                <td>
                                                    <div className="nome-politica">{tentativa.nomePoliticaAcordo}</div>
                                                    <div className="datas-politica">
                                                        {dateToShort(tentativa.dataValidadeInicio)}
                                                        {" até "}
                                                        {dateToShort(tentativa.dataValidadeFim)}
                                                    </div>
                                                </td>
                                                <td className="col-fase">
                                                    <div className="valor-proposta">
                                                        {Format.toBRL(tentativa.valorAcordo)}
                                                    </div>
                                                    <div className="estado-proposta">
                                                        {tentativa.descricaoStatusPoliticaAcordo}
                                                    </div>
                                                </td>
                                                <td className="col-download">
                                                    {tentativa.idDocumentoMinutaAcordo && (
                                                        <button
                                                            onClick={() =>
                                                                downloadDocumento(
                                                                    tentativa.idDocumentoMinutaAcordo,
                                                                    `${tentativa.nomePoliticaAcordo}.zip`
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                className="download-icon"
                                                                alt="Ícone de download"
                                                                src={DownloadIcon}
                                                            />
                                                        </button>
                                                    )}
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>
                        ) : (
                            <></>
                        )
                    }
                </Loading>
            </div>
        </div>
    );
};
