import React, { useEffect, useState } from "react";
import { OmnijusSelectField } from "../fields/omnijus-select-field";
import { Loading } from "@omnijus/common";
import { TipoDocumentoService } from "../../../services/tipo-documento/tipo-documento-service";

interface OmnijusTipoDocumentoProps {
    hideLabel?: boolean;
    name?: string;
    label?: string;
    isDisabled?: boolean;
    isMulti?: boolean;
    options?: { id: number; value: string }[] | undefined;
}

export const OmnijusTipoDocumento = (props: OmnijusTipoDocumentoProps) => {
    const [promiseOpcoes, setPromiseOpcoes] = useState<Promise<{ id: number; value: string }[] | undefined>>();

    useEffect(() => {
        setPromiseOpcoes((props.options && Promise.resolve(props.options)) || TipoDocumentoService.listarCombo(true));
    }, [props.options]);

    return (
        <Loading promise={promiseOpcoes}>
            {(opcoes) => (
                <OmnijusSelectField
                    isMulti={props.isMulti}
                    name={props.name || "idTipoDocumento"}
                    label={props.label}
                    options={opcoes
                        ?.map((o) => ({
                            value: o.id.toString(),
                            label: o.value.toString(),
                        }))
                        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
                />
            )}
        </Loading>
    );
};
