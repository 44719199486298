import { Loading } from "@omnijus/common";
import { Format } from "../../../../lib/format";
import React, { useEffect, useState } from "react";
import { PedidoProcesso } from "../../../../services/processo/pedido/pedido-processo";
import { PedidoProcessoService } from "../../../../services/processo/pedido/pedido-processo-service";
import "./card-pedido-processo.scss";

interface CardPedidoProcessoProps {
    idProcesso: string;
}

export const CardPedidoProcesso = (props: CardPedidoProcessoProps) => {
    const [promisePedidoProcesso, setPromisePedidoProcesso] = useState<Promise<PedidoProcesso[] | undefined>>();

    useEffect(() => {
        setPromisePedidoProcesso(PedidoProcessoService.getAll(props.idProcesso));
    }, [props.idProcesso]);

    return (
        <div className="card-processo-detalhe">
            <header>
                <h3>Pedidos do processo</h3>
            </header>
            <Loading promise={promisePedidoProcesso}>
                {(pedidosProcesso) => {
                    if (pedidosProcesso) {
                        return (
                            <div className="card-body-scrollable">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Tipo do pedido</th>
                                            <th>Descrição do risco</th>
                                            <th className="text-right">Valor do risco</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {pedidosProcesso.length === 0 ? (
                                            <tr>
                                                <td className="center" colSpan={3}>
                                                    Nenhum pedido.
                                                </td>
                                            </tr>
                                        ) : (
                                            pedidosProcesso.map((p) => (
                                                <tr key={p.idPedidoProcesso}>
                                                    <td>{p.descricaoTipoPedido}</td>
                                                    <td>{p.descricaoClassificacaoRisco}</td>
                                                    <td className="text-right">{Format.toBRL(p.valorRisco)}</td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        );
                    } else {
                        return <></>;
                    }
                }}
            </Loading>
        </div>
    );
};
