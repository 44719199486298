import { Loading, OmnijusSelectField } from "@omnijus/common";
import React, { useEffect, useState } from "react";
import { ProcessoFiltroService } from "../../../services/filtros/filtros-processo-service";
import { FiltroEstado } from "../../../services/filtros/models/filtro-estado";

interface OmnijusFiltroEstadoProps {
    hideLabel?: boolean;
    name?: string;
    label?: string;
}

export const OmnijusFiltroEstado = ({ hideLabel, label, name }: OmnijusFiltroEstadoProps) => {
    const [promiseOpcoesEstado, setPromiseOpcoesEstado] = useState<Promise<FiltroEstado[] | undefined>>();

    useEffect(() => {
        setPromiseOpcoesEstado(ProcessoFiltroService.listarEstado());
    }, []);

    return (
        <Loading promise={promiseOpcoesEstado}>
            {(opcoesEstado) => (
                <OmnijusSelectField
                    name={name || "siglaUf"}
                    label={hideLabel ? undefined : label || "Estado"}
                    options={opcoesEstado
                        ?.map((o) => ({
                            value: o.id.toString(),
                            label: o.value.toString(),
                        }))
                        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
                />
            )}
        </Loading>
    );
};
