import { ButtonSecondary, OmnijusCard } from "@omnijus/common";
import { format } from "date-fns";
import React from "react";
import DataTable from "react-data-table-component";
import { SolicitacaoPeticionamentoMotivoReprovacao } from "services/peticionamento/models/solicitacao-peticionamento-motivo-reprovacao";
import Modal from "react-modal";
import styles from "./listar-peticionamentos.module.scss";

export const ModalMotivosReprovacao = (props: {
    isModalOpen: boolean;
    onCloseModal: () => void;
    motivosReprovacao: SolicitacaoPeticionamentoMotivoReprovacao[];
}) => {
    return (
        <Modal
            style={{
                content: {
                    width: "60vw",
                    margin: "auto",
                },
            }}
            isOpen={props.isModalOpen}
            onRequestClose={props.onCloseModal}
            shouldCloseOnEsc
            ariaHideApp={false}
            shouldCloseOnOverlayClick={false}
        >
            <OmnijusCard
                body={
                    <>
                        <DataTable
                            pagination={true}
                            paginationPerPage={20}
                            noDataComponent={<p>Nenhum registro encontrado!</p>}
                            data={props.motivosReprovacao || []}
                            columns={[
                                {
                                    name: "Data/hora",
                                    selector: (item) => (item.dataHora ? new Date(item.dataHora) : "-"),
                                    format: (item) =>
                                        item.dataHora ? format(new Date(item.dataHora), "dd/MM/yyyy HH:mm:ss") : "-",
                                    sortable: true,
                                    wrap: true,
                                },
                                {
                                    name: "Motivo",
                                    selector: (item) => item.descricaoMotivoReprovacao,
                                    wrap: true,
                                    sortable: true,
                                },
                            ]}
                        />
                        <div className={styles.alignRight}>
                            <ButtonSecondary className={styles.marginTop} onClick={props.onCloseModal}>
                                Fechar
                            </ButtonSecondary>
                        </div>
                    </>
                }
            />
        </Modal>
    );
};
