import React from "react";
import { useParams } from "react-router-dom";
import Back from "../../../shared/buttons/button-back/button-back";
import { CabecalhoProcesso } from "./detalhes-cards/cabecalho-processo";
import styles from "./detalhes-processo.module.scss";
import { DocumentosProcesso } from "./detalhes-cards/documentos-processo";
import { MovimentacaoEAtividade } from "./detalhes-cards/movimentacao-e-atividade";
import { TentativasDeAcordo } from "./detalhes-cards/tentativas-de-acordo";
import { DocumentosAutomatizados } from "./detalhes-cards/documentos-automatizados";
import { CardPedidoProcesso } from "./detalhes-cards/card-pedido-processo";
import { CardSubsidioProcesso } from "./detalhes-cards/card-subsidio-processo";
import { CardAudienciaProcesso } from "./detalhes-cards/card-audiencia-processo";

export const DetalhesProcesso = () => {
    const params = useParams<{ id: string }>();

    return (
        <div>
            <Back />
            <h2 className="titulo-destaque">Detalhes do processo</h2>
            <CabecalhoProcesso idProcesso={params.id} />
            <div className={styles.linhaFlex}>
                <div>
                    <MovimentacaoEAtividade idProcesso={params.id} />
                </div>
                <div>
                    <CardAudienciaProcesso idProcesso={params.id} />
                    <DocumentosProcesso idProcesso={params.id} />
                    <TentativasDeAcordo idProcesso={params.id} />
                    <CardPedidoProcesso idProcesso={params.id} />
                    <CardSubsidioProcesso idProcesso={params.id} />
                    <DocumentosAutomatizados idProcesso={params.id} />
                </div>
            </div>
        </div>
    );
};
