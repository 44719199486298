import { useHistory, useLocation } from "react-router-dom";
import { ButtonPrimary } from "../../../shared/form/fields/omnijus-button-primary";
import styles from "../movimentacao-assistida.module.scss";
import { MovimentacaoAssistidaService } from "../../../services/movimentacao-assistida/movimentacao-assistida-service";
import { AnaliseRotuloAcao } from "../../../services/movimentacao-assistida/models/registrar-historico-analise-rotulo";
import { ActionFeedback } from "@omnijus/common";
import React from "react";
import { analiseRotuloEditavel } from "./analise-rotulo";
import { FiltroMovimentoRotulo } from "../../../services/filtros/filtro-movimento-rotulo";
import { AnaliseRotuloHistoryState } from "./analise-rotulo-history-state";

export const AnaliseRotuloBotaoPular = ({
    idProcessoMovimentoRotulo,
    idProcessoMovimento,
    idStatusDocumentoRotulo,
    filtroMovimentoRotulo,
}: {
    idStatusDocumentoRotulo: number;
    idProcessoMovimentoRotulo: string;
    idProcessoMovimento: string;
    filtroMovimentoRotulo: FiltroMovimentoRotulo;
}) => {
    const history = useHistory<AnaliseRotuloHistoryState>();
    const location = useLocation<AnaliseRotuloHistoryState>();
    const idsMovimentosPulados = location.state?.idsMovimentosPulados ?? [];
    return (
        <ButtonPrimary
            disabled={!analiseRotuloEditavel(idStatusDocumentoRotulo)}
            className={!analiseRotuloEditavel(idStatusDocumentoRotulo) ? styles.botaoEsmaecido : ""}
            onClick={async () => {
                if (!analiseRotuloEditavel(idStatusDocumentoRotulo)) {
                    return;
                }

                const idProcessoDocumentoRotulo = await ActionFeedback.processing({
                    title: "Processando...",
                    execution: (async () => {
                        await MovimentacaoAssistidaService.registrarHistoricoAnaliseRotulo({
                            idProcessoMovimentoRotulo: idProcessoMovimentoRotulo,
                            analiseRotuloAcao: AnaliseRotuloAcao.Pular,
                        });
                        return await MovimentacaoAssistidaService.obterIdProximaAnaliseDeRotulo(
                            filtroMovimentoRotulo,
                            idProcessoMovimentoRotulo,
                            [...idsMovimentosPulados, idProcessoMovimento]
                        );
                    })(),
                    onError: async (error: any) =>
                        await ActionFeedback.error({
                            icon: "error",
                            html: error?.json?.erros?.join("<br />") ?? error?.json?.title ?? error.message ?? "Erro",
                        }),
                });

                if (idProcessoDocumentoRotulo) {
                    history.replace(`/movimentacao-assistida/analise-rotulo/${idProcessoDocumentoRotulo}`, {
                        ...location.state,
                        idsMovimentosPulados: [...idsMovimentosPulados, idProcessoMovimento],
                    });
                } else {
                    await ActionFeedback.info({
                        text: "Não foram encontrados novos registros. Você será redirecionado para a lista de movimentos.",
                    });

                    history.push("/movimentacao-assistida/movimentos", { idsMovimentosPulados: [] });
                }
            }}
        >
            Pular
        </ButtonPrimary>
    );
};
