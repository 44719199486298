export interface ModuloViewModel {
    id: string;
    descricao: string;
    funcionalidades?: FuncionalidadeViewModel[];
}

export interface FuncionalidadeViewModel {
    id: string;
    descricao: string;
    tag: string;
    acoes: AcoesViewModel[];
}

export interface AcoesViewModel {
    id: string;
    descricao: string;
    tag: string;
}

export enum ModuloEnum {
    CLIENTE = "cliente",
    ESCRITORIO = "escritorio",
    PRESTADOR = "prestador",
    OPERACIOANAL = "operacional",
}
