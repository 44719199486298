import { ButtonPrimary, OmnijusCard, OmnijusTextField } from "@omnijus/common";
import FilterIcon from "../../../assets/images/filter-icon.png";
import { Form, Formik } from "formik";
import React from "react";
import { OmnijusCheckboxFieldSingle } from "../../../shared/form/fields/omnijus-checkbox-field-single";
import { ParametrosPesquisarEscritorio } from "./escritorio-parametros";

export const FiltrosEscritorio = (props: {
    filtros: ParametrosPesquisarEscritorio;
    onBuscar: (filtro: ParametrosPesquisarEscritorio) => {};
}) => {
    return (
        <OmnijusCard
            className="filtro-card filtros-processos"
            collapsable={true}
            header={
                <>
                    <img alt="Ícone de filtros" src={FilterIcon} />
                    <span className="titulo">Filtros</span>
                </>
            }
            body={
                <div className="filtros-wrapper">
                    <Formik
                        onSubmit={(filtros) => {
                            props.onBuscar(filtros);
                        }}
                        initialValues={props.filtros}
                    >
                        <Form className="filtros">
                            <OmnijusTextField name="nome" label="Nome" />
                            <OmnijusCheckboxFieldSingle name="ativo" label="Ativo" />

                            <div className="acoes">
                                <ButtonPrimary type="submit">Filtrar</ButtonPrimary>
                            </div>
                        </Form>
                    </Formik>
                </div>
            }
        />
    );
};
