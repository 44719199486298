import { mdiChevronDown } from "@mdi/js";
import Icon from "@mdi/react";
import { Dropdown, OmnijusLogo, useAuth } from "@omnijus/common";
import { NavLinkControleAcesso, WrapperAcessoFuncionalidadeOperacional } from "../../contexts/acessos-helper";
import { LocationState } from "history";
import React from "react";
import { Link, NavLink, NavLinkProps } from "react-router-dom";
import styles from "./navigation.module.scss";

const OmnijusNavLink = <S extends any = LocationState>(
    props: React.PropsWithoutRef<NavLinkProps<S>> & React.RefAttributes<HTMLAnchorElement>
): ReturnType<NavLink<S>> => {
    return <NavLink {...props} className={props.className || styles.navLink} activeClassName={styles.active} />;
};

export const Navigation = () => {
    const auth = useAuth();

    return (
        <nav className={styles.navBar}>
            <Link className={styles.navLinkDashboard} to="/">
                <OmnijusLogo />
            </Link>
            <div className={styles.navItems}>
                <NavLinkControleAcesso
                    tags={["dashboard"]}
                    to="/dashboard"
                    exact
                    className={styles.navLink}
                    activeClassName={styles.active}
                >
                    Dashboard
                </NavLinkControleAcesso>

                <WrapperAcessoFuncionalidadeOperacional tags={["movimentacao_assistida"]}>
                    <Dropdown
                        items={
                            <ul className={styles.submenu}>
                                <li>
                                    <NavLinkControleAcesso
                                        tags={["movimentacao_assistida"]}
                                        activeClassName={styles.active}
                                        className={styles.link}
                                        exact
                                        to="/movimentacao-assistida/dashboard"
                                    >
                                        Dashboard
                                    </NavLinkControleAcesso>
                                </li>
                                <li>
                                    <NavLink
                                        activeClassName={styles.active}
                                        className={styles.link}
                                        exact
                                        to="/movimentacao-assistida/movimentos"
                                    >
                                        Movimentos
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        activeClassName={styles.active}
                                        className={styles.link}
                                        exact
                                        to="/movimentacao-assistida/analise-respostas"
                                    >
                                        Análise de Respostas
                                    </NavLink>
                                </li>
                            </ul>
                        }
                    >
                        <div className={styles.navLink}>Movimentação Assistida</div>
                    </Dropdown>
                </WrapperAcessoFuncionalidadeOperacional>

                <WrapperAcessoFuncionalidadeOperacional tags={["audiencias"]}>
                    <Dropdown
                        items={
                            <ul className={styles.submenu}>
                                <li>
                                    <NavLinkControleAcesso
                                        tags={["audiencias"]}
                                        activeClassName={styles.active}
                                        className={styles.link}
                                        exact
                                        to="/audiencias/consulta"
                                    >
                                        Consulta Audiência
                                    </NavLinkControleAcesso>
                                </li>
                                <li>
                                    <NavLinkControleAcesso
                                        tags={["audiencias"]}
                                        activeClassName={styles.active}
                                        className={styles.link}
                                        exact
                                        to="/audiencias/detalhamento-atividades-executores"
                                    >
                                        Detalhamento Atividade Executores
                                    </NavLinkControleAcesso>
                                </li>
                                <li>
                                    <NavLinkControleAcesso
                                        tags={["audiencias"]}
                                        activeClassName={styles.active}
                                        className={styles.link}
                                        exact
                                        to="/audiencias/acompanhamento-audiencias"
                                    >
                                        Acompanhamento Audiências
                                    </NavLinkControleAcesso>
                                </li>
                            </ul>
                        }
                    >
                        <OmnijusNavLink
                            isActive={(match) => !!match?.url.startsWith("/audiencias")}
                            to="#"
                            className={styles.navLink}
                        >
                            Audiências
                        </OmnijusNavLink>
                    </Dropdown>
                </WrapperAcessoFuncionalidadeOperacional>

                <NavLinkControleAcesso
                    tags={["peticionamentos"]}
                    to="/peticionamentos"
                    exact
                    className={styles.navLink}
                    activeClassName={styles.active}
                >
                    Peticionamentos
                </NavLinkControleAcesso>

                <NavLinkControleAcesso
                    tags={["agendamentos"]}
                    to="/agendamentos"
                    exact
                    className={styles.navLink}
                    activeClassName={styles.active}
                >
                    Agendamentos
                </NavLinkControleAcesso>

                <WrapperAcessoFuncionalidadeOperacional
                    tags={["configuracao_perfil", "configuracao_usuario", "configuracao_escritorio"]}
                >
                    <Dropdown
                        items={
                            <ul className={styles.submenu}>
                                <li>
                                    <NavLinkControleAcesso
                                        exact
                                        to="/configuracoes/perfil"
                                        activeClassName={styles.active}
                                        className={styles.link}
                                        tags={["configuracao_perfil"]}
                                    >
                                        Perfil
                                    </NavLinkControleAcesso>
                                </li>
                                <li>
                                    <NavLinkControleAcesso
                                        activeClassName={styles.active}
                                        className={styles.link}
                                        exact
                                        to="/configuracoes/usuario"
                                        tags={["configuracao_usuario"]}
                                    >
                                        Usuário
                                    </NavLinkControleAcesso>
                                </li>
                                <li>
                                    <NavLinkControleAcesso
                                        activeClassName={styles.active}
                                        className={styles.link}
                                        exact
                                        to="/configuracoes/escritorio?ativo=true"
                                        tags={["configuracao_escritorio"]}
                                    >
                                        Escritório
                                    </NavLinkControleAcesso>
                                </li>
                            </ul>
                        }
                    >
                        <div className={styles.navLink}>Configurações</div>
                    </Dropdown>
                </WrapperAcessoFuncionalidadeOperacional>
                <WrapperAcessoFuncionalidadeOperacional tags={["processos"]}>
                    <Dropdown
                        items={
                            <ul className={styles.submenu}>
                                <li>
                                    <NavLinkControleAcesso
                                        tags={["processos"]}
                                        to="/processos"
                                        exact
                                        className={styles.link}
                                        activeClassName={styles.active}
                                    >
                                        Listagem de Processos
                                    </NavLinkControleAcesso>
                                </li>
                                <li>
                                    <NavLinkControleAcesso
                                        tags={["processos"]}
                                        to="/processos-sem-documento"
                                        exact
                                        className={styles.link}
                                        activeClassName={styles.active}
                                    >
                                        Processos sem Documentos
                                    </NavLinkControleAcesso>
                                </li>
                            </ul>
                        }
                    >
                        <div className={styles.navLink}>Processos</div>
                    </Dropdown>
                </WrapperAcessoFuncionalidadeOperacional>

                <NavLinkControleAcesso
                    tags={["atualizacao_processos"]}
                    to="/atualizacao-processos"
                    className={styles.navLink}
                    activeClassName={styles.active}
                >
                    Atualização de Processos
                </NavLinkControleAcesso>

                <NavLinkControleAcesso
                    tags={["anomalias"]}
                    to="/anomalias"
                    exact
                    className={styles.navLink}
                    activeClassName={styles.active}
                >
                    Anomalias
                </NavLinkControleAcesso>
            </div>
            <div className={styles.navRight}>
                <div className={styles.item}>
                    <Dropdown
                        options={[
                            {
                                label: "Dados pessoais",
                                onClick: async () => {
                                    window.open(`${process.env.REACT_APP_AUTH_URL}`);
                                },
                            },
                            {
                                label: "Sair",
                                onClick: () => {
                                    const returnUrl = `${window.location.protocol}//${window.location.host}`;
                                    auth?.userManager.signoutRedirect({ postLogoutUri: returnUrl });
                                },
                            },
                        ]}
                    >
                        <div className={styles.perfil}>
                            <div className={styles.user}>
                                {String(auth?.user?.profile.name ?? auth?.user?.profile.email).charAt(0)}
                            </div>

                            <div className={styles.userName}>
                                <h4>{auth?.user?.profile.name}</h4>
                            </div>

                            <div className={styles.dropIcon}>
                                <Icon path={mdiChevronDown} size={1} />
                            </div>
                        </div>
                    </Dropdown>
                </div>
            </div>
        </nav>
    );
};
