import { Loading } from "@omnijus/common";
import React, { useEffect, useState } from "react";
import { ProcessoFiltroService } from "../../../services/filtros/filtros-processo-service";
import { FiltroPedido } from "../../../services/filtros/models/filtro-pedido";
import { OmnijusSelectField } from "../fields/omnijus-select-field";

interface OmnijusFiltroPedidoProps {
    hideLabel?: boolean;
    name?: string;
    label?: string;
}

export const OmnijusFiltroPedido = (props: OmnijusFiltroPedidoProps) => {
    const [promiseOpcoesPedido, setPromiseOpcoesPedido] = useState<Promise<FiltroPedido[] | undefined>>();

    useEffect(() => {
        setPromiseOpcoesPedido(ProcessoFiltroService.listarTipoPedido());
    }, []);

    return (
        <Loading promise={promiseOpcoesPedido}>
            {(opcoesPedido) => (
                <OmnijusSelectField
                    name={props.name || "IdTipoPedidoProcesso"}
                    label={props.hideLabel ? undefined : props.label || "Pedido"}
                    options={opcoesPedido?.map((o) => ({
                        value: o.id.toString(),
                        label: o.value.toString(),
                    }))}
                />
            )}
        </Loading>
    );
};
