import { NomesTiposParametros, RequestApi } from "@omnijus/common";

export interface ParametrosPesquisarEscritorio {
    nome?: string;
    ativo?: boolean;
}

const tiposParametros: NomesTiposParametros<Required<ParametrosPesquisarEscritorio>> = {
    nome: "string",
    ativo: "boolean",
};

export const parseParametrosPesquisarEscritorio = (queryString: string): ParametrosPesquisarEscritorio =>
    RequestApi.parseQueryString(queryString, tiposParametros);
