import { RequestApi } from "@omnijus/common";
import { CLIENT_ID } from "../../../services/client-id";
import { PedidoProcesso } from "./pedido-processo";

const processoUrl = process.env.REACT_APP_BFF_OPERACIONAL_API_URL + "/api/Processo/v1/ProcessoDetalhe";

const requestApi = new RequestApi(CLIENT_ID);

export const PedidoProcessoService = {
    getAll: async (idProcesso: string) => {
        let url = new URL(`${processoUrl}/${idProcesso}/Pedidos`);
        return await requestApi.get<PedidoProcesso[]>(url.toString());
    },
};
