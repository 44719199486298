export interface RegistrarHistoricoAnaliseRotuloCommand {
    idProcessoMovimentoRotulo: string;
    analiseRotuloAcao: AnaliseRotuloAcao;
}

export enum AnaliseRotuloAcao {
    Aprovar = 1,
    Pular = 2,
    Sair = 3,
    Acessar = 4,
}
