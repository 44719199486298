import { Loading, OmnijusSelectField, SelectObject } from "@omnijus/common";
import React, { useEffect, useState } from "react";
import { ProcessoFiltroService } from "../../../services/filtros/filtros-processo-service";
import { FiltroFase } from "../../../services/filtros/models/filtro-fase";

interface OmnijusFiltroFaseProcessoProps {
    hideLabel?: boolean;
    name?: string;
    label?: string;
    isMulti?: boolean;
    idRito: number;
}

export const OmnijusFiltroFaseProcesso = (props: OmnijusFiltroFaseProcessoProps) => {
    const [promise, setPromise] = useState<Promise<FiltroFase[] | undefined>>();

    useEffect(() => {
        setPromise(ProcessoFiltroService.listarFase(props.idRito));
    }, [props.idRito]);

    return (
        <Loading promise={promise}>
            {(opcoes) => (
                <OmnijusSelectField
                    name={props.name || "IdFaseProcesso"}
                    label={props.hideLabel ? undefined : props.label || "Fase"}
                    isMulti={props.isMulti}
                    options={opcoes
                        ?.map((o) => {
                            return {
                                value: o.id.toString(),
                                label: o.value,
                            } as SelectObject;
                        })
                        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
                />
            )}
        </Loading>
    );
};
