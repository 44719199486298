import { ControleAcesso, Error404, useControleAcesso } from "@omnijus/common";

import { RotaControleAcesso } from "./contexts/acessos-helper";
import { EdicaoAgendamento } from "./pages/agendamentos/edicao-agendamento/edicao-agendamento";
import { Agendamentos } from "./pages/agendamentos/listar-agendamentos";
import { Anomalias } from "./pages/anomalias/anomalias";
import { DetalhamentoAnomalia } from "./pages/anomalias/detalhamento/detalhamento-anomalia";
import { AtualizacaoProcesso } from "./pages/atualizacao-processos/atualizacao-processo";
import { PesquisaProcessos } from "./pages/atualizacao-processos/pesquisa-processos";
import { ConsultaAudiencia } from "./pages/audiencia/consulta/consulta-audiencia";
import { DetalhamentoAudiencia } from "./pages/audiencia/detalhamento/detalhamento-audiencia";
import { EdicaoAudiencia } from "./pages/audiencia/edicao/edicao-audiencia";
import { InclusaoAudiencia } from "./pages/audiencia/inclusao/inclusao-audiencia";
import { Escritorio } from "./pages/configuracoes/escritorio/escritorio";
import { Escritorios } from "./pages/configuracoes/escritorio/escritorios";
import { Perfil } from "./pages/configuracoes/perfil/perfil";
import { Perfis } from "./pages/configuracoes/perfil/perfis";
import { Usuario } from "./pages/configuracoes/usuario/usuario";
import { Usuarios } from "./pages/configuracoes/usuario/usuarios";
import { Dashboard } from "./pages/dashboard/dashboard";
import { AnaliseRespostasEntrevistaGuiada } from "./pages/movimentacao-assistida/analise-respostas-entrevista-guiada";
import { AnaliseRotulo } from "./pages/movimentacao-assistida/analise-rotulo/analise-rotulo";
import { DashboardMovimentacaoAssistida } from "./pages/movimentacao-assistida/dashboard-movimentacao-assistida";
import { EntrevistasGuiadas } from "./pages/movimentacao-assistida/entrevistas-guiadas";
import { Movimentos } from "./pages/movimentacao-assistida/movimentos";
import { CriarPeticionamento } from "./pages/peticionamentos/criar-peticionamento";
import { DocumentosPeticionamento } from "./pages/peticionamentos/documentos-peticionamento";
import { ListarPeticionamentos } from "./pages/peticionamentos/listar-peticionamentos";
import { DetalhesProcesso } from "./pages/processos/detalhes-processo/detalhes-processo";
import { ListagemProcessos } from "./pages/processos/listagem-processos/listagem-processos";
import { AcompanhamentoAudiencias } from "./pages/relatorios/acompanhamento-audiencias";
import { DetalhamentoAtividadesExecutores } from "./pages/relatorios/detalhamento-atividades-executores";
import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { Navigation } from "./shared/navigation/navigation";
import { ProcessosSemDocumentos } from "./pages/processos/listagem-processos/processos-sem-documentos";

const GetInitialRoute = (controleAcesso: ControleAcesso) => {
    if (controleAcesso.possuiAcessoFuncionalidade("dashboard")) {
        return "/dashboard";
    } else if (controleAcesso.possuiAcessoFuncionalidade("movimentacao_assistida")) {
        return "/movimentacao-assistida/movimentos";
    } else if (controleAcesso.possuiAcessoFuncionalidade("audiencias")) {
        return "/audiencias/consulta";
    } else if (controleAcesso.possuiAcessoFuncionalidade("peticionamentos")) {
        return "/peticionamentos";
    } else if (controleAcesso.possuiAcessoFuncionalidade("agendamentos")) {
        return "/agendamentos";
    } else if (controleAcesso.possuiAcessoFuncionalidade("processos")) {
        return "/processos";
    }

    return "/";
};

export const Router = () => {
    const controleAcesso = useControleAcesso();
    return (
        <BrowserRouter>
            <Navigation />
            <div className="container">
                <Switch>
                    <Route exact path="/">
                        <Redirect to={GetInitialRoute(controleAcesso)} />
                    </Route>

                    <RotaControleAcesso path="/audiencia/detalhamento/:id" tags={["audiencias"]}>
                        <DetalhamentoAudiencia />
                    </RotaControleAcesso>

                    <RotaControleAcesso path="/audiencia/edicao/:id" tags={["audiencias"]}>
                        <EdicaoAudiencia />
                    </RotaControleAcesso>

                    <RotaControleAcesso path="/audiencia/inclusao" tags={["audiencias"]}>
                        <InclusaoAudiencia />
                    </RotaControleAcesso>

                    <RotaControleAcesso path="/audiencias/consulta" tags={["audiencias"]}>
                        <ConsultaAudiencia />
                    </RotaControleAcesso>

                    <RotaControleAcesso path="/audiencias/acompanhamento-audiencias" tags={["audiencias"]}>
                        <AcompanhamentoAudiencias />
                    </RotaControleAcesso>

                    <RotaControleAcesso path="/audiencias/detalhamento-atividades-executores" tags={["audiencias"]}>
                        <DetalhamentoAtividadesExecutores />
                    </RotaControleAcesso>

                    <RotaControleAcesso
                        path="/movimentacao-assistida/analise-rotulo/:id"
                        tags={["movimentacao_assistida"]}
                    >
                        <AnaliseRotulo />
                    </RotaControleAcesso>

                    <RotaControleAcesso
                        path="/movimentacao-assistida/analise-respostas/:id"
                        tags={["movimentacao_assistida"]}
                    >
                        <AnaliseRespostasEntrevistaGuiada />
                    </RotaControleAcesso>

                    <RotaControleAcesso
                        path="/movimentacao-assistida/analise-respostas"
                        tags={["movimentacao_assistida"]}
                    >
                        <EntrevistasGuiadas />
                    </RotaControleAcesso>

                    <RotaControleAcesso path="/movimentacao-assistida/analise-rotulo" tags={["movimentacao_assistida"]}>
                        <AnaliseRotulo />
                    </RotaControleAcesso>

                    <RotaControleAcesso path="/movimentacao-assistida/dashboard" tags={["movimentacao_assistida"]}>
                        <DashboardMovimentacaoAssistida />
                    </RotaControleAcesso>

                    <RotaControleAcesso path="/movimentacao-assistida/movimentos" tags={["movimentacao_assistida"]}>
                        <Movimentos />
                    </RotaControleAcesso>

                    <RotaControleAcesso path="/peticionamentos/:id/documentos" tags={["peticionamentos"]}>
                        <DocumentosPeticionamento />
                    </RotaControleAcesso>

                    <RotaControleAcesso path="/peticionamentos/criar" tags={["peticionamentos"]}>
                        <CriarPeticionamento />
                    </RotaControleAcesso>

                    <RotaControleAcesso path="/peticionamentos" tags={["peticionamentos"]}>
                        <ListarPeticionamentos />
                    </RotaControleAcesso>

                    <RotaControleAcesso path="/agendamentos/:id" tags={["agendamentos"]}>
                        <EdicaoAgendamento />
                    </RotaControleAcesso>
                    <RotaControleAcesso path="/agendamentos" tags={["agendamentos"]}>
                        <Agendamentos />
                    </RotaControleAcesso>

                    <RotaControleAcesso path="/dashboard" tags={["dashboard"]}>
                        <Dashboard />
                    </RotaControleAcesso>

                    <RotaControleAcesso path="/configuracoes/perfil/novo" tags={["configuracao_perfil"]}>
                        <Perfil />
                    </RotaControleAcesso>

                    <RotaControleAcesso path="/configuracoes/perfil/editar/:id" tags={["configuracao_perfil"]}>
                        <Perfil />
                    </RotaControleAcesso>

                    <RotaControleAcesso path="/configuracoes/perfil/detalhes/:id" tags={["configuracao_perfil"]}>
                        <Perfil />
                    </RotaControleAcesso>

                    <RotaControleAcesso path="/configuracoes/perfil" tags={["configuracao_perfil"]}>
                        <Perfis />
                    </RotaControleAcesso>
                    {/* 
                    <RotaControleAcesso path="/configuracoes/usuario/buscar" tags={["configuracao_usuario"]}>
                        <NovoUsuario />
                    </RotaControleAcesso> */}

                    <RotaControleAcesso path="/configuracoes/usuario/editar/:id" tags={["configuracao_usuario"]}>
                        <Usuario />
                    </RotaControleAcesso>

                    <RotaControleAcesso path="/configuracoes/usuario/detalhes/:id" tags={["configuracao_usuario"]}>
                        <Usuario />
                    </RotaControleAcesso>

                    <RotaControleAcesso path="/configuracoes/usuario" tags={["configuracao_usuario"]}>
                        <Usuarios />
                    </RotaControleAcesso>

                    {/* <RotaControleAcesso path="/configuracoes/usuario/novo" tags={["configuracao_usuario"]}>
                        <Usuario />
                    </RotaControleAcesso>

                    <RotaControleAcesso path="/configuracoes/usuario/editar/:id" tags={["configuracao_usuario"]}>
                        <Usuario />
                    </RotaControleAcesso>

                    <RotaControleAcesso path="/configuracoes/usuario/detalhes/:id" tags={["configuracao_usuario"]}>
                        <Usuario />
                    </RotaControleAcesso>

                    <RotaControleAcesso path="/configuracoes/usuario" tags={["configuracao_usuario"]}>
                        <Usuarios />
                    </RotaControleAcesso> */}

                    <RotaControleAcesso path="/configuracoes/escritorio/novo" tags={["configuracao_escritorio"]}>
                        <Escritorio />
                    </RotaControleAcesso>

                    <RotaControleAcesso path="/configuracoes/escritorio/editar/:id" tags={["configuracao_escritorio"]}>
                        <Escritorio />
                    </RotaControleAcesso>

                    <RotaControleAcesso
                        path="/configuracoes/escritorio/detalhes/:id"
                        tags={["configuracao_escritorio"]}
                    >
                        <Escritorio />
                    </RotaControleAcesso>

                    <RotaControleAcesso path="/configuracoes/escritorio" tags={["configuracao_escritorio"]}>
                        <Escritorios />
                    </RotaControleAcesso>

                    <RotaControleAcesso path="/processos/detalhes/:id" tags={["processos"]}>
                        <DetalhesProcesso />
                    </RotaControleAcesso>

                    <RotaControleAcesso path="/processos-sem-documento" tags={["processos"]}>
                        <ProcessosSemDocumentos />
                    </RotaControleAcesso>
                    <RotaControleAcesso path="/processos" tags={["processos"]}>
                        <ListagemProcessos />
                    </RotaControleAcesso>

                    <RotaControleAcesso path="/atualizacao-processos/:numeroProcesso" tags={["atualizacao_processos"]}>
                        <AtualizacaoProcesso />
                    </RotaControleAcesso>

                    <RotaControleAcesso path="/anomalias" tags={["anomalias"]}>
                        <Anomalias />
                    </RotaControleAcesso>
                    <RotaControleAcesso path="/anomalia/:id" tags={["anomalias"]}>
                        <DetalhamentoAnomalia />
                    </RotaControleAcesso>

                    <RotaControleAcesso path="/atualizacao-processos" tags={["atualizacao_processos"]}>
                        <PesquisaProcessos />
                    </RotaControleAcesso>

                    <Route path="*">
                        <Error404 />
                    </Route>
                </Switch>
            </div>
        </BrowserRouter>
    );
};
