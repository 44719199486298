import { ActionFeedback, Back, ButtonPrimary, Loading, OmnijusCard, OmnijusTextField } from "@omnijus/common";
import { Form, Formik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { EscritorioService } from "../../../services/configuracoes/escritorio/escritorio-service";
import { EscritorioViewModel } from "../../../services/configuracoes/escritorio/escritorio-viewmodel";
import { OmnijusCheckboxFieldSingle } from "../../../shared/form/fields/omnijus-checkbox-field-single";
import { object as YupObject, string as YupString } from "yup";
import "./escritorio.scss";

const validationSchema = YupObject().shape({
    nome: YupString()
        .required("Informe o nome do escritório")
        .min(3, "O nome do escritório deve ter no mínimo 3 caracteres")
        .max(30, "O nome do escritório deve ter no máximo 30 caracteres"),
});

export const Escritorio = () => {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();

    const novo = useMemo(() => !id, [id]);

    const [promise, setPromise] = useState<Promise<EscritorioViewModel | undefined>>();

    useEffect(() => {
        setPromise(
            novo
                ? Promise.resolve({
                      nome: "",
                      ativo: true,
                  })
                : EscritorioService.obter(id)
        );
    }, [id, novo]);

    return (
        <Loading promise={promise}>
            {(escritorio) =>
                escritorio ? (
                    <>
                        <div className="marginBottom">
                            <Back />
                        </div>

                        <Formik
                            initialValues={escritorio}
                            validationSchema={validationSchema}
                            onSubmit={async (values) => {
                                await ActionFeedback.processing({
                                    title: novo ? "Criando escritório" : "Atualizando escritório",
                                    execution: novo
                                        ? EscritorioService.inserir(values)
                                        : EscritorioService.atualizar(id, values),
                                });

                                await ActionFeedback.info({
                                    text: `Escritório ${novo ? "criado" : "atualizado"} com sucesso!`,
                                });

                                history.push("/configuracoes/escritorio");
                            }}
                        >
                            {() => (
                                <Form>
                                    <OmnijusCard
                                        header={<h3>Escritório</h3>}
                                        body={
                                            <>
                                                <div className="grid_8_2_2">
                                                    <OmnijusTextField name="nome" label="Nome"></OmnijusTextField>
                                                    <div style={{ textAlign: "right" }}>
                                                        <OmnijusCheckboxFieldSingle
                                                            style={{ justifyContent: "flex-end" }}
                                                            name="ativo"
                                                            label="Ativo"
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    />

                                    <div className="linhaSubmit">
                                        <ButtonPrimary type="submit">Salvar</ButtonPrimary>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </>
                ) : (
                    <>Erro carregando escritório</>
                )
            }
        </Loading>
    );
};
