import React, { useEffect, useState } from "react";
import { OmnijusSelectField } from "../fields/omnijus-select-field";
import { Loading } from "@omnijus/common";
import { MovimentacaoAssistidaService } from "../../../services/movimentacao-assistida/movimentacao-assistida-service";
import { RotuloMovimento } from "../../../services/movimentacao-assistida/models/rotulo-movimento";

interface OmnijusFiltroRotuloMovimentoProps {
    hideLabel?: boolean;
    name?: string;
    label?: string;
    isDisabled?: boolean;
    isMulti?: boolean;
}

export const OmnijusFiltroRotuloMovimento = (props: OmnijusFiltroRotuloMovimentoProps) => {
    const [promiseOpcoes, setPromiseOpcoes] = useState<Promise<RotuloMovimento[] | undefined>>();

    useEffect(() => {
        setPromiseOpcoes(MovimentacaoAssistidaService.listarRotulos());
    }, []);

    return (
        <Loading promise={promiseOpcoes}>
            {(opcoes) => (
                <OmnijusSelectField
                    isMulti={props.isMulti}
                    name={props.name || "idRotuloMovimento"}
                    label={props.label}
                    options={opcoes
                        ?.map((o) => ({
                            value: o.id,
                            label: o.nome.toUpperCase(),
                        }))
                        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
                />
            )}
        </Loading>
    );
};
