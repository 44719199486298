import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import styles from "./listar-peticionamentos.module.scss";
import { Loading, OmnijusCard, SelectObject } from "@omnijus/common";
import { format } from "date-fns";
import { MovimentoProcesso } from "../../services/processo/models/movimento-processo";
import { ProcessoDetalheService } from "../../services/processo/processo-detalhe-service";

export const DocumentosProcesso = ({
    idProcesso,
    handleDocumentoSelecionado,
}: {
    idProcesso: string;
    handleDocumentoSelecionado: (documentoSelecionado: SelectObject | undefined) => void;
}) => {
    const [promise, setPromise] = useState<Promise<MovimentoProcesso[] | undefined>>();

    useEffect(() => {
        setPromise(ProcessoDetalheService.getMovimentos(idProcesso, true));
    }, [idProcesso]);

    return (
        <div className={styles.marginTop}>
            <Loading promise={promise}>
                {(movimentos) => {
                    return (
                        <OmnijusCard
                            header={<h2>Documentos do Processo</h2>}
                            body={
                                <DataTable
                                    pagination={true}
                                    paginationPerPage={20}
                                    noDataComponent={<p>Nenhum registro encontrado!</p>}
                                    data={
                                        movimentos?.flatMap((m) =>
                                            m.documentos?.map((documento) => ({
                                                data: m.data,
                                                ...documento,
                                            }))
                                        ) || []
                                    }
                                    columns={[
                                        {
                                            name: "Título",
                                            selector: (documento) =>
                                                documento && (
                                                    <span
                                                        title="Clique para visualizar o documento"
                                                        className={styles.hyperlink}
                                                        onClick={() => {
                                                            handleDocumentoSelecionado({
                                                                value: documento.path,
                                                                label: documento.nome,
                                                            });
                                                        }}
                                                    >
                                                        {documento.nome}
                                                    </span>
                                                ),
                                            sortable: true,
                                            wrap: true,
                                        },
                                        {
                                            name: "Data/hora atualização",
                                            selector: (documento) =>
                                                documento && (documento.data ? new Date(documento.data) : "-"),
                                            format: (documento) =>
                                                documento && documento.data
                                                    ? format(new Date(documento.data), "dd/MM/yyyy HH:mm:ss")
                                                    : "-",
                                            sortable: true,
                                            wrap: true,
                                        },
                                    ]}
                                />
                            }
                        />
                    );
                }}
            </Loading>
        </div>
    );
};
