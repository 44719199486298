export interface RegistrarHistoricoAnaliseRespostasCommand {
    idProcessoSolicitacaoAtividade: string;
    analiseRespostasAcao: AnaliseRespostasAcao;
}

export enum AnaliseRespostasAcao {
    Aprovar = 1,
    Pular = 2,
    Sair = 3,
    Acessar = 4,
    Reprovar = 5,
}
