import { RequestApi } from "@omnijus/common";
import { CLIENT_ID } from "../../services/client-id";

const solicitacaoAtividadeUrl =
    process.env.REACT_APP_BFF_OPERACIONAL_API_URL + "/api/Atividade/v1/SolicitacaoAtividade";

const requestApi = new RequestApi(CLIENT_ID);

export interface ParametrosDetalhamentoAtividadesExecutores {
    IdCliente: string[];
    IdAtividade: string[];
    IdEntrevistaGuiada: string[];
    dataDe: Date | undefined;
    dataAte: Date | undefined;
    IdStatusSolicitacao: string[];
    atividadesAbandonadas: boolean | undefined;
    atividadesGeraramRevisao: boolean | undefined;
}

export interface ResponseDetalhamentoAtividadesExecutores {
    base64: string;
}

export const detalhamentoAtividadesExecutores = async (
    filtro: ParametrosDetalhamentoAtividadesExecutores
): Promise<ResponseDetalhamentoAtividadesExecutores | undefined> => {
    const url = `${solicitacaoAtividadeUrl}/DetalhamentoAtividadesExecutores`;
    return await requestApi.post<ResponseDetalhamentoAtividadesExecutores | undefined>(url, filtro);
};
