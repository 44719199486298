import React, { useEffect, useState } from "react";
import { FiltroStatusSolicitacaoService } from "../../../services/filtros/filtro-status-solicitacao-service";
import { OmnijusSelectField } from "../fields/omnijus-select-field";
import { FiltroStatusSolicitacao } from "../../../services/filtros/models/filtro-status-solicitacao";
import { Loading } from "@omnijus/common";

interface OmnijusFiltroStatusSolicitacaoProps {
    hideLabel?: boolean;
    name?: string;
    label?: string;
    isDisabled?: boolean;
    isMulti?: boolean;
}

export const OmnijusFiltroStatusSolicitacao = (props: OmnijusFiltroStatusSolicitacaoProps) => {
    const [promiseOpcoes, setPromiseOpcoes] = useState<Promise<FiltroStatusSolicitacao[] | undefined>>();

    useEffect(() => {
        setPromiseOpcoes(FiltroStatusSolicitacaoService.listar());
    }, []);

    return (
        <Loading promise={promiseOpcoes}>
            {(opcoes) => (
                <OmnijusSelectField
                    isMulti={props.isMulti}
                    name={props.name || "IdStatusSolicitacao"}
                    label={props.label}
                    options={opcoes
                        ?.map((o) => ({
                            value: o.id.toString(),
                            label: o.value.toString(),
                        }))
                        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
                />
            )}
        </Loading>
    );
};
