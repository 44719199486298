import { Loading } from "@omnijus/common";
import { useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { ProcessoFiltroService } from "../../../services/filtros/filtros-processo-service";
import { FiltroFase } from "../../../services/filtros/models/filtro-fase";
import { OmnijusSelectField } from "../fields/omnijus-select-field";

const filtroRitoName = "IdRitoProcesso";

interface OmnijusFiltroFaseProps {
    hideLabel?: boolean;
    name?: string;
    label?: string;
    reloadOnChangeName?: string;
}

export const OmnijusFiltroFase = (props: OmnijusFiltroFaseProps) => {
    const formikContext = useFormikContext<any>();
    const { setFieldValue } = formikContext;
    const idRito = formikContext.values[props.reloadOnChangeName || filtroRitoName];
    const [promiseOpcoesFase, setPromiseOpcoesFase] = useState<Promise<FiltroFase[] | undefined>>();
    const isFirstRun = useRef(true);
    const name = props.name || "IdFaseProcesso";

    useEffect(() => {
        isFirstRun.current || setFieldValue(name, undefined);
        isFirstRun.current = false;
        setPromiseOpcoesFase(ProcessoFiltroService.listarFase(idRito));
    }, [idRito, props.reloadOnChangeName, name, setFieldValue]);

    return (
        <Loading promise={promiseOpcoesFase}>
            {(opcoesFase) => (
                <OmnijusSelectField
                    name={name}
                    label={props.hideLabel ? undefined : props.label || "Fase"}
                    options={opcoesFase?.map((o) => ({
                        value: o.id.toString(),
                        label: o.value.toString(),
                    }))}
                />
            )}
        </Loading>
    );
};
