import ptBr from "date-fns/locale/pt-BR";
import { Field, FieldProps } from "formik";
import React from "react";
import ReactDatePicker from "react-datepicker";
import styles from "../field.module.scss";

export const OmnijusDateField = (props: {
    name: string;
    placeholder?: string;
    value?: Date | null;
    onChange?: (value?: Date) => void;
    label?: string;
    className?: string;
    monthOnly?: boolean;
    disabled?: boolean;
    timeSelect?: boolean;
    timeInput?: boolean;
    timeFormat?: string;
    customInput?: React.ReactNode;
    dateFormat?: string;
    isClearable?: boolean;
}) => {
    return (
        <div className={`${styles["field-control"]} ${props.className || ""}`}>
            {props.label ? (
                <label className={styles["label"]} htmlFor={props.name}>
                    {props.label}
                </label>
            ) : null}
            {props.value !== undefined && props.onChange ? (
                <ReactDatePicker
                    disabled={props.disabled}
                    autoComplete="off"
                    isClearable={props.isClearable !== undefined ? props.isClearable : !props.disabled}
                    selected={props.value}
                    onChange={(value) => {
                        if (!(value instanceof Array) && props.onChange) {
                            props.onChange(value ? value : undefined);
                        }
                    }}
                    className={(props.className && styles[props.className]) || styles["input"]}
                    name={props.name}
                    placeholderText={props.placeholder}
                    locale={ptBr}
                    monthClassName={() => styles.mesDatePicker}
                    showMonthYearPicker={props.monthOnly}
                    showFullMonthYearPicker={props.monthOnly}
                    dateFormat={
                        props.dateFormat
                            ? props.dateFormat
                            : props.monthOnly
                            ? `MM/yyyy${props.timeFormat ? ` ${props.timeFormat}` : ""}`
                            : `dd/MM/yyyy${props.timeFormat ? ` ${props.timeFormat}` : ""}`
                    }
                    showTimeSelect={props.timeSelect}
                    showTimeInput={props.timeInput}
                    customInput={props.customInput}
                />
            ) : (
                <Field name={props.name} id={props.name} placeholder={props.placeholder}>
                    {({ field, meta, form }: FieldProps) => (
                        <>
                            <ReactDatePicker
                                disabled={props.disabled}
                                autoComplete="off"
                                isClearable={!props.disabled}
                                selected={(field.value && new Date(field.value)) || null}
                                onChange={(value) => {
                                    form.setFieldTouched(field.name, true);
                                    form.setFieldValue(field.name, value);

                                    if (!(value instanceof Array) && props.onChange) {
                                        props.onChange(value ? value : undefined);
                                    }
                                }}
                                className={(props.className && styles[props.className]) || styles["input"]}
                                name={field.name}
                                placeholderText={props.placeholder}
                                onBlur={field.onBlur}
                                locale={ptBr}
                                monthClassName={() => styles.mesDatePicker}
                                showMonthYearPicker={props.monthOnly}
                                showFullMonthYearPicker={props.monthOnly}
                                dateFormat={
                                    props.dateFormat
                                        ? props.dateFormat
                                        : props.monthOnly
                                        ? `MM/yyyy${props.timeFormat ? ` ${props.timeFormat}` : ""}`
                                        : `dd/MM/yyyy${props.timeFormat ? ` ${props.timeFormat}` : ""}`
                                }
                                showTimeSelect={props.timeSelect}
                                showTimeInput={props.timeInput}
                                customInput={props.customInput}
                            />
                            <p className={styles["error"]}>{meta.touched && meta.error ? meta.error : ""}</p>
                        </>
                    )}
                </Field>
            )}
        </div>
    );
};
