import { ActionFeedback, ButtonPrimary, ButtonSecondary, OmnijusCard } from "@omnijus/common";
import { Form, Formik } from "formik";
import React from "react";
import Modal from "react-modal";
import styles from "./modal-redesignacao.module.scss";
import { object as YupObject, date as YupDate } from "yup";
import { AudienciaService } from "../../../services/audiencia/audiencia-service";
import { OmnijusDateField } from "../../../shared/form/fields/omnijus-date-field";

interface ModalRedesignacaoAudienciaProps {
    idProcessoAudiencia: string;
    numeroProcesso: string;
    descricaoStatusAudiencia: string;
    dataHoraAudiencia: string;
    dataHoraAudienciaSemFormatacao: string;
    isModalOpen: boolean;
    onCloseModal: () => void;
}

export const ModalRedesignacaoAudiencia = (props: ModalRedesignacaoAudienciaProps) => {
    return (
        <>
            <Modal
                style={{
                    content: {
                        width: "50vw",
                        margin: "auto",
                    },
                }}
                isOpen={props.isModalOpen}
                onRequestClose={props.onCloseModal}
                shouldCloseOnEsc
                ariaHideApp={false}
            >
                <OmnijusCard
                    header={<h2>Redesignação da Audiência</h2>}
                    body={
                        <>
                            <Formik
                                initialValues={{ novaDataAudiencia: undefined }}
                                onSubmit={async (values) => {
                                    const redesignacaoConfirmada = await ActionFeedback.confirm(
                                        "Confirma a redesignação desta audiência?",
                                        "Sim",
                                        "Não"
                                    );

                                    if (redesignacaoConfirmada) {
                                        await ActionFeedback.processing({
                                            title: "Processando...",
                                            execution: AudienciaService.RedesignarAudiencia({
                                                idProcessoAudiencia: props.idProcessoAudiencia,
                                                novaDataAudiencia: new Date(values.novaDataAudiencia!),
                                            }),
                                            onError: async (error: any) =>
                                                await ActionFeedback.error({
                                                    icon: "error",
                                                    html: error?.json?.erros
                                                        ? error.json.erros.join("<br />")
                                                        : error?.json?.title
                                                        ? error?.json?.title
                                                        : "",
                                                }),
                                        });

                                        await ActionFeedback.info({
                                            title: "Redesignação realizada com sucesso!",
                                        });

                                        props.onCloseModal();
                                    }
                                }}
                                validationSchema={YupObject().shape({
                                    novaDataAudiencia: YupDate()
                                        .required("Informe a nova data da audiência")
                                        .typeError("Data inválida")
                                        .min(
                                            new Date(props.dataHoraAudienciaSemFormatacao) > new Date()
                                                ? new Date(props.dataHoraAudienciaSemFormatacao)
                                                : new Date(),
                                            new Date(props.dataHoraAudienciaSemFormatacao) > new Date()
                                                ? "A nova data deve ser maior que a data atual da audiência"
                                                : "A nova data deve ser maior que a data atual"
                                        ),
                                })}
                            >
                                <Form>
                                    <p>
                                        <strong>Nº do Processo: </strong> {props.numeroProcesso}
                                    </p>
                                    <p>
                                        <strong>Data/Hora atual da Audiência: </strong>
                                        {props.dataHoraAudiencia}
                                    </p>
                                    <p>
                                        <strong>Status atual da Audiência: </strong>
                                        {props.descricaoStatusAudiencia}
                                    </p>
                                    <div className={styles.card}>
                                        <div className={styles.options}>
                                            <OmnijusDateField
                                                timeInput={true}
                                                name="novaDataAudiencia"
                                                label="Nova data da audiência"
                                            />
                                        </div>
                                        <span className={styles.alertMessage}>
                                            ATENÇÃO! Esta atividade não pode ser desfeita. Confirma a{" "}
                                            <strong>REDESIGNAÇÃO</strong> da audiência para o processo nº{" "}
                                            {props.numeroProcesso} agendada originalmente para dia{" "}
                                            {props.dataHoraAudiencia}?
                                        </span>
                                        <div className={styles.buttonSubmit}>
                                            <ButtonPrimary type="submit">Redesignar Audiência</ButtonPrimary>
                                        </div>
                                    </div>

                                    <div className={styles.buttonClose}>
                                        <ButtonSecondary type="button" onClick={props.onCloseModal}>
                                            Fechar
                                        </ButtonSecondary>
                                    </div>
                                </Form>
                            </Formik>
                        </>
                    }
                ></OmnijusCard>
            </Modal>
        </>
    );
};
