import { Loading, OmnijusCard } from "@omnijus/common";
import React, { useEffect, useState } from "react";
import styles from "./movimentacao-assistida.module.scss";
import { MovimentacaoAssistidaService } from "../../services/movimentacao-assistida/movimentacao-assistida-service";

export const CardEntrevistaGuiada = ({ nomeEntrevistaGuiada }: { nomeEntrevistaGuiada: string }) => (
    <div className={styles.marginBottom}>
        <OmnijusCard
            body={
                <>
                    <h2>Entrevistas Guiadas</h2>
                    <p>{nomeEntrevistaGuiada}</p>
                </>
            }
        />
    </div>
);

export const CardEntrevistaGuiadaMovimentacaoAssistida = ({
    idProcesso,
    idRotuloMovimento,
}: {
    idProcesso: string;
    idRotuloMovimento: string;
}) => {
    const [promiseEntrevistaGuiada, setPromiseEntrevistaGuiada] = useState<Promise<string | undefined>>();
    useEffect(() => {
        if (idProcesso) {
            setPromiseEntrevistaGuiada(
                MovimentacaoAssistidaService.obterEntrevistaGuiada(idProcesso, idRotuloMovimento)
            );
        }
    }, [idProcesso, idRotuloMovimento]);

    return (
        <Loading promise={promiseEntrevistaGuiada}>
            {(nomeEntrevistaGuiada) =>
                nomeEntrevistaGuiada ? (
                    <CardEntrevistaGuiada nomeEntrevistaGuiada={nomeEntrevistaGuiada} />
                ) : (
                    <p>Erro carregando nome da entrevista guiada</p>
                )
            }
        </Loading>
    );
};
