import { useControleAcesso, WrapperAcessoAcao, WrapperAcessoFuncionalidade } from "@omnijus/common";
import React from "react";
import { PropsWithChildren } from "react";
import { Route } from "react-router";
import { NavLink } from "react-router-dom";

export type TagsFuncionalidades =
    | "configuracao_perfil"
    | "configuracao_usuario"
    | "configuracao_escritorio"
    | "configuracao_escritorio1"
    | "dashboard"
    | "movimentacao_assistida"
    | "audiencias"
    | "peticionamentos"
    | "agendamentos"
    | "processos"
    | "atualizacao_processos"
    | "anomalias";

export type TagsAcoes = "alterar_data_prevista_peticionamento" | "encaminhar_analise_escritorio";

export const WrapperAcessoFuncionalidadeOperacional = (props: PropsWithChildren<{ tags: TagsFuncionalidades[] }>) => (
    <WrapperAcessoFuncionalidade {...props} />
);
export const WrapperAcessoAcaoOperacional = (props: PropsWithChildren<{ tags: TagsAcoes[] }>) => (
    <WrapperAcessoAcao {...props} />
);

export function RotaControleAcesso(props: PropsWithChildren<{ path: string; tags: TagsFuncionalidades[] }>) {
    const controleAcesso = useControleAcesso();

    if (controleAcesso.possuiAcessoFuncionalidade(...props.tags)) {
        return (
            <Route exact path={props.path}>
                {props.children}
            </Route>
        );
    }

    return <p style={{ textAlign: "center" }}>Acesso negado</p>;
}

export function NavLinkControleAcesso(
    props: PropsWithChildren<{
        exact?: boolean;
        to: string;
        activeClassName: string;
        className: string;
        tags: TagsFuncionalidades[];
    }>
) {
    return (
        <WrapperAcessoFuncionalidade tags={props.tags}>
            <NavLink
                activeClassName={props.activeClassName}
                className={props.className}
                exact={props.exact}
                to={props.to}
            >
                {props.children}
            </NavLink>
        </WrapperAcessoFuncionalidade>
    );
}
