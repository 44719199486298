import { RequestApi } from "@omnijus/common";
import { FiltroMovimentoRotulo } from "../../services/filtros/filtro-movimento-rotulo";
import { CLIENT_ID } from "../../services/client-id";
import { AnaliseRotuloViewModel } from "./models/analise-rotulo";
import { AprovarAnaliseRotuloCommand } from "./models/aprovar-analise-rotulo";
import { EntrevistaGuiadaViewModel } from "./models/entrevista-guiada";
import { InformacoesDashboard } from "./models/informacoes-dashboard";
import { MovimentoRotuloViewModel } from "./models/movimento-rotulo";
import { RegistrarHistoricoAnaliseRotuloCommand } from "./models/registrar-historico-analise-rotulo";
import { RotuloMovimento } from "./models/rotulo-movimento";
import { FiltroEntrevistasGuiadas } from "../../services/filtros/filtro-entrevistas-guiadas";
import { AnaliseRespostasEntrevistaGuiadaViewModel } from "./models/analise-respostas-entrevista-guiada";
import { RegistrarHistoricoAnaliseRespostasCommand } from "./models/registrar-historico-analise-respostas";
import { AprovarAnaliseRespostasCommand } from "./models/aprovar-analise-respostas";
import { ReprovarAnaliseRespostasCommand } from "./models/reprovar-analise-respostas";
import { PagedResult } from "../../lib/paged-result";
import { PaginationInfo } from "../../lib/pagination-info";

const processoBaseUrl = process.env.REACT_APP_BFF_OPERACIONAL_API_URL + "/api/Processo/v1";
const movimentacaoAssistidaUrl = processoBaseUrl + "/MovimentacaoAssistida";
const rotuloMovimentoUrl = processoBaseUrl + "/RotuloMovimento";

const requestApi = new RequestApi(CLIENT_ID);

export const MovimentacaoAssistidaService = {
    obterInformacoesDashboard: async () => {
        const url = `${movimentacaoAssistidaUrl}/ObterInformacoesDashboard`;

        return await requestApi.get<InformacoesDashboard[]>(url, (res, json) => ({
            res: res,
            json: json,
        }));
    },
    consultarMovimentos: async (
        filtro: FiltroMovimentoRotulo,
        paginationInfo: PaginationInfo
    ): Promise<PagedResult<MovimentoRotuloViewModel> | undefined> => {
        const url = `${movimentacaoAssistidaUrl}/ConsultarMovimentos`;

        const res = await requestApi.post<PagedResult<MovimentoRotuloViewModel>>(url, { ...filtro, ...paginationInfo });
        return (
            res && {
                ...res,
                results: res.results && [
                    ...res.results.map((r) => ({
                        ...r,
                        dataHoraMovimento: r.dataHoraMovimento && new Date(r.dataHoraMovimento),
                        dataHora: r.dataHora && new Date(r.dataHora),
                        dataHoraDistribuicao: r.dataHoraDistribuicao && new Date(r.dataHoraDistribuicao),
                    })),
                ],
            }
        );
    },
    obterInformacoesParaAnaliseDeRotulo: async (idProcessoMovimentoRotulo: string) => {
        const url = `${movimentacaoAssistidaUrl}/AnaliseRotulo/${idProcessoMovimentoRotulo}`;

        return await requestApi.get<AnaliseRotuloViewModel>(url, (res, json) => ({
            res: res,
            json: json,
        }));
    },
    listarStatusDocumentoRotulo: async () => {
        const url = `${movimentacaoAssistidaUrl}/ListarStatusDocumentoRotulo`;

        return await requestApi.get<{ id: number; value: string }[]>(url, (res, json) => ({
            res: res,
            json: json,
        }));
    },
    listarRotulos: async () => {
        const url = rotuloMovimentoUrl;

        return await requestApi.get<RotuloMovimento[]>(url, (res, json) => ({
            res: res,
            json: json,
        }));
    },
    registrarHistoricoAnaliseRotulo: async (command: RegistrarHistoricoAnaliseRotuloCommand) => {
        const url = `${movimentacaoAssistidaUrl}/RegistrarHistoricoAnaliseRotulo`;

        return await requestApi.post(url, command, (res, json) => ({
            res: res,
            json: json,
        }));
    },
    aprovarAnaliseRotulo: async (command: AprovarAnaliseRotuloCommand) => {
        const url = `${movimentacaoAssistidaUrl}/AnaliseRotulo/Aprovar`;

        return await requestApi.post(url, command, (res, json) => ({
            res: res,
            json: json,
        }));
    },
    obterIdProximaAnaliseDeRotulo: async (
        filtros: FiltroMovimentoRotulo,
        idProcessoMovimentoRotulo: string,
        idsMovimentosIgnorados: string[]
    ): Promise<string | undefined> => {
        const url = new URL(`${movimentacaoAssistidaUrl}/AnaliseRotulo/${idProcessoMovimentoRotulo}/ProximoId`);

        return await requestApi.post(url.toString(), { ...filtros, idsMovimentosIgnorados }, (res, json) => ({
            res: res,
            json: json,
        }));
    },
    obterEntrevistaGuiada: async (idProcesso: string, idRotuloMovimento: string): Promise<string | undefined> => {
        const url = new URL(`${movimentacaoAssistidaUrl}/ObterEntrevistaGuiada`);

        url.searchParams.append("idProcesso", idProcesso);
        url.searchParams.append("idRotuloMovimento", idRotuloMovimento);

        return await requestApi.get(url.toString(), (res, json) => ({
            res: res,
            json: json,
        }));
    },
    consultarEntrevistasGuiadas: async (filtro: FiltroEntrevistasGuiadas) => {
        const url = new URL(`${movimentacaoAssistidaUrl}/ConsultarEntrevistas`);

        url.search = RequestApi.objectToQueryString(filtro);

        return await requestApi.get<EntrevistaGuiadaViewModel[]>(url.toString(), (res, json) => ({
            res: res,
            json: json,
        }));
    },
    obterInformacoesParaAnaliseDeRespostas: async (idProcessoSolicitacaoAtividade: string) => {
        const url = `${movimentacaoAssistidaUrl}/AnaliseRespostas/${idProcessoSolicitacaoAtividade}`;

        return await requestApi.get<AnaliseRespostasEntrevistaGuiadaViewModel>(url, (res, json) => ({
            res: res,
            json: json,
        }));
    },
    registrarHistoricoAnaliseRespostas: async (command: RegistrarHistoricoAnaliseRespostasCommand) => {
        const url = `${movimentacaoAssistidaUrl}/RegistrarHistoricoAnaliseRespostas`;

        return await requestApi.post(url, command, (res, json) => ({
            res: res,
            json: json,
        }));
    },
    obterIdProximaAnaliseDeRespostas: async (dataHoraMovimento: Date): Promise<string | undefined> => {
        const url = new URL(`${movimentacaoAssistidaUrl}/AnaliseRespostas/ProximoId`);

        url.searchParams.append("dataHoraMovimento", dataHoraMovimento.toString());

        return await requestApi.get(url.toString(), (res, json) => ({
            res: res,
            json: json,
        }));
    },
    aprovarAnaliseRespostas: async (command: AprovarAnaliseRespostasCommand) => {
        const url = `${movimentacaoAssistidaUrl}/AnaliseRespostas/Aprovar`;

        return await requestApi.post(url, command, (res, json) => ({
            res: res,
            json: json,
        }));
    },
    reprovarAnaliseRespostas: async (command: ReprovarAnaliseRespostasCommand) => {
        const url = `${movimentacaoAssistidaUrl}/AnaliseRespostas/Reprovar`;

        return await requestApi.post(url, command, (res, json) => ({
            res: res,
            json: json,
        }));
    },
};
