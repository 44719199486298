import { mdiTrashCan } from "@mdi/js";
import Icon from "@mdi/react";
import {
    ActionFeedback,
    CardFiltros,
    Loading,
    OmnijusCard,
    OmnijusSelectField,
    OmnijusTextField,
    RequestApi,
} from "@omnijus/common";
import { useFormikContext } from "formik";
import { PagedResult } from "../../../lib/paged-result";
import { PaginationInfo } from "../../../lib/pagination-info";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import { useHistory, useLocation } from "react-router-dom";
import { ModuloEnum } from "../../../services/configuracoes/modulo/modulo-viewmodel";
import { parsePerfilFilter, PerfilFilter } from "../../../services/configuracoes/perfil/perfil-filter";
import { PerfilService } from "../../../services/configuracoes/perfil/perfil-service";
import { ListaPerfilComClienteViewModel } from "../../../services/configuracoes/perfil/perfil-viewmodel";
import { ButtonOutlined } from "../../../shared/buttons/button-outlined/button-outlined";
import { OmnijusFiltroCliente } from "../../../shared/form/custom-fields/omnijus-filtro-cliente";
import { OmnijusFiltroEscritorio } from "../../../shared/form/custom-fields/omnijus-filtro-escritorio";
import { OmnijusFiltroModulo } from "../../../shared/form/custom-fields/omnijus-filtro-modulo";
import styles from "./perfil.module.scss";

export const Perfis = () => {
    const history = useHistory();
    const query = useLocation().search;
    const [dataBusca, setDataBusca] = useState(new Date());

    const [promise, setPromise] = useState<Promise<PagedResult<ListaPerfilComClienteViewModel> | undefined>>();
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>({
        sortColumn: "",
        sortDirection: "asc",
        pageNumber: 1,
        rowsPerPage: 20,
    });

    const filtro = useMemo(() => parsePerfilFilter(query), [query]);

    useEffect(() => {
        setPromise(PerfilService.listar(filtro, paginationInfo));
    }, [filtro, paginationInfo, dataBusca]);

    const buscar = useCallback(
        (filtros: PerfilFilter) => {
            const search = RequestApi.objectToQueryString(filtros);
            history.replace({ search });
            setDataBusca(new Date());
        },
        [history]
    );

    const remover = useCallback(
        async (id: string) => {
            const resposta = await ActionFeedback.confirm("Deseja remover o perfil?");

            if (!resposta) {
                return;
            }

            await ActionFeedback.processing({
                title: "Removendo usuário do perfil...",
                execution: PerfilService.excluir(id),
            });

            setDataBusca(new Date());
        },
        [setDataBusca]
    );

    const handleRowsPerPage = (rowsPerPage: number) => {
        setPaginationInfo((paginationInfo) => ({ ...paginationInfo, rowsPerPage, pageNumber: 1 }));
    };

    const handleSort = (column: IDataTableColumn<any>, sortDirection: "asc" | "desc") => {
        setPaginationInfo((paginationInfo) => ({
            ...paginationInfo,
            sortDirection,
            sortColumn: String(column.id),
            pageNumber: 1,
        }));
    };

    const handleChangePage = (page: number) => {
        setPaginationInfo((paginationInfo) => ({ ...paginationInfo, pageNumber: page }));
    };

    const columns = useMemo<IDataTableColumn<ListaPerfilComClienteViewModel>[]>(
        () => [
            {
                name: "Descrição",
                selector: "descricao",
                id: "descricao",
                maxWidth: "30%",
                sortable: true,
                wrap: false,
            },
            {
                name: "Tipo",
                maxWidth: "140px",
                sortable: true,
                cell: (row) =>
                    row.idModulo === ModuloEnum.ESCRITORIO ? (row.idEscritorio ? "Personalizado" : "Padrão") : "",
            },
            {
                name: "Modulo",
                maxWidth: "320%",
                selector: "descricaoModulo",
                sortable: true,
                id: "descricaoModulo",
                wrap: false,
            },
            {
                name: "Cliente/Escritório",
                maxWidth: "40%",
                selector: "nomeEscritorioOuCliente",
                sortable: false,
                id: "nomeEscritorioOuCliente",
                wrap: true,
            },
            {
                name: "Qtd. Usuários",
                maxWidth: "20px",
                selector: "qtdUsuarios",
                sortable: true,
                id: "qtdUsuarios",
            },
            {
                name: "Status",
                maxWidth: "20px",
                selector: "ativo",
                sortable: true,
                id: "ativo",
                cell: (row) => (row.ativo ? "Ativo" : "Inativo"),
            },
            {
                name: "",
                maxWidth: "10px",
                sortable: false,
                id: "excluir",
                cell: (row) => (
                    <span onClick={() => remover(row.id)}>
                        <Icon path={mdiTrashCan} size={1} />
                    </span>
                ),
            },
        ],
        [remover]
    );

    return (
        <div className="perfis">
            <h2>Perfis</h2>
            <div className={styles.cardFiltro}>
                <CardFiltros
                    textoBotao="Filtrar"
                    onBuscar={async (values) => {
                        buscar(values);
                    }}
                    initialValues={filtro}
                    botaoFiltrarInline
                >
                    <FormFiltroPerfil />
                </CardFiltros>
            </div>
            <Loading promise={promise}>
                {(pagedResult) => (
                    <OmnijusCard
                        body={
                            <>
                                <DataTable
                                    title={"Perfil"}
                                    actions={
                                        <ButtonOutlined onClick={() => history.push("/configuracoes/perfil/novo")}>
                                            Novo Perfil
                                        </ButtonOutlined>
                                    }
                                    customStyles={{
                                        rows: {
                                            style: {
                                                cursor: "pointer",
                                            },
                                        },
                                    }}
                                    highlightOnHover
                                    pagination
                                    paginationPerPage={paginationInfo.rowsPerPage}
                                    noDataComponent={<p>A consulta não retornou registros</p>}
                                    data={pagedResult?.results || []}
                                    paginationServer
                                    onChangeRowsPerPage={handleRowsPerPage}
                                    paginationDefaultPage={pagedResult?.pageNumber ?? 1}
                                    onChangePage={handleChangePage}
                                    paginationRowsPerPageOptions={[10, 20, 50]}
                                    onSort={handleSort}
                                    sortServer
                                    defaultSortField={
                                        columns.find((c) => c.id === paginationInfo.sortColumn)?.selector as string
                                    }
                                    defaultSortAsc={paginationInfo.sortDirection === "asc"}
                                    paginationTotalRows={pagedResult?.total}
                                    onRowClicked={(row) => history.push(`/configuracoes/perfil/editar/${row.id}`)}
                                    columns={columns}
                                />
                            </>
                        }
                    />
                )}
            </Loading>
        </div>
    );
};

export const FormFiltroPerfil = () => {
    const { values, setFieldValue } = useFormikContext<PerfilFilter>();

    useEffect(() => {
        values.idModulo !== ModuloEnum.CLIENTE && setFieldValue("idCliente", undefined);
        values.idModulo !== ModuloEnum.ESCRITORIO && setFieldValue("idEscritorio", undefined);
    }, [values.idModulo, setFieldValue]);

    return (
        <>
            <OmnijusTextField name="descricao" label="Descrição" />
            <OmnijusFiltroModulo />
            {values.idModulo === ModuloEnum.CLIENTE && <OmnijusFiltroCliente />}
            {values.idModulo === ModuloEnum.ESCRITORIO && (
                <>
                    <OmnijusFiltroEscritorio />
                    <OmnijusSelectField
                        name="relacionadoEscritorio"
                        label="Tipo"
                        options={[
                            { label: "Personalizado", value: "true" },
                            { label: "Padrão", value: "false" },
                        ]}
                    />
                </>
            )}
        </>
    );
};
