import { CardFiltros, Loading, OmnijusCard, OmnijusRangeDateField, RequestApi } from "@omnijus/common";
import { format } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import { useHistory, useLocation } from "react-router-dom";
import { FiltroMovimentoRotulo } from "../../services/filtros/filtro-movimento-rotulo";
import { MovimentoRotuloViewModel } from "../../services/movimentacao-assistida/models/movimento-rotulo";
import { MovimentacaoAssistidaService } from "../../services/movimentacao-assistida/movimentacao-assistida-service";
import { OmnijusCampoNumeroProcesso } from "../../shared/form/custom-fields/omnijus-campo-numero-processo";
import { OmnijusFiltroRotuloMovimento } from "../../shared/form/custom-fields/omnijus-filtro-rotulo-movimento";
import { OmnijusFiltroStatusDocumentoRotulo } from "../../shared/form/custom-fields/omnijus-filtro-status-documento-rotulo";
import { date as YupDate, object as YupObject, string as YupString } from "yup";
import styles from "./movimentacao-assistida.module.scss";
import queryString from "query-string";
import { PagedResult } from "../../lib/paged-result";
import { PaginationInfo } from "../../lib/pagination-info";
import { OmnijusFiltroCliente } from "../../shared/form/custom-fields/omnijus-filtro-cliente";
import { OmnijusFiltroTribunal } from "../../shared/form/custom-fields/omnijus-filtro-tribunal";
import { OmnijusFiltroEstado } from "../../shared/form/custom-fields/omnijus-filtro-estado";
import { AnaliseRotuloHistoryState } from "./analise-rotulo/analise-rotulo-history-state";

const validationSchema = YupObject().shape({
    numeroProcesso: YupString().min(25, "O número de processo deve conter exatamente 20 dígitos"),
    dataHoraDistribuicaoDe: YupDate().nullable().typeError("Data inválida"),
    dataHoraDistribuicaoAte: YupDate()
        .nullable()
        .typeError("Data inválida")
        .test("maior", "A data final não pode ser anterior à data inicial", function (dataAte) {
            const { dataHoraDistribuicaoDe } = this.parent;

            return !(dataAte && dataHoraDistribuicaoDe && dataAte < dataHoraDistribuicaoDe);
        }),
    dataHoraMovimentoDe: YupDate().nullable().typeError("Data inválida"),
    dataHoraMovimentoAte: YupDate()
        .nullable()
        .typeError("Data inválida")
        .test("maior", "A data final não pode ser anterior à data inicial", function (dataAte) {
            const { dataHoraMovimentoDe } = this.parent;

            return !(dataAte && dataHoraMovimentoDe && dataAte < dataHoraMovimentoDe);
        }),
    dataHoraAprovacaoDe: YupDate().nullable().typeError("Data inválida"),
    dataHoraAprovacaoAte: YupDate()
        .nullable()
        .typeError("Data inválida")
        .test("maior", "A data final não pode ser anterior à data inicial", function (dataAte) {
            const { dataHoraAprovacaoDe } = this.parent;

            return !(dataAte && dataHoraAprovacaoDe && dataAte < dataHoraAprovacaoDe);
        }),
});

const columns: IDataTableColumn<MovimentoRotuloViewModel>[] = [
    {
        name: "Nº do Processo",
        minWidth: "220px",
        width: "220px",
        selector: "numeroProcesso",
        format: (row) => <div style={{ cursor: "text" }}>{row.numeroProcesso}</div>,
        sortable: true,
        id: "numeroProcesso",
    },
    {
        name: "Cliente",
        minWidth: "200px",
        width: "200px",
        selector: "nomeCliente",
        sortable: true,
    },
    {
        name: "Data Distribuição",
        minWidth: "200px",
        width: "200px",
        selector: "dataHoraDistribuicao",
        format: (row) => format(row.dataHoraDistribuicao, "dd/MM/yyyy"),
        sortable: true,
        id: "dataHoraDistribuicao",
    },
    {
        name: "Data Movimento",
        minWidth: "200px",
        width: "200px",
        selector: "dataHoraMovimento",
        format: (row) => format(row.dataHoraMovimento, "dd/MM/yyyy HH:mm"),
        sortable: true,
        id: "dataHoraMovimento",
    },
    {
        name: "Rótulo",
        minWidth: "200px",
        maxWidth: "400px",
        selector: "descricaoRotulo",
        sortable: true,
        id: "descricaoRotulo",
    },
    {
        name: "Status",
        minWidth: "200px",
        width: "200px",
        selector: "descricaoStatusDocumentoRotulo",
        sortable: true,
        id: "descricaoStatusDocumentoRotulo",
    },
    {
        name: "Usuário",
        minWidth: "200px",
        width: "200px",
        selector: "nomeUsuario",
        format: (row) => row.nomeUsuario || "-",
        sortable: true,
        id: "nomeUsuario",
    },
    {
        name: "Data Hora",
        minWidth: "200px",
        width: "200px",
        selector: "dataHora",
        format: (row) => (row.dataHora && format(row.dataHora, "dd/MM/yyyy HH:mm")) || "-",
        sortable: true,
        id: "dataHora",
    },
];

const DefinirFiltro = (status: string | string[] | null, intervalo: string | string[] | null) => {
    const filtro: FiltroMovimentoRotulo = {
        idStatusDocumentoRotulo: 1,
    };

    if (status) {
        const statusInvalido = isNaN(+status);

        filtro.idStatusDocumentoRotulo = statusInvalido ? 1 : +status;
    }

    if (intervalo) {
        switch (intervalo) {
            case "0-24":
                filtro.dataHoraMovimentoDe = new Date(new Date().getTime() - 27 * 60 * 60 * 1000);
                break;
            case "24-48":
                filtro.dataHoraMovimentoDe = new Date(new Date().getTime() - 51 * 60 * 60 * 1000);
                filtro.dataHoraMovimentoAte = new Date(new Date().getTime() - 27 * 60 * 60 * 1000);
                break;
            case "48":
                filtro.dataHoraMovimentoAte = new Date(new Date().getTime() - 51 * 60 * 60 * 1000);
                break;
        }
    }

    return filtro;
};

export const Movimentos = () => {
    const history = useHistory<AnaliseRotuloHistoryState>();
    const location = useLocation<AnaliseRotuloHistoryState>();
    const [promise, setPromise] = useState<Promise<PagedResult<MovimentoRotuloViewModel> | undefined>>();
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>({
        rowsPerPage: 20,
        pageNumber: 1,
        sortColumn: "dataHoraMovimento",
        sortDirection: "asc",
    });
    const { status, intervalo } = queryString.parse(useLocation().search);
    const [filtro, setFiltro] = useState<FiltroMovimentoRotulo>({ idStatusDocumentoRotulo: 1 });

    const fetchMovimentacoes = useCallback(
        (filtro: FiltroMovimentoRotulo) => {
            setPromise(MovimentacaoAssistidaService.consultarMovimentos(filtro, paginationInfo));
        },
        [paginationInfo]
    );

    const handleRowsPerPage = (rowsPerPage: number) => {
        setPaginationInfo((paginationInfo) => ({ ...paginationInfo, rowsPerPage, pageNumber: 1 }));
    };

    const handleSort = (column: IDataTableColumn, sortDirection: "asc" | "desc") => {
        setPaginationInfo((paginationInfo) => ({
            ...paginationInfo,
            sortDirection,
            sortColumn: String(column.id),
            pageNumber: 1,
        }));
    };

    const handleChangePage = (page: number) => {
        setPaginationInfo((paginationInfo) => ({ ...paginationInfo, pageNumber: page }));
    };

    useEffect(() => {
        setFiltro(DefinirFiltro(status, intervalo));
    }, [status, intervalo]);
    useEffect(() => {
        fetchMovimentacoes(filtro);
    }, [fetchMovimentacoes, filtro]);

    return (
        <div className={styles["consulta-movimentos"]}>
            <h2>Movimentos</h2>
            <CardFiltros
                textoBotao="Filtrar"
                onBuscar={async (values) => {
                    setFiltro(values);
                }}
                initialValues={
                    {
                        numeroProcesso: "",
                        dataHoraDistribuicaoDe: undefined,
                        dataHoraDistribuicaoAte: undefined,
                        dataHoraMovimentoDe: filtro.dataHoraMovimentoDe,
                        dataHoraMovimentoAte: filtro.dataHoraMovimentoAte,
                        idRotuloMovimento: "",
                        idStatusDocumentoRotulo: filtro.idStatusDocumentoRotulo,
                        dataHoraAprovacaoDe: undefined,
                        dataHoraAprovacaoAte: undefined,
                        usuarioAprovacao: "",
                        idCliente: "",
                        idOrgao: "",
                        siglaUf: "",
                    } as FiltroMovimentoRotulo
                }
                botaoFiltrarInline
                validationSchema={validationSchema}
            >
                <OmnijusCampoNumeroProcesso name="numeroProcesso" label="Nº do Processo" />
                <OmnijusRangeDateField
                    nameDe="dataHoraDistribuicaoDe"
                    nameAte="dataHoraDistribuicaoAte"
                    label="Data da Distribuição"
                />
                <OmnijusRangeDateField
                    nameDe="dataHoraMovimentoDe"
                    nameAte="dataHoraMovimentoAte"
                    label="Data do Movimento"
                />
                <OmnijusFiltroRotuloMovimento label="Rótulo" />
                <OmnijusRangeDateField
                    nameDe="dataHoraAprovacaoDe"
                    nameAte="dataHoraAprovacaoAte"
                    label="Data Hora Aprovação"
                />
                <OmnijusFiltroStatusDocumentoRotulo label="Status" />
                <OmnijusFiltroCliente name="idCliente" label="Cliente" />

                <OmnijusFiltroEstado name="siglaUf" label="Estado" />
                <OmnijusFiltroTribunal reloadOnChangeNameUf="siglaUf" label="Tribunal" name="idOrgao" />
            </CardFiltros>
            {promise ? (
                <Loading promise={promise}>
                    {(pagedResult) => (
                        <OmnijusCard
                            body={
                                <DataTable
                                    noHeader={true}
                                    customStyles={{
                                        rows: {
                                            style: {
                                                cursor: "pointer",
                                            },
                                        },
                                    }}
                                    pagination
                                    paginationPerPage={paginationInfo.rowsPerPage}
                                    noDataComponent={<p>A consulta não retornou registros</p>}
                                    data={pagedResult?.results || []}
                                    paginationServer
                                    onChangeRowsPerPage={handleRowsPerPage}
                                    paginationDefaultPage={pagedResult?.pageNumber ?? 1}
                                    onChangePage={handleChangePage}
                                    onSort={handleSort}
                                    sortServer
                                    defaultSortField={
                                        columns.find((c) => c.id === paginationInfo.sortColumn)?.selector as string
                                    }
                                    defaultSortAsc={paginationInfo.sortDirection === "asc"}
                                    paginationTotalRows={pagedResult?.total}
                                    onRowClicked={(row) =>
                                        history.push({
                                            pathname: `/movimentacao-assistida/analise-rotulo/${row.idProcessoMovimentoRotulo}`,
                                            search: RequestApi.objectToQueryString(filtro),
                                            state: { ...location.state, idsMovimentosPulados: [] },
                                        })
                                    }
                                    columns={columns}
                                />
                            }
                        />
                    )}
                </Loading>
            ) : (
                <p className="text-center">Utilize os filtros para realizar uma nova busca!</p>
            )}
        </div>
    );
};
