import React, { useEffect, useState } from "react";
import { DetalheProcesso } from "../../../services/processo/models/detalhe-processo";
import { ProcessoService } from "../../../services/processo/processo-service";
import "../../../pages/processos/listagem-processos/listagem-processos.scss";
import { Loading, OmnijusCard } from "@omnijus/common";
import { GridListagemProcessos } from "../../../pages/processos/listagem-processos/grid-listagem-processos";
import { useHistory } from "react-router-dom";
import { IdStatusProcesso } from "../../../services/processo/models/id-status-processo";

const atualizacaoProcessoUrl = "/atualizacao-processos/";

export const ProcessosSemDocumentos = () => {
    const [promiseProcessos, setPromiseProcessos] = useState<Promise<DetalheProcesso[]>>();
    const history = useHistory();

    useEffect(() => {
        setPromiseProcessos(
            ProcessoService.listarProcessos({
                SemDocumentoDeMovimento: true,
                IdStatusProcesso: IdStatusProcesso.EmPreAnalise,
            })
        );
    }, []);

    return (
        <div className="processos">
            <h2 className="titulo-destaque">Processos sem Documentos</h2>
            <OmnijusCard
                body={
                    promiseProcessos ? (
                        <Loading promise={promiseProcessos}>
                            {(listaProcessos) =>
                                listaProcessos && (
                                    <GridListagemProcessos
                                        filtrosAplicados={[]}
                                        listaProcessos={listaProcessos}
                                        onRowClicked={(p) => history.push(atualizacaoProcessoUrl + p.numeroProcesso)}
                                    />
                                )
                            }
                        </Loading>
                    ) : (
                        <p className="text-center">Erro buscando processos</p>
                    )
                }
            />
        </div>
    );
};
