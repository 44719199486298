import { ButtonDownload, RequestApi, SelectObject } from "@omnijus/common";
import React, { useState, useEffect } from "react";
import Select from "react-select";

interface SelectDocumentoProps {
    documentos: Array<SelectObject>;
    documentoSelecionado?: SelectObject;
    iframeStyle?: React.CSSProperties;
}

export function SelectDocumento(props: SelectDocumentoProps) {
    const [documentoSelecionado, setDocumentoSelecionado] = useState<SelectObject | null>(null);

    useEffect(() => {
        if (props.documentos.length > 0) {
            setDocumentoSelecionado(props.documentoSelecionado || props.documentos[0]);
        }
    }, [props.documentoSelecionado, props.documentos]);

    return (
        <>
            {props.documentos.length > 0 ? (
                <div style={{ display: "grid", gap: "1rem", gridTemplateColumns: "10fr 2fr" }}>
                    <Select
                        options={props.documentos}
                        value={documentoSelecionado}
                        onChange={(value) => {
                            setDocumentoSelecionado(value);
                        }}
                    />
                    {documentoSelecionado && (
                        <ButtonDownload
                            onClick={() => {
                                const urlSegments = documentoSelecionado.value.split("/");
                                RequestApi.downloadExternal(
                                    documentoSelecionado.value,
                                    urlSegments[urlSegments.length - 1]
                                );
                            }}
                        >
                            Download
                        </ButtonDownload>
                    )}
                </div>
            ) : null}
            {documentoSelecionado && (
                <>
                    <iframe
                        width="100%"
                        height="100%"
                        style={props.iframeStyle || { minHeight: "50rem", marginTop: "1rem" }}
                        key={documentoSelecionado.value.toString()}
                        title="Documento"
                        src={`/pdfjs/web/viewer.html?v=1&file=${encodeURIComponent(documentoSelecionado.value)}`}
                    />
                </>
            )}
        </>
    );
}
