import { useHistory, useLocation } from "react-router-dom";
import { ButtonPrimary } from "../../../shared/form/fields/omnijus-button-primary";
import styles from "../movimentacao-assistida.module.scss";
import { ActionFeedback } from "@omnijus/common";
import { MovimentacaoAssistidaService } from "../../../services/movimentacao-assistida/movimentacao-assistida-service";
import React from "react";
import { analiseRotuloEditavel } from "./analise-rotulo";
import { RotuloMovimento } from "../../../services/movimentacao-assistida/models/rotulo-movimento";
import { FiltroMovimentoRotulo } from "../../../services/filtros/filtro-movimento-rotulo";
import { AnaliseRotuloHistoryState } from "./analise-rotulo-history-state";

export const AnaliseRotuloBotaoAprovar = ({
    idProcessoMovimentoRotulo,
    idStatusDocumentoRotulo,
    rotuloMovimento,
    filtroMovimentoRotulo,
}: {
    rotuloMovimento: RotuloMovimento;
    idStatusDocumentoRotulo: number;
    idProcessoMovimentoRotulo: string;
    idProcessoMovimento: string;
    filtroMovimentoRotulo: FiltroMovimentoRotulo;
}) => {
    const history = useHistory<AnaliseRotuloHistoryState>();
    const location = useLocation<AnaliseRotuloHistoryState>();
    const idsMovimentosPulados = location.state?.idsMovimentosPulados ?? [];
    return (
        <ButtonPrimary
            disabled={!analiseRotuloEditavel(idStatusDocumentoRotulo)}
            className={analiseRotuloEditavel(idStatusDocumentoRotulo) ? styles.botaoAprovar : styles.botaoEsmaecido}
            onClick={async () => {
                if (!analiseRotuloEditavel(idStatusDocumentoRotulo)) {
                    return;
                }

                if (!rotuloMovimento) {
                    await ActionFeedback.error({
                        icon: "error",
                        text: "A análise não poderá ser concluída enquanto não for associado pelo menos um rótulo ao movimento",
                    });

                    return;
                } else if (rotuloMovimento.nome.toUpperCase() === "NÃO ROTULADO") {
                    await ActionFeedback.error({
                        icon: "error",
                        text: 'A análise não poderá ser concluída enquanto o rótulo "NÃO ROTULADO" estiver presente no movimento',
                    });

                    return;
                }

                await ActionFeedback.processing({
                    title: "Aprovando...",
                    execution: MovimentacaoAssistidaService.aprovarAnaliseRotulo({
                        idProcessoMovimentoRotulo: idProcessoMovimentoRotulo,
                        idRotuloMovimento: rotuloMovimento.id,
                    }),
                    onError: async (error: any) =>
                        await ActionFeedback.error({
                            icon: "error",
                            html: error?.json?.erros
                                ? error.json.erros.join("<br />")
                                : error?.json?.title
                                ? error?.json?.title
                                : "",
                        }),
                });

                const continuar = await ActionFeedback.confirm(
                    "",
                    "Sim",
                    "Não",
                    "Deseja ir para a próxima análise?",
                    "question"
                );

                if (continuar) {
                    const proximoIdProcessoMovimentoRotulo = await ActionFeedback.processing({
                        title: "Processando...",
                        execution: MovimentacaoAssistidaService.obterIdProximaAnaliseDeRotulo(
                            filtroMovimentoRotulo,
                            idProcessoMovimentoRotulo,
                            idsMovimentosPulados
                        ),
                        onError: async (error: any) =>
                            await ActionFeedback.error({
                                icon: "error",
                                html: error?.json?.erros
                                    ? error.json.erros.join("<br />")
                                    : error?.json?.title
                                    ? error?.json?.title
                                    : "",
                            }),
                    });

                    if (proximoIdProcessoMovimentoRotulo) {
                        history.replace(`/movimentacao-assistida/analise-rotulo/${proximoIdProcessoMovimentoRotulo}`, {
                            ...location.state,
                            idsMovimentosPulados: idsMovimentosPulados,
                        });
                    } else {
                        await ActionFeedback.info({
                            text: "Não foram encontrados novos registros. Você será redirecionado para a lista de movimentos.",
                        });

                        history.push("/movimentacao-assistida/movimentos");
                    }
                } else {
                    history.push("/movimentacao-assistida/movimentos");
                }
            }}
        >
            Aprovar
        </ButtonPrimary>
    );
};
