import { OmnijusCard } from "@omnijus/common";
import { format } from "date-fns";
import React from "react";
import DataTable from "react-data-table-component";
import { ReactComponent as TrashIcon } from "../../assets/images/trash-icon.svg";
import { ReactComponent as UndoIcon } from "../../assets/images/undo-icon.svg";
import Swal from "sweetalert2";
import styles from "./agendamentos.module.scss";
import { Agendamento } from "../../services/agendamento/models/agendamento";
import { IdStatusAgendamento } from "../../services/agendamento/models/id-status-agendamento";

export const GridAgendamentos = ({
    agendamentos,
    dispatch,
}: {
    agendamentos: Agendamento[];
    dispatch: (
        tipo: "marcarParaExclusao" | "remover" | "desfazerMarcacaoParaExclusao",
        agendamento: Agendamento,
        descricaoMotivoExclusao?: string
    ) => void;
}) => {
    return (
        <div>
            <OmnijusCard
                body={
                    <DataTable
                        conditionalRowStyles={[
                            {
                                when: (row) =>
                                    row.descricaoMotivoExclusao !== undefined && row.descricaoMotivoExclusao !== "",
                                style: {
                                    backgroundColor: "#d62755",
                                    color: "white",
                                },
                            },
                        ]}
                        noHeader={true}
                        pagination={true}
                        paginationPerPage={3}
                        noDataComponent={<p>Nenhum item adicionado</p>}
                        data={agendamentos || []}
                        columns={[
                            {
                                name: "Providência",
                                selector: (row) => row.descricaoProvidencia,
                                wrap: true,
                            },
                            {
                                name: "Movimento",
                                selector: (row) => row.descricaoMovimento,
                                wrap: true,
                            },
                            {
                                name: "Data Prazo Segurança",
                                selector: (row) =>
                                    row.dataPrazoSeguranca && format(new Date(row.dataPrazoSeguranca), "dd/MM/yyyy"),
                            },
                            {
                                name: "Data Prazo Fatal",
                                selector: (row) =>
                                    row.dataPrazoFatal && format(new Date(row.dataPrazoFatal), "dd/MM/yyyy"),
                            },
                            {
                                name: "Motivo Exclusão",
                                selector: (row) => row.descricaoMotivoExclusao,
                                wrap: true,
                            },
                            {
                                name: "",
                                width: "5rem",
                                selector: (row) =>
                                    row.descricaoMotivoExclusao ? (
                                        <UndoIcon
                                            style={{ cursor: "pointer" }}
                                            className={styles.undoIcon}
                                            title="Clique para desfazer"
                                            onClick={() => {
                                                dispatch("desfazerMarcacaoParaExclusao", row);
                                            }}
                                        />
                                    ) : row.idStatusAgendamento === IdStatusAgendamento.EmAndamento ? (
                                        <TrashIcon
                                            style={{ cursor: "pointer" }}
                                            className={styles.trashIcon}
                                            title="Clique para remover este agendamento"
                                            onClick={() => {
                                                if (row.idAgendamento) {
                                                    Swal.fire({
                                                        title: "Excluir Agendamento",
                                                        text: "Informe o motivo da exclusão deste agendamento",
                                                        input: "textarea",
                                                        showCancelButton: true,
                                                        confirmButtonText: "Ok",
                                                        cancelButtonText: "Cancelar",
                                                    }).then((result) => {
                                                        if (result.isConfirmed && result.value) {
                                                            dispatch("marcarParaExclusao", row, result.value);
                                                        }
                                                    });
                                                } else {
                                                    dispatch("remover", row);
                                                }
                                            }}
                                        />
                                    ) : (
                                        <TrashIcon className={styles.trashIconDisabled} />
                                    ),
                            },
                        ]}
                    />
                }
            />
        </div>
    );
};
