import { toast } from "react-toastify";

export const showToast = (textoNotificacao: string) => {
    toast.info(textoNotificacao, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
};
