import { RequestApi } from "@omnijus/common";
import { CLIENT_ID } from "../../services/client-id";
import { FiltroStatusAudiencia } from "./models/filtro-status-audiencia";
import { FiltroTipoAudiencia } from "./models/filtro-tipo-audiencia";

const requestApi = new RequestApi(CLIENT_ID);
const baseUrl = process.env.REACT_APP_BFF_OPERACIONAL_API_URL + "/api/Processo/v1";

export const FiltrosAudienciaService = {
    listarStatusAudiencia: () => {
        const url = `${baseUrl}/ProcessoAudiencia/ListarStatusAudiencia`;
        return requestApi.get<Array<FiltroStatusAudiencia>>(url);
    },
    listarStatusSolicitacaoAudiencia: () => {
        const url = `${baseUrl}/ProcessoAudiencia/ListarStatusSolicitacaoAudiencia`;
        return requestApi.get<Array<FiltroStatusAudiencia>>(url);
    },
    listarTipoAudiencia: () => {
        const url = `${baseUrl}/ProcessoAudiencia/ListarTipoAudiencia`;
        return requestApi.get<Array<FiltroTipoAudiencia>>(url);
    },
};
