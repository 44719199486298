import React, { useEffect, useState } from "react";
import DownloadIcon from "../../../../assets/images/download-icon.svg";
import "./card-subsidio-processo.scss";
import { ActionFeedback } from "@omnijus/common";
import { SubsidioProcessoService } from "../../../../services/processo/subsidio/subsidio-processo-service";
import { SubsidioProcesso } from "../../../../services/processo/subsidio/models/subsidio-processo";
import { dateToDDMMYYYY, Loading } from "@omnijus/common";

interface CardSubsidioProcessoProps {
    idProcesso: string;
}

export const CardSubsidioProcesso = (props: CardSubsidioProcessoProps) => {
    const [promiseSubsidioProcesso, setPromiseSubsidioProcesso] = useState<Promise<SubsidioProcesso[] | undefined>>();
    const [contemDocumento, setContemDocumento] = useState(false);

    useEffect(() => {
        setPromiseSubsidioProcesso(
            SubsidioProcessoService.getAll(props.idProcesso).then((r) => {
                let existeAlgumDocumento = r?.reduce((prev, curr) => prev || curr.contemDocumentoAssociado, false);
                setContemDocumento(existeAlgumDocumento || false);
                return r;
            })
        );
    }, [props.idProcesso]);

    const downloadSubsidio = async (idSubsidioProcesso: string, filename: string) => {
        await ActionFeedback.processing({
            title: "Gerando arquivo para download",
            execution: SubsidioProcessoService.downloadSubsidio(idSubsidioProcesso, filename),
        });
    };

    const downloadTodosSubsidios = async (idProcesso: string, filename: string) => {
        await ActionFeedback.processing({
            title: "Gerando arquivo para download",
            execution: SubsidioProcessoService.downloadTodosSubsidios(idProcesso, filename),
        });
    };

    return (
        <div className="card-processo-detalhe">
            <header>
                <h3>Subsídios do processo</h3>
                {contemDocumento ? (
                    <button
                        onClick={() => downloadTodosSubsidios(props.idProcesso, `subsidios-${props.idProcesso}.zip`)}
                    >
                        <img className="download-icon" alt="Ícone de download" src={DownloadIcon} />
                    </button>
                ) : (
                    <></>
                )}
            </header>
            <Loading promise={promiseSubsidioProcesso}>
                {(subsidiosProcesso) => {
                    if (subsidiosProcesso) {
                        return (
                            <div className="card-body-scrollable">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Data da solicitação</th>
                                            <th>Data da resolução</th>
                                            <th>Descrição</th>
                                            <th>Situação</th>
                                            <th>Qualidade</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {subsidiosProcesso.length === 0 ? (
                                            <tr>
                                                <td className="center" colSpan={3}>
                                                    Nenhum subsídio.
                                                </td>
                                            </tr>
                                        ) : (
                                            subsidiosProcesso.map((s) => (
                                                <tr key={s.idSubsidioProcesso}>
                                                    <td>{dateToDDMMYYYY(s.dataSolicitacao)}</td>
                                                    <td>{s.dataRetorno ? dateToDDMMYYYY(s.dataRetorno) : ""}</td>
                                                    <td>{s.descricaoSubsidio}</td>
                                                    <td>{s.descricaoStatusSolicitacao}</td>
                                                    <td>{s.qualidadeSubsidioFornecido}</td>
                                                    <td className="col-download">
                                                        {s.contemDocumentoAssociado ? (
                                                            <button
                                                                onClick={() =>
                                                                    downloadSubsidio(
                                                                        s.idSubsidioProcesso,
                                                                        `subsidio-${s.idSubsidioProcesso}.zip`
                                                                    )
                                                                }
                                                            >
                                                                <img
                                                                    className="download-icon"
                                                                    alt="Ícone de download"
                                                                    src={DownloadIcon}
                                                                />
                                                            </button>
                                                        ) : null}
                                                    </td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        );
                    } else {
                        return <></>;
                    }
                }}
            </Loading>
        </div>
    );
};
