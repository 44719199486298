import { Loading } from "@omnijus/common";
import { useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { FiltroForo } from "../../../services/filtros/models/filtro-foro";
import { DominioService } from "../../../services/processo/dominio-service";
import { OmnijusSelectField } from "../fields/omnijus-select-field";

const filtroOrgaoName = "IdOrgao";
const filtroComarcaName = "NomeComarcaOrgaoUnidade";

interface OmnijusFiltroForoProps {
    reloadOnChangeNameOrgao?: string;
    reloadOnChangeNameComarca?: string;
    name?: string;
    label?: string;
    exigirOrgaoEComarca?: boolean;
}

export const OmnijusFiltroForo = (props: OmnijusFiltroForoProps) => {
    const formikContext = useFormikContext<any>();
    const { setFieldValue } = formikContext;
    const isFirstRun = useRef(true);
    const name = props.name || "IdOrgaoUnidade";
    const idOrgao = formikContext.values[props.reloadOnChangeNameOrgao || filtroOrgaoName];
    const nomeComarca = formikContext.values[props.reloadOnChangeNameComarca || filtroComarcaName];
    const [promiseOpcoesForo, setPromiseOpcoesForo] = useState<Promise<FiltroForo[] | undefined>>();

    useEffect(() => {
        isFirstRun.current || setFieldValue(name, undefined);
        isFirstRun.current = false;

        if (props.exigirOrgaoEComarca && (!idOrgao || !nomeComarca)) {
            setPromiseOpcoesForo(Promise.resolve([]));
            return;
        }

        setPromiseOpcoesForo(
            DominioService.consultarOrgaoUnidade({
                idsOrgaos: idOrgao ? [idOrgao] : [],
                nomeComarca: nomeComarca,
            })
        );
    }, [
        idOrgao,
        nomeComarca,
        props.reloadOnChangeNameOrgao,
        props.reloadOnChangeNameComarca,
        name,
        setFieldValue,
        props.exigirOrgaoEComarca,
    ]);

    return (
        <Loading promise={promiseOpcoesForo}>
            {(opcoesForo) => (
                <OmnijusSelectField
                    name={name}
                    label={props.label || "Foro"}
                    options={opcoesForo
                        ?.map((o) => ({
                            value: o.id.toString(),
                            label: o.value.toString(),
                        }))
                        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
                />
            )}
        </Loading>
    );
};
