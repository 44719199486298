import { Field, FieldProps } from "formik";
import React from "react";
import "./inputs.scss";

interface OmnijusCheckboxFieldProps {
    label?: string;
    name: string;
    options: Array<{ label: string; value: string }>;
    inline?: boolean;
    vertical?: boolean;
}

export const OmnijusCheckboxField = (props: OmnijusCheckboxFieldProps) => {
    return (
        <Field name={props.name} id={props.name}>
            {({ field, meta }: FieldProps) => (
                <div className={"form-field-control"}>
                    {props.label ? (
                        <label className="label" htmlFor={props.name}>
                            {props.label}
                        </label>
                    ) : null}
                    <div className={`omni-radio-check ${props.vertical ? " vertical" : ""}`}>
                        {props.options.map((o, i) => (
                            <div className="option" key={o.value}>
                                <input
                                    {...field}
                                    id={o.value}
                                    type="checkbox"
                                    value={o.value}
                                    className={"input" + (meta.touched && meta.error ? " input-error" : "")}
                                />
                                <label style={{ margin: "auto" }} htmlFor={o.value}>
                                    {o.label}
                                </label>
                            </div>
                        ))}
                    </div>
                    <div className="error">{meta.touched && meta.error ? meta.error : ""}</div>
                </div>
            )}
        </Field>
    );
};
