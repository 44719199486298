import { RequestApi } from "@omnijus/common";
import { CLIENT_ID } from "../../services/client-id";

const requestApi = new RequestApi(CLIENT_ID);

const documentoAutomatizadoUrl =
    process.env.REACT_APP_BFF_OPERACIONAL_API_URL + "/api/Processo/v1/ProcessoDocumentoAutomatizado";

export const DocumentosAutomatizadosService = {
    downloadDocumentoAutomatizado: async (idProcessoDocumento: string, filename: string) => {
        const url = `${documentoAutomatizadoUrl}/ProcessoDocumento/${idProcessoDocumento}/Download`;
        return await requestApi.download(url, filename);
    },
    downloadDocumentosAutomatizadosProcesso: async (idProcesso: string, filename: string) => {
        const url = `${documentoAutomatizadoUrl}/Processo/${idProcesso}/ProcessoDocumento/Download`;
        return await requestApi.download(url, filename);
    },
};
