import { Loading } from "@omnijus/common";
import { useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { ProcessoFiltroService } from "../../../services/filtros/filtros-processo-service";
import { FiltroMomento } from "../../../services/filtros/models/filtro-momento";
import { OmnijusSelectField } from "../fields/omnijus-select-field";

const filtroRitoName = "IdRitoProcesso";
const filtroFaseName = "IdFaseProcesso";

interface OmnijusFiltroMomentoProps {
    hideLabel?: boolean;
    name?: string;
    label?: string;
    reloadOnChangeNameRito?: string;
    reloadOnChangeNameFase?: string;
}

export const OmnijusFiltroMomento = (props: OmnijusFiltroMomentoProps) => {
    const formikContext = useFormikContext<any>();
    const { setFieldValue } = formikContext;
    const isFirstRun = useRef(true);
    const name = props.name || "IdMomentoProcesso";
    const idRito = formikContext.values[props.reloadOnChangeNameRito || filtroRitoName];
    const idFase = formikContext.values[props.reloadOnChangeNameFase || filtroFaseName];
    const [promiseOpcoesMomento, setPromiseOpcoesMomento] = useState<Promise<FiltroMomento[] | undefined>>();

    useEffect(() => {
        isFirstRun.current || setFieldValue(name, undefined);
        isFirstRun.current = false;
        setPromiseOpcoesMomento(ProcessoFiltroService.listarMomento(idRito, idFase));
    }, [idRito, idFase, props.reloadOnChangeNameRito, props.reloadOnChangeNameFase, name, setFieldValue]);

    return (
        <Loading promise={promiseOpcoesMomento}>
            {(opcoesMomento) => (
                <OmnijusSelectField
                    name={name}
                    label={props.hideLabel ? undefined : props.label || "Momento"}
                    options={opcoesMomento?.map((o) => ({
                        value: o.id.toString(),
                        label: o.value.toString(),
                    }))}
                />
            )}
        </Loading>
    );
};
