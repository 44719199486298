import { Copiavel, Loading, OmnijusCard } from "@omnijus/common";
import { showToast } from "../../../lib/toast";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { AudienciaService } from "../../../services/audiencia/audiencia-service";
import { DocumentoAudiencia } from "../../../services/audiencia/models/documento-audiencia";
import styles from "./detalhamento-audiencia.module.scss";

interface DetalhamentoAudienciaDocumentosProps {
    idAudiencia: string;
}

export const DetalhamentoAudienciaDocumentos = (props: DetalhamentoAudienciaDocumentosProps) => {
    const [promise, setPromise] = useState<Promise<Array<DocumentoAudiencia> | undefined>>();

    useEffect(() => {
        setPromise(AudienciaService.obterDocumentos(props.idAudiencia));
    }, [props.idAudiencia]);

    return (
        <OmnijusCard
            header={<h3>Documentos</h3>}
            body={
                <Loading promise={promise}>
                    {(documentos) =>
                        documentos === undefined ? (
                            <p>Não encontrado</p>
                        ) : (
                            <DataTable
                                data={documentos}
                                noHeader={true}
                                className={styles["documentos"]}
                                noDataComponent="Sem documentos"
                                columns={[
                                    {
                                        name: "Tipo",
                                        selector: (doc) => doc.descricaoTipo,
                                        sortable: true,
                                    },
                                    {
                                        name: "Link",
                                        selector: (doc) => (
                                            <Copiavel
                                                informacaoCopiavel={doc.link}
                                                mensagem="Link do documento copiado"
                                                onCopiado={() => showToast("Link do documento copiado")}
                                            />
                                        ),
                                        sortable: true,
                                    },
                                ]}
                            />
                        )
                    }
                </Loading>
            }
        />
    );
};
