import { Loading, OmnijusSelectField } from "@omnijus/common";
import React, { useEffect, useState } from "react";
import { listarModulos } from "../../../services/configuracoes/modulo/modulo-service";
import { ModuloViewModel } from "../../../services/configuracoes/modulo/modulo-viewmodel";

interface OmnijusFiltroModuloProps {
    name?: string;
    label?: string;
    isDisabled?: boolean;
    isMulti?: boolean;
}

export const OmnijusFiltroModulo = (props: OmnijusFiltroModuloProps) => {
    const [promise, setPromise] = useState<Promise<ModuloViewModel[] | undefined>>();

    useEffect(() => {
        setPromise(listarModulos());
    }, []);

    return (
        <Loading promise={promise}>
            {(modulos) => (
                <OmnijusSelectField
                    isMulti={props.isMulti}
                    name={props.name || "idModulo"}
                    label={props.label || "Módulo"}
                    isDisabled={props.isDisabled}
                    options={modulos?.map((o) => ({
                        value: o.id.toString(),
                        label: o.descricao.toString(),
                    }))}
                />
            )}
        </Loading>
    );
};
