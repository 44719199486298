import React from "react";
import styles from "./campo-detalhamento.module.scss";

interface CampoDetalhamentoProps {
    label: string;
    value: any;
}

export const CampoDetalhamento = (props: CampoDetalhamentoProps) => {
    return (
        <div className={styles["campo"]}>
            <p className={styles["label"]}>{props.label}</p>
            <p className={styles["value"]}>{props.value}</p>
        </div>
    );
};
