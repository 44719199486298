import { CardFiltros, Loading, OmnijusCard, OmnijusTextField } from "@omnijus/common";
import { format } from "date-fns";
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import { AnomaliaService } from "../../services/anomalia/anomalia-service";
import { AnomaliaListada } from "../../services/anomalia/models/anomalia-listada";
import { FiltroConsultaAnomalias } from "../../services/anomalia/models/filtro-consulta-anomalias";
import { OmnijusCampoNumeroProcesso } from "../../shared/form/custom-fields/omnijus-campo-numero-processo";
import { OmnijusFiltroArea } from "../../shared/form/custom-fields/omnijus-filtro-area";
import { OmnijusFiltroCliente } from "../../shared/form/custom-fields/omnijus-filtro-cliente";
import { OmnijusFiltroCpfCnpj } from "../../shared/form/custom-fields/omnijus-filtro-cpf-cnpj";
import { OmnijusFiltroRito } from "../../shared/form/custom-fields/omnijus-filtro-rito";
import { OmnijusFiltroTribunal } from "../../shared/form/custom-fields/omnijus-filtro-tribunal";
import { OmnijusDateField } from "../../shared/form/fields/omnijus-date-field";
import { object as YupObject } from "yup";
import styles from "./anomalias.module.scss";

export const Anomalias = () => {
    const history = useHistory();
    const [promise, setPromise] = useState<Promise<Array<AnomaliaListada> | undefined>>();

    return (
        <div className={styles["consulta-anomalias"]}>
            <h2>Consulta Anomalia</h2>
            <CardFiltros
                textoBotao="Filtrar"
                onBuscar={async (values) => setPromise(AnomaliaService.consultar(values))}
                initialValues={{
                    idCliente: undefined,
                    numeroProcesso: "",
                    nomeAutor: "",
                    documentoAutor: "",
                    idArea: undefined,
                    idRito: undefined,
                    nomeMovimento: "",
                    idTipo: undefined,
                    idOrgao: undefined,
                    dataCriacao: undefined,
                    dataFinalizacao: undefined,
                    dataMovimentoGerador: undefined,
                    prazo: undefined,
                    idUserFinalizacao: undefined,
                } as FiltroConsultaAnomalias}
                botaoFiltrarInline
                validationSchema={YupObject().shape({
                })}
            >
                <OmnijusFiltroCliente name="idCliente" label="Cliente" />
                <OmnijusCampoNumeroProcesso name="numeroProcesso" label="Nº do Processo" />
                <OmnijusTextField name="nomeAutor" label="Nome do Autor" />
                <OmnijusFiltroCpfCnpj name="documentoAutor" label="Documento do Autor" />
                <OmnijusFiltroArea name="idArea" />
                <OmnijusFiltroRito name="idRito" reloadOnChangeName="idArea" />
                <OmnijusTextField name="nomeMovimento" label="Nome do Movimento" />
                <OmnijusFiltroTribunal name="idOrgao" />
                <OmnijusDateField name="dataCriacao" label="Data da Criação" />
                <OmnijusDateField name="dataFinalizacao" label="Data da Finalização" />
                <OmnijusDateField name="dataMovimentoGerador" label="Data do Movimento" />
                <OmnijusDateField name="prazo" label="Prazo" />
            </CardFiltros>
            {promise ? (
                <Loading promise={promise}>
                    {(anomalias) => (
                        <OmnijusCard
                            body={
                                <DataTable
                                    noHeader={true}
                                    customStyles={{
                                        rows: {
                                            style: {
                                                cursor: "pointer",
                                            },
                                        },
                                    }}
                                    pagination={true}
                                    paginationPerPage={20}
                                    noDataComponent={<p>A consulta não retornou registros</p>}
                                    data={anomalias || []}
                                    onRowClicked={(row) => history.push(`/anomalia/${row.id}`)}
                                    columns={[
                                        {
                                            name: "Cliente",
                                            minWidth: "200px",
                                            selector: (row) => row.nomeCliente || "-",
                                            sortable: true,
                                        },
                                        {
                                            name: "Nº Processo",
                                            minWidth: "200px",
                                            selector: (row) => row.numeroProcesso || "-",
                                            sortable: true,
                                        },
                                        {
                                            name: "Parte Autora",
                                            minWidth: "200px",
                                            selector: (row) => row.nomeAutor || "-",
                                            sortable: true,
                                        },
                                        {
                                            name: "Documento Autor",
                                            minWidth: "200px",
                                            selector: (row) => row.documentoAutor || "-",
                                            sortable: true,
                                        },
                                        {
                                            name: "Área/Rito",
                                            minWidth: "200px",
                                            selector: (row) => row.descricaoArea && row.descricaoRito ?
                                                `${row.descricaoArea}/${row.descricaoRito}`
                                                : "-",
                                            sortable: true,
                                        },
                                        {
                                            name: "Nome do Movimento",
                                            minWidth: "235px",
                                            selector: (row) => row.nomeMovimento || "-",
                                            sortable: true,
                                        },
                                        {
                                            name: "Tipo da Anomalia",
                                            minWidth: "235px",
                                            selector: (row) => row.descricaoTipoAnomalia,
                                            sortable: true,
                                        },
                                        {
                                            name: "Tribunal",
                                            minWidth: "200px",
                                            selector: (row) => row.descricaoOrgao || "-",
                                            sortable: true,
                                        },
                                        {
                                            name: "Data Criação",
                                            minWidth: "200px",
                                            selector: (row) => format(new Date(row.dataCriacao), "dd/MM/yyyy HH:mm"),
                                            sortable: true,
                                        },
                                        {
                                            name: "Data Finalização",
                                            minWidth: "200px",
                                            selector: (row) => row.dataFinalizacao ?
                                                format(new Date(row.dataFinalizacao), "dd/MM/yyyy HH:mm")
                                                : "-",
                                            sortable: true,
                                        },
                                        {
                                            name: "Data Movimento",
                                            minWidth: "200px",
                                            selector: (row) => row.dataMovimentoGerador ?
                                                format(new Date(row.dataMovimentoGerador), "dd/MM/yyyy HH:mm")
                                                : "-",
                                            sortable: true,
                                        },
                                        {
                                            name: "Prazo",
                                            minWidth: "200px",
                                            selector: (row) => format(new Date(row.prazo), "dd/MM/yyyy HH:mm"),
                                            sortable: true,
                                        },
                                        {
                                            name: "Usuário Finalizador Anomalia",
                                            minWidth: "200px",
                                            selector: (row) => row.nomeUsuarioFinalizacao,
                                            sortable: true,
                                        }
                                    ]}
                                />
                            }
                        />
                    )}
                </Loading>
            ) : (
                <p className="text-center">Utilize os filtros para realizar uma nova busca!</p>
            )}
        </div>
    );
};
