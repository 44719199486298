import { ButtonDownload, OmnijusCard, RequestApi, SelectObject } from "@omnijus/common";
import React from "react";

const style = {
    display: "grid",
    gap: "1rem",
    gridTemplateColumns: "10fr 2fr",
    fileName: {
        alignSelf: "center",
    },
    downloadButton: {
        justifySelf: "right",
    },
};

interface SelectDocumentoProps {
    documentoSelecionado: SelectObject;
    iframeStyle?: React.CSSProperties;
}

export function PdfViewer(props: SelectDocumentoProps) {
    return (
        <OmnijusCard
            body={
                <>
                    <div style={style}>
                        <div style={style.fileName}>
                            {props.documentoSelecionado?.label ||
                                "Clique no título de algum documento para visualizá-lo aqui"}
                        </div>
                        <div style={style.downloadButton}>
                            <ButtonDownload
                                onClick={() => {
                                    const urlSegments = props.documentoSelecionado.value.split("/");
                                    RequestApi.downloadExternal(
                                        props.documentoSelecionado.value,
                                        urlSegments[urlSegments.length - 1]
                                    );
                                }}
                            >
                                Download
                            </ButtonDownload>
                        </div>
                    </div>
                    <iframe
                        width="100%"
                        height="100%"
                        style={props.iframeStyle || { minHeight: "60rem", marginTop: "1rem" }}
                        key={props.documentoSelecionado.value.toString()}
                        title="Documento"
                        src={`/pdfjs/web/viewer.html?v=1&file=${encodeURIComponent(props.documentoSelecionado.value)}`}
                    />
                </>
            }
        />
    );
}
