import { Back, ButtonPrimary, Loading } from "@omnijus/common";
import { MovimentosProcesso } from "../../shared/movimentos-processo/movimentos-processo";
import { CabecalhoProcesso } from "../../pages/processos/detalhes-processo/detalhes-cards/cabecalho-processo";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DetalheProcesso } from "../../services/processo/models/detalhe-processo";
import { ProcessoService } from "../../services/processo/processo-service";
import styles from "./atualizacao-processo.module.scss";
import { FormAtualizacaoFaseMomento } from "./atualizacao-fase-momento/form-atualizacao-fase-momento";
import { FormAtualizacaoStatus } from "./form-atualizacao-status";
import { exibirModalAdicaoMovimento } from "../../pages/atualizacao-processos/adicao-movimento/modal-adicao-movimento";
import { Movimento } from "../../services/agendamento/models/movimento";

const BotaoAdicionarMovimento = ({
    numeroProcesso,
    onAdicionaMovimentos,
}: {
    numeroProcesso: string;
    onAdicionaMovimentos?: () => void;
}) => {
    return (
        <ButtonPrimary
            onClick={async () => {
                const adicionouMovimento = await exibirModalAdicaoMovimento(numeroProcesso);
                if (adicionouMovimento) {
                    onAdicionaMovimentos && onAdicionaMovimentos();
                }
            }}
        >
            Adicionar Movimento
        </ButtonPrimary>
    );
};

export const AtualizacaoProcesso = () => {
    const { numeroProcesso } = useParams<{ numeroProcesso: string }>();
    const [promiseDetalheProcesso, setPromiseDetalheProcesso] = useState<Promise<DetalheProcesso | undefined>>();
    const [promiseMovimentos, setPromiseMovimentos] = useState<Promise<Movimento[]>>();

    const buscaDetalhesProcesso = useCallback(() => {
        if (numeroProcesso?.length === 25) {
            setPromiseDetalheProcesso(ProcessoService.obterDetalhesProcesso(numeroProcesso));
        } else {
            setPromiseDetalheProcesso(Promise.reject("Número de processo inválido"));
        }
    }, [numeroProcesso]);

    useEffect(() => {
        buscaDetalhesProcesso();
    }, [buscaDetalhesProcesso]);

    return (
        <div className={styles.atualizacaoProcesso}>
            <Back />
            <Loading promise={promiseDetalheProcesso}>
                {(detalheProcesso) =>
                    detalheProcesso ? (
                        <>
                            <div className={styles.cabecalho}>
                                <CabecalhoProcesso processo={detalheProcesso} />
                                <Loading promise={promiseMovimentos || Promise.resolve(undefined)}>
                                    {(movimentos) => (
                                        <MovimentosProcesso
                                            idProcesso={detalheProcesso.idProcesso}
                                            numeroProcesso={detalheProcesso.numeroProcesso}
                                            exibirBotaoUpload
                                            movimentos={movimentos}
                                            actions={
                                                <BotaoAdicionarMovimento
                                                    onAdicionaMovimentos={() =>
                                                        setPromiseMovimentos(
                                                            ProcessoService.obterMovimentos(detalheProcesso.idProcesso)
                                                        )
                                                    }
                                                    numeroProcesso={detalheProcesso.numeroProcesso}
                                                />
                                            }
                                        />
                                    )}
                                </Loading>
                            </div>
                            <FormAtualizacaoFaseMomento
                                detalheProcesso={detalheProcesso}
                                onSubmit={buscaDetalhesProcesso}
                            />
                            <FormAtualizacaoStatus detalheProcesso={detalheProcesso} onSubmit={buscaDetalhesProcesso} />
                        </>
                    ) : (
                        <div>Não foi possível carregar as informações do processo</div>
                    )
                }
            </Loading>
        </div>
    );
};
