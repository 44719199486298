import { Loading } from "@omnijus/common";
import React, { useEffect, useState } from "react";
import { FiltrosAudienciaService } from "../../../services/filtros/filtros-audiencia-service";
import { FiltroStatusAudiencia } from "../../../services/filtros/models/filtro-status-audiencia";
import { OmnijusSelectField } from "../../../shared/form/fields/omnijus-select-field";

interface OmnijusFiltroStatusAudienciaProps {
    hideLabel?: boolean;
    name?: string;
    label?: string;
    isMulti?: boolean;
}

export const OmnijusFiltroStatusAudiencia = (props: OmnijusFiltroStatusAudienciaProps) => {
    const [promise, setPromise] = useState<Promise<Array<FiltroStatusAudiencia> | undefined>>();
    const name = props.name || "idStatusProcessoAudiencia"

    useEffect(() => {
        setPromise(FiltrosAudienciaService.listarStatusAudiencia());
    }, []);

    return (
        <Loading promise={promise}>
            {(opcoes) => (
                <OmnijusSelectField
                    name={name}
                    label={props.hideLabel ? undefined : props.label || "Status Audiência"}
                    isMulti={props.isMulti}
                    options={opcoes
                        ?.map((o) => ({
                            value: o.id.toString(),
                            label: o.value,
                        }))
                        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
                />
            )}
        </Loading>
    );
};
