import { ActionFeedback, Back, ButtonPrimary, Loading, OmnijusCard, OmnijusTextAreaField } from "@omnijus/common";
import { ReactComponent as Checked } from "../../../assets/images/checkmark-icon.svg";
import { ReactComponent as ExclamationOutlined } from "../../../assets/images/error-icon-outline.svg";
import { format } from "date-fns";
import { Form, Formik } from "formik";
import { MovimentosProcesso } from "../../../shared/movimentos-processo/movimentos-processo";
import { CabecalhoProcesso } from "../../../pages/processos/detalhes-processo/detalhes-cards/cabecalho-processo";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AnomaliaService } from "../../../services/anomalia/anomalia-service";
import { AtualizacaoAnotacao } from "../../../services/anomalia/models/atualizacao-anotacao";
import { DetalheAnomalia } from "../../../services/anomalia/models/detalhe-anomalia";
import { IdTipoAnomalia } from "../../../services/anomalia/models/id-tipo-anomalia";
import { DetalheProcesso } from "../../../services/processo/models/detalhe-processo";
import { ProcessoService } from "../../../services/processo/processo-service";
import { ButtonPrimarySmall } from "../../../shared/buttons/button-primary/button-primary";
import styles from "./detalhamento-anomalia.module.scss";
import { DetalhamentoFaltaPedidoLiminar } from "./detalhamento-falta-pedido-liminar";
import { DetalhamentoOutrosPedidosLiminar } from "./detalhamento-outros-pedidos-liminar";

export const DetalhamentoAnomalia = () => {
    const [promise, setPromise] = useState<Promise<DetalheAnomalia | undefined>>();
    const [promiseDetalheProcesso, setPromiseDetalheProcesso] = useState<Promise<DetalheProcesso | undefined>>();
    const { id } = useParams<{ id: string }>();
    const fetchAnomalia = useCallback(() => {
        setPromise(
            AnomaliaService.obter(id).then((anomalia) => {
                if (anomalia?.movimento?.numeroProcesso) {
                    setPromiseDetalheProcesso(
                        ProcessoService.obterDetalhesProcesso(anomalia.movimento?.numeroProcesso)
                    );
                }

                return anomalia;
            })
        );
    }, [id]);

    useEffect(() => {
        fetchAnomalia();
    }, [fetchAnomalia]);

    return (
        <>
            <Back />
            <h2>Gestão de Anomalia</h2>
            <Loading promise={promise}>
                {(anomalia) =>
                    anomalia ? (
                        <div className={styles["detalhamento-anomalia"]}>
                            {promiseDetalheProcesso ? (
                                <Loading promise={promiseDetalheProcesso} className={styles["cabecalho-processo"]}>
                                    {(detalheProcesso) =>
                                        detalheProcesso !== undefined ? (
                                            <>
                                                <CabecalhoProcesso processo={detalheProcesso} />
                                                <MovimentosProcesso
                                                    idProcesso={detalheProcesso.idProcesso}
                                                    numeroProcesso={detalheProcesso.numeroProcesso}
                                                />
                                            </>
                                        ) : null
                                    }
                                </Loading>
                            ) : null}
                            <OmnijusCard
                                header={<h3>Anotações</h3>}
                                body={
                                    <Formik
                                        initialValues={
                                            {
                                                anotacao: anomalia.anotacao,
                                            } as AtualizacaoAnotacao
                                        }
                                        onSubmit={async (values) => {
                                            await ActionFeedback.processing({
                                                title: "Salvando anotação",
                                                execution: AnomaliaService.atualizarAnotacao(anomalia.id, values),
                                            });

                                            ActionFeedback.info({
                                                title: "Anotação salva com sucesso",
                                            });
                                        }}
                                    >
                                        <Form className={styles["anotacoes"]}>
                                            <OmnijusTextAreaField name="anotacao" label="Anotações" />
                                            <ButtonPrimary>Salvar anotações</ButtonPrimary>
                                        </Form>
                                    </Formik>
                                }
                            />
                            <OmnijusCard
                                header={<h3>Ações</h3>}
                                className={styles["acoes"]}
                                body={
                                    <>
                                        {anomalia.tipoAnomalia.id === IdTipoAnomalia.FaltaPedidoLiminar ? (
                                            <DetalhamentoFaltaPedidoLiminar
                                                detalhe={anomalia.faltaPedidoLiminar!}
                                                onResolved={fetchAnomalia}
                                            />
                                        ) : anomalia.tipoAnomalia.id === IdTipoAnomalia.OutrosPedidosLiminar ? (
                                            <DetalhamentoOutrosPedidosLiminar
                                                detalhe={anomalia.outrosPedidosLiminar!}
                                                onResolved={fetchAnomalia}
                                            />
                                        ) : null}
                                        <div className={styles["acao-anomalia"]}>
                                            <h4>Sobre Anomalia</h4>
                                            {anomalia.dataHoraFinalizacao ? (
                                                <div className={styles["titulo-acao"]}>
                                                    <Checked className={styles["icone-acao-tomada"]} />
                                                    <p className={styles["texto"]}>
                                                        O usuário{" "}
                                                        <span className={styles["highlight"]}>
                                                            {anomalia.nomeUserFinalizacao}
                                                        </span>
                                                        &nbsp; tomou a ação de{" "}
                                                        <span className={styles["highlight"]}>Finalizar anomalia</span>
                                                        &nbsp; em{" "}
                                                        <span className={styles["highlight"]}>
                                                            {format(
                                                                new Date(anomalia.dataHoraFinalizacao),
                                                                "dd/MM/yyyy HH:mm"
                                                            )}
                                                        </span>
                                                    </p>
                                                </div>
                                            ) : (
                                                <div className={styles["titulo-acao"]}>
                                                    <ExclamationOutlined className={styles["icone-acao-nao-tomada"]} />
                                                    <p className={styles["texto"]}>Nenhuma ação tomada até o momento</p>
                                                    <div className={styles["acoes"]}>
                                                        <ButtonPrimarySmall
                                                            onClick={async () => {
                                                                await ActionFeedback.processing({
                                                                    title: "Finalizando anomalia",
                                                                    execution: AnomaliaService.finalizacao(anomalia.id),
                                                                });

                                                                await ActionFeedback.info({
                                                                    title: "Anomalia finalizada com sucesso",
                                                                });

                                                                fetchAnomalia();
                                                            }}
                                                        >
                                                            Resolver
                                                        </ButtonPrimarySmall>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                }
                            />
                        </div>
                    ) : (
                        <p>Anomalia inexistente ou sem acesso</p>
                    )
                }
            </Loading>
        </>
    );
};
