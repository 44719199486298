import { Loading, OmnijusSelectField } from "@omnijus/common";
import React, { useEffect, useState } from "react";
import { FiltrosClienteService } from "../../../services/filtros/filtros-cliente-service";
import { FiltroCliente } from "../../../services/filtros/models/filtro-cliente";
// import { OmnijusSelectField } from "../fields/omnijus-select-field";

interface OmnijusFiltroClienteProps {
    hideLabel?: boolean;
    name?: string;
    label?: string;
    isDisabled?: boolean;
    isMulti?: boolean;
}

export const OmnijusFiltroCliente = (props: OmnijusFiltroClienteProps) => {
    const [promiseOpcoesCliente, setPromiseOpcoesCliente] = useState<Promise<FiltroCliente[] | undefined>>();

    useEffect(() => {
        setPromiseOpcoesCliente(FiltrosClienteService.listarClientes());
    }, []);

    return (
        <Loading promise={promiseOpcoesCliente}>
            {(opcoesArea) => (
                <OmnijusSelectField
                    isMulti={props.isMulti}
                    isDisabled={props.isDisabled}
                    name={props.name || "IdCliente"}
                    label={props.hideLabel ? undefined : props.label || "Cliente"}
                    options={opcoesArea
                        ?.map((o) => ({
                            value: o.id,
                            label: o.razaoSocial?.toUpperCase() || "",
                        }))
                        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
                />
            )}
        </Loading>
    );
};
