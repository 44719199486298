import { RequestApi } from "@omnijus/common";
import { DetalheConsultaAudiencias } from "../../services/audiencia/models/detalhe-consulta-audiencias";
import { CancelamentoAudiencia } from "./models/cancelamento-audiencia";
import { DadosParceiroAudiencia } from "./models/dados-parceiro-audiencia";
import { DetalheAudiencia } from "./models/detalhe-audiencia";
import { DocumentoAudiencia } from "./models/documento-audiencia";
import { EnderecamentoAudiencia } from "./models/enderecamento-audiencia";
import { ExecutorAudiencia } from "./models/executor-audiencia";
import { FiltroConsultaAudiencias } from "./models/filtro-consulta-audiencias";
import { InformacoesPoliticaEProcesso } from "./models/informacoes-politica-processo";
import { LinhaTempoAudiencia } from "./models/linha-tempo-audiencia";
import { ComboMotivoCancelamentoAudiencia } from "./models/combo-motivo-cancelamento-audiencia";
import { ObterInformacoesPoliticaProcessoCommand } from "./models/obter-informacoes-politica-processo-command";
import { ParametroEdicaoAudiencia } from "./models/parametro-edicao-audiencia";
import { ParametroInclusaoAudiencia } from "./models/parametro-inclusao-audiencia";
import { CLIENT_ID } from "../../services/client-id";

const processoBaseUrl = process.env.REACT_APP_BFF_OPERACIONAL_API_URL + "/api/Processo/v1/ProcessoAudiencia";
const audienciaBaseUrl = process.env.REACT_APP_BFF_OPERACIONAL_API_URL + "/api/Processo/v1/ProcessoAudiencia";
const motivoCancelamentoAudienciaUrl =
    process.env.REACT_APP_BFF_OPERACIONAL_API_URL + "/api/Processo/v1/MotivoCancelamentoAudiencia";

const requestApi = new RequestApi(CLIENT_ID);

export const AudienciaService = {
    consultar: async (filtro: FiltroConsultaAudiencias) => {
        const url = `${processoBaseUrl}/ConsultaAudiencias`;

        let audiencias = await requestApi.post<Array<DetalheConsultaAudiencias>>(url, filtro);

        if (audiencias) {
            audiencias = audiencias.map((audiencia) => ({
                ...audiencia,
                dataHoraAudiencia: new Date(audiencia.dataHoraAudiencia),
            }));
        }

        return audiencias;
    },
    obterInformacoesPoliticaProcesso: async (command: ObterInformacoesPoliticaProcessoCommand) => {
        const url = new URL(processoBaseUrl + "/ObterInformacoesPoliticaEProcesso");
        const query = RequestApi.objectToQueryString(command);

        return await requestApi.get<InformacoesPoliticaEProcesso>(`${url.toString()}?${query}`);
    },
    criar: async (parametros: ParametroInclusaoAudiencia) => {
        await requestApi.post(processoBaseUrl, parametros);
    },
    editar: async (parametros: ParametroEdicaoAudiencia) => {
        await requestApi.put(processoBaseUrl, parametros);
    },
    obter: async (id: string) => {
        const detalhe = await requestApi.get<DetalheAudiencia>(processoBaseUrl + `/${id}/Detalhe`);

        if (detalhe) {
            detalhe.dataHora = new Date(detalhe?.dataHora);
        }

        return detalhe;
    },
    obterEnderecamento: async (id: string) => {
        return await requestApi.get<EnderecamentoAudiencia>(processoBaseUrl + `/${id}/Enderecamento`);
    },
    obterDocumentos: async (id: string) => {
        return await requestApi.get<Array<DocumentoAudiencia>>(processoBaseUrl + `/${id}/Documentos`);
    },
    obterDetalheCancelamento: async (id: string) => {
        return await requestApi.get<CancelamentoAudiencia>(processoBaseUrl + `/${id}/DetalheCancelamento`);
    },
    obterLinhaTempo: async (id: string) => {
        const linhaTempo = await requestApi.get<LinhaTempoAudiencia>(processoBaseUrl + `/${id}/LinhaTempo`);

        if (linhaTempo) {
            linhaTempo.aprovacaoDocumentos = linhaTempo.aprovacaoDocumentos && new Date(linhaTempo.aprovacaoDocumentos);
            linhaTempo.avaliacaoExecutores = linhaTempo.avaliacaoExecutores && new Date(linhaTempo.avaliacaoExecutores);
            linhaTempo.criacao = linhaTempo.criacao && new Date(linhaTempo.criacao);
            linhaTempo.geracaoContestacao = linhaTempo.geracaoContestacao && new Date(linhaTempo.geracaoContestacao);
            linhaTempo.geracaoDocumentos = linhaTempo.geracaoDocumentos && new Date(linhaTempo.geracaoDocumentos);
            linhaTempo.integracaoParceiro = linhaTempo.integracaoParceiro && new Date(linhaTempo.integracaoParceiro);
            linhaTempo.realizacaoAudiencia = linhaTempo.realizacaoAudiencia && new Date(linhaTempo.realizacaoAudiencia);
            linhaTempo.recebimentoAta = linhaTempo.recebimentoAta && new Date(linhaTempo.recebimentoAta);
        }

        return linhaTempo;
    },
    obterDadosSolicitacaoParceiro: async (id: string) => {
        return await requestApi.get<DadosParceiroAudiencia>(processoBaseUrl + `/${id}/DadosSolicitacaoParceiro`);
    },
    obterExecutores: async (id: string) => {
        return await requestApi.get<Array<ExecutorAudiencia>>(audienciaBaseUrl + `/${id}/Executores`);
    },
    ListarMotivosCancelamentoAudiencia: async () => {
        return await requestApi.get<ComboMotivoCancelamentoAudiencia[]>(
            `${motivoCancelamentoAudienciaUrl}/ListarMotivos`
        );
    },
    CancelarAudiencia: async ({
        idProcessoAudiencia,
        idMotivoCancelamentoAudiencia,
    }: {
        idProcessoAudiencia: string;
        idMotivoCancelamentoAudiencia: number;
    }) => {
        return await requestApi.post(
            `${processoBaseUrl}/CancelarAudiencia`,
            {
                idProcessoAudiencia,
                idMotivoCancelamentoAudiencia,
            },
            (res, json) => ({
                res: res,
                json: json,
            })
        );
    },
    RedesignarAudiencia: async ({
        idProcessoAudiencia,
        novaDataAudiencia,
    }: {
        idProcessoAudiencia: string;
        novaDataAudiencia: Date;
    }) => {
        return await requestApi.post(
            `${processoBaseUrl}/RedesignarAudiencia`,
            {
                idProcessoAudiencia,
                novaDataAudiencia,
            },
            (res, json) => ({
                res: res,
                json: json,
            })
        );
    },
};
