import { Loading } from "@omnijus/common";
import React, { useState } from "react";
import { EdicaoAgendamentoService } from "../../../services/agendamento/edicao-agendamento-service";
import { AgendamentoProcesso } from "../../../services/agendamento/models/agendamento-processo";
import { OmnijusDateField } from "../../../shared/form/fields/omnijus-date-field";

interface EdicaoPrazoFatalProps {
    agendamento: AgendamentoProcesso;
}

export const EdicaoPrazoFatal = (props: EdicaoPrazoFatalProps) => {
    const [promise, setPromise] = useState(Promise.resolve());
    const [value, setValue] = useState<Date | null>(new Date(props.agendamento.dataPrazoFatal));

    return (
        <Loading promise={promise}>
            {() => (
                <OmnijusDateField
                    label="Prazo Fatal"
                    name="prazoFatal"
                    isClearable={false}
                    value={value}
                    onChange={(value) => {
                        if (value) {
                            setValue(value);
                            setPromise(
                                EdicaoAgendamentoService.atualizarPrazoFatal(
                                    props.agendamento.idAgendamentoProcesso,
                                    value
                                )
                            );
                        } else {
                            setValue(null);
                        }
                    }}
                />
            )}
        </Loading>
    );
};
