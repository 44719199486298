import { ActionFeedback, ButtonSecondary, OmnijusCard, OmnijusTextField } from "@omnijus/common";
import { Form, Formik } from "formik";
import React, { useLayoutEffect, useReducer } from "react";
import { OmnijusFiltroProvidencia } from "../../shared/form/custom-fields/omnijus-filtro-providencia";
import { OmnijusDateField } from "../../shared/form/fields/omnijus-date-field";
import styles from "./agendamentos.module.scss";
import { AgendamentoProcessoService } from "../../services/agendamento/agendamento-processo-service";
import { DetalheProcesso } from "../../services/processo/models/detalhe-processo";
import { date as YupDate, object as YupObject, number as YupNumber } from "yup";
import { PastaCliente } from "../../services/agendamento/models/pasta-cliente";
import { IncluirAgendamentoProcessoCommand } from "../../services/agendamento/models/incluir-agendamento-processo";
import { ButtonPrimary } from "../../shared/form/fields/omnijus-button-primary";
import { AgendamentoProcesso } from "../../services/agendamento/models/agendamento-processo";
import { GridAgendamentos } from "./grid-agendamentos";
import { reducer } from "./reducer";
import { Agendamento } from "../../services/agendamento/models/agendamento";
import { IdStatusAgendamento } from "../../services/agendamento/models/id-status-agendamento";
import { Providencia } from "../../services/providencia/models/providencia";
import { OmnijusMovimentosProcesso } from "../../shared/form/custom-fields/omnijus-movimentos-processo";
import { Movimento } from "../../services/agendamento/models/movimento";
import { format } from "date-fns";

export const FormDadosAgendamento = ({
    detalheProcesso,
    setDetalheProcesso,
    providencias,
    pastaCliente,
    setPastaCliente,
    onCloseModal,
    idProcessoMovimento,
    movimentos,
}: {
    detalheProcesso?: DetalheProcesso;
    setDetalheProcesso: React.Dispatch<React.SetStateAction<DetalheProcesso | undefined>>;
    providencias?: Providencia[];
    pastaCliente: PastaCliente | undefined;
    setPastaCliente: React.Dispatch<React.SetStateAction<PastaCliente | undefined>>;
    onCloseModal: () => void;
    idProcessoMovimento?: string;
    movimentos?: Movimento[];
}) => {
    const handleSair = () => {
        setDetalheProcesso(undefined);
        setPastaCliente(undefined);

        dispatch({
            tipo: "limpar",
            agendamento: {} as Agendamento,
        });

        onCloseModal();
    };

    useLayoutEffect(() => {
        if (detalheProcesso && providencias && movimentos) {
            (async () => {
                const res = await AgendamentoProcessoService.listar({
                    numeroProcesso: detalheProcesso.numeroProcesso,
                    somenteNaoExcluidos: true,
                });

                const dadosIniciais =
                    res?.map((x: AgendamentoProcesso) => {
                        return {
                            idAgendamento: x.idAgendamentoProcesso,
                            dataPrazoFatal: x.dataPrazoFatal,
                            dataPrazoSeguranca: x.dataPrazoSeguranca,
                            idProcesso: detalheProcesso.idProcesso,
                            idProvidencia: x.idProvidencia,
                            idStatusAgendamento: x.idStatusAgendamento,
                            descricaoProvidencia: providencias.find((providencia) => providencia.id === x.idProvidencia)
                                ?.descricao,
                            idProcessoMovimento: x.idProcessoMovimento,
                            descricaoMovimento: movimentos.find(
                                (movimento) => movimento.idProcessoMovimento === x.idProcessoMovimento
                            )?.movimentacao,
                            descricaoPastaCliente: x.descricaoPastaCliente,
                        } as Agendamento;
                    }) || [];

                dispatch({
                    tipo: "redefinir",
                    agendamento: {} as Agendamento,
                    dadosIniciais: dadosIniciais,
                });
            })();
        }
    }, [detalheProcesso, movimentos, providencias]);

    const [agendamentos, dispatch] = useReducer(reducer, []);

    return detalheProcesso && providencias ? (
        <>
            <div>
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            descricaoPastaCliente: pastaCliente?.descricao,
                            idProcesso: detalheProcesso.idProcesso,
                            idProvidencia: undefined,
                            dataPrazoSeguranca: undefined,
                            dataPrazoFatal: undefined,
                            descricaoMotivoExclusao: undefined,
                            descricaoProvidencia: undefined,
                            idAgendamento: undefined,
                            idStatusAgendamento: undefined,
                            idProcessoMovimento: idProcessoMovimento,
                        } as Agendamento
                    }
                    onSubmit={(values, { resetForm }) => {
                        dispatch({
                            tipo: "adicionar",
                            agendamento: {
                                dataPrazoFatal: values.dataPrazoFatal,
                                dataPrazoSeguranca: values.dataPrazoSeguranca,
                                idProvidencia: values.idProvidencia,
                                idProcesso: detalheProcesso.idProcesso,
                                idStatusAgendamento: IdStatusAgendamento.EmAndamento,
                                descricaoProvidencia: providencias.find(
                                    (x) => values.idProvidencia && x.id === +values.idProvidencia
                                )?.descricao,
                                idProcessoMovimento: values.idProcessoMovimento,
                                descricaoMovimento: movimentos?.find(
                                    (x) =>
                                        values.idProcessoMovimento &&
                                        x.idProcessoMovimento === values.idProcessoMovimento
                                )?.movimentacao,
                            } as Agendamento,
                        });

                        if (!pastaCliente?.id && values.descricaoPastaCliente) {
                            setPastaCliente({
                                descricao: values.descricaoPastaCliente,
                            });
                        }

                        resetForm();
                    }}
                    validationSchema={YupObject().shape({
                        idProvidencia: YupNumber().required("Informe a providência"),
                        dataPrazoSeguranca: YupDate()
                            .required("Informe uma data")
                            .typeError("Data inválida")
                            .min(
                                new Date(
                                    new Date().getFullYear(),
                                    new Date().getMonth(),
                                    new Date().getDate(),
                                    0,
                                    0,
                                    0,
                                    0
                                ),
                                "Informe uma data maior ou igual a data de hoje"
                            ),
                        dataPrazoFatal: YupDate()
                            .required("Informe uma data")
                            .typeError("Data inválida")
                            .min(
                                new Date(
                                    new Date().getFullYear(),
                                    new Date().getMonth(),
                                    new Date().getDate(),
                                    0,
                                    0,
                                    0,
                                    0
                                ),
                                "Informe uma data maior ou igual a data de hoje"
                            ),
                    })}
                >
                    {(formik) => (
                        <Form className={`${styles.marginTop} ${styles.criarAgendamentos}`}>
                            <div>
                                <OmnijusCard
                                    body={
                                        <>
                                            <div className={styles.marginTop}>
                                                <OmnijusTextField
                                                    name="descricaoPastaCliente"
                                                    label="Pasta Cliente"
                                                    disabled={
                                                        pastaCliente?.id !== undefined ||
                                                        (pastaCliente?.descricao !== undefined &&
                                                            agendamentos.length > 0)
                                                    }
                                                    masks={[
                                                        {
                                                            case: "uppercase",
                                                            mask: "####################",
                                                            charType: "alphanumeric",
                                                        },
                                                    ]}
                                                />
                                            </div>
                                            <div className={styles.marginTop2}>
                                                <OmnijusFiltroProvidencia label="Providência" options={providencias} />
                                            </div>
                                            <div className={styles.marginTop2}>
                                                <OmnijusMovimentosProcesso
                                                    label="Movimento"
                                                    idProcesso={detalheProcesso.idProcesso}
                                                    idProcessoMovimento={idProcessoMovimento}
                                                    comboMovimentos={movimentos?.map((x) => {
                                                        return {
                                                            id: x.idProcessoMovimento,
                                                            value: `${format(new Date(x.data), "dd/MM/yyyy")} - ${
                                                                x.fase
                                                            } - ${x.movimentacao}`,
                                                        };
                                                    })}
                                                />
                                            </div>
                                            <div className={`${styles.criarAgendamentos} ${styles.marginTop2}`}>
                                                <OmnijusDateField
                                                    name="dataPrazoSeguranca"
                                                    label="Data Prazo Segurança"
                                                />
                                                <OmnijusDateField name="dataPrazoFatal" label="Data Prazo Fatal" />
                                            </div>

                                            <div className={`${styles.marginTop} ${styles.alignRight}`}>
                                                <ButtonPrimary type="submit">Adicionar</ButtonPrimary>
                                            </div>
                                        </>
                                    }
                                />
                            </div>

                            {agendamentos && (
                                <GridAgendamentos
                                    agendamentos={agendamentos}
                                    dispatch={(
                                        tipo: "marcarParaExclusao" | "remover" | "desfazerMarcacaoParaExclusao",
                                        agendamento: Agendamento,
                                        descricaoMotivoExclusao?: string
                                    ) => {
                                        dispatch({
                                            tipo: tipo,
                                            agendamento: agendamento,
                                            descricaoMotivoExclusao: descricaoMotivoExclusao,
                                        });
                                    }}
                                />
                            )}
                        </Form>
                    )}
                </Formik>
            </div>
            <div className={styles.alignRight}>
                {agendamentos.length > 0 && (
                    <ButtonPrimary
                        className={`${styles.marginTop} ${styles.marginRight} ${styles.botaoSalvar}`}
                        type="button"
                        onClick={async () => {
                            const command: IncluirAgendamentoProcessoCommand = {
                                idProcesso: detalheProcesso.idProcesso,
                                idPastaCliente: pastaCliente?.id,
                                descricaoPastaCliente: pastaCliente?.descricao,
                                agendamentos: agendamentos,
                            };

                            await ActionFeedback.processing({
                                title: "Processando...",
                                execution: AgendamentoProcessoService.incluir(command),
                                onError: async (error: any) => {
                                    return await ActionFeedback.error({
                                        icon: "error",
                                        html: error?.json?.erros
                                            ? error.json.erros.join("<br />")
                                            : error?.json?.title
                                            ? error?.json?.title
                                            : "Não foi possível salvar os agendamentos",
                                    });
                                },
                            });

                            await ActionFeedback.info({
                                text: "Agendamentos salvos com sucesso!",
                            });

                            handleSair();
                        }}
                    >
                        Salvar
                    </ButtonPrimary>
                )}
                <ButtonSecondary
                    className={styles.marginTop}
                    onClick={() => {
                        handleSair();
                    }}
                >
                    Sair
                </ButtonSecondary>
            </div>
        </>
    ) : (
        <div className={styles.alignRight}>
            <ButtonSecondary
                className={styles.marginTop}
                onClick={() => {
                    handleSair();
                }}
            >
                Sair
            </ButtonSecondary>
        </div>
    );
};
