import { Agendamento } from "services/agendamento/models/agendamento";

export function reducer(
    state: Agendamento[],
    action: {
        tipo: "adicionar" | "remover" | "limpar" | "redefinir" | "marcarParaExclusao" | "desfazerMarcacaoParaExclusao";
        agendamento: Agendamento;
        descricaoMotivoExclusao?: string;
        dadosIniciais?: Agendamento[];
    }
) {
    let _state: Agendamento[];
    let _agendamento: Agendamento | undefined;

    switch (action.tipo) {
        case "adicionar":
            return [{ ...action.agendamento }, ...state];

        case "marcarParaExclusao":
            _state = [...state];

            _agendamento = _state.find((agendamento) => agendamento === action.agendamento);

            if (_agendamento && action.descricaoMotivoExclusao) {
                _agendamento.descricaoMotivoExclusao = action.descricaoMotivoExclusao;
            }

            return _state;

        case "limpar":
            return [];

        case "redefinir":
            return action.dadosIniciais || [];

        case "remover":
            return state.filter((agendamento) => agendamento !== action.agendamento);

        case "desfazerMarcacaoParaExclusao":
            _state = [...state];

            _agendamento = _state.find((agendamento) => agendamento === action.agendamento);

            if (_agendamento) {
                _agendamento.descricaoMotivoExclusao = undefined;
            }

            return _state;

        default:
            return state;
    }
}
