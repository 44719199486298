import React from "react";
import { OmnijusRangeDateField } from "../../shared/form/fields/omnijus-range-date-field";
import { RelatorioAudienciasService } from "../../services/relatorio-audiencias/relatorio-audiencias-service";
import { date as YupDate, object as YupObject } from "yup";
import { format, addYears } from "date-fns";
import { ActionFeedback } from "@omnijus/common";
import { OmnijusCampoNumeroProcesso } from "../../shared/form/custom-fields/omnijus-campo-numero-processo";
import { OmnijusFiltroCliente } from "../../shared/form/custom-fields/omnijus-filtro-cliente";
import { CardFiltros, RequestApi } from "@omnijus/common";
import { CLIENT_ID } from "../../services/client-id";

type RelatorioAudienciasCommand = {
    clientes: string[];
    numeroProcesso: string;
    dataDe?: Date;
    dataAte?: Date;
};

const initialValues: RelatorioAudienciasCommand = {
    clientes: Array.of<string>(),
    numeroProcesso: "",
    dataDe: undefined,
    dataAte: undefined,
};

const requestApi = new RequestApi(CLIENT_ID);

export const AcompanhamentoAudiencias = () => {
    return (
        <div>
            <h2>Acompanhamento Audiências</h2>
            <CardFiltros
                textoBotao="Gerar"
                onBuscar={async (values: RelatorioAudienciasCommand) => {
                    const resposta = await ActionFeedback.processing({
                        title: "Gerando arquivo para download",
                        execution: RelatorioAudienciasService.relatorioAudiencias(values),
                        onError: () =>
                            ActionFeedback.error({
                                icon: "error",
                                titleText: "Ocorreu um erro durante a geração do arquivo.",
                            }),
                    });

                    if (resposta?.base64) {
                        await requestApi.download(
                            resposta.base64,
                            `AcompanhamentoAudiencias_${format(new Date(), "yyyyMMddHHmmss")}.xls`
                        );

                        ActionFeedback.info({ title: "Dados gerados com sucesso!" });
                    } else {
                        ActionFeedback.info({ title: "Nenhum registro encontrado para os parâmetros informados!" });
                    }
                }}
                initialValues={initialValues}
                botaoFiltrarInline
                validationSchema={YupObject().shape({
                    dataDe: YupDate().when("numeroProcesso", {
                        is: (numeroProcesso) => numeroProcesso?.length > 0,
                        then: YupDate()
                            .nullable()
                            .typeError("Data inválida")
                            .max(addYears(new Date(), 1), "A data não pode passar de 1 ano no futuro"),
                        otherwise: YupDate()
                            .required("Informe a data inicial")
                            .typeError("Data inválida")
                            .max(addYears(new Date(), 1), "A data não pode passar de 1 ano no futuro"),
                    }),
                    dataAte: YupDate().when("numeroProcesso", {
                        is: (numeroProcesso) => numeroProcesso?.length > 0,
                        otherwise: YupDate()
                            .required("Informe a data final")
                            .typeError("Data inválida")
                            .max(addYears(new Date(), 1), "A data não pode passar de 1 ano no futuro")
                            .test("maior", "A data final não pode ser anterior à data inicial", function (dataAte) {
                                const { dataDe } = this.parent;

                                if (dataAte && dataDe && dataAte < dataDe) {
                                    return false;
                                }

                                return true;
                            })
                            .test("datediff", "Diferença de datas deve ser de no máximo 3 meses", function (dataAte) {
                                const { dataDe } = this.parent;

                                if (dataAte && dataDe) {
                                    let timediff = dataAte.getTime() - (dataDe as Date).getTime();
                                    let diff = timediff / (1000 * 3600 * 24);
                                    return diff <= 90;
                                }

                                return true;
                            }),
                        then: YupDate()
                            .nullable()
                            .typeError("Data inválida")
                            .max(addYears(new Date(), 1), "A data não pode passar de 1 ano no futuro"),
                    }),
                })}
            >
                <OmnijusFiltroCliente name="clientes" label="Cliente" isMulti />
                <OmnijusCampoNumeroProcesso name="numeroProcesso" label="Nº do Processo" />
                <OmnijusRangeDateField nameDe="dataDe" nameAte="dataAte" label="Data da Audiência" />
            </CardFiltros>
        </div>
    );
};
