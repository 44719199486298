import { ButtonPrimary, ButtonSecondary, Loading, OmnijusSelectField } from "@omnijus/common";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { RotuloMovimento } from "../../../services/movimentacao-assistida/models/rotulo-movimento";
import Modal from "react-modal";
import styles from "../movimentacao-assistida.module.scss";
import { MovimentacaoAssistidaService } from "../../../services/movimentacao-assistida/movimentacao-assistida-service";

interface ModalRotulosProps {
    isModalOpen: boolean;
    onCloseModal: () => void;
    rotuloMovimento: RotuloMovimento;
    onSetRotulo: (rotulo: RotuloMovimento) => void;
}

export const ModalRotulos = ({ isModalOpen, onCloseModal, rotuloMovimento, onSetRotulo }: ModalRotulosProps) => {
    const [promiseRotulos, setPromiseRotulos] = useState<Promise<RotuloMovimento[] | undefined>>();

    useEffect(() => {
        setPromiseRotulos(MovimentacaoAssistidaService.listarRotulos());
    }, []);

    return (
        <>
            <Modal
                style={{
                    content: {
                        width: "50vw",
                        margin: "auto",
                        height: "45vh",
                    },
                }}
                isOpen={isModalOpen}
                onRequestClose={onCloseModal}
                shouldCloseOnEsc
                ariaHideApp={false}
            >
                <Loading promise={promiseRotulos}>
                    {(rotulos) =>
                        rotulos ? (
                            <Formik
                                initialValues={{
                                    idRotuloMovimento: rotuloMovimento.id,
                                }}
                                onSubmit={(values) => {
                                    const rotuloMovimento = rotulos.find(
                                        (rotulo) => rotulo.id === values.idRotuloMovimento
                                    );

                                    if (rotuloMovimento) {
                                        onSetRotulo(rotuloMovimento);
                                    }
                                    onCloseModal();
                                }}
                            >
                                {() => (
                                    <Form>
                                        <OmnijusSelectField
                                            name="idRotuloMovimento"
                                            label="Rótulo"
                                            options={rotulos
                                                .filter((r) => r.nome !== "NÃO ROTULADO")
                                                .map((r, index) => {
                                                    return {
                                                        label: r.nome,
                                                        value: r.id,
                                                        key: index,
                                                    };
                                                })}
                                        />

                                        <div className={styles.alignRight}>
                                            <ButtonPrimary
                                                className={`${styles.marginRight} ${styles.marginTop}`}
                                                type="submit"
                                            >
                                                Ok
                                            </ButtonPrimary>
                                            <ButtonSecondary className={styles.marginTop} onClick={onCloseModal}>
                                                Fechar
                                            </ButtonSecondary>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        ) : (
                            <div>Erro carregando rótulos</div>
                        )
                    }
                </Loading>
            </Modal>
        </>
    );
};
