import React from "react";
import { OmnijusFiltroStatusSolicitacao } from "../../shared/form/custom-fields/omnijus-filtro-status-solicitacao";
import { OmnijusFiltroAtividade } from "../../shared/form/custom-fields/omnijus-filtro-atividade";
import { OmnijusFiltroCliente } from "../../shared/form/custom-fields/omnijus-filtro-cliente";
import { OmnijusFiltroTemplate } from "../../shared/form/custom-fields/omnijus-filtro-template";
import { OmnijusCheckboxField } from "../../shared/form/fields/omnijus-checkbox-field";
import { OmnijusRangeDateField } from "../../shared/form/fields/omnijus-range-date-field";
import { ParametrosDetalhamentoAtividadesExecutores } from "../../services/solicitacao-atividade/solicitacao-atividade-service";
import { detalhamentoAtividadesExecutores } from "../../services/solicitacao-atividade/solicitacao-atividade-service";
import { date as YupDate, object as YupObject } from "yup";
import { format } from "date-fns";
import { ActionFeedback } from "@omnijus/common";
import { CardFiltros, RequestApi } from "@omnijus/common";
import { CLIENT_ID } from "../../services/client-id";

const requestApi = new RequestApi(CLIENT_ID);

export const DetalhamentoAtividadesExecutores = () => {
    return (
        <div>
            <h2>Detalhamento das Atividades e Executores</h2>
            <CardFiltros
                textoBotao="Gerar"
                onBuscar={async (values) => {
                    const parametros: ParametrosDetalhamentoAtividadesExecutores = {
                        IdCliente: values.cliente || [],
                        dataAte: values.dataAte,
                        dataDe: values.dataDe,
                        IdAtividade: values.IdAtividade || [],
                        IdEntrevistaGuiada: values.IdEntrevistaGuiada || [],
                        IdStatusSolicitacao: values.IdStatusSolicitacao || [],
                        atividadesAbandonadas: values.atividadesAbandonadas ? true : false,
                        atividadesGeraramRevisao: values.atividadesGeraramRevisao ? true : false,
                    };

                    const resposta = await ActionFeedback.processing({
                        title: "Gerando arquivo para download",
                        execution: detalhamentoAtividadesExecutores(parametros),
                        onError: () =>
                            ActionFeedback.error({
                                icon: "error",
                                titleText: "Ocorreu um erro durante a geração do arquivo.",
                            }),
                    });

                    if (resposta?.base64) {
                        await requestApi.download(
                            resposta.base64,
                            `DetalhamentoAtividades_${format(new Date(), "yyyyMMddHHmmss")}.xls`
                        );

                        ActionFeedback.info({ title: "Dados gerados com sucesso!" });
                    }
                }}
                initialValues={{
                    IdAtividade: [],
                    IdEntrevistaGuiada: [],
                    cliente: [],
                    dataDe: new Date(new Date().getFullYear(), new Date().getMonth() - 6, new Date().getDate()),
                    dataAte: undefined,
                    IdStatusSolicitacao: [],
                    atividadesAbandonadas: false,
                    atividadesGeraramRevisao: false,
                }}
                botaoFiltrarInline
                validationSchema={YupObject().shape({
                    dataDe: YupDate().typeError("Data inválida").max(new Date(), "A data não pode ser futura"),
                    dataAte: YupDate()
                        .typeError("Data inválida")
                        .max(new Date(), "A data não pode ser futura")
                        .test("maior", "A data final não pode ser anterior à data inicial", function (dataAte) {
                            const { dataDe } = this.parent;

                            if (dataAte && dataDe && dataAte < dataDe) {
                                return false;
                            }

                            return true;
                        })
                        .test("datediff", "Diferença de datas deve ser de no máximo 6 meses", function (dataAte) {
                            const { dataDe } = this.parent;

                            if (dataAte && dataDe) {
                                let timediff = dataAte.getTime() - (dataDe as Date).getTime();
                                let diff = timediff / (1000 * 3600 * 24);
                                return diff <= 180;
                            }

                            return true;
                        }),
                })}
            >
                <OmnijusFiltroCliente name="cliente" label="Cliente" isMulti />
                <OmnijusFiltroAtividade label="Atividade" name="IdAtividade" isMulti />
                <OmnijusFiltroTemplate name="IdEntrevistaGuiada" label="Template (Cards)" isMulti />
                <OmnijusRangeDateField nameDe="dataDe" nameAte="dataAte" label="Data da solicitação" />
                <OmnijusFiltroStatusSolicitacao
                    hideLabel={false}
                    isMulti={true}
                    label="Status da Atividade"
                    name="IdStatusSolicitacao"
                />
                <div style={{ marginTop: "0.5rem" }}>
                    <OmnijusCheckboxField
                        inline
                        label=""
                        name="atividadesAbandonadas"
                        options={[{ label: "Atividades abandonadas", value: "atividadesAbandonadas" }]}
                    />
                    <OmnijusCheckboxField
                        inline
                        label=""
                        name="atividadesGeraramRevisao"
                        options={[{ label: "Atividades que geraram revisão", value: "atividadesGeraramRevisao" }]}
                    />
                </div>
            </CardFiltros>
        </div>
    );
};
