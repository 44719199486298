import { RequestApi } from "@omnijus/common";
import { Format } from "../../lib/format";
import { CLIENT_ID } from "../../services/client-id";
import { FiltroAlegacao } from "./models/filtro-alegacao";
import { FiltroArea } from "./models/filtro-area";
import { FiltroClasse } from "./models/filtro-classe";
import { FiltroComarca } from "./models/filtro-comarca";
import { FiltroEstado } from "./models/filtro-estado";
import { FiltroFase } from "./models/filtro-fase";
import { FiltroMomento } from "./models/filtro-momento";
import { FiltroOrgao } from "./models/filtro-orgao";
import { FiltroOrigemFalha } from "./models/filtro-origem-falha";
import { FiltroPedido } from "./models/filtro-pedido";
import { FiltroRito } from "./models/filtro-rito";
import { FiltroSecao } from "./models/filtro-secao";
import { FiltroStatus } from "./models/filtro-status";
import { FiltroTipoResultadoSentenca } from "./models/filtro-tipo-resultado-sentenca";

const processoFiltroUrl = process.env.REACT_APP_BFF_OPERACIONAL_API_URL + "/api/Dominio/v1/Filtro";

const requestApi = new RequestApi(CLIENT_ID);

export const ProcessoFiltroService = {
    listarArea: async () => {
        let url = processoFiltroUrl + "/ListarArea";
        return await requestApi.get<FiltroArea[]>(url);
    },
    listarOrgao: async (idArea?: number, idEstado?: string) => {
        let url = processoFiltroUrl + "/ListarOrgaoIdAreaUF";
        let params = [];

        if (idArea) {
            params.push(`idArea=${idArea}`);
        }
        if (idEstado) {
            params.push(`uf=${idEstado}`);
        }

        return await requestApi.get<FiltroOrgao[]>(url + Format.arrayParamsToQueryString(params));
    },
    listarForo: async (idOrgao?: string, nomeComarca?: string) => {
        let url = processoFiltroUrl + "/ListarForoIdOrgaoNomeComarca";
        let params = [];

        if (idOrgao) {
            params.push(`idOrgao=${idOrgao}`);
        }
        if (nomeComarca) {
            params.push(`nomeComarca=${nomeComarca}`);
        }

        return await requestApi.get<FiltroOrgao[]>(url + Format.arrayParamsToQueryString(params));
    },
    listarComarca: async (idOrgao?: string) => {
        let url = processoFiltroUrl + "/ListarComarcaIdOrgao" + (idOrgao ? `?idOrgao=${idOrgao}` : "");
        return await requestApi.get<FiltroComarca[]>(url);
    },
    listarSecao: async (idUnidade?: string) => {
        let url = processoFiltroUrl + "/ListarSecaoIdUnidade" + (idUnidade ? `?idUnidade=${idUnidade}` : "");
        return await requestApi.get<FiltroSecao[]>(url);
    },
    listarEstado: async () => {
        let url = processoFiltroUrl + "/ListarUF";
        return await requestApi.get<FiltroEstado[]>(url);
    },
    listarRito: async (idArea?: number) => {
        let url = processoFiltroUrl + "/ListarRitoPorIdArea" + (idArea ? `?idArea=${idArea}` : "");
        return await requestApi.get<FiltroRito[]>(url);
    },
    listarFase: async (idRito?: number) => {
        let url =
            processoFiltroUrl + "/ListarFaseProcessoPorIdRitoProcesso" + (idRito ? `?idRitoProcesso=${idRito}` : "");
        return await requestApi.get<FiltroFase[]>(url);
    },
    listarClasse: async (idRito?: number) => {
        let url =
            processoFiltroUrl + "/ListarClasseProcessoPorIdRitoProcesso" + (idRito ? `?idRitoProcesso=${idRito}` : "");
        return await requestApi.get<FiltroClasse[]>(url);
    },
    listarMomento: async (idRito?: number, idFase?: number) => {
        let url = processoFiltroUrl + "/ListarMomentoProcessoPorIdRitoProcessoIdFaseProcesso";
        let params = [];

        if (idRito) {
            params.push(`idRitoProcesso=${idRito}`);
        }
        if (idFase) {
            params.push(`idFaseProcesso=${idFase}`);
        }

        return await requestApi.get<FiltroMomento[]>(url + Format.arrayParamsToQueryString(params));
    },
    listarAlegacao: async (idRito?: number) => {
        let url =
            processoFiltroUrl + "/ListarAlegacaoMeritoPorIdRitoProcesso" + (idRito ? `?idRitoProcesso=${idRito}` : "");
        return await requestApi.get<FiltroAlegacao[]>(url);
    },
    listarStatus: async () => {
        let url = processoFiltroUrl + "/ListarStatusProcesso";
        return await requestApi.get<FiltroStatus[]>(url);
    },
    listarTipoPedido: async () => {
        let url = processoFiltroUrl + "/TipoPedidoProcesso";
        return await requestApi.get<FiltroPedido[]>(url);
    },
    listarTipoResultadoSentenca: async () => {
        let url = processoFiltroUrl + "/ListarTipoResultadoSentenca";
        return await requestApi.get<FiltroTipoResultadoSentenca[]>(url);
    },
    listarOrigemFalha: async () => {
        let url = processoFiltroUrl + "/ListarOrigemFalha";
        return await requestApi.get<FiltroOrigemFalha[]>(url);
    },
};
