import { Loading, tempoDesde } from "@omnijus/common";
import DirectoryIcon from "../../../../assets/images/directory-icon.png";
import { Format } from "../../../../lib/format";
import React, { useEffect, useState } from "react";
import { DetalheProcesso } from "../../../../services/processo/models/detalhe-processo";
import { ProcessoDetalheService } from "../../../../services/processo/processo-detalhe-service";
import { Card } from "../../../../shared/card/card";
import "./cabecalho-processo.scss";

const Campo = (props: { chave: string; valor: string }) => (
    <div className="campo">
        <span className="chave">{props.chave}:</span>
        <span className="valor">{props.valor}</span>
    </div>
);

interface CabecalhoProcessoProps {
    idProcesso?: string;
    processo?: DetalheProcesso;
}

export const CabecalhoProcesso = ({ idProcesso, processo }: CabecalhoProcessoProps) => {
    const [promise, setPromise] = useState<Promise<DetalheProcesso | undefined>>();

    useEffect(() => {
        if (processo) {
            setPromise(Promise.resolve(processo));
            return;
        }
        if (!idProcesso) {
            console.error("CabecalhoProcesso sem idProcesso ou processo");
            return;
        }
        setPromise(ProcessoDetalheService.getDetalhes(idProcesso));
    }, [idProcesso, processo]);

    return (
        <Loading promise={promise}>
            {(processo) =>
                processo ? (
                    <div className="cabecalho-processo">
                        <Card
                            title={`Processo nº ${processo.numeroProcesso}`}
                            value={<div className="classe-processo">{`Classe: ${processo.descricaoTipoAcao}`}</div>}
                            icon={DirectoryIcon}
                            iconBackground="cor-acento-1-gradient"
                        >
                            <hr />
                            <div className="linha">
                                <Campo chave="Assunto" valor={processo.assunto.join(", ")} />
                            </div>
                            <div className="linha">
                                <Campo chave="Escritorio" valor={processo.nomeEscritorio ?? ""} />
                                <Campo chave="Tribunal" valor={processo.tribunal} />
                            </div>
                            <div className="linha">
                                <Campo
                                    chave="Autor"
                                    valor={
                                        processo.autor.documento
                                            ? `${processo.autor.nome} (${processo.autor.documento})`
                                            : processo.autor.nome
                                    }
                                />
                                <Campo
                                    chave="Advogado do autor"
                                    valor={
                                        processo.autor.advogado?.map((a) => a.nomeAdvogado).join(", ") ||
                                        "[não possui advogado adverso]"
                                    }
                                />
                            </div>
                            <div className="linha">
                                <Campo chave="Réu" valor={processo.reu?.nome} />
                                <Campo
                                    chave="Data de distribuição"
                                    valor={
                                        new Date(processo.dataHoraDistribuicao).toLocaleDateString() +
                                        " | " +
                                        tempoDesde(new Date(processo.dataHoraDistribuicao))
                                    }
                                />
                            </div>
                            <div className="linha">
                                <Campo chave="Valor da Causa" valor={Format.toBRL(processo.valorAcao)} />
                                <Campo chave="Foro / Vara" valor={`${processo.foro} / ${processo.vara}`} />
                            </div>
                            <div className="linha">
                                <Campo chave="Juiz" valor={processo.juiz} />
                            </div>
                            <div className="linha">
                                <Campo chave="Status" valor={processo.descricaoStatusProcesso} />
                                <Campo
                                    chave="Rito / Fase / Momento"
                                    valor={`${processo.descricaoRito} / ${processo.descricaoFase} / ${processo.descricaoMomento}`}
                                />
                            </div>
                            {processo.resumo && (
                                <div className="linha">
                                    <Campo chave="Resumo" valor={processo.resumo} />
                                </div>
                            )}
                            {processo.processoDetalheSentenca && (
                                <div className="linha">
                                    <Campo
                                        chave="Resultado da sentença"
                                        valor={processo.processoDetalheSentenca.descricaoTipoResultadoSentenca}
                                    />
                                </div>
                            )}
                        </Card>
                    </div>
                ) : (
                    <></>
                )
            }
        </Loading>
    );
};
