import { Loading } from "@omnijus/common";
import { Format } from "../../../../lib/format";
import React, { useEffect, useState } from "react";
import { ProcessoDetalheService } from "../../../../services/processo/processo-detalhe-service";
import { Rotulo, RotuloProps } from "../../../../shared/rotulo-movimentacao/rotulo-movimentacao";
import { AlertPrestadorProfile } from "./alert-prestador-profile";
import "./movimentacao-e-atividade.scss";

interface TimelineItem {
    data: Date;
    tipo: string;
    titulo: string;
    children: JSX.Element;
    rotulos?: RotuloProps[];
}

const dateToTempoMovimentacao = (tempo: Date) =>
    "" + tempo.toLocaleDateString("pt-BR") + " - " + tempo.toLocaleTimeString("pt-BR");

const Timeline = (props: { items: TimelineItem[] }) => {
    props.items.sort((a, b) => b.data.getTime() - a.data.getTime());
    const linhasCards = props.items.map((item, ii) => cardItem(item, ii));

    function cardItem(item: TimelineItem, key: number) {
        return (
            <div key={key} className={"timeline-item " + item.tipo}>
                <div className={"card-movimentacao"}>
                    <div className="bolinha-timeline"></div>
                    <div className="movimentacao-data">{dateToTempoMovimentacao(item.data)}</div>
                    <div className="movimentacao-titulo">{item.titulo}</div>
                    {item.children}
                    <div className="movimentacao-rotulos">
                        {item.rotulos?.map((r) => (
                            <Rotulo key={r.nome} {...r} />
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="timeline">
            <div className="timeline-line">
                <div className="hoje">Hoje</div>
                <div className="vertical-line" />
            </div>
            <div className="timeline-items">{linhasCards}</div>
        </div>
    );
};

export const MovimentacaoEAtividade = (props: { idProcesso: string }) => {
    const [promise, setPromise] = useState<Promise<any[]>>();

    const getTimelineItems = async (idProcesso: string) => {
        const [movimentos, atividades] = await Promise.all([
            ProcessoDetalheService.getMovimentos(idProcesso, false),
            ProcessoDetalheService.getAtividades(idProcesso),
        ]);

        let timelineItems: any[] = [];

        if (atividades) {
            timelineItems = timelineItems.concat(
                atividades.map((a) => ({
                    data: new Date(a.dataExecucao),
                    titulo: a.nomeAtividade.toUpperCase(),
                    tipo: "atividade",
                    subtitulo: a.nomePrestadorServico,
                    children: (
                        <div className="executor-atividade">
                            {a.idPrestadorServicoUsuarioLogin ? (
                                <AlertPrestadorProfile id={a.idPrestadorServicoUsuarioLogin}>
                                    {a.nomePrestadorServico}
                                </AlertPrestadorProfile>
                            ) : (
                                a.nomePrestadorServico
                            )}
                            {a.numeroEntidadeClasse !== null && <p>OAB: {a.numeroEntidadeClasse}</p>}
                            {a.idPrestadorServicoUsuarioLogin && <p>Valor: {Format.toBRL(a.valorExecucao)}</p>}
                        </div>
                    ),
                }))
            );
        }
        if (movimentos) {
            timelineItems = timelineItems.concat(
                movimentos.map((m) => ({
                    ...m,
                    data: new Date(m.data),
                    titulo: m.movimentacao,
                    tipo: "movimentacao",
                    children: <span>{m.fase}</span>,
                }))
            );
        }

        return timelineItems;
    };

    useEffect(() => {
        setPromise(getTimelineItems(props.idProcesso));
    }, [props.idProcesso]);

    return (
        <Loading promise={promise}>
            {(timelineItems) =>
                timelineItems ? (
                    <div className="movimentacao-processo">
                        <h3 className="titulo-destaque">
                            <div>Movimentação</div>
                            <div className="titulo-atividades">Atividades</div>
                        </h3>
                        <div className="card-body-scrollable">
                            <Timeline items={timelineItems} />
                        </div>
                    </div>
                ) : (
                    <></>
                )
            }
        </Loading>
    );
};
