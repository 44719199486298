import { Loading } from "@omnijus/common";
import { useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { ProcessoFiltroService } from "../../../services/filtros/filtros-processo-service";
import { FiltroRito } from "../../../services/filtros/models/filtro-rito";
import { OmnijusSelectField } from "../fields/omnijus-select-field";

const filtroAreaName = "IdAreaAtuacaoOrgao";

interface OmnijusFiltroRitoProps {
    hideLabel?: boolean;
    name?: string;
    label?: string;
    reloadOnChangeName?: string;
}

export const OmnijusFiltroRito = (props: OmnijusFiltroRitoProps) => {
    const formikContext = useFormikContext<any>();
    const { setFieldValue } = formikContext;
    const idArea = formikContext.values[props.reloadOnChangeName || filtroAreaName];
    const [promiseOpcoesRito, setPromiseOpcoesRito] = useState<Promise<FiltroRito[] | undefined>>();
    const isFirstRun = useRef(true);
    const name = props.name || "IdRitoProcesso";

    useEffect(() => {
        isFirstRun.current || setFieldValue(name, undefined);
        setPromiseOpcoesRito(ProcessoFiltroService.listarRito(idArea));
        isFirstRun.current = false;
    }, [idArea, name, setFieldValue]);

    return (
        <Loading promise={promiseOpcoesRito}>
            {(opcoesRito) => (
                <OmnijusSelectField
                    name={name}
                    label={props.hideLabel ? undefined : props.label || "Rito"}
                    options={opcoesRito
                        ?.map((o) => ({
                            value: o.id.toString(),
                            label: o.value.toString(),
                        }))
                        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
                />
            )}
        </Loading>
    );
};
