import { OmnijusCard } from "@omnijus/common";
import React from "react";
import {
    Resposta,
    RespostaHumanizada,
    RespostaOperand,
} from "../../../services/movimentacao-assistida/models/informacao-auxiliar-revisao";
import { OperandNode, OperandTree } from "./operand-tree";
import "./resposta-revisao.scss";

export const RespostasRevisao = (props: { respostas: RespostaHumanizada[] }) => {
    // Guarda a hierarquia dos operadores junto com o id e o nome
    const operandsTree = new OperandTree();

    // Gera os nós de operandos de cada resposta
    const processRespostaToOperand = (resposta: Resposta) => {
        //let node = new OperandNode(resposta.operandId, resposta.operandName);
        let node = new OperandNode(resposta.operandId, resposta.question);

        if (resposta.operand === RespostaOperand.OBJECT && resposta.fields) {
            node.children = resposta.fields.map((f) => processRespostaToOperand(f));
        }

        return node;
    };

    // Busca o valor da resposta baseado em um id de operando
    const getRespostaOperandValue = (respostas: Resposta[], operandId: string): Resposta | undefined => {
        try {
            let result = respostas.find((r) => r.operandId === operandId);

            if (!result) {
                result = respostas
                    .map((r) => {
                        if (r.fields) {
                            return getRespostaOperandValue(r.fields, operandId);
                        } else {
                            return undefined;
                        }
                    })
                    .find((r) => r && r.operandId === operandId);
            }

            return result;
        } catch (err) {
            console.error("Erro ao obter a resposta do operador", err);
            console.error("Respostas", respostas);
            console.error("OperandId", operandId);
            return undefined;
        }
    };

    // Itera por todas as respostas para criar cada coluna
    const getRespostasTemplate = (respostas: RespostaHumanizada[], operand: OperandNode, offsetRow: boolean) => {
        let valoresRespostas = respostas.map((r) => getRespostaOperandValue(r.fields, operand.id));
        let divergentes = false;
        let valorRespostaAnterior = valoresRespostas[0];

        for (let valorResposta of valoresRespostas) {
            divergentes = valorRespostaAnterior?.value !== valorResposta?.value;
            valorRespostaAnterior = valorResposta;
        }

        return (
            <tr key={operand.id} className={(offsetRow ? "offset-row" : "") + (divergentes ? " row-divergente" : "")}>
                <td>{operand.name}</td>
                {valoresRespostas.map((v, i) => (
                    <td key={v?.operandId + i.toString()}>
                        {v?.value === "true" ? "Sim" : v?.value === "false" ? "Não" : v?.value}
                    </td>
                ))}
            </tr>
        );
    };

    // Gerar as linhas da tabela baseando-se na hierarquia dos operadores
    const getBodyTemplate = (operands: OperandNode[], depth: number): JSX.Element[] => {
        return operands.map((o) =>
            o.children.length > 0 ? (
                <React.Fragment key={o.id}>
                    <tr>
                        <td colSpan={3} className="header-row">
                            {o.name}
                        </td>
                    </tr>
                    {getBodyTemplate(o.children, 1)}
                </React.Fragment>
            ) : (
                getRespostasTemplate(props.respostas, o, depth > 0)
            )
        );
    };

    // Itera por todas as respostas e pede a criação dos nós
    props.respostas.forEach((r) => {
        r.fields.forEach((r) => operandsTree.addNode(processRespostaToOperand(r)));
    });

    return (
        <OmnijusCard
            //header={<h2>Respostas</h2>}
            className="no-padding"
            body={
                <table className="respostas-revisao">
                    <colgroup>
                        <col span={1} />
                        <col span={props.respostas.length} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th className="text-left">Respostas</th>
                            {props.respostas.map((r, index) => (
                                <th key={index}>{r.descricao}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>{getBodyTemplate(operandsTree.toArray(), 0)}</tbody>
                </table>
            }
        />
    );
};
