import { ButtonPrimary, ButtonSecondary } from "@omnijus/common";
import { Form, Formik } from "formik";
import { ActionFeedback } from "@omnijus/common";
import { BusinessException } from "../../../lib/BusinessException";
import React, { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { AudienciaService } from "../../../services/audiencia/audiencia-service";
import { InformacoesPoliticaEProcesso } from "../../../services/audiencia/models/informacoes-politica-processo";
import { LocalAudiencia } from "../../../services/audiencia/models/local-audiencia";
import { ModalidadeAudiencia } from "../../../services/audiencia/models/modalidade-audiencia";
import { ParametroInclusaoAudiencia } from "../../../services/audiencia/models/parametro-inclusao-audiencia";
import { InclusaoAudienciaDadosPrincipais } from "./inclusao-audiencia-dados-principais";
import { InclusaoAudienciaEnderecamento } from "./inclusao-audiencia-enderecamento";
import { InclusaoAudienciaInformacoesParceiro } from "./inclusao-audiencia-informacoes-parceiro";
import { validationSchema } from "./inclusao-audiencia-validation-schema";
import styles from "./inclusao-audiencia.module.scss";

export type FormularioInclusaoAudiencia = Partial<ParametroInclusaoAudiencia> & {
    nomeCliente: string;
    nomeTribunal: string;
    nomeForo: string;
    nomeVara: string;
    etapaAndamento: string;
};

export const InclusaoAudiencia = () => {
    const [informacoesCriacaoAudiencia, setInformacoesCriacaoAudiencia] = useState<InformacoesPoliticaEProcesso>();
    const validation = useMemo(() => {
        if (!informacoesCriacaoAudiencia) {
            return undefined;
        }

        return validationSchema(informacoesCriacaoAudiencia);
    }, [informacoesCriacaoAudiencia]);
    const history = useHistory();
    const onCancelar = async () => {
        const confirmed = await ActionFeedback.confirm(
            "Deseja mesmo cancelar a inclusão da audiência?",
            "Cancelar a inclusão da audiência",
            "Continuar a inclusão"
        );

        if (confirmed) {
            history.goBack();
        }
    };

    const thenObterInformacoes = useCallback(async (informacoes?: InformacoesPoliticaEProcesso) => {
        if (!informacoes?.processo) {
            await ActionFeedback.error({ titleText: "Não foi possível encontrar informações do processo" });

            return;
        }

        setInformacoesCriacaoAudiencia(informacoes);
    }, []);

    return (
        <div className={styles["criar-audiencia"]}>
            <h2>Inclusão de Audiência</h2>
            <Formik
                enableReinitialize={true}
                initialValues={
                    {
                        numeroProcesso: informacoesCriacaoAudiencia?.processo?.numero || "",
                        nomeCliente: "",
                        nomeTribunal: "",
                        nomeForo: "",
                        nomeVara: "",
                        nomeLocal: informacoesCriacaoAudiencia?.processo?.nomeTribunal || "",
                        modalidade: ModalidadeAudiencia.Presencial,
                        linkAudiencia: "",
                        local: LocalAudiencia.MesmoLocal,
                        quantidadeAudiencista:
                            informacoesCriacaoAudiencia?.politicaAudiencia?.quantidadeAudiencista || null,
                        quantidadePreposto: informacoesCriacaoAudiencia?.politicaAudiencia?.quantidadePreposto || null,
                        etapaAndamento: "",
                        idTipoAudiencia: undefined,
                        dataHora: undefined,
                        idStatusAudiencia: undefined,
                    } as FormularioInclusaoAudiencia
                }
                validationSchema={validation}
                onSubmit={async (parametro) => {
                    await ActionFeedback.processing({
                        title: "Adicionando audiência",
                        execution: AudienciaService.criar(parametro as ParametroInclusaoAudiencia),
                        onError: async (error) => {
                            const exception = JSON.parse(error.message);

                            if ("title" in exception && "errors" in exception) {
                                const businessException = exception as BusinessException;

                                await ActionFeedback.error({
                                    icon: "warning",
                                    title: businessException.title,
                                    footer: `
                                        <div class="${styles["erros"]}">
                                            ${Object.entries(businessException.errors)
                                                .map(
                                                    (dicionario) =>
                                                        `<div class="${styles["item"]}">
                                                        <span class="${styles["label"]}">${dicionario[0]}</span>
                                                        <span class="${styles["value"]}">${dicionario[1].join(
                                                            "; "
                                                        )}</span>
                                                    </div>`
                                                )
                                                .join("")}
                                        </div>`,
                                });
                            } else {
                                await ActionFeedback.error({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Ocorreu um erro",
                                });
                            }
                        },
                    });

                    ActionFeedback.info({
                        title: "Audiência adicionada com sucesso",
                    });

                    history.goBack();
                }}
            >
                <Form className={styles["cards-wrapper"]}>
                    <InclusaoAudienciaEnderecamento
                        processo={informacoesCriacaoAudiencia?.processo}
                        onLimparProcesso={() => setInformacoesCriacaoAudiencia(undefined)}
                        onPesquisarProcesso={(numeroProcesso) => {
                            if (numeroProcesso.length === 25) {
                                ActionFeedback.processing({
                                    title: "Buscando processo",
                                    execution: AudienciaService.obterInformacoesPoliticaProcesso({
                                        numeroProcesso: numeroProcesso,
                                    }),
                                }).then(thenObterInformacoes);
                            }
                        }}
                    />
                    {informacoesCriacaoAudiencia !== undefined ? (
                        <>
                            <InclusaoAudienciaDadosPrincipais
                                processo={informacoesCriacaoAudiencia.processo}
                                politicaAudiencia={informacoesCriacaoAudiencia!.politicaAudiencia}
                            />
                            <InclusaoAudienciaInformacoesParceiro />
                            <div className={styles["actions"]}>
                                <ButtonSecondary onClick={onCancelar}>Cancelar</ButtonSecondary>
                                <ButtonPrimary type="submit">Salvar</ButtonPrimary>
                            </div>
                        </>
                    ) : (
                        <div className={styles["actions"]}>
                            <ButtonSecondary onClick={onCancelar}>Cancelar</ButtonSecondary>
                        </div>
                    )}
                </Form>
            </Formik>
        </div>
    );
};
