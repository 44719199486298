import classNames from "classnames";
import { useField } from "formik";
import React from "react";
import CurrencyInput from "react-currency-input-field";
import styles from "../field.module.scss";

interface OmnijusCurrencyFieldProps {
    name: string;
    label?: string;
    disabled?: boolean;
    placeholder?: string;
}

export const OmnijusCurrencyField = (props: OmnijusCurrencyFieldProps) => {
    const [, meta, helpers] = useField(props.name);
    return (
        <div className={classNames([styles["field-control"]])}>
            <label htmlFor={props.name}>{props.label}</label>
            <CurrencyInput
                id={props.name}
                intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                name={props.name}
                disabled={props.disabled}
                placeholder={props.placeholder}
                allowDecimals
                groupSeparator="."
                decimalSeparator=","
                decimalsLimit={2}
                onValueChange={(value) => {
                    helpers.setValue(
                        value === undefined || value === "" || value === null
                            ? undefined
                            : parseFloat(value.replace(",", "."))
                    );
                }}
                className={classNames(styles["input"], { [styles["input-error"]]: meta.touched && meta.error })}
            />
            <div className={styles["error"]}>{meta.touched && meta.error ? meta.error : ""}</div>
        </div>
    );
};
