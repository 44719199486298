import { ActionFeedback } from "@omnijus/common";
import { ReactComponent as Checked } from "../../../assets/images/checkmark-icon.svg";
import { ReactComponent as ExclamationOutlined } from "../../../assets/images/error-icon-outline.svg";
import { format } from "date-fns";
import { Field, Form, Formik } from "formik";
import React from "react";
import { AnomaliaService } from "../../../services/anomalia/anomalia-service";
import { DetalheAnomaliaOutrosPedidosLiminar } from "../../../services/anomalia/models/detalhe-anomalia-outros-pedidos-liminar";
import { ButtonPrimarySmall } from "../../../shared/form/fields/omnijus-button-primary";
import * as Yup from "yup";
import styles from "./detalhamento-anomalia.module.scss";

interface DetalhamentoOutrosPedidosLiminarProps {
    detalhe: DetalheAnomaliaOutrosPedidosLiminar;
    onResolved: () => void;
}

export const DetalhamentoOutrosPedidosLiminar = (props: DetalhamentoOutrosPedidosLiminarProps) => {
    return (
        <div className={styles["acao-anomalia"]}>
            <h4>Sobre Fluxo</h4>
            {props.detalhe.dataHoraResolucao ? 
                <div className={styles["acao-tomada"]}>
                    <Checked className={styles["icone-acao-tomada"]} /> 
                    <p className={styles["texto"]}>
                        O usuário <span className={styles["highlight"]}>{props.detalhe.nomeUserResolucao}</span>&nbsp;
                        tomou a ação de <span className={styles["highlight"]}>{props.detalhe.seguirFluxo ? "Seguir fluxo" : "Cancelar fluxo"}</span>&nbsp;
                        em <span className={styles["highlight"]}>{format(new Date(props.detalhe.dataHoraResolucao), "dd/MM/yyyy HH:mm")}</span>
                    </p>
                </div>
                : 
                <div className={styles["tomar-acao"]}>
                    <div className={styles["titulo-acao"]}>
                        <ExclamationOutlined className={styles["icone-acao-nao-tomada"]} />
                        <p className={styles["texto"]}>Nenhuma ação tomada até o momento</p>
                    </div>
                    <Formik
                        initialValues={{
                            seguirFluxo: String(props.detalhe.seguirFluxo)
                        }}
                        validationSchema={Yup.object().shape({
                            seguirFluxo: Yup.string().required()
                        })}
                        onSubmit={async values => {
                            await ActionFeedback.processing({
                                title: "Resolvendo fluxo",
                                execution: AnomaliaService.resolverOutrosPedidosLiminar(props.detalhe.id, {
                                    seguirFluxo: values.seguirFluxo === "true"
                                })
                            });

                            props.onResolved();
                        }}
                    >
                        <Form className={styles["campos-resolucao"]}>
                            <div className={styles["radio-group"]}>
                                <label>
                                    <Field name="seguirFluxo" type="radio" value="true" className={styles["no-margin"]} />
                                    Seguir fluxo
                                </label>
                                <label>
                                    <Field name="seguirFluxo" type="radio" value="false" className={styles["no-margin"]} />
                                    Cancelar fluxo
                                </label>
                            </div>
                            <div className={`${styles["acoes"]} ${styles["acao-coluna"]}`}>
                                <ButtonPrimarySmall>Resolver</ButtonPrimarySmall>
                            </div>
                        </Form>
                    </Formik>
                </div>}
        </div>
    );
}